import axios from 'axios';
import store from '@/store/store'
import Vue from 'vue'
import router from '@/router/router'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// 요청 인터셉터
axios.interceptors.request.use(config => {
    // console.log(`Request: ${config.url}`);
    if (config.headers) {
        const headers = config.headers[config.method];
        const json = JSON.stringify(headers, null, 2);
        console.log(json);
        console.log('--------------------------------------------------------------------------------');
    }
    if (config.data instanceof FormData || config.data instanceof URLSearchParams) {
        const data = [...config.data.entries()].reduce((acc, [k, v]) => ({...acc, [k]: v}), {});
        const json = JSON.stringify(data, null, 2);
        console.log(json);
    }

    if(config.url != '/user/getLoginCheck' && config.url != '/user/login') {
      axios.get('/user/getLoginCheck').then((res) => {
        
        if(res.data.loginYn == 'N') {
          store.state.session.compSno = '';
          store.state.session.userSno = '';
          router.push("/login");
        }
  
      }).catch(
        function (error) {
        console.log(error);
        }
      )
    }

    return config;
}, error => {
    console.error('Request Error:', error);
    return Promise.reject(error);
});

// 응답 인터셉터
axios.interceptors.response.use(response => {
    console.log(`Response: ${response.config.url}`);
    if (response.headers) {
        const headers = response.headers;
        const json = JSON.stringify(headers, null, 2);
        console.log(json);
        console.log('--------------------------------------------------------------------------------');
    }
    const json = JSON.stringify(response.data, null, 2);
    console.log(json);
    return response;
}, error => {
    console.error('Response Error:', error);
    return Promise.reject(error);
});

export default axios;
