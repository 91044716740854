<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>일별/월별 조회</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="stmtDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="byDay">일계표</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="byMonth">월계표</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">
              <v-col cols="10" v-show="stmtDiv == 'byDay'">
                <v-row dense align="center">
                  <v-col cols="1"><div class=""><span>조회수 : {{inqryLvlByDay == 'smpl' ? filteredSmplByDayStmtCount : filteredDtlByDayStmtCount}} 건</span></div></v-col>
                  <v-col cols="7" class="d-flex justify-end align-center hei-40p">
                    <v-row dense align="center">
                      <v-col cols="2"><div class="f-r"><span>조회일자</span></div></v-col>
                      <v-col cols="2">
                        <date-picker v-model="srchDtByDay"></date-picker>
                      </v-col>
                      <v-col cols="1"></v-col>
                      <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldSalesByDay" hide-details dense label="매출"></v-checkbox>
                      </v-col>
                      <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldPurchByDay" hide-details dense label="매입"></v-checkbox>
                      </v-col>
                      <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldColctByDay" hide-details dense label="수금"></v-checkbox>
                      </v-col>
                      <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldPayByDay" hide-details dense label="지급"></v-checkbox>
                      </v-col>
                      <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldExpnsByDay" hide-details dense label="경비"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4" class="d-flex justify-start align-center hei-40p">
                    <v-radio-group
                      v-model="inqryLvlByDay"
                      row
                      hide-details
                      dense
                      class="ma-0 pa-0"
                      >
                      <v-radio 
                        :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                        v-for="item in inqryLvlList"
                        >
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" v-show="stmtDiv == 'byMonth'">
                <v-row dense align="center">
                  <!-- <v-col cols="1"><div class=""><span>조회수 : {{smplByDayStmts.length}} 건</span></div></v-col> -->
                  <v-col cols="4" class="d-flex justify-end align-center hei-40p">
                    <v-row dense align="center">
                      <v-col cols="2"><div class=""><span>조회기간</span></div></v-col>
                      <v-col cols="4">
                        <date-picker v-model="srchBgnDt"></date-picker>
                      </v-col>
                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                      <v-col cols="4">
                        <date-picker v-model="srchEndDt"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <!-- <v-col cols="3" class="d-flex justify-start align-center hei-40p">
                    <v-radio-group
                      v-model="inqryLvl"
                      row
                      hide-details
                      dense
                      class="ma-0 pa-0"
                      >
                      <v-radio 
                        :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                        v-for="item in inqryLvlList"
                        >
                      </v-radio>
                    </v-radio-group>
                  </v-col> -->
                </v-row>
              </v-col>
                               
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" v-show="stmtDiv == 'byMonth'" @click="onStmtInqry">조회</v-btn>
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>

            <!-- 일계표 간단 -->
            <v-row dense align="center" v-show="stmtDiv == 'byDay' && inqryLvlByDay == 'smpl'">              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredSmplByDayStmts" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexSmplByDayInitialized"
                    :itemsSourceChanged="onFlexSmplByDayItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    :allowMerging="'Cells'"
                    :scrollPositionChanged="onFlexSmplByDayScrollPositionChanged"
                    >
                    <wj-flex-grid-column :header="'구분'" :binding="'div_nm'" :width="60" align="center" />
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="200" />
                    <wj-flex-grid-column :header="'품목'" :binding="'sitem_nm'" :width="150" />
                    <wj-flex-grid-column :header="'결제방법'" :binding="'pay_mthd_nm'" :width="100" />
                    <wj-flex-grid-column :header="'수량'" :binding="'sum_qty'" :width="50" align="right"/>
                    <wj-flex-grid-column :header="'중량'" :binding="'sum_wegt'" :width="100" align="right" />
                    <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="150" align="right"/>
                    <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="150" align="right" />
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="150" align="right" />
                    <wj-flex-grid-column :header="'계산서'" :binding="'txbil_issue_yn'" :width="100" align="center"/>
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>

            <!-- 일계표 상세 -->
            <v-row dense align="center" v-if="stmtDiv == 'byDay' && inqryLvlByDay == 'dtl'">              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredDtlByDayStmts" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexDtlByDayInitialized"
                    :itemsSourceChanged="onFlexDtlByDayItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    :scrollPositionChanged="onFlexSmplByDayScrollPositionChanged"
                    >
                    <wj-flex-grid-column :header="'구분'" :binding="'div_nm'" :width="60" align="center"/>
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="200" />
                    <wj-flex-grid-column :header="'품목'" :binding="'sitem_nm'" :width="150" />
                    <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="150" />
                    <wj-flex-grid-column :header="'결제방법'" :binding="'pay_mthd_nm'" :width="100" />
                    <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="50" align="right"/>
                    <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="100" align="right"/>
                    <wj-flex-grid-column :header="'단가'" :binding="'sitem_unit_price'" :width="120" align="right"/>
                    <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="150" align="right"/>
                    <!-- <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="150" align="right"/> -->
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="150" align="right"/>
                    <wj-flex-grid-column :header="'계산서'" :binding="'txbil_issue_yn'" :width="100" align="center"/>
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>

            <!-- 월계표(기간) -->
            <v-row dense align="center" v-if="stmtDiv == 'byMonth'">              
              <v-col cols="6">
                <div><span style="font-weight: bold;">[ 금일계/전일계 ]</span></div>
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="byMonthSumryStmts" 
                    :selectionMode="'ListBox'"
                    style="height:300px;"
                    :initialized="onFlexByMonthSumryInitialized"
                    :itemsSourceChanged="onFlexByMonthSumryItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    :allowMerging="'ColumnHeaders'"
                    :autoGenerateColumns="false"
                    >
                    <wj-flex-grid-column :header="'구분'" :binding="'sales_purch_div_nm'" :allowMerging="true" width="1*" align="center"/>
                    <wj-flex-grid-column :header="'중량'" :binding="'prev_sum_wegt'" width="2*" />
                    <wj-flex-grid-column :header="'금액'" :binding="'prev_sum_amt'" width="3*" />
                    <wj-flex-grid-column :header="'중량'" :binding="'end_sum_wegt'" width="2*" />
                    <wj-flex-grid-column :header="'금액'" :binding="'end_sum_amt'" width="3*" />
                    <wj-flex-grid-column :header="'중량'" :binding="'sum_sum_wegt'" width="2*" />
                    <wj-flex-grid-column :header="'금액'" :binding="'sum_sum_amt'" width="3*" />
                  </wj-flex-grid>
                </div>
              </v-col>
              <v-col cols="6">
                <div><span style="font-weight: bold;">[ 거래처별 매출 내역 ]</span></div>
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredByMonthSalesStmts" 
                    :selectionMode="'ListBox'"
                    style="height:300px;"
                    :initialized="onFlexByMonthSalesInitialized"
                    :itemsSourceChanged="onFlexByMonthSalesItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    >
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" width="2*" />
                    <wj-flex-grid-column :header="'구분'" :binding="'sales_purch_div_nm'" width="0.6*" align="center"/>
                    <wj-flex-grid-column :header="'중량'" :binding="'sum_wegt'" width="1*" />
                    <wj-flex-grid-column :header="'공급가액'" :binding="'sum_suply_amt'" width="1.5*" />
                    <wj-flex-grid-column :header="'부가세액'" :binding="'sum_vat_amt'" width="1.5*" />
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_sum_amt'" width="1.5*" />
                  </wj-flex-grid>
                </div>
              </v-col>
              <v-col cols="6">
                <div><span style="font-weight: bold;">[ 강종별 매출 내역 ]</span></div>
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="byMonthSkindStmts" 
                    :selectionMode="'ListBox'"
                    style="height:300px;"
                    :initialized="onFlexByMonthSkindInitialized"
                    :itemsSourceChanged="onFlexByMonthSkindItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    >
                    <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="2*" />
                    <wj-flex-grid-column :header="'중량'" :binding="'sum_wegt'" width="1*" align="right"/>
                    <wj-flex-grid-column :header="'공급가액'" :binding="'sum_suply_amt'" width="1.5*" align="right"/>
                    <wj-flex-grid-column :header="'부가세액'" :binding="'sum_vat_amt'" width="1.5*" align="right"/>
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_sum_amt'" width="1.5*" align="right"/>
                  </wj-flex-grid>
                </div>
              </v-col>
              <v-col cols="6">
                <div><span style="font-weight: bold;">[ 거래처별 매입 내역 ]</span></div>
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredByMonthPurchStmts" 
                    :selectionMode="'ListBox'"
                    style="height:300px;"
                    :initialized="onFlexByMonthPurchInitialized"
                    :itemsSourceChanged="onFlexByMonthPurchItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    >
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" width="2*" />
                    <wj-flex-grid-column :header="'구분'" :binding="'sales_purch_div_nm'" width="0.6*" align="center"/>
                    <wj-flex-grid-column :header="'중량'" :binding="'sum_wegt'" width="1*" />
                    <wj-flex-grid-column :header="'공급가액'" :binding="'sum_suply_amt'" width="1.5*" />
                    <wj-flex-grid-column :header="'부가세액'" :binding="'sum_vat_amt'" width="1.5*" />
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_sum_amt'" width="1.5*" />
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB, SUM_BG_COLOR} from "@/common/constant";

const TEXT_SALES = '[매  입]';
const TEXT_PURCH = '[매  출]';
const TEXT_COLCT = '[수  금]';
const TEXT_PAY = '[지  급]';
const TEXT_SUM = '[합  계]';

let __extends =
  (this && this.__extends) ||
  (function() {
    let extendStatics = function(d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function(d, b) {
            d.__proto__ = b;
          }) ||
        function(d, b) {
          for (let p in b) if (b.prototype.hasOwnProperty.call(p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function(d, b) {
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();

let CustomMergeManager = (function(_super) {
  __extends(CustomMergeManager, _super);
  function CustomMergeManager() {
      return _super.call(this) || this;
  }
  CustomMergeManager.prototype.getMergedRange = function(panel, r, c, clip) {
    if(clip === void 0) {
      clip = true;
    }
    let rng = new wjGrid.CellRange(r, c);

    // console.log("getMergedRange", panel, r, c, panel.columns[c].binding);
    // if(panel.columns[c].binding === 'div_nm' && (panel.getCellData(rng.row, c, true) === TEXT_SALES || panel.getCellData(rng.row, c, true) === TEXT_PURCH)) {
    //   rng.col2 = panel.columns.length - 1;
    //   console.log("getMergedRange 2", rng);
    // }
    
    // row 의 column 병합 : 가로
    for(let i = rng.col; i < panel.columns.length - 1; i++) {
      // console.log("panel.getCellData(rng.row, i, true)", panel.getCellData(rng.row, i, true));
      if((panel.getCellData(rng.row, i + 1, true) == null || panel.getCellData(rng.row, i + 1, true) == '' || panel.getCellData(rng.row, i + 1, true) == 0) ||  // null 인 경우 병합 제외
         (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i + 1, true)))
        break;
      rng.col2 = i + 1;
    }
    for(let i = rng.col; i > 0; i--) {
      if((panel.getCellData(rng.row, i - 1, true) == null || panel.getCellData(rng.row, i - 1, true) == '' || panel.getCellData(rng.row, i - 1, true) == 0) ||  // null 인 경우 병합 제외
         (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i - 1, true)))
        break;
      rng.col = i - 1;
    }
    // column 의 row 병합 : 세로
    // for(let i = rng.row; i < panel.rows.length - 1; i++) {
    //   if(panel.getCellData(i, rng.col, true) != panel.getCellData(i + 1, rng.col, true))
    //     break;
    //   rng.row2 = i + 1;
    // }
    // for(let i = rng.row; i > 0; i--) {
    //   if(panel.getCellData(i, rng.col, true) != panel.getCellData(i - 1, rng.col, true))
    //     break;
    //   rng.row = i - 1;
    // }
    return rng;
  };
  return CustomMergeManager;
})(wjGrid.MergeManager);

export default {
  components: {datePicker},
  data(){
    return{
      stmtDiv: "byDay",

      // 일계표
      // srchDtByDay: '2023-07-28',
      srchDtByDay: dayjs().format('YYYY-MM-DD'),

      incldSalesByDay: true,
      incldPurchByDay: true,
      incldColctByDay: true,
      incldPayByDay: true,
      incldExpnsByDay: true,

      inqryLvlByDay: "smpl",

      smplByDayStmts: [],
      dtlByDayStmts: [],

      flexSmplByDay: null,    // 일계표 간단
      flexDtlByDay: null,    // 일계표 상세

      // 월계표
      srchBgnDt: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      // incldSalesByMonth: true,
      // incldPurchByMonth: true,
      // incldColctByMonth: true,
      // incldPayByMonth: true,
      // incldExpnsByMonth: true,

      // inqryLvlByMonth: "smpl",

      inqryLvlList: [
        {cd: "smpl", nm: "간단조회"}, 
        {cd: "dtl", nm: "상세조회"}, 
        // {cd: "intg", nm: "통합조회"}
      ],

      byMonthCustrStmts: [],
      // byMonthSalesStmts: [],
      // byMonthPurchStmts: [],
      byMonthSumryStmts: [],
      byMonthSkindStmts: [],

      flexByMonthSales: null,    // 월계표(기간/매출)
      flexByMonthPurch: null,    // 월계표(기간/매입)
      flexByMonthSumry: null,    // 월계표(요악)
      flexByMonthSkind: null,    // 월계표(강종)
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.byDayByMonthStmt.code);
    },
    filteredSmplByDayStmts() {
      return this.smplByDayStmts.filter((e) =>
        (this.incldSalesByDay && e.div_odr == 1) ||
        (this.incldPurchByDay && e.div_odr == 2) ||
        (this.incldColctByDay && e.div_odr == 3) ||
        (this.incldPayByDay && e.div_odr == 4)
      )
    },
    filteredSmplByDayStmtCount() {
      return this.filteredSmplByDayStmts.filter((e) => e.cp_odr == 2).length;
    },
    filteredDtlByDayStmts() {
      return this.dtlByDayStmts.filter((e) =>
        (this.incldSalesByDay && e.div_odr == 1) ||
        (this.incldPurchByDay && e.div_odr == 2) ||
        (this.incldColctByDay && e.div_odr == 3) ||
        (this.incldPayByDay && e.div_odr == 4)
      )
    },
    filteredDtlByDayStmtCount() {
      return this.filteredDtlByDayStmts.filter((e) => e.cp_odr == 2).length;
    },
    filteredByMonthSalesStmts() {
      console.log("filteredByMonthSalesStmts");
      return this.byMonthCustrStmts.filter((e) =>
        (e.sales_purch_div == 'sales')
      )
    },
    filteredByMonthPurchStmts() {
      return this.byMonthCustrStmts.filter((e) =>
        (e.sales_purch_div == 'purch')
      )
    }
  },
  watch: {
    srchDtByDay: {
      immediate: true,
      handler: function () {
        this.loadByDayStmts();
      },
    },
    // srchDtByMonth: {
    //   immediate: true,
    //   handler: function () {
    //     this.loadByMonthStmts();
    //   },
    // },
  },
  created() {
    
  },
  mounted() {
    // this.loadByDayStmts(); // watch에서 날짜 설정 시 조회됨
    this.loadByMonthStmts();
  },
  methods: {
    onFlexSmplByDayInitialized(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // flex.select(ht.row, ht.col);
      });

      this.flexSmplByDay = flex;

      flex.mergeManager = new CustomMergeManager();

      // "미설정" color 설정
      flex.formatItem.addHandler((flex, event) => {
        if(event.panel == flex.cells) {
          const col = flex.columns[event.col];

          if(event.row >= 0 && (col.binding == "txbil_issue_yn")) {
            if(flex.getCellData(event.row, event.col) == "미발행") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미발행</span>';
            }
          }
        }
      });
    },
    onFlexSmplByDayItemsSourceChanged(flex) {
      // row 색상 변경 : [매  입], [매  출], [합  계], [수  금], [지  급]
      flex.formatItem.addHandler((flex, event) => {
        if (event.panel.cellType === wjGrid.CellType.Cell) {
          let valueColumnIndex = flex.columns.getColumn('div_nm').index;

          // if (valueColumnIndex !== -1) {
            let cellValue = flex.getCellData(event.row, valueColumnIndex);
            
            if (cellValue == TEXT_SALES || cellValue == TEXT_PURCH || cellValue == TEXT_COLCT || cellValue == TEXT_PAY) {
              event.cell.style.backgroundColor = '#CCC760';
              event.cell.style.fontWeight = 'bold';
            } else if (cellValue == TEXT_SUM) {
              event.cell.style.backgroundColor = SUM_BG_COLOR;
              event.cell.style.fontWeight = 'bold';
            } 
          // }
        }
      });
    },
    onFlexSmplByDayScrollPositionChanged(flex) {
      console.log("onFlexSmplByDayScrollPositionChanged");
      // row 색상 변경 : [매  입], [매  출], [합  계], [수  금], [지  급]
      flex.formatItem.addHandler((flex, event) => {
        if (event.panel.cellType === wjGrid.CellType.Cell) {
          let valueColumnIndex = flex.columns.getColumn('div_nm').index;

          // if (valueColumnIndex !== -1) {
            let cellValue = flex.getCellData(event.row, valueColumnIndex);
            
            if (cellValue == TEXT_SALES || cellValue == TEXT_PURCH || cellValue == TEXT_COLCT || cellValue == TEXT_PAY) {
              event.cell.style.backgroundColor = '#CCC760';
              event.cell.style.fontWeight = 'bold';
            } else if (cellValue == TEXT_SUM) {
              event.cell.style.backgroundColor = SUM_BG_COLOR;
              event.cell.style.fontWeight = 'bold';
            } 
          // }
        }
      });
    },
    onFlexDtlByDayInitialized(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // flex.select(ht.row, ht.col);
      });

      this.flexDtlByDay = flex;

      flex.mergeManager = new CustomMergeManager();

      // "미설정" color 설정
      flex.formatItem.addHandler((flex, event) => {
        if(event.panel == flex.cells) {
          const col = flex.columns[event.col];

          if(event.row >= 0 && (col.binding == "txbil_issue_yn")) {
            if(flex.getCellData(event.row, event.col) == "미발행") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미발행</span>';
            }
          }
        }
      });
    },
    onFlexDtlByDayItemsSourceChanged(flex) {
      // row 색상 변경 : [매  입], [매  출], [합  계], [수  금], [지  급]
      flex.formatItem.addHandler((flex, event) => {
        if (event.panel.cellType === wjGrid.CellType.Cell) {
          let valueColumnIndex = flex.columns.getColumn('div_nm').index;

          // if (valueColumnIndex !== -1) {
            let cellValue = flex.getCellData(event.row, valueColumnIndex);
            
            if (cellValue == TEXT_SALES || cellValue == TEXT_PURCH || cellValue == TEXT_COLCT || cellValue == TEXT_PAY) {
              event.cell.style.backgroundColor = '#CCC760';
              event.cell.style.fontWeight = 'bold';
            } else if (cellValue == TEXT_SUM) {
              event.cell.style.backgroundColor = SUM_BG_COLOR;
              event.cell.style.fontWeight = 'bold';
            } 
          // }
        }
      });
      // flex.itemFormatter = (panel, r, c, cell) => {
      //   // console.log("onFlexLedgrItemsSourceChanged", r, c, panel);
      //   if (panel === flex.cells) {
      //     if(this.filteredDtlByDayStmts[r].div_nm == "[매  출]" || this.filteredDtlByDayStmts[r].div_nm == "[매  입]"){
      //       cell.style.backgroundColor = "#7C3E66";
      //       cell.style.color = "white";
      //     }
      //   }
      // }
    },
    // 월계표(요약)
    onFlexByMonthSumryInitialized(flex) {
      // create extra header row
      let extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;
      //
      // add extra header row to the flex
      let panel = flex.columnHeaders;
      panel.rows.splice(0, 0, extraRow);
      //
      // populate the extra header row
      for (let colIndex = 1; colIndex <= 2; colIndex++) {
        panel.setCellData(0, colIndex, "전일계");
      }
      for (let colIndex = 3; colIndex <= 4; colIndex++) {
        panel.setCellData(0, colIndex, "금일계");
      }
      for (let colIndex = 5; colIndex <= 6; colIndex++) {
        panel.setCellData(0, colIndex, "계");
      }
      //
      // merge headers vertically
      ["sales_purch_div_nm"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
      });
      //
      // center-align merged header cells
      flex.formatItem.addHandler(function(s, e) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
          let html = e.cell.innerHTML;
          e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
          // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
        }
      });

      this.flexByMonthSumry = flex;
    },
    onFlexByMonthSumryItemsSourceChanged(flex) {
      
    },
    // 월계표(강종)
    onFlexByMonthSkindInitialized(flex) {
      this.flexByMonthSkind = flex;
    },
    onFlexByMonthSkindItemsSourceChanged(flex) {
      
    },
    // 월계표(기간/매출)
    onFlexByMonthSalesInitialized(flex) {
      this.flexByMonthSales = flex;
    },
    onFlexByMonthSalesItemsSourceChanged(flex) {
      
    },
    // 월계표(기간/매입)
    onFlexByMonthPurchInitialized(flex) {
      this.flexByMonthPurch = flex;
    },
    onFlexByMonthPurchItemsSourceChanged(flex) {
      
    },
    // 일계표 조회
    async loadByDayStmts() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", this.srchDtByDay);

      try {
        const res = await this.$http.post('/accounting/getByDayStmtList', params);
        const smplStmts = res.data.smplList.map(stmt => ({
          ...stmt,
          custr_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.custr_nm,
          sitem_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.sitem_nm,
          pay_mthd_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.pay_mthd_nm,
          sum_qty: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.sum_qty,
          sum_wegt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.sum_wegt,
          suply_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.suply_amt,
          vat_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.vat_amt,
          sum_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.sum_amt,
          txbil_issue_yn: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.txbil_issue_yn,
        }));

        this.smplByDayStmts = smplStmts;

        const dtlStmts = res.data.dtlList.map(stmt => ({
          ...stmt,
          custr_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.custr_nm,
          sitem_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.sitem_nm,
          spec: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY || stmt.div_nm == TEXT_SUM ? stmt.div_nm : stmt.spec,
          pay_mthd_nm: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.pay_mthd_nm,
          qty: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.qty,
          wegt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.wegt,
          sitem_unit_price: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.sitem_unit_price,
          suply_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.suply_amt,
          vat_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.vat_amt,
          sum_amt: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.sum_amt,
          txbil_issue_yn: stmt.div_nm == TEXT_SALES || stmt.div_nm == TEXT_PURCH || stmt.div_nm == TEXT_COLCT || stmt.div_nm == TEXT_PAY ? stmt.div_nm : stmt.txbil_issue_yn,
        }));

        this.dtlByDayStmts = dtlStmts;
      } catch (e) {
        console.log(`Error on function loadSales: ${e}`);
      }
    },
    // 월계표 조회
    async loadByMonthStmts() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/accounting/getByMonthStmtList', params);
        
        // 거래처별 매출/매입
        const byCustrStmts = res.data.byCustrList.map(stmt => ({
          ...stmt,
        }));

        this.byMonthCustrStmts = byCustrStmts;
        console.log("loadByMonthStmts:", this.byCustrStmts);

        // 강종별 매출
        const bySkindStmts = res.data.bySkindList.map(stmt => ({
          ...stmt,
        }));

        this.byMonthSkindStmts = bySkindStmts;

        // 요약 매출/매입
        const bySumryStmts = res.data.bySumryList.map(stmt => ({
          ...stmt,
        }));

        this.byMonthSumryStmts = bySumryStmts;
      } catch (e) {
        console.log(`Error on function loadByMonthStmts: ${e}`);
      }
    },
    // 조회 버튼 클릭
    onStmtInqry() {
      this.loadByMonthStmts();
    },

  }
}
</script>

<style>
.wj-cell .v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: pre-wrap;
}
</style>