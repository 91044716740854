<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>계정별 현황</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center">
              <v-col cols="12">
                <v-row dense align="center">
                  <!-- 계정명 검색 -->
                  <v-col cols="2">
                    <v-row dense align="center">
                      <v-col cols="4">
                        <div class="d-flex"><span class="">계정명</span></div>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          class=""
                          hide-details
                          dense
                          outlined
                          v-model="pendingWord"
                          @focusout="searchWord = pendingWord"
                          @keyup.enter="searchWord = pendingWord"
                          clearable
                          @click:clear="searchWord = ''"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- 조회 조건 -->
                  <v-col cols="10">
                    <v-row dense align="center">
                      <v-col cols="10">
                        <v-row dense align="center" class="">
                          <v-col cols="10">
                            <v-row dense align="center">
                              <v-col cols="2"><div class=""><span>조회수 : {{ ledgrList.length - 1 }} 건</span></div></v-col>
                              <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                              <v-col cols="5">
                                <v-row dense align="center">
                                  <v-col cols="4">
                                    <date-picker v-model="srchBgnDt"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="4">
                                    <date-picker v-model="srchEndDt"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>                              
                            </v-row>
                          </v-col>
                        </v-row>  
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- 계정 그리드 row -->
                <v-row dense align="center">              
                  <!-- 계정 그리드 -->
                  <v-col cols="2">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredAcctList"
                      :selectionMode="'ListBox'"
                      style="height:650px;"
                      :initialized="onFlexAcctInit"
                      :itemsSourceChanged="onFlexAcctItemsSourceChanged"
                      :headersVisibility="'Column'"
                      >
                      <wj-flex-grid-column :header="'코드'" :binding="'acct_cd'" width="4*" align="center"/>
                      <wj-flex-grid-column :header="'계정명'" :binding="'acct_nm'" width="10*" />
                      <wj-flex-grid-column :header="'구분'" :binding="'acct_div_nm'" width="4*" align="center"/>
                    </wj-flex-grid>
                  </v-col>
                  <!-- 통계 영역 -->
                  <v-col cols="10">
                    <v-row dense align="center">
                      <!-- 통계 row -->
                      <v-col cols="12">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수금액 : {{ stats.colctAmt }} 원</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">지급액 : {{ stats.payAmt }} 원</span></div></v-col>
                        </v-row>
                      </v-col>
                      <!-- 현황 그리드 -->
                      <v-col cols="12">
                        <wj-flex-grid 
                          :isReadOnly=true
                          :itemsSource="ledgrList" 
                          :selectionMode="'ListBox'"
                          style="height:650px;"
                          :itemsSourceChanged="onFlexLedgrItemsSourceChanged"
                          :headersVisibility="'Column'"
                          :alternatingRowStep="0"
                          >
                          <wj-flex-grid-column :header="'일자'" :binding="'ledgr_dt'" :width="90" align="center"/>
                          <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="120" />
                          <wj-flex-grid-column :header="'구분'" :binding="'item_div_nm'" :width="50" align="center"/>
                          <wj-flex-grid-column :header="'수금액'" :binding="'colct_amt'" :width="120" align="right"/>
                          <wj-flex-grid-column :header="'지급액'" :binding="'pay_amt'" :width="120" align="right"/>
                          <wj-flex-grid-column :header="'누계'" :binding="'acmlt_amt'" :width="120" align="right" :css-class="customColumnStyle"/>
                          <wj-flex-grid-column :header="'메모'" :binding="'memo'" :width="300" />
                        </wj-flex-grid>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

// . 통계 정보
const amt = {colctAmt: 0, payAmt: 0};

export default {
  components: {datePicker},
  data(){
    return{
      compSno: '',
      userSno: 0,

      acctList: [],
      
      acctCd: '',
      searchWord: "",
      pendingWord: "",

      srchBgnDt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      stats: {...amt},

      flexAcct: null,
      flexLedgr: null,
      ledgrList: [],

    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.ledgrByAcct.code);
    },
    filteredAcctList() {
      return this.acctList.filter(({acct_nm}) => 
        (!this.searchWord || this.searchWord.trim() === '' ||
        acct_nm && acct_nm.toUpperCase().includes(this.searchWord.toUpperCase()))
      );
    },
    customColumnStyle() {
      return 'sum-bg-color';
    }
  },
  watch: {
    acctCd: {
      immediate: true,
      handler: function () {
        this.loadLedgrList();
      },
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    this.getAcctList();
  },
  methods: {
    // 계정 그리드
    onFlexAcctInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.acctCd = flex.getCellData(ht.row, 0);
      });

      this.flexAcct = flex;
    },
    onFlexAcctItemsSourceChanged(flex) {
      if(flex != null && this.filteredAcctList.length > 0) {
        this.acctCd = flex.getCellData(0, 0);
      }
    },
    onFlexLedgrItemsSourceChanged(flex) {
      console.log("onFlexLedgrItemsSourceChanged");

      if(flex != null && this.ledgrList.length > 0) {
        // 통계 계산
        this.calculateStats();
      }
    },

    // 조회 버튼 클릭
    onLedgrInqry() {
      this.loadLedgrList();
    },
    // 출력 버튼 클릭

    // 계정 목록 조회
    getAcctList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getAcctList", params).then((res) => {
        console.log("getCustrList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.acctList = res.data.acctList;
      }).catch((error) => {
          console.log(error);
          alert(error.message);
        }
      )
    },

    // (원장) 계정별 현황 목록 조회
    async loadLedgrList() {
      if(this.acctCd == null || this.acctCd == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("acct_cd", this.acctCd);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByAcct', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrList = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrList: ${e}`);
      }
    },
    
    // 통계 계산
    calculateStats() {
      let colctAmt = 0;
      let payAmt = 0;

      this.ledgrList.forEach((e) => {
        colctAmt += e.colct_amt;
        payAmt += e.pay_amt;
      });

      this.stats.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.stats.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
    },
    
  }
}
</script>

<style>

</style>