<template>
  <!--[[[[[[[[[[[[[[[        품목 검색 모달        ]]]]]]]]]]]]]]]-->
  <v-dialog
    v-model="showDialog"
    width="800px"
    height="800px"
    
    @keydown.esc="close"
    @click:outside="close"
    >
    <v-container>
      <v-card style="text-align:center;" class="pb-3">
        <v-toolbar
          color="indigo darken-2"
          class="elevation-0"
          height="50px">
          <v-toolbar-title
            class="white--text">
            품목 선택
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        
        <div class="pa-3" style="overflow: auto;">
          <v-row dense class="" align="center">
            <v-col cols="4">
              <v-row class="">
                <!-- <v-col cols="4"><div><span>총 건수 : {{ custrCount }} 건</span></div></v-col> -->
                <v-col cols="12">
                  <v-row dense class="" align="center">
                    <v-col cols="3" align="right"><div class=""><span class="">강종</span></div></v-col>
                    <v-col cols="9">
                      <div class="d-flex">
                        <v-text-field
                          class=""
                          v-model="searchWordSkind"
                          hide-details
                          dense
                          outlined
                          height="25"
                          @keyup="searchWordSkindKeyUp"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="gridSkindList"
                    :selectionMode="'ListBox'"
                    style="height:500px;"
                    :initialized="flexSkindInitialized"
                    :itemsSourceChanged="skindItemsSourceChanged"
                    :headersVisibility="'Column'"
                  >
                    <wj-flex-grid-column :header="'강종코드'" :binding="'skind_cd'" width="2*" />
                    <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="5*" />
                  </wj-flex-grid>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="8">
              <v-row class="">
                <!-- <v-col cols="4"><div><span>총 건수 : 555건</span></div></v-col> -->
                <v-col cols="12">
                  <v-row dense class="" align="center">
                    <v-col cols="3" align="right"><div class=""><span class="">품목</span></div></v-col>
                    <v-col cols="9">
                      <div class="d-flex">
                        <v-text-field
                          class=""
                          v-model="searchWordSitem"
                          hide-details
                          dense
                          outlined
                          height="25"
                          @keyup="searchWordSitemKeyUp"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col cols="12" class="mt-0 pt-0">
                  <wj-flex-grid
                    :isReadOnly=true
                    :itemsSource="gridSitemList"
                    :selectionMode="'ListBox'"
                    style="height:500px;"
                    :initialized="flexSitemInitialized"
                    :itemsSourceChanged="sitemItemsSourceChanged"
                    :headersVisibility="'Column'" 
                    >
                    <wj-flex-grid-column :header="'품목코드'" :binding="'sitem_cd'" width="1*" />
                    <wj-flex-grid-column :header="'품목명'" :binding="'sitem_nm'" width="2*" />
                    <!-- <wj-flex-grid-column :header="'구분'" :binding="'sitem_div_nm'" width="1*" /> -->
                    <wj-flex-grid-column :header="'규격'" :binding="'stnd'" width="1*" />
                    <!-- <wj-flex-grid-column :header="'가로/길이'" :binding="'width_len'" width="1*" />
                    <wj-flex-grid-column :header="'세로/Ø'" :binding="'depth_phi'" width="1*" /> -->
                    <wj-flex-grid-column :header="'품목단가'" :binding="'set_price_yn'" width="1*" :visible="custrSno != null && custrSno != ''"/>
                    <wj-flex-grid-column :header="'가공단가'" :binding="'set_mchng_price_yn'" width="1*" :visible="custrSno != null && custrSno != ''"/>
                  </wj-flex-grid>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-btn color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="$emit('close')">취소</v-btn>
        <v-btn color="indigo darken-3" class="white--text" width="70" @click="setSelected">선택</v-btn>
      </v-card>
    </v-container>	
  </v-dialog>
</template>

<script>
export default {
  props: ["p_compSno", "p_custrSno"],
  data() {
    return {
      showDialog: true,
      compSno: this.p_compSno,
      custrSno: this.p_custrSno,

      isDestroying: false,
      getListMode: 'select',

      isSkindItemsSourceChanging: false,
      gridSkindList: [],
      skindList: [],
      skindCd: null,
      // skindNm: null,
      selectedSkindRow: 0,
      selectedSkindCol: 0,

      isSitemItemsSourceChanging: false,
      gridSitemList: [],
      sitemList: [],
      selectedItem: null,

      flexGrid: null,   // 강종 그리드

      flexSitem: null,  // 품목 그리드

      selectedRow: 0,
      selectedSitemRow: 0,
      selectedSitemCol: 0,

      // search: "",
      searchWordSkind: "",
      searchWordSitem: "",
    };
  },
  created() {

  },
  mounted() {
    console.log("searchSitemPopup mounted.", this.compSno);
    this.getSkindList();
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.isDestroying = true;
  },
  methods: {
    // 강종 그리드 생성됨
    flexSkindInitialized(flexgrid) {
      this.flexGrid = flexgrid;

      flexgrid.addEventListener(flexgrid.hostElement, "mousedown", e => {
        let ht = flexgrid.hitTest(e);
        // console.log("ht:", ht);       
        if(ht.cellType == 1 && ht.row >= 0) {
          // console.log("mousedown:", ht.row, ht.col);
          this.selectedSkindRow = ht.row;
          this.selectedSkindCol = ht.col;
          this.skindCd = this.flexGrid.getCellData(this.selectedSkindRow, 0);

          this.getSitemList();
        }
      });
    },
    // 강종 flexgrid 데이터 변경됨
    skindItemsSourceChanged() {
      console.log("skindItemsSourceChanged:", this.flexGrid, this.selectedSkindRow, this.gridSkindList.length, this.isSkindItemsSourceChanging, this.getListMode);

      if(this.flexGrid != null && !this.isDestroying && this.gridSkindList.length > 0) {
        this.flexGrid.select(-1, 0);
        this.flexGrid.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedSkindRow = 0;
          this.selectedSkindCol = 0;
        }
      } 
      
      if(this.isSkindItemsSourceChanging && this.flexGrid != null && !this.isDestroying && this.gridSkindList.length > 0 && this.selectedSkindRow >= 0) {
        this.flexGrid.rows[0].isSelected = false;
        this.flexGrid.select(this.selectedSkindRow, this.selectedSkindCol);
        this.skindCd = this.flexGrid.getCellData(this.selectedSkindRow, 0);
        
        this.isSkindItemsSourceChanging = false;
        this.getSitemList();
      }
    },

    // 품목 그리드 생성됨
    flexSitemInitialized(flex) {
      this.flexSitem = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        let ht = flex.hitTest(e);
        // console.log("ht:", ht);       
        if(ht.cellType == 1 && ht.row >= 0) {
          // console.log("mousedown:", ht.row, ht.col);
          this.selectedSitemRow = ht.row;
        }
      });

      flex.addEventListener(flex.hostElement, "dblclick", e => {
        let ht = flex.hitTest(e);
        // console.log("ht:", ht);       
        if(ht.cellType == 1 && ht.row >= 0) {
          // console.log("mousedown:", ht.row, ht.col);
          this.selectedSitemRow = ht.row;
          this.setSelected();
        }
      });

      // "미설정" color 설정
      flex.formatItem.addHandler((flex, event) => {
        if(event.panel == flex.cells) {
          const col = flex.columns[event.col];

          if(event.row >= 0 && (col.binding == "set_price_yn" || col.binding == "set_mchng_price_yn")) {
            if(flex.getCellData(event.row, event.col) == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      });
    },
    // 품목 그리드 변경됨
    sitemItemsSourceChanged() {
      console.log("sitemItemsSourceChanged:", this.flexSitem, this.selectedSitemRow, this.gridSitemList.length, this.isSitemItemsSourceChanging, this.getListMode);
    },

    searchWordSkindKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSkindList = this.skindList.filter((e) => {
        return e.skind_nm_disp.indexOf(this.searchWordSkind) > -1;
      });
    },
    searchWordSitemKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSitemList = this.sitemList.filter((e) => {
        return (e.sitem_cd.indexOf(this.searchWordSitem) > -1 || e.sitem_nm.indexOf(this.searchWordSitem) > -1 || e.stnd.indexOf(this.searchWordSitem) > -1);
      });
      console.log("searchWordSitemKeyUp gridSitemList:", this.gridSitemList);
    },

    getSkindList() {
      this.isSkindItemsSourceChanging = true;

      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.skindList.forEach((e) => {
          e.skind_nm_disp = e.skind_cd + " : " + e.skind_nm;
        });

        this.skindList = res.data.skindList;
        this.gridSkindList = res.data.skindList;

        if(this.skindList.length > 0) {
          // 첫번 째 강종 선택
          // this.skindCd = this.skindList[0].skind_cd;
          // this.skindNm = this.skindList[0].skind_nm;
          // this.getListMode = 'select';
          // this.getSitemList();
        } else {
          alert("등록된 강종이 없습니다.")
        }
      }).catch(function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    // skindCdChanged() {
    //   for(let i = 0; i < this.skindCdList.length; i++) {
    //     if(this.skindCdList[i].skind_cd == this.skindCd) {
    //       this.skindNm = this.skindCdList[i].skind_nm;
    //       break;
    //     }
    //   }

    //   this.getSitemList();
    // },
    getSitemList() {
      // console.log("getSitemList");
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("skind_cd", this.skindCd);

      if(this.custrSno != null && this.custrSno != "") {
        params.append("custr_sno", this.custrSno);
        params.append("sitem_price", "Y");
        params.append("mchng_price", "Y");
      }

      this.$http.post("/base/getSteelItemList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.sitemList = res.data.sitemList;
        this.gridSitemList = res.data.sitemList;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    setSelected() {    
      // console.log("setSelected:");
      const sitemCd = this.flexSitem.getCellData(this.selectedSitemRow, 0);

      for(let i = 0; i < this.gridSitemList.length; i++) {
        if(sitemCd == this.gridSitemList[i].sitem_cd) {
          this.selectedItem = this.gridSitemList[i];
          break;
        }
      }

      // 가공단가는 설정되지 않아도 가능하도록 수정함
      // if(this.selectedItem.set_price_yn == "미설정" || this.selectedItem.set_mchng_price_yn == "미설정") {
      if(this.selectedItem.set_price_yn == "미설정") {
        alert("품목 단가 설정이 필요합니다.");
      } else {
        this.$emit('selected', this.selectedItem.sitem_cd, this.selectedItem.sitem_nm, this.selectedItem.stnd);
      }
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>