export const SUM_BG_COLOR = "#B0AB51";

export const SUB_TAB = {
  vuetest: {code: 'vuetest', name: '뷰테스트'},
  estimate: {code: 'estimate', name: '견적관리'},
  custrInqry: {code: 'custrInqry', name: '거래처조회'},
  custrLabel: {code: 'custrLabel', name: '거래처라벨'},
  order: {code: 'order', name: '수주/발주 관리'},
  sales: {code: 'sales', name: '매출/매입 관리'},
  collectPay: {code: 'collectPay', name: '수금/지급 관리'},
  unCollectPay: {code: 'unCollectPay', name: '미수금/미지급 관리'},
  cashLedger: {code: 'cashLedger', name: '금전출납부'},
  byDayByMonthStmt: {code: 'byDayByMonthStmt', name: '일별/월별 조회'},
  incomeStmt: {code: 'incomeStmt', name: '손익 계산서'},
  trialBalance: {code: 'trialBalance', name: '총계정 집계표'},
  ocr: {code: 'ocr', name: 'ocr 인식<<개발중>>'},
  invntInqry: {code: 'invntInqry', name: '재고 조회'},
  salesInqry: {code: 'salesInqry', name: '명세서(매출) 조회'},
  ledgrByCustr: {code: 'ledgrByCustr', name: '거래처별 현황'},
  ledgrBySitem: {code: 'ledgrBySitem', name: '품목별 현황'},
  ledgrByAcct: {code: 'ledgrByAcct', name: '계정별 현황'},
  ledgrByDay: {code: 'ledgrByDay', name: '일별 현황'},
  invntStus: {code: 'invntStus', name: '재고 현황'},
  customer: {code: 'customer', name: '거래처 관리'},
  steelKind: {code: 'steelKind', name: '강종 관리'},
  steelItem: {code: 'steelItem', name: '품목 관리'},
  account: {code: 'account', name: '계정 관리'},
  sitemUnitPrice: {code: 'sitemUnitPrice', name: '품목단가 관리'},
  gravity: {code: 'gravity', name: '업체별 비중관리'},
  mchngUnitPrice: {code: 'mchngUnitPrice', name: '가공단가 관리'},
};

export const ORD_DIV = {
  sales: {code: 'sales', name: '수주'},
  purch: {code: 'purch', name: '발주'},
};

export const SALES_PURCH_DIV = {
  sales: {code: 'sales', name: '매출'},
  purch: {code: 'purch', name: '매입'},
};

export const COLCT_PAY_DIV = {
  colct: {code: 'colct', name: '수금'},
  pay: {code: 'pay', name: '지급'},
};

export const ACC_TYPE = {
  cash: {code: 'cash', name: '현금'},
  pnote: {code: 'pnote', name: '어음'},
  card: {code: 'card', name: '카드'},
}
