<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="1">
            <div class="">
              <h2>가공단가 관리</h2>
            </div>
          </v-col>
          <v-col cols="11">
            <!-- <v-btn small color="blue darken-2" class="white--text" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn> -->
            <v-btn small color="blue darken-2" class="white--text" v-show="!isFavorited" @click="addFavorite">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="" v-show="isFavorited" @click="deleteFavorite">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="3">
            <v-row class="">
              <v-col cols="4"><div><span>조회수 : {{custrCount}}건</span></div></v-col>
              <v-col cols="8">
                <div class="d-flex">
                  <span class="pr-2">거래처</span>
                  <v-text-field
                    class=""
                    v-model="searchWordCustr"
                    hide-details
                    dense
                    outlined
                    height="25"
                    @keyup="searchWordCustrKeyUp"
                    :readonly="isOnPendingPrice"
                    @click="onSearchWordClick"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" class="mt-0 pt-0">
                <wj-flex-grid 
                  :isReadOnly=true
                  :itemsSource="gridCustrList"
                  :selectionMode="'ListBox'"
                  style="height:650px;"
                  :initialized="flexCustrInitialized"
                  :itemsSourceChanging="custrItemsSourceChanging"
                  :itemsSourceChanged="custrItemsSourceChanged"
                  :headersVisibility="'Column'"
                  >
                  <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                  <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*" />
                  <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="5*" align="center"/>
                  <wj-flex-grid-column :header="'단가'" :binding="'set_price_yn'" width="5*" align="center"/>
                </wj-flex-grid>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row dense class="bgcolor-grey1">
              <v-col cols="4" class="d-flex justify-start align-center hei-40p" >
                <h4>가공단가정보</h4>
              </v-col>
              <v-col cols="8" class="d-flex justify-end align-center hei-40p" >
                <v-btn small color="blue darken-2" class="white--text mr-3" width="70" :disabled="isOnPendingPrice">표준단가</v-btn>
                <v-btn small color="blue darken-2" class="white--text mr-3" width="100" :disabled="isOnPendingPrice">단가불러오기</v-btn>
                <v-btn small color="blue darken-2" class="white--text mr-3" width="70" :disabled="isOnPendingPrice">쫄대단가</v-btn>
                <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingPrice">취소</v-btn>
                <v-btn small color="indigo darken-3" class="white--text font-weight-bold" width="70" @click="saveBtnClicked" :disabled="!isOnPendingPrice">저장</v-btn> 
              </v-col>
              <v-col cols="12" class="d-flex justify-end align-center hei-40p">
                <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3 font-weight-bold" min-width="70" :disabled="isUnitPriceDeleteDisabled" @click="deletePriceRow">항목삭제</v-btn>
                <v-btn small color="blue darken-2" class="white--text font-weight-bold" min-width="70" @click="addPriceRow">항목추가</v-btn> 
              </v-col>
              <v-col cols="12">
                <wj-flex-grid
                  :itemsSource="pendingPriceList"
                  :selectionMode="'MultiRange'"
                  :showMarquee="true"
                  style="height:600px;"
                  :allowSorting="false"
                  :initialized="flexPriceInitialized"
                  :itemsSourceChanging="priceItemsSourceChanging"
                  :itemsSourceChanged="priceItemsSourceChanged"
                  :deferResizing="true"
                  :alternatingRowStep="0"
                  :headersVisibility="'All'"
                  >
                  <!-- <wj-flex-grid-column :header="'□'" :binding="'select_yn'" :width="50"  /> -->
                  <!-- <wj-flex-grid-column :header="'no'" :binding="'test'" width="1*" />
                  <wj-flex-grid-column :header="'코드'" :binding="'test'" width="1*" />
                  <wj-flex-grid-column :header="'면적'" :binding="'test'" width="1*" />
                  <wj-flex-grid-column :header="'수식'" :binding="'test'" width="1*" /> -->
                  <wj-flex-grid-column :header="'* 가공비구분'" :binding="'mchng_price_div'" :width="150" :dataMap="mchngPriceDivDataMap"/>
                  <wj-flex-grid-column :header="'면적값'" :binding="'area_val'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'면가공비'" :binding="'face_price'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'각가공비'" :binding="'side_price'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'스퀘어가공비'" :binding="'sqre_price'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'연마비'" :binding="'grind_price'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'기타가공비'" :binding="'etc_price'" :width="120" align="right"/>
                </wj-flex-grid>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import * as wjGrid from '@grapecity/wijmo.grid';
import {Selector} from '@grapecity/wijmo.grid.selector';

export default {
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == 'Y' ? true : false,
      getListMode: 'select',  // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false,   // 항목 변경 여부

      isItemsSourceChanging: false, // 목록 조회 중. getOOO에서 설정 -> itemsSourceChanged에서 해제
      gridCustrList: [],   // 그리드에 표시되는 filter된 목록
      custrList: [],
      custrCount: 0,
      custrSno: null,
      selectedCustrRow: 0,
      selectedCustrCol: 1,

      selector: null,   // 그리드 체크박스

      priceList: [],
      pendingPriceList: [],
      selectedPriceRow: 0,
      selectedPriceCol: 0,
      deletedPriceList: [],

      mchngPriceDivList: [],
      mchngPriceDivDataMap: null,

      flexCustr: null,     // 거래처 flexgrid
      flexPrice: null,
      
      searchWordCustr: null,  // 거래처 검색어

    }
  },
  computed: {
    isOnPendingPrice() {
      // console.log("isOnPendingPrice:", this.pendingPriceList, this.pendingPriceList);
      return JSON.stringify(this.priceList.map((e) => ({...e, select_yn:false}))) !== JSON.stringify(this.pendingPriceList.map((e) => ({...e, select_yn:false})));
    },
    isUnitPriceDeleteDisabled() {
      return !this.pendingPriceList.some(({select_yn}) => select_yn);
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    this.getMchngPriceDivList();
    this.getCustrList();
  },
  methods: {
	  // 거래처 flexgrid 생성죔
	  flexCustrInitialized(flex) {
      // console.log("flexCustrInitialized:", flex);
      this.flexCustr = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("flexDownEvent:", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);       
        if(ht.cellType == 1 && ht.row >= 0) {
          let isConfirmed = true;

          if(this.isOnPendingPrice) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);
            }
          }

          if(isConfirmed) {
            this.selectedCustrRow = ht.row;
            this.selectedCustrCol = ht.col;
            this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);

            this.getMchngUnitPriceList();
          }
        }
      });

      // "미설정" color 설정
      flex.formatItem.addHandler((flexgrid, event) => {
        if(event.panel == flexgrid.cells) {
          const col = flexgrid.columns[event.col];

          if(event.row >= 0 && col.binding == "set_price_yn") {
            if(flexgrid.getCellData(event.row, event.col) == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      });
    },
    // 거래처 flexgrid 데이터 변경 전
    custrItemsSourceChanging() {
      if(this.flexCustr && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexCustr);
      }
    },
    // 거래처 목록 그리드(flexCustr) 데이터 변경됨
    custrItemsSourceChanged() {
      // console.log("custrItemsSourceChanged:", this.flexCustr, this.selectedCustrRow, this.gridCustrList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexCustr && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexCustr);
      }

      if(this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0) {
        this.flexCustr.select(-1, 0);
        this.flexCustr.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedCustrRow = 0;
          this.selectedCustrCol = 1;
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0 && this.selectedCustrRow >= 0) {
        this.flexCustr.rows[0].isSelected = false;
        this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);

        this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);
        // console.log("custrItemsSourceChanged custrSno:", this.custrSno);
        
        this.isItemsSourceChanging = false;       
        this.getMchngUnitPriceList();
      }
    },
    // 단가 그리드(flexPrice) 생성됨
    flexPriceInitialized(flex) {
      this.flexPrice = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("e", e);
        let ht = flex.hitTest(e);    
        // console.log("ht:", ht, ht.cellType, ht.row); 
        // if(ht.row >= 0)  console.log("CellData:", this.flexPrice.getCellData(ht.row, 0));
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)

        }
      });

      this.selector = new Selector(flex, {
        itemChecked: () => {
          // this.selectedItems = flex.rows.filter(r => r.isSelected);
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
          // console.log("onEstmDtlGridInit this.selector itemChecked", flex);
        }
      });

      // 글자색 지정
      flex.itemFormatter = (panel, r, c, cell) => {
        if (panel === flex.cells /*&& panel.rows[r].isSelected*/) {
          cell.style.color = "black";
        }
      }

      flex.selectionMode = "MultiRange";
    },
    // 단가 flexgrid 데이터 변경 전
    priceItemsSourceChanging() {
      if(this.flexPrice && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexPrice);
      }
    },
    // 단가 그리드(flexPrice) 데이터 변경됨
    priceItemsSourceChanged() {
      // console.log("priceItemsSourceChanged:", this.flexPrice, this.pricelList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexPrice && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexPrice);
        
        // dataMap 복원
        let column = this.flexPrice.getColumn("mchng_price_div");
        column.dataMap = this.mchngPriceDivDataMap;
      }
    },


    addFavorite() {
      this.$emit('addFavorite');
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit('deleteFavorite');
      this.isFavorited = false;
    },
    searchWordCustrKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridCustrList = this.custrList.filter((e) => {
        return (e.custr_nm.indexOf(this.searchWordCustr) > -1 || e.custr_div_nm.indexOf(this.searchWordCustr) > -1);
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingPrice) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
    
    async getMchngPriceDivList() {
      this.mchngPriceDivList = await this.$getCmnCdList('mchng_price_div');

      // flexGrid용 dataMap 생성
      let mchngPriceDivDataMap = [];
      
      this.mchngPriceDivList.forEach((e) => {
        mchngPriceDivDataMap.push({id: e.cmn_cd, name: e.cmn_cd_nm});
      });

      this.mchngPriceDivDataMap = new wjGrid.DataMap(mchngPriceDivDataMap, 'id', 'name')
    },
    getCustrList() {
      // console.log("getCustrList:", this.compSno);
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("mchng_price", "Y");

      this.$http.post("/base/getCustrList", params).then((res) => {
        // console.log("getCustrList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.custrList = res.data.custrList;
        this.gridCustrList = res.data.custrList;
        this.custrCount = this.custrList.length;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getMchngUnitPriceList() {
      // console.log("getMchngUnitPriceList called.", this.compSno, this.custrSno);
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", this.custrSno);
      params.append("sitem_cd", this.sitemCd);

      this.$http.post("/base/getMchngUnitPriceList", params).then((res) => {
        // console.log("getMchngUnitPriceList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.priceList.forEach((e) => {
          e.new_yn = 'N';
          e.select_yn = false;
        });

        this.priceList = JSON.parse(JSON.stringify(res.data.priceList));
        this.pendingPriceList = JSON.parse(JSON.stringify(this.priceList));
        this.deletedPriceList = [];
      }).catch(function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    // 단가정보 입력 데이터 검증
    validateInput() {
      // // 가로(폭)
      // if(this.$refs.estm_dt.date == null || this.$refs.estm_dt.date == "") {
      //   alert('가로(폭) 필수 입력값입니다.');
      //   return false;
      // }

      return true;
    },
    // 단가정보 저장 버튼 클릭됨
    saveBtnClicked() {
      // console.log("saveBtnClicked:", this.pendingPriceList, this.deletedPriceList);
      // 입력 데이터 검증
      const isValid = this.validateInput();
      
      if(isValid) {
      // if(confirm("저장하시겠습니까?")) {
        const params = new URLSearchParams();

        // params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);
        params.append("custr_sno", this.custrSno);

        this.pendingPriceList.forEach((e) => {
          for(let i = 0; i < this.mchngPriceDivList.length; i++) {
            if(e.mchng_price_div_nm == this.mchngPriceDivList[i].cmn_cd_nm) {
              e.mchng_price_div = this.mchngPriceDivList[i].cmn_cd;
            }
          }
        });

        params.append("priceList", JSON.stringify(this.pendingPriceList));
        params.append("deletedPriceList", JSON.stringify(this.deletedPriceList));
        
        this.$http.post("/base/upsertMchngUnitPrice", params).then((res) => {
          console.log("upsertMchngUnitPrice res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");

          this.getMchngUnitPriceList();
          this.isNew = false;
          // this.isItemChanged = false;

          // 거래처 그리드의 단가 미설정 -> 설정 업데이트
          this.flexCustr.setCellData(this.selectedCustrRow, "set_price_yn", "설정");
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingPrice) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        this.getMchngUnitPriceList();
      }
    },
    addPriceRow() {
      // console.log("addPriceRow:", this.flexPrice.collectionView);
      const nextOdr = this.pendingPriceList.reduce((acc, {odr}) => acc < odr ? odr : acc, 0) + 1;
      this.pendingPriceList = [
        ...this.pendingPriceList, {
          new_yn: 'Y',
          select_yn: false,
          odr: nextOdr,
          comp_sno: this.compSno,
          custr_sno: this.custrSno,
        }
      ]

      // this.flexPrice.collectionView.addNew();
      // const row = this.pendingPriceList.length - 1;
      // this.pendingPriceList[row].new_yn = 'Y';
      // this.pendingPriceList[row].select_yn = false;
      // this.pendingPriceList[row].odr = row == 0 ? 1 : this.pendingPriceList[row - 1].odr + 1;
      // this.pendingPriceList[row].comp_sno = this.compSno;
      // this.pendingPriceList[row].custr_sno = this.custrSno;
      // console.log("addPriceRow:", this.pendingPriceList);
    },
    deletePriceRow() {
      // console.log("deletePriceRow:", this.pendingPriceList);
      for(let i = 0; i < this.pendingPriceList.length; i++) {
        if(this.pendingPriceList[i].select_yn) {
          if(this.pendingPriceList[i].new_yn == 'N') {
            this.deletedPriceList.push(this.pendingPriceList[i]);
          }
          this.flexPrice.collectionView.removeAt(i);
          i--;
        }
      }
      // console.log("deletePriceRow:", this.pendingPriceList, this.deletedPriceList);
    }
  }
}
</script>

	   

<style>


</style>