<template>
  <v-container fluid class="d-flex flex-column">
    <v-row dense class="" align="center">
      <v-col cols="12" class="d-flex">
        <div>
          <h2>견적관리</h2>
        </div>
        <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
        <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
      </v-col>
    </v-row>
    <v-row dense class="pt-0 mt-3" style="height:210px;" align="center">
      <v-col cols="1">
        <div class=""><span>조회수 : {{ filteredEstms.length }} 건</span></div>
      </v-col>
      <v-col cols="1">
        <div class="f-r"><span>조회일자</span></div>
      </v-col>
      <v-col cols="1">
        <date-picker v-model="srchDt" :disabled="isOnPendingEstm" :is-checked="false"></date-picker>
      </v-col>
      <v-col cols="1">
        <div class="f-r"><span>견적상태</span></div>
      </v-col>
      <v-col cols="1">
        <v-select
            class=""
            v-model="estmStus"
            :items="estmStusFilterCombo"
            item-value="cmn_cd"
            item-text="cmn_cd_nm"
            hide-details dense outlined :disabled="isOnPendingEstm"
        ></v-select>
      </v-col>
      <v-col cols="1">
        <div class="f-r"><span>거래처</span></div>
      </v-col>
      <v-col cols="2">
        <v-text-field
            class="" v-model="pendingWord"
            hide-details dense outlined clearable :disabled="isOnPendingEstm"
            @click:clear="searchWord = ''"
            @focusout="searchWord = pendingWord"
            @keyup.enter="searchWord = pendingWord"
        ></v-text-field>
      </v-col>
      <v-col cols="4" align="right">
        <!-- <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold">조회</v-btn> -->
        <v-btn small min-width="70px" color="blue darken-2" class="white--text font-weight-bold" :disabled="!pendingEstm.estm_sno" @click="onCreateEstm">견적 추가</v-btn>
      </v-col>
      <v-col cols="12" class="pt-0 mt-0">
        <wj-flex-grid
          :isReadOnly=true
          :itemsSource="filteredEstms"
          :selectionMode="isOnPendingEstm ? 'None' : 'ListBox'"
          style="height:175px;"
          :initialized="onEstmGridInit"
          :itemsSourceChanging="onEstmGridChanging"
          :itemsSourceChanged="onEstmGridChanged"
          headersVisibility="Column">
          <wj-flex-grid-column header="견적일련번호" binding="estm_sno" width="2*" :visible="false" />
          <wj-flex-grid-column header="거래처일련번호" binding="custr_sno" width="4*" :visible="false"/>
          <wj-flex-grid-column header="견적번호" binding="estm_no" width="4.5*" align="center"/>
          <wj-flex-grid-column header="일자" binding="estm_dt" width="4*" align="center"/>
          <!-- <wj-flex-grid-column header="견적명" binding="estm_nm" width="8*" /> -->
          <wj-flex-grid-column header="거래처명" binding="custr_nm" width="8*" />
          <wj-flex-grid-column header="건수" binding="dtl_cnt" width="2*" format="N0" align="right"/>
          <wj-flex-grid-column header="소재비" binding="sitem_amt" width="3*" format="N0" align="right"/>
          <wj-flex-grid-column header="가공비" binding="mchng_amt" width="3*" format="N0" align="right"/>
          <wj-flex-grid-column header="공급가액" binding="suply_amt" width="3*" format="N0" align="right"/>
          <wj-flex-grid-column header="부가세액" binding="vat_amt" width="3*" format="N0" align="right"/>
          <wj-flex-grid-column header="합계금액" binding="sum_amt" width="3*" format="N0" align="right"/>
          <wj-flex-grid-column header="견적" binding="estm_yn" width="2*" dataType="Boolean"/>
          <wj-flex-grid-column header="FAX" binding="fax_yn" width="2*" dataType="Boolean" />
          <wj-flex-grid-column header="Mail" binding="email_yn" width="2*" dataType="Boolean" />
          <wj-flex-grid-column header="견적상태" binding="estm_stus_nm" width="3*" />
          <wj-flex-grid-column header="작성자" binding="reg_user_nm" width="3*" />
          <wj-flex-grid-column header="비고사항" binding="rm" width="15*" />
        </wj-flex-grid>
      </v-col>
    </v-row>

    <v-card class="pa-1 bgcolor-grey1 mt-3 elevation-0" height="510px">
      <v-row dense>
        <v-col cols="12" class="d-flex justify-end align-center hei-40p">
          <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" v-show="pendingEstm.estm_sno && !isDummy" @click="onEstmDelete">삭제</v-btn>
          <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70" v-show="pendingEstm.estm_sno" :disabled="isDummy || isOnPendingEstm">작업지시서</v-btn>
          <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70" v-show="pendingEstm.estm_sno" :disabled="isDummy || isOnPendingEstm">견적서</v-btn>
          <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70" v-show="pendingEstm.estm_sno" :disabled="isDummy || isOnPendingEstm || estm.estm_stus !== 'yet'" @click="onEstmStep('salesOrd')">수주처리</v-btn>
          <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70" v-show="pendingEstm.estm_sno" :disabled="isDummy || isOnPendingEstm || estm.estm_stus !== 'done'" @click="onEstmStep('sales')">출고처리</v-btn>
          <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70" v-show="pendingEstm.estm_sno" :disabled="isDummy || isOnPendingEstm" @click="onEstmCopy">복사</v-btn>
          <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3 font-weight-bold" min-width="70" :disabled="isEstmCancellationDisabled" @click="onEstmCancel">취소</v-btn>
          <v-btn small color="indigo darken-3" class="white--text font-weight-bold" :disabled="isEstmSaveDisabled" min-width="70" @click="onEstmSave">저장</v-btn>
        </v-col>
      </v-row>
      <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
        <v-row dense style="height:30px;" align="center">
          <v-col cols="12">
            <h3>견적 내용</h3>
          </v-col>
        </v-row>
        <v-row dense style="height:40px;" align="center">
          <v-col cols="1" align="right">
            <div class=""><span>견적번호</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.estm_no" hide-details dense outlined readonly disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span style="color:red;">* </span><span>견적일자</span></div>
          </v-col>
          <v-col cols="1">
            <date-picker v-model="pendingEstm.estm_dt" :disabled="isDummy"></date-picker>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>출고예정일</span></div>
          </v-col>
          <v-col cols="1">
            <date-picker v-model="pendingEstm.out_ship_scdul_dt" :disabled="isDummy"></date-picker>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>견적상태</span></div>
          </v-col>
          <v-col cols="1">
            <v-select
              class=""
              v-model="pendingEstm.estm_stus"
              :items="$store.state.commonCode.estm_stus"
              item-value="cmn_cd"
              item-text="cmn_cd_nm"
              hide-details dense outlined
              background-color="white"
              :disabled="isDummy"
              >
            </v-select>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>견적명</span></div>
          </v-col>
          <v-col cols="3">
            <v-text-field class="" v-model="pendingEstm.estm_nm" hide-details dense outlined background-color="white" :disabled="isDummy"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense style="height:40px;" align="center">
          <v-col cols="1" align="right">
            <div class=""><span style="color:red;">* </span><span>거래처명</span></div>
          </v-col>
          <v-col cols="2">
            <v-text-field
              class=""
              v-model="pendingEstm.custr_nm"
              hide-details dense outlined readonly
              background-color="white"
              append-icon="mdi-magnify"
              @click="onCustr"
              @click:append="onCustr"
              :disabled="isDummy"
            ></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>대표자</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.repr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>사업자번호</span></div>
          </v-col>
          <v-col cols="2">
            <v-text-field class="" v-model="pendingEstm.biz_rno" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>전화번호</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.tel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>팩스번호</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.fax_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense style="height:40px;" align="center">
          <v-col cols="1" align="right">
            <div class=""><span>업체담당자</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.chrgr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>휴대전화번호</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field class="" v-model="pendingEstm.chrgr_cel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>이메일</span></div>
          </v-col>
          <v-col cols="3">
            <v-text-field class="" v-model="pendingEstm.chrgr_email" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>주소</span></div>
          </v-col>
          <v-col cols="3">
            <v-text-field class="" v-model="pendingEstm.biz_plc_addr" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense style="height:40px;" align="center">
          <v-col cols="1" class="pb-7" align="right">
            <div class=""><span>세액구분</span></div>
          </v-col>
          <v-col cols="1" class="pb-7">
            <v-select
              class=""
              v-model="pendingEstm.tax_div"
              :items="$store.state.commonCode.tax_div"
              item-value="cmn_cd"
              item-text="cmn_cd_nm"
              hide-details
              outlined
              dense
              background-color="white"
              :disabled="isDummy"
              >
            </v-select>
          </v-col>
          <v-col cols="1" class="pb-7" align="right">
            <div class=""><span>공급가액</span></div>
          </v-col>
          <v-col cols="1" class="pb-7">
            <numeric-text-field v-model="pendingEstm.suply_amt" :disabled="true" ></numeric-text-field>
          </v-col>
          <v-col cols="1" class="pb-7" align="right">
            <div class=""><span>부가세액</span></div>
          </v-col>
          <v-col cols="1" class="pb-7">
            <numeric-text-field v-model="pendingEstm.vat_amt" :disabled="pendingEstm.tax_div != 'tax' || isDummy"></numeric-text-field>
          </v-col>
          <v-col cols="1" class="pb-7" align="right">
            <div class=""><span>합계금액</span></div>
          </v-col>
          <v-col cols="1" class="pb-7">
            <numeric-text-field v-model="sumAmt" :disabled="true" ></numeric-text-field>
          </v-col>
          <v-col cols="1" class="pb-7" align="right"><div class=""><span>비고사항</span></div></v-col>
          <v-col cols="3" style="">
            <v-row align="center">
              <v-col cols="12">
                <v-textarea
                  class=""
                  v-model="pendingEstm.rm"
                  hide-details
                  dense
                  outlined
                  no-resize
                  rows="2"
                  row-height="20"
                  background-color="white"
                  :disabled="isDummy"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense style="height:40px;" align="center">
          <v-col cols="1" align="right">
            <div class=""><span>납품조건</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field
              class=""
              v-model="pendingEstm.dlv_cond"
              hide-details
              dense
              outlined
              background-color="white"
              :disabled="isDummy"
              >
            </v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>결제조건</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field
              class=""
              v-model="pendingEstm.pay_cond"
              hide-details
              dense
              outlined
              background-color="white"
              :disabled="isDummy"
              >
            </v-text-field>
          </v-col>
          <v-col cols="1" align="right">
            <div class=""><span>유효기한</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field
              class=""
              v-model="pendingEstm.valid_limit"
              hide-details
              dense
              outlined
              background-color="white"
              :disabled="isDummy"
              >
            </v-text-field>
            <!-- <date-picker v-model="pendingEstm.valid_limit_dt"></date-picker> -->
          </v-col>
        </v-row>
        <v-row dense align="center">
          <!-- <v-col cols="1">
            <label class="d-flex align-center">
              <input
                  class="mr-1" type="checkbox"
                  :checked="0 < pendingDtls.length && pendingDtls.every((({select_yn}) => select_yn))"
                  @change.prevent="onCheckAll"
              >전체선택</label>
          </v-col> -->
          <v-col cols="1"><span>견적항목</span></v-col>
          <v-col cols="1"><span>조회수 : {{ pendingDtls.length }}</span></v-col>
          <v-col cols="1"><span>중량 : {{ dtlsWegt }}</span></v-col>
          <v-col cols="1"><span>수량 : {{ dtlsQty }}</span></v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" align="right">
            <v-btn small min-width="70px" class="pa-0 ma-0 mr-3 font-weight-bold" :disabled="isEstmDtlDeletDisabled" @click="onEstmDtlDelete">항목삭제</v-btn>
            <v-btn small min-width="70px" color="blue darken-4" class="white--text font-weight-bold" :disabled="isDummy" @click="onEstmDtlAdd">항목추가</v-btn>
          </v-col>
          <v-col cols="12">
            <wj-flex-grid
                :itemsSource="pendingDtls"
                style="height: 180px;"
                selectionMode="MultiRange"
                :deferResizing="true"
                :alternatingRowStep="0"
                :showMarquee="true"
                :allowDelete="true"
                :allowSorting="false"
                :initialized="onEstmDtlGridInit"
                :itemsSourceChanged="onEstmDtlGridChanged"
                headersVisibility="All"
                :cellEditEnding="onEstmDtlEditEnding"
                :cellEditEnded="onEstmDtlEditEnded"
            >
              <!-- 헤더에 체크박스 컬럼 추가 -->
              <!-- <wj-flex-grid-column header="□" binding="select_yn" :width="50" dataType="Boolean" /> -->
              <wj-flex-grid-column header="품목" binding="" :width="50" :cellTemplate="createSitemTemplate"/>
              <wj-flex-grid-column header="* 품목코드" binding="sitem_cd" :width="85" :isRequired="true" align="center"/>
              <wj-flex-grid-column header="품목명" binding="sitem_nm" :width="150" :isReadOnly="true"/>
              <wj-flex-grid-column header="규격" binding="spec" :width="120" :isReadOnly="true"/>
              <wj-flex-grid-column header="단위" binding="unit" :width="50" :isReadOnly="true" :dataMap="sitemUnits" align="center"/>
              <wj-flex-grid-column header="가공범위" binding="mchng_range" :width="145" data-type="String" :dataMap="mchngRanges"/>
              <wj-flex-grid-column header="소재두께/Ø" binding="thck" :width="90" :isReadOnly="true" format="N1" align="right"/>
              <wj-flex-grid-column header="가공두께/Ø" binding="mchng_thck" :width="90"  format="N1" align="right"/>
              <wj-flex-grid-column header="로스" binding="thck_loss" :width="55" :isReadOnly="true" format="N1" align="right"/>
              <wj-flex-grid-column header="가로/길이" binding="width_len" :width="80" format="N1" align="right"/>
              <wj-flex-grid-column header="로스" binding="width_len_loss" :width="55" :isReadOnly="true" format="N1" align="right"/>
              <wj-flex-grid-column header="세로" binding="depth_phi" :width="80" format="N1" align="right"/>
              <wj-flex-grid-column header="로스" binding="depth_phi_loss" :width="55" :isReadOnly="true" format="N1" align="right"/>
              <wj-flex-grid-column header="수량" binding="qty" :width="50" format="N0" align="right"/>
              <wj-flex-grid-column header="중량" binding="wegt" :width="60" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="소재단가" binding="sitem_unit_price" :width="80" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="소재비" binding="sitem_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="면가공비" binding="face_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="각가공비" binding="side_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="스퀘어가공비" binding="sqre_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="연마비" binding="grind_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="기타가공비" binding="etc_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="합계금액" binding="sum_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
              <wj-flex-grid-column header="비고" binding="rm" :width="200" />
              <wj-flex-grid-column header="도번" binding="item_nm" :width="200" />
              <wj-flex-grid-column header="품번" binding="custr_item_nm" :width="200" />
              <wj-flex-grid-column header="비중" binding="spgrv" :width="60" :isReadOnly="true"/>
              <wj-flex-grid-column header="면취" binding="chamf_mchng_yn" :width="50" dataType="Boolean" />
              <wj-flex-grid-column header="T맞춤" binding="thck_mchng_yn" :width="60" dataType="Boolean" />
            </wj-flex-grid>
          </v-col>
        </v-row>
      </v-card>
    </v-card>

    <search-custr-popup
      v-if="popup.custr.isOpened"
      :p_compSno="$store.state.session.compSno"
      p_salesPurchDiv="sales"
      @close="popup.custr.onClose && popup.custr.onClose()"
      @selected="custrSno => popup.custr.onClose && popup.custr.onClose(custrSno)"
    ></search-custr-popup>

    <search-sitem-popup
      v-if="popup.sitem.isOpened"
      :p_compSno="$store.state.session.compSno"
      :p_custrSno="pendingEstm.custr_sno"
      @close="popup.sitem.onClose && popup.sitem.onClose()"
      @selected="(...param) => popup.sitem.onClose && popup.sitem.onClose(...param)"
    ></search-sitem-popup>

    <confirm-copy-popup
      v-if="isCopyOpened"
      p_copyType="estm"
      :p_trgtDt="pendingEstm.estm_dt"
      :p_scdulDt="pendingEstm.out_ship_scdul_dt"
      @close="isCopyOpened = false"
      @copyConfirmed="copyConfirmed"
    ></confirm-copy-popup>

    <step-process-popup
      v-if="isStepOpened"
      :p_procType="stepProcType"
      :p_trgtDt="pendingEstm.estm_dt"
      :p_scdulDt="pendingEstm.out_ship_scdul_dt"
      @close="isStepOpened = false"
      @stepProcess="stepProcess"
    ></step-process-popup>
  </v-container>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Selector} from '@grapecity/wijmo.grid.selector';
import datePicker from "@/components/common/datePicker.vue";
import searchCustrPopup from '@/components/common/searchCustrPopup.vue';
import SearchSitemPopup from '@/components/common/searchSitemPopup.vue';
import numericTextField from '@/components/common/numericTextField.vue';
import {SUB_TAB} from "@/common/constant";
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import ConfirmCopyPopup from '@/components/common/confirmCopyPopup.vue';
import StepProcessPopup from '@/components/common/stepProcessPopup.vue';

const DUMMY_ESTM_SNO = '__dummy__';

const genEmptyEstm = ({
  estm_sno = null,
  estm_dt = moment().format('YYYY-MM-DD'),
}) => ({
  estm_sno: estm_sno,
  comp_sno: null,
  estm_no: null,
  estm_nm: '',
  estm_dt: estm_dt,
  out_ship_scdul_dt: moment().format('YYYY-MM-DD'),
  estm_stus: 'yet',
  valid_limit: null,
  pay_cond: '',
  dlv_cond: '',
  tax_div: 'tax',
  suply_amt: null,
  vat_amt: null,
  sum_amt: null,
  rm: '',
  custr_sno: null,
  custr_nm: null,
  repr_nm: null,
  biz_rno: null,
  tel_no: null,
  fax_no: null,
  biz_plc_addr: null,
  chrgr_nm: null,
  chrgr_cel_no: null,
  chrgr_email: null,
  estmDtlList: [],
  isItemChanged: false,
});

const genEmptyEstmDtlPrice = () => ({
  sitem_unit_price: null,
  sitem_amt: null,
  wegt: null,
  face_mchng_amt: null,
  side_mchng_amt: null,
  sqre_mchng_amt: null,
  grind_mchng_amt: null,
  etc_mchng_amt: null,
  sum_amt: null,
});

const genEmptyEstmDtl = () => ({
  uuid: uuidv4(),
  odr: null,
  select_yn: null,
  sitem_cd: null,
  sitem_nm: null,
  spec: null,
  unit: null,
  mchng_range: null,
  thck: null,
  mchng_thck: null,
  thck_loss: null,
  width_len: null,
  width_len_loss: null,
  depth_phi: null,
  depth_phi_loss: null,
  qty: null,
  spgrv: null,
  ...genEmptyEstmDtlPrice(),
  rm: null,
  item_nm: null,
  custr_item_nm: null,
  chamf_mchng_yn: null,
  thck_mchng_yn: null,
});

export default {
  components: {datePicker, searchCustrPopup, SearchSitemPopup, numericTextField, ConfirmCopyPopup, StepProcessPopup},
  created() {
    this.$store.dispatch('refreshCommonCode', 'estm_stus');
    this.$store.dispatch('refreshCommonCode', 'tax_div');
    this.$store.dispatch('refreshCommonCode', 'sitem_unit');
    this.$store.dispatch('refreshCommonCode', 'mchng_range');
    this.$store.dispatch('refreshCommonCode', 'mchng_price_div');
    this.$store.dispatch('refreshSitems');
  },
  data() {
    return {
      selector: null,   // 그리드 체크박스

      estmGrid: null,
      estmDtlGrid: null,

      isDtlGridChanging: false,

      srchDt: moment().format('YYYY-MM-DD'),
      estmStus: 'all',
      pendingWord: '',
      searchWord: '',
      estms: [],
      estm: null,
      pendingEstm: genEmptyEstm({estm_sno: DUMMY_ESTM_SNO}),
      restoreEstm: null,
      estmDtls: [],
      pendingDtls: [],
      restoreDtls: null,
      restoreDtl: null,

      popup: {
        custr: {
          isOpened: false,
          onClose: null,
        },
        sitem: {
          isOpened: false,
          onClose: null,
        },
      },

      isCopyOpened: false,  // 복사 팝업
      isStepOpened: false,  // 수주/출고 처리 팝업
      stepProcType: "salesOrd", // 수주/출고 처리 유형

      createSitemTemplate: CellMaker.makeButton({
        text: '선택',
        click: (e, ctx) => this.onSitemClicked(e, ctx),
      }),
    };
  },
  methods: {
    async loadEstms() {
      let params = new FormData();
      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", this.srchDt);
      try {
        const res = await this.$http.post('/sales/getEstmList', params);
        const estms = res.data.estmList.map(estm => ({
          ...estm,
          estm_sno: estm.estm_sno != null && `${estm.estm_sno}`,
          estm: estm.estm_yn === 'Y',
          fax: estm.fax_yn === 'Y',
          email: estm.email_yn === 'Y',
        }));

        this.estms = estms;

      } catch (e) {
        console.log(`Error on function loadEstms: ${e}`);
      }
    },
    async loadEstm(estmSno) {

      if (estmSno === DUMMY_ESTM_SNO) {
        const estm = genEmptyEstm({
          estm_sno: DUMMY_ESTM_SNO,
          estm_dt: this.srchDt,
        });
        const estmDtls = [];
        this.estm = JSON.parse(JSON.stringify(estm));
        this.pendingEstm = JSON.parse(JSON.stringify(estm));
        this.restoreEstm = null;
        this.estmDtls = JSON.parse(JSON.stringify(estmDtls));
        this.pendingDtls = JSON.parse(JSON.stringify(estmDtls));
        this.restoreDtls = null;
        return;
      }

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      params.append("comp_sno", compSno);
      params.append("estm_sno", estmSno);
      try {
        this.isDtlGridChanging = true;

        const res = await this.$http.post('/sales/getEstm', params);
        const estm = {
          ...res.data.estm,
          estm_sno: res.data.estm.estm_sno != null && `${res.data.estm.estm_sno}`,
          vat_amt: res.data.estm.vat_amt + '',
        };
        const estmDtls = res.data.estmDtlList.map(dtl => ({
          ...dtl,
          uuid: uuidv4(),
          new_yn: false,
          select_yn: false,
          spec: this.makeEstmDtlSpec(dtl),
          chamf_mchng_yn: dtl.chamf_mchng_yn === 'Y',
          thck_mchng_yn: dtl.thck_mchng_yn === 'Y',
        }));
        this.estm = JSON.parse(JSON.stringify(estm));
        this.pendingEstm = JSON.parse(JSON.stringify(estm));
        this.restoreEstm = null;
        this.estmDtls = JSON.parse(JSON.stringify(estmDtls));
        this.pendingDtls = JSON.parse(JSON.stringify(estmDtls));
        this.restoreDtls = null;

        await Promise.all(estmDtls.filter(dtl => dtl.sitem_cd).map(({sitem_cd}) =>
            this.$store.dispatch('cacheSitemUnitPrice', {
              sitemCd: sitem_cd,
              custrSno: estm.custr_sno,
            })
        ));

      } catch (e) {
        console.log(`Error on function loadEstm: ${e}`);
      }
    },
    onEstmGridInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        if (this.isOnPendingEstm) return;

        const estmSno = flex.getCellData(ht.row, 0);
        if (estmSno !== this.pendingEstm.estm_sno)
          await this.loadEstm(estmSno);
      });
      this.estmGrid = flex;
    },
    onEstmGridChanging() {
      // console.log("estimate > itemsSourceChanging");
      // if(this.flexGrid && !this.isDestroying) {
      //   this.isItemsSourceChanging = true;
      //   this.$saveFlexGridState(this.flexGrid);
      // }
    },
    onEstmGridChanged() {
      // console.log("itemsSourceChanged:", this.flexGrid, this.selectedRow, this.gridList.length, this.isItemsSourceChanging, this.getListMode);
      // if(this.flexGrid && !this.isDestroying) {
      //   this.$restoreFlexGridState(this.flexGrid);
      // }
      //
      // if(this.gridList.length == 0) {
      //   this.isNew = true;
      //   this.initEstmInfo();
      // }
      //
      // if(this.flexGrid != null && !this.isDestroying && this.gridList.length > 0 && this.flexGrid.collectionView.itemCount > 0) {
      //   this.flexGrid.select(-1, 0);
      //   this.flexGrid.rows[0].isSelected = false;
      //
      //   // 그리드 내 항목의 위치 설정
      //   if(this.getListMode == "select") {
      //     // 첫 항목
      //     this.selectedRow = 0;
      //     this.selectedCol = 2;
      //   } else if(this.getListMode == "insert" || this.getListMode == "update") {
      //     console.log("this.estm_sno:", this.estm_sno);
      //     // 추가된 항목
      //     for(let i = 0; i < this.gridList.length; i++) {
      //       if(this.gridList[i].estm_sno == this.estm_sno) {
      //         this.selectedRow = i;
      //         break;
      //       }
      //     }
      //   } else if(this.getListMode == "delete") {
      //     // 다음 항목. 마지막인 경우 이전 항목
      //     this.selectedRow = this.selectedRow < this.gridList.length ? this.selectedRow : this.selectedRow - 1;
      //   }
      // }
      //
      // if(this.isItemsSourceChanging && this.flexGrid != null && !this.isDestroying && this.gridList.length > 0 && this.selectedRow >= 0 && this.flexGrid.collectionView.itemCount > 0) {
      //   console.log("selected:", this.selectedRow, this.selectedCol);
      //   this.flexGrid.rows[0].isSelected = false;
      //   this.flexGrid.select(this.selectedRow, this.selectedCol);
      //   this.estmSno = this.flexGrid.getCellData(this.selectedRow, 0);
      //   this.custr_sno = this.flexGrid.getCellData(this.selectedRow, 1);
      //
      //   // row에 isSelected 설정 시 Ctrl + C -> isSelected row 가 복사됨, 마우스 드래그한 블럭 복사 안됨
      //   // flexgrid의 selectionMode를 'Row'로 설정할 경우 주석 처리해야 함
      //   // this.flexGrid.rows[this.selectedRow].isSelected = true;
      //
      //   this.isItemsSourceChanging = false;
      //   this.getEstm();
      // }
    },
    onEstmDtlGridInit(flex) {
      this.estmDtlGrid = flex;
      // const headerTemplate = '<label><input type="checkbox" id="chkSelectAll" /> All</label>';
      // flex.columns.push(new wjGrid.Column({
      //   header: headerTemplate,
      //   width: 100,
      //   // isReadOnly: true // 헤더는 읽기 전용으로 설정
      // }));

      this.selector = new Selector(flex, {
        itemChecked: () => {
          // this.selectedItems = flex.rows.filter(r => r.isSelected);
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
          // console.log("onEstmDtlGridInit this.selector itemChecked", flex);
        }
      });

      flex.selectionMode = "MultiRange";
    },
    onEstmDtlGridChanged() {
      if(this.pendingEstm.suply_amt != null) {
        this.isDtlGridChanging = false;
      }
      // console.log("dtlItemsSourceChanged:", this.flexDtl, this.selectedDtlRow, this.estmDtlList.length, this.isItemsSourceChanging, this.getListMode);
      // // console.log("estmDtlList.length:", this.estmDtlList.length);
      //
      // this.selectedDtlRow = -1;
      //
      // // 견적 계산용 정보 조회
      // this.estmDtlList.forEach((e) => {
      //   this.setSitemForCalc(e.sitem_cd, e.sitem_nm);
      // });
    },
    onEstmDtlEditEnding(flex, event) {
      const pendingDtl = this.pendingDtls[event.row];
      this.restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
    },
    async onEstmDtlEditEnded(flex, event) {
      const pendingEstm = this.pendingEstm;
      const pendingDtl = this.pendingDtls[event.row];
      const restoreDtl = this.restoreDtl;
      this.restoreDtl = null;
      const column = flex.columns[event.col];

      await this.refreshEstmDtl(column.binding, pendingEstm, pendingDtl, restoreDtl);
    },
    async refreshEstmDtl(binding, pendingEstm, pendingDtl, restoreDtl) {
      if ('select_yn' !== binding && !this.pendingEstm.custr_sno) {
        alert('거래처를 선택하세요.');
        Object.assign(pendingDtl, restoreDtl);
        return this.estmDtlGrid.invalidate();
      }
      if ('sitem_cd' === binding) {
        const sitem = await this.makeEstmDtlSitem(pendingEstm, pendingDtl);
        if (!sitem) {
          alert('품목이 존재하지 않습니다.');
          Object.assign(pendingDtl, restoreDtl);
          return this.estmDtlGrid.invalidate();
        }
        Object.assign(pendingDtl, sitem);
        pendingDtl.width_len = null;
        pendingDtl.depth_phi = null;
        pendingDtl.spec = this.makeEstmDtlSpec(pendingDtl);
        const loss = await this.makeEstmDtlLoss(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, loss);
      }
      if ('mchng_range' === binding) {
        const price = await this.makeEstmDtlPrice(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('width_len' === binding) {
        pendingDtl.spec = this.makeEstmDtlSpec(pendingDtl);
        const loss = await this.makeEstmDtlLoss(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, loss);
        const price = await this.makeEstmDtlPrice(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('depth_phi' === binding) {
        pendingDtl.spec = this.makeEstmDtlSpec(pendingDtl);
        const price = await this.makeEstmDtlPrice(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('qty' === binding) {
        const price = await this.makeEstmDtlPrice(pendingEstm, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      this.estmDtlGrid.invalidate();
      // this.pendingDtls = this.pendingDtls.map((dtl, index) => index !== event.row ? dtl : pendingDtl)
    },
    // onCheckAll(event) {
    //   this.pendingDtls.forEach(dtl => dtl.select_yn = event.target.checked);
    //   this.estmDtlGrid.invalidate();
    // },
    async onSitemClicked(event, context) {
      const pendingEstm = this.pendingEstm;
      const pendingDtl = this.pendingDtls[context.row.index];

      if (!pendingEstm.custr_sno)
        return alert('거래처가 설정되지 않았습니다.');

      const [sitemCd] = await new Promise(r => this.popup.sitem = {
        isOpened: true,
        onClose: (...param) => r(param)
      });
      this.popup.sitem.isOpened = false;
      if (!sitemCd) return;

      const restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
      pendingDtl.sitem_cd = sitemCd;
      await this.refreshEstmDtl('sitem_cd', pendingEstm, pendingDtl, restoreDtl);
    },
    async makeEstmDtlSitem(estm, estmDtl) {
      const [,, sitem] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: estmDtl.sitem_cd,
        custrSno: estm.custr_sno,
      });
      if (!sitem) return;
      return {
        sitem_cd: sitem.sitem_cd,
        sitem_nm: sitem.sitem_nm,
        unit: sitem.unit,
        thck: sitem.thck,
        mchng_thck: sitem.thck,
        spgrv: sitem.spgrv,
      };
    },
    makeEstmDtlSpec(estmDtl) {
      return `${estmDtl.thck || '-'}T*${estmDtl.width_len || '-'}*${estmDtl.depth_phi || '-'}`;
    },
    async makeEstmDtlLoss(estm, estmDtl) {
      if (!estmDtl.sitem_cd) return {};
      const [,,, sitemPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: estmDtl.sitem_cd,
        custrSno: estm.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width - l.width);   // '<' -> '-' : r.width 값이 클 때 순서 바꿈
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width <= estmDtl.width_len);  // '<' -> '<='  : 이상(geq) 값이 같은 경우도 해당함
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice || {};
      // console.log("makeEstmDtlLoss:", sortedItemPrices, retrievedItemPrice, firstItemPrice, itemPrice, estmDtl);
      estmDtl.thck_loss = itemPrice.thck_loss;
      estmDtl.width_len_loss = itemPrice.width_len_loss;
      estmDtl.depth_phi_loss = itemPrice.depth_phi_loss;

      return {
        thck_loss: itemPrice.thck_loss,
        width_len_loss: itemPrice.width_len_loss,
        depth_phi_loss: itemPrice.depth_phi_loss,
      };
    },
    async makeEstmDtlPrice(estm, estmDtl) {
      if (!estmDtl.sitem_cd || !estmDtl.spgrv || !estmDtl.width_len || !estmDtl.depth_phi || !estmDtl.qty)
        return genEmptyEstmDtlPrice();
      const [,,, sitemPrices, mchngPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: estmDtl.sitem_cd,
        custrSno: estm.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width < l.width);
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width < estmDtl.width_len);
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice;
      if (!itemPrice) return genEmptyEstmDtlPrice();

      const width_len = Math.floor(Number(estmDtl.width_len) * 100) / 100;
      const width_len_loss = Math.floor(Number(estmDtl.width_len_loss) * 100) / 100;
      const depth_phi = Math.floor(Number(estmDtl.depth_phi) * 100) / 100;
      const depth_phi_loss = Math.floor(Number(estmDtl.depth_phi_loss) * 100) / 100;
      const thck = Math.floor(Number(estmDtl.thck) * 100) / 100;
      const thck_loss = Math.floor(Number(estmDtl.thck_loss) * 100) / 100;

      const widthSum = width_len + width_len_loss; // 가로 mm
      const depthPhiSum = depth_phi + depth_phi_loss; // 세로 mm
      const thckSum = thck + thck_loss; // 두께 mm
      const face = widthSum * depthPhiSum; // 윗면 mm2
      const side = depthPhiSum * thckSum; // 옆면 mm2
      const front = widthSum * thckSum; // 옆면 mm2

      // 소재단가 = 가로, 세로 단가
      const sitem_unit_price = estmDtl.depth_phi < 1000 ? itemPrice.price1 : itemPrice.price2;
      // 중량 = (두께mm + 두께로스mm) * (가로mm + 가로로스mm) * (세로mm + 세로로스mm) * 수량 * 비중 / 1_000_000
      const wegtCalc = widthSum /* mm */ * depthPhiSum /* mm */ * thckSum /* mm */ *
          estmDtl.qty * estmDtl.spgrv / 1_000_000; // 10 cm2 == 1kg
      const _wegt = Math.max(wegtCalc, itemPrice.min_wegt);
      const wegt = Math.floor(_wegt * 100_000) / 100_000;
      // 소재비 = 소재단가 * 중량
      const sitem_amt = Math.floor(sitem_unit_price * wegt);

      const [
          face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt
      ] = (() => {
        // if (!estmDtl.mchng_range) return [];
        if (!estmDtl.mchng_range) return [null, null, null, null, null, sitem_amt];

        // 가공단가
        const mchngMin = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'min');
        const mchngCalc = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'calc');

        const mchngItems = estmDtl.mchng_range.split('_');
        // 면 가공비
        const face_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f1': face,
          'f2': face * 2
        }[cur] || 0), 0) / 100;
        const _face_mchng_amt = 0 < face_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.face_price * face_mchng_unit_calc, mchngMin && mchngMin.face_price || 0) * estmDtl.qty
            : 0;
        const face_mchng_amt = Math.floor(_face_mchng_amt);
        // 각 가공비
        const side_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          's2': front * 2,
          's4': front * 2 + side * 2,
        }[cur] || 0), 0) / 100;
        const _side_mchng_amt = 0 < side_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.side_price * side_mchng_unit_calc, mchngMin && mchngMin.side_price || 0) * estmDtl.qty
            : 0;
        const side_mchng_amt = Math.floor(_side_mchng_amt);
        // 스퀘어 가공비
        const sqre_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f6': face * 2 + front * 2 + side * 2
        }[cur] || 0), 0) / 100;
        const _sqre_mchng_amt = 0 < sqre_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.sqre_price * sqre_mchng_unit_calc, mchngMin && mchngMin.sqre_price || 0) * estmDtl.qty
            : 0;
        const sqre_mchng_amt = Math.floor(_sqre_mchng_amt);
        // 연마비
        const _grind_mchng_amt = mchngItems.reduce((acc, cur) => acc + ({
          'g': Math.max(mchngCalc && mchngCalc.grind_price, mchngMin && mchngMin.grind_price || 0)
        }[cur] || 0), 0) * estmDtl.qty;
        const grind_mchng_amt = Math.floor(_grind_mchng_amt);
        // 기타 가공비
        const etc_mchng_amt = Math.max(mchngCalc && mchngCalc.etc_price, mchngMin && mchngMin.etc_price || 0) * estmDtl.qty;
        console.log("etc_mchng_amt : ", etc_mchng_amt, mchngCalc, mchngMin);
        // 합계
        const sum_amt = sitem_amt + face_mchng_amt + side_mchng_amt + sqre_mchng_amt + grind_mchng_amt + etc_mchng_amt;

        return [face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt];
      })();

      return {
        sitem_unit_price, wegt, sitem_amt,
        face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt,
        sum_amt,
      };
    },
    onCreateEstm() {
      const isOnPendingEstm = this.isOnPendingEstm;
      if (isOnPendingEstm && !confirm('저장되지 않은 데이터가 있습니다. 새 견적을 추가하시겠습니까?'))
        return;

      const canRestore = this.estm && !!this.estm.estm_sno;
      const restoreEstm = canRestore ? JSON.parse(JSON.stringify(this.estm)) : null;
      const restoreDtls = canRestore ? JSON.parse(JSON.stringify(this.estmDtls)) : null;

      const estm = genEmptyEstm({
        estm_dt: this.srchDt,
      });
      this.estm = JSON.parse(JSON.stringify(estm));
      this.pendingEstm = JSON.parse(JSON.stringify(estm));
      this.restoreEstm = restoreEstm;

      this.estmDtls = [];
      this.pendingDtls = [];
      this.restoreDtls = restoreDtls;
    },
    verifyEstm() {
      const pendingEstm = this.pendingEstm;
      if (!pendingEstm.estm_dt) {
        alert('견적일자는 필수 입력값입니다.');
        return false;
      }
      // if (!pendingEstm.out_ship_scdul_dt) {
      //   alert('출고예정일은 필수 입력값입니다.');
      //   return false;
      // }
      // if (!pendingEstm.estm_stus) {
      //   alert('견적상태는 필수 입력값입니다.');
      //   return false;
      // }
      // if (!pendingEstm.estm_nm) {
      //   alert('견적명은 필수 입력값입니다.');
      //   return false;
      // }
      if (!pendingEstm.custr_sno) {
        alert('거래처는 필수 입력값입니다.');
        return false;
      }
      // if (!pendingEstm.tax_div) {
      //   alert('세액구분은 필수 입력값입니다.');
      //   return false;
      // }
      return true;
    },
    async onEstmSave() {
      if (!this.verifyEstm()) return;

      const params = new URLSearchParams();
      params.append('new_yn', !this.pendingEstm.estm_sno ? 'Y' : 'N');
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('comp_sno', this.$store.state.session.compSno);
      params.append('custr_sno', this.pendingEstm.custr_sno);
      params.append('estm_sno', this.pendingEstm.estm_sno);
      params.append('estm_nm', this.pendingEstm.estm_nm);
      params.append('estm_dt', this.pendingEstm.estm_dt);
      params.append('out_ship_scdul_dt', this.pendingEstm.out_ship_scdul_dt);
      params.append('estm_stus', this.pendingEstm.estm_stus);
      params.append('tax_div', this.pendingEstm.tax_div);

      if (null != this.pendingEstm.valid_limit) params.append('valid_limit', this.pendingEstm.valid_limit);
      if (null != this.pendingEstm.pay_cond) params.append('pay_cond', this.pendingEstm.pay_cond);
      if (null != this.pendingEstm.dlv_cond) params.append('dlv_cond', this.pendingEstm.dlv_cond);
      params.append('suply_amt', `${this.suplyAmt}`);
      params.append('vat_amt', `${this.vatAmt}`);
      params.append('sum_amt', `${this.sumAmt}`);
      params.append('rm', this.pendingEstm.rm);

      const estmDtls = this.pendingDtls.filter(({sitem_cd}) => sitem_cd).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('estmDtlList', JSON.stringify(estmDtls));
      const deletedDtls = this.estmDtls.filter(({uuid}) => this.pendingDtls.every(pending => pending.uuid !== uuid)).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('deletedDtlList', JSON.stringify(deletedDtls));

      try {
        const res = await this.$http.post('/sales/upsertEstm', params);
        if (res.data.code === 0)
          await this.loadEstm(res.data.estm_sno);
        await this.loadEstms();

      } catch (e) {
        console.log(`Error on function onEstmSave: ${e}`);
      }
    },
    async onEstmDelete() {
      if (!confirm('삭제하시겠습니까?')) return;

      const params = new FormData();
      params.append('estm_sno', this.pendingEstm.estm_sno);

      await this.$http.post('/sales/deleteEstm', params);
      alert('삭제되었습니다.');

      this.pendingEstm = genEmptyEstm({
        estm_sno: DUMMY_ESTM_SNO,
        estm_dt: this.srchDt,
      });

      await this.loadEstms();
    },
    onEstmCancel() {
      if (this.isOnPendingEstm && !confirm('편집을 취소하시겠습니까?'))
      if (!confirm('편집을 취소하시겠습니까?'))
        return;

      this.isDtlGridChanging = true;

      const restoreEstm = this.restoreEstm || this.estm;
      const restoreDtls = this.restoreDtls || this.estmDtls;
      this.estm = JSON.parse(JSON.stringify(restoreEstm));
      this.estmDtls = JSON.parse(JSON.stringify(restoreDtls));
      this.pendingEstm = JSON.parse(JSON.stringify(restoreEstm));
      this.pendingDtls = JSON.parse(JSON.stringify(restoreDtls));
      this.restoreEstm = null;
      this.restoreDtls = null;
    },
    async onCustr() {
      const custrSno = await new Promise(r => {
        this.popup.custr = {
          isOpened: true, onClose: r
        }
      });
      if (custrSno) {
        const params = new FormData();
        params.append("custr_sno", custrSno);

        try {
          const res = await this.$http.post('/base/getCustrDetail', params)
          const custr = res.data.custr;

          this.pendingEstm.custr_sno = custr.custr_sno;
          this.pendingEstm.custr_nm = custr.custr_nm;
          this.pendingEstm.repr_nm = custr.repr_nm;
          this.pendingEstm.tel_no = custr.tel_no;
          this.pendingEstm.fax_no = custr.fax_no;
          this.pendingEstm.biz_rno = custr.biz_rno;
          this.pendingEstm.biz_plc_addr = custr.biz_plc_addr;
          this.pendingEstm.chrgr_nm = custr.chrgr_nm;
          this.pendingEstm.chrgr_cel_no = custr.chrgr_cel_no;
          this.pendingEstm.chrgr_email = custr.chrgr_email;

        } catch (e) {
          console.log(`Error on function onCustr: ${e}`);
        }
      }
      this.popup.custr = {isOpened: false};
    },
    onEstmDtlAdd() {
      const nextOdr = this.pendingDtls.reduce((acc, {odr}) => odr < acc ? acc : odr, 0) + 1;
      this.pendingDtls = [
        ...this.pendingDtls, {
          ...genEmptyEstmDtl(),
          uuid: uuidv4(),
          new_yn: true,
          sitem_cd: null,
          odr: nextOdr,
          select_yn: false,
          chamf_mchng_yn: false,
          thck_mchng_yn: false,
        }
      ];
    },
    onEstmDtlDelete() {
      this.pendingDtls = this.pendingDtls.filter(({select_yn}) => !select_yn);
      console.log("onEstmDtlDelete", this.estmDtlGrid);
    },
    // 복사 버튼
    onEstmCopy() {
      this.isCopyOpened = true;
    },
    copyConfirmed(trgtDt, scdulDt) {
      console.log("copyConfirmed : ", trgtDt, scdulDt);
      this.isCopyOpened = false;

      this.copyEstm(trgtDt, scdulDt);
    },
    async copyEstm(trgtDt, scdulDt) {
      const params = new FormData();

      console.log("copyEstm > this.$store.state.session.userSno : ", this.$store.state.session.userSno);

      params.append('user_sno', this.$store.state.session.userSno);
      params.append("estm_sno", this.pendingEstm.estm_sno);
      params.append("trgt_dt", trgtDt);
      params.append("scdul_dt", scdulDt);

      try {
        const res = await this.$http.post('/sales/copyEstm', params);
        if (res.data.code === 0) {
          alert("복사되었습니다.");
          // await this.loadEstm(res.data.estm_sno);
          this.loadEstms();
        }
      } catch (e) {
        console.log(`Error on function copyEstm: ${e}`);
      }
    },
    // 수주/출고 처리
    onEstmStep(procType) {
      this.stepProcType = procType;
      this.isStepOpened = true;
    },
    stepProcess(procType, trgtDt, scdulDt, payMthd) {
      console.log("stepProcess : ", procType, trgtDt, scdulDt);
      this.isStepOpened = false;

      this.stepProcessEstm(procType, trgtDt, scdulDt, payMthd);
    },
    async stepProcessEstm(procType, trgtDt, scdulDt, payMthd) {
      const params = new FormData();

      params.append('user_sno', this.$store.state.session.userSno);
      params.append("estm_sno", this.pendingEstm.estm_sno);
      params.append("trgt_dt", trgtDt);

      let apiNm = "";
      if(procType == "salesOrd") {
        params.append("scdul_dt", scdulDt);
        apiNm = "stepProcEstmToOrd";
      } else if(procType == "sales") {
        params.append("pay_mthd", payMthd);
        apiNm = "stepProcEstmToSales";
      }

      try {
        const res = await this.$http.post(`/sales/${apiNm}`, params);
        if (res.data.code === 0) {
          alert("처리되었습니다.");
          // await this.loadEstm(res.data.estm_sno);
          this.loadEstms();
        }
      } catch (e) {
        console.log(`Error on function stepProcessEstm: ${e}`);
      }
    },
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.estimate.code);
    },
    estmStusFilterCombo() {
      return [
        {cls_cd: 'estm_stus', cmn_cd: 'all', cmn_cd_nm: '전체', disp_odr: '0'},
        ...this.$store.state.commonCode.estm_stus
      ];
    },
    filteredEstms() {
      return this.estms.filter(({custr_nm, estm_stus}) =>
          (!this.searchWord || this.searchWord.trim() === '' ||
              custr_nm && custr_nm.toUpperCase().includes(this.searchWord.toUpperCase())) &&
          (this.estmStus === 'all' || this.estmStus === estm_stus)
      );
    },
    isEstmCancellationDisabled() {
      if (this.isOnPendingEstm) return false

      if  (this.restoreEstm) return false

      return true;
    },
    isEstmSaveDisabled() {
      if (!this.pendingEstm.estm_sno) return false;
      if (this.isOnPendingEstm) return false

      return true;
    },
    isOnPendingEstm() {
      console.log("isOnPendingEstm", this.estm, this.pendingEstm);
      return !this.isDummy && 
          ( JSON.stringify(this.estm) !== JSON.stringify(this.pendingEstm) ||
            JSON.stringify(this.estmDtls.map(dtl => ({...dtl, select_yn: false}))) !== JSON.stringify(this.pendingDtls.map(dtl => ({...dtl, select_yn: false}))) );
    },
    isDummy() {
      return this.estm && this.estm.estm_sno == DUMMY_ESTM_SNO ? true : false;
    },
    dtlsWegt() {
      return Math.round(this.pendingDtls.reduce((acc, {wegt}) => wegt ? acc + wegt : acc, 0), 2);
    },
    dtlsQty() {
      return this.pendingDtls.reduce((acc, {qty}) => qty ? acc + qty : acc, 0)
    },
    sitemUnits() {
      return new wjGrid.DataMap(this.$store.state.commonCode.sitem_unit, 'cmn_cd', 'cmn_cd_nm')
    },
    mchngRanges() {
      return new wjGrid.DataMap(this.$store.state.commonCode.mchng_range, 'cmn_cd', 'cmn_cd_nm')
    },
    _suplyAmt() {
      const amt = this.pendingDtls.reduce((acc, {sum_amt}) => acc + (null != sum_amt ? Number(sum_amt) : 0), 0);
      return Math.floor(amt);
    },
    _vatAmt() {
      const amt = this.pendingEstm.tax_div === 'tax' ? Number(this.suplyAmt) / 10 : 0;
      return Math.floor(amt);
    },
    suplyAmt() {
      return Number(this.pendingEstm.suply_amt);
    },
    vatAmt() {
      return Number(this.pendingEstm.vat_amt);
    },
    sumAmt() {
      return Number(this.suplyAmt) + Number(this.vatAmt);
    },
    isEstmDtlDeletDisabled() {
      return !this.pendingDtls.some(({select_yn}) => select_yn);
    },
  },
  watch: {
    async filteredEstms(newValue) {
      const [first] = newValue;
      const sno = first && first.estm_sno || DUMMY_ESTM_SNO;
      await this.loadEstm(sno);
    },
    srchDt: {
      immediate: true,
      handler: function () {
        this.loadEstms();
      },
    },
    _suplyAmt: {
      immediate: true,
      handler: function () {
        if(this.isDtlGridChanging) {
          return;
        }
        this.pendingEstm.suply_amt = this._suplyAmt;
      },
    },
    _vatAmt: {
      immediate: true,
      handler: function () {
        if(this.isDtlGridChanging) {
          return;
        }
        this.pendingEstm.vat_amt = this._vatAmt;
      },
    }
  }
}
</script>

<style>
  /* .wj-colheaders .wj-header{
    background: #ff0000;
    text-align: center !important;
  } */
</style>

