<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>거래처별 현황</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="gridDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="sitem">품목별</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="day">일별</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="month">월별</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="salesPurch">매출/매입</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="colctPay">수금/지급</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center">
              <!-- 품목별 -->
              <v-col cols="12" v-show="gridDiv == 'sitem'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <!-- 거래처 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">거래처</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustrBySitem"
                              @focusout="searchWordCustrBySitem = pendingWordCustrBySitem"
                              @keyup.enter="searchWordCustrBySitem = pendingWordCustrBySitem"
                              clearable
                              @click:clear="searchWordCustrBySitem = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center" class="">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ ledgrListBySitem.length - 1 }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="5">
                                    <v-row dense align="center">
                                      <v-col cols="4">
                                        <date-picker v-model="srchBgnDtBySitem"></date-picker>
                                      </v-col>
                                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                      <v-col cols="4">
                                        <date-picker v-model="srchEndDtBySitem"></date-picker>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-radio-group
                                        v-model="dtlDiv"
                                        hide-details
                                        row
                                        dense
                                        class="ma-0 pa-0"
                                        >
                                        <v-radio 
                                          :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                                          v-for="item in dtlDivList"
                                          >
                                        </v-radio>
                                      </v-radio-group>
                                    </v-row>
                                  </v-col> 
                                  
                                </v-row>
                              </v-col>
                            </v-row>  
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- 거래처 그리드 row -->
                    <v-row dense align="center">              
                      <!-- 거래처 그리드 -->
                      <v-col cols="2">
                        <wj-flex-grid 
                          :isReadOnly=true
                          :itemsSource="filteredCustrListBySitem"
                          :selectionMode="'ListBox'"
                          style="height:650px;"
                          :initialized="onFlexCustrBySitemInit"
                          :itemsSourceChanged="onFlexCustrBySitemItemsSourceChanged"
                          :headersVisibility="'Column'"
                          >
                          <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                          <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*" />
                          <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="5*" align="center"/>
                        </wj-flex-grid>
                      </v-col>
                      <!-- 통계 영역 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <!-- 통계 row -->
                          <v-col cols="12">
                            <v-row dense align="center" class="">
                              <v-col cols="5">
                                <v-row dense align="center">
                                  <v-col cols="3"><div class=""><span style="color:blue;">출고수량 : {{ statsBySitem.outShipQty }} 건</span></div></v-col>
                                  <v-col cols="3"><div class=""><span style="color:blue;">입고수량 : {{ statsBySitem.inShipQty }} 건</span></div></v-col>
                                  <v-col cols="3"><div class=""><span style="color:blue;">출고중량 : {{ statsBySitem.outShipWegt }} kg</span></div></v-col>
                                  <v-col cols="3"><div class=""><span style="color:blue;">입고중량 : {{ statsBySitem.inShipWegt }} kg</span></div></v-col>
                                </v-row>
                              </v-col>                 
                              <v-col cols="4">
                                <v-row dense align="center">
                                  <v-col cols="4"><div class=""><span style="color:blue;">매출 : {{ statsBySitem.salesAmt }} 원</span></div></v-col>
                                  <v-col cols="4"><div class=""><span style="color:blue;">매입 : {{ statsBySitem.purchAmt }} 원</span></div></v-col>
                                  <v-col cols="4"><div class=""><span style="color:blue;">수금 : {{ statsBySitem.colctAmt }} 원</span></div></v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="6"><div class=""><span style="color:blue;">지급 : {{ statsBySitem.payAmt }} 원</span></div></v-col>
                                  <v-col cols="6"><div class=""><span style="color:blue;">현미수금 : {{ statsBySitem.unColctPayAmt }} 원</span></div></v-col>
                                </v-row>
                              </v-col>
                              
                            </v-row>
                          </v-col>
                          <!-- 현황 그리드 -->
                          <v-col cols="12">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="ledgrListBySitem" 
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :itemsSourceChanged="onFlexLedgrBySitemItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'일자'" :binding="'ledgr_dt'" :width="90" align="center"/>
                              <wj-flex-grid-column :header="'구분'" :binding="'item_div_nm'" :width="50" align="center"/>
                              <wj-flex-grid-column :header="'품명'" :binding="'item_nm'" :width="120" />
                              <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="150" />
                              <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="50" align="center"/>
                              <wj-flex-grid-column :header="'비고'" :binding="'rm'" :width="200" :visible="dtlDiv == 'snglDtl'"/>
                              <wj-flex-grid-column :header="'가로/길이'" :binding="'width_len'" :width="80" align="right" :visible="dtlDiv == 'snglDtl'"/>
                              <wj-flex-grid-column :header="'세로'" :binding="'depth_phi'" :width="80" align="right" :visible="dtlDiv == 'snglDtl'"/>
                              <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="50" align="right"/>
                              <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="80" align="right"/>
                              <wj-flex-grid-column :header="'단가'" :binding="'sitem_unit_price'" :width="80" align="right"/>
                              <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                              <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                              <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="120" align="right"/>
                              <wj-flex-grid-column :header="'잔액'" :binding="'acmlt_amt'" :width="120" align="right" :css-class="customColumnStyle"/>
                            </wj-flex-grid>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 일별 -->
              <v-col cols="12" v-show="gridDiv == 'day'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <!-- 거래처 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">거래처</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustrByDay"
                              @focusout="searchWordCustrByDay = pendingWordCustrByDay"
                              @keyup.enter="searchWordCustrByDay = pendingWordCustrByDay"
                              clearable
                              @click:clear="searchWordCustrByDay = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 row -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ ledgrListByDay.length - 1 }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="5">
                                    <v-row dense align="center">
                                      <v-col cols="4">
                                        <date-picker v-model="srchBgnDtByDay"></date-picker>
                                      </v-col>
                                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                      <v-col cols="4">
                                        <date-picker v-model="srchEndDtByDay"></date-picker>
                                      </v-col>
                                    </v-row>
                                  </v-col>                          
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>  
                    </v-row>

                    <!-- 거래처 그리드 row -->
                    <v-row dense align="center">              
                      <v-col cols="12">
                        <v-row dense align="center">
                          <!-- 거래처 그리드 -->
                          <v-col cols="2">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredCustrListByDay"
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexCustrByDayInit"
                              :itemsSourceChanged="onFlexCustrByDayItemsSourceChanged"
                              :headersVisibility="'Column'"
                              >
                              <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                              <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*" />
                              <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="5*" align="center"/>
                            </wj-flex-grid>
                          </v-col>
                          <v-col cols="10">
                            <!-- 통계 row -->
                            <v-row dense align="center">
                              <v-col cols="12">
                                <v-row dense align="center" class="">
                                  <v-col cols="4">
                                    <v-row dense align="center">
                                      <v-col cols="4"><div class=""><span style="color:blue;">매출 : {{ statsByDay.salesAmt }} 원</span></div></v-col>
                                      <v-col cols="4"><div class=""><span style="color:blue;">매입 : {{ statsByDay.purchAmt }} 원</span></div></v-col>
                                      <v-col cols="4"><div class=""><span style="color:blue;">수금 : {{ statsByDay.colctAmt }} 원</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">지급 : {{ statsByDay.payAmt }} 원</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">현미수금 : {{ statsByDay.unColctPayAmt }} 원</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- 현황 그리드 -->
                              <v-col cols="12">
                                <wj-flex-grid 
                                  :isReadOnly=true
                                  :itemsSource="ledgrListByDay" 
                                  :selectionMode="'ListBox'"
                                  style="height:650px;"
                                  :itemsSourceChanged="onFlexLedgrByDayItemsSourceChanged"
                                  :headersVisibility="'Column'"
                                  :alternatingRowStep="0"
                                  >
                                  <wj-flex-grid-column :header="'일자'" :binding="'ledgr_dt'" :width="90" align="center"/>
                                  <wj-flex-grid-column :header="'구분'" :binding="'item_div_nm'" :width="50" align="center"/>
                                  <wj-flex-grid-column :header="'품명'" :binding="'item_nm'" :width="120"/>
                                  <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="150"/>
                                  <wj-flex-grid-column :header="'비고'" :binding="'rm'" :width="200"/>
                                  <wj-flex-grid-column :header="'세금계산서'" :binding="'txbil_issue_yn'" :width="90" align="center"/>
                                  <wj-flex-grid-column :header="'매출액(+VAT)'" :binding="'sales_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'매입액(+VAT)'" :binding="'purch_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'수금액'" :binding="'colct_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'지급금액'" :binding="'pay_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'누계금액'" :binding="'acmlt_amt'" :width="120" align="right" :css-class="customColumnStyle"/>
                                </wj-flex-grid>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>               
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <!-- 월별 -->
              <v-col cols="12" v-show="gridDiv == 'month'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- 조회 조건 row -->
                    <v-row dense align="center">
                      <!-- 거래처 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">거래처</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustrByMonth"
                              @focusout="searchWordCustrByMonth = pendingWordCustrByMonth"
                              @keyup.enter="searchWordCustrByMonth = pendingWordCustrByMonth"
                              clearable
                              @click:clear="searchWordCustrByMonth = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center" class="">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ ledgrListByMonth.length - 1 }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="2">
                                    <v-select
                                      class=""
                                      v-model="priodDiv"
                                      :items="priodDivList"
                                      item-value="cd"
                                      item-text="nm"
                                      hide-details dense outlined
                                    ></v-select>
                                  </v-col>  
                                  <v-col cols="5" v-show="priodDiv == 'sel'">
                                    <v-row dense align="center">
                                      <v-col cols="4">
                                        <date-picker v-model="srchBgnDtByMonth"></date-picker>
                                      </v-col>
                                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                      <v-col cols="4">
                                        <date-picker v-model="srchEndDtByMonth"></date-picker>
                                      </v-col>
                                    </v-row>
                                  </v-col>                        
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>  
                    </v-row>

                    <!-- 거래처 그리드 row -->
                    <v-row dense align="center">              
                      <v-col cols="12">
                        <v-row dense align="center">
                          <!-- 거래처 그리드 -->
                          <v-col cols="2" >
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredCustrListByMonth"
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexCustrByMonthInit"
                              :itemsSourceChanged="onFlexCustrByMonthItemsSourceChanged"
                              :headersVisibility="'Column'"
                              >
                              <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                              <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*" />
                              <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="5*" align="center"/>
                            </wj-flex-grid>
                          </v-col>
                          <v-col cols="10">
                            <!-- 통계 row -->
                            <v-row dense align="center">
                              <v-col cols="12">
                                <v-row dense align="center" class="">              
                                  <v-col cols="4">
                                    <v-row dense align="center">
                                      <v-col cols="4"><div class=""><span style="color:blue;">매출 : {{ statsByMonth.salesAmt }} 원</span></div></v-col>
                                      <v-col cols="4"><div class=""><span style="color:blue;">매입 : {{ statsByMonth.purchAmt }} 원</span></div></v-col>
                                      <v-col cols="4"><div class=""><span style="color:blue;">수금 : {{ statsByMonth.colctAmt }} 원</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">지급 : {{ statsByMonth.payAmt }} 원</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">현미수금 : {{ statsByMonth.unColctPayAmt }} 원</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- 현황 그리드 -->
                              <v-col cols="12">
                                <wj-flex-grid 
                                  :isReadOnly=true
                                  :itemsSource="ledgrListByMonth" 
                                  :selectionMode="'ListBox'"
                                  style="height:650px;"
                                  :itemsSourceChanged="onFlexLedgrByMonthItemsSourceChanged"
                                  :headersVisibility="'Column'"
                                  :alternatingRowStep="0"
                                  >
                                  <wj-flex-grid-column :header="'월'" :binding="'ledgr_mm'" :width="90" />
                                  <wj-flex-grid-column :header="'매출액'" :binding="'sales_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'매입액'" :binding="'purch_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'수금액'" :binding="'colct_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'지급액'" :binding="'pay_amt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'누계금액'" :binding="'acmlt_amt'" :width="120" align="right" :css-class="customColumnStyle"/>
                                </wj-flex-grid>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>               
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 매출/매입 -->
              <v-col cols="12" v-show="gridDiv == 'salesPurch'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- 조회 조건 row -->
                    <v-row dense align="center">
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="1"><div class=""><span>조회수 : {{ ledgrListSalesPurch.length }} 건</span></div></v-col>
                          <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                          <v-col cols="3">
                            <v-row dense align="center">
                              <v-col cols="5">
                                <date-picker v-model="srchBgnDtSalesPurch"></date-picker>
                              </v-col>
                              <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                              <v-col cols="5">
                                <date-picker v-model="srchEndDtSalesPurch"></date-picker>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustrSalesPurch"
                              @focusout="searchWordCustrSalesPurch = pendingWordCustrSalesPurch"
                              @keyup.enter="searchWordCustrSalesPurch = pendingWordCustrSalesPurch"
                              clearable
                              @click:clear="searchWordCustrSalesPurch = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1"><div class="f-r"><span>매입/매출 구분</span></div></v-col>
                          <v-col cols="1">
                            <v-select
                              class=""
                              v-model="salesPurchDiv"
                              :items="salesPurchDivList"
                              item-value="cd"
                              item-text="nm"
                              hide-details dense outlined
                            ></v-select>
                          </v-col>                          
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>

                    <v-row dense align="center">              
                      <v-col cols="12">
                        <!-- 통계 row -->
                        <v-row dense align="center">
                          <v-col cols="12">
                            <v-row dense align="center">
                              <v-col cols="2"><div class=""><span style="color:blue;">매출액 : {{ statsSalesPurch.salesAmt }} 원</span></div></v-col>
                              <v-col cols="2"><div class=""><span style="color:blue;">매입액 : {{ statsSalesPurch.purchAmt }} 원</span></div></v-col>
                            </v-row>
                          </v-col>
                          <!-- 현황 그리드 -->
                          <v-col cols="12">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredLedgrListSalesPurch" 
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :itemsSourceChanged="onFlexLedgrSalesPurchItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'상호'" :binding="'custr_nm'" :width="200" />
                              <wj-flex-grid-column :header="'구분'" :binding="'item_div_nm'" :width="60" align="center"/>
                              <wj-flex-grid-column :header="'연락처'" :binding="'tel_no'" :width="150"/>
                              <wj-flex-grid-column :header="'팩스'" :binding="'fax_no'" :width="150"/>
                              <wj-flex-grid-column :header="'이월금액'" :binding="'crovr_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'매출액(+VAT)'" :binding="'sales_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'매입액(+VAT)'" :binding="'purch_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'누계금액'" :binding="'acmlt_amt'" :width="150" :css-class="customColumnStyle"/>
                              <wj-flex-grid-column :header="'메모'" :binding="'memo'" :width="200" />
                            </wj-flex-grid>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 수금/지급 -->
              <v-col cols="12" v-show="gridDiv == 'colctPay'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- 조회 조건 row -->
                    <v-row dense align="center">
                      <v-col cols="10">
                        <v-row dense align="center" class="">
                          <v-col cols="12">
                            <v-row dense align="center">
                              <v-col cols="1"><div class=""><span>조회수 : {{ ledgrListColctPay.length }} 건</span></div></v-col>
                              <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtColctPay"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtColctPay"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                              <v-col cols="1">
                                <v-text-field
                                  class=""
                                  hide-details
                                  dense
                                  outlined
                                  v-model="pendingWordCustrColctPay"
                                  @focusout="searchWordCustrColctPay = pendingWordCustrColctPay"
                                  @keyup.enter="searchWordCustrColctPay = pendingWordCustrColctPay"
                                  clearable
                                  @click:clear="searchWordCustrColctPay = ''"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1"><div class="f-r"><span>수금/지급 구분</span></div></v-col>
                              <v-col cols="1">
                                <v-select
                                  class=""
                                  v-model="colctPayDiv"
                                  :items="colctPayDivList"
                                  item-value="cd"
                                  item-text="nm"
                                  hide-details dense outlined
                                ></v-select>
                              </v-col>                          
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>

                    <v-row dense align="center">              
                      <v-col cols="12">
                        <!-- 통계 row -->
                        <v-row dense align="center">
                          <v-col cols="12">
                            <v-row dense align="center">
                              <v-col cols="2"><div class=""><span style="color:blue;">수금액 : {{ statsColctPay.colctAmt }} 원</span></div></v-col>
                              <v-col cols="2"><div class=""><span style="color:blue;">지급액 : {{ statsColctPay.payAmt }} 원</span></div></v-col>
                            </v-row>
                          </v-col>
                          <!-- 현황 그리드 -->
                          <v-col cols="12">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredLedgrListColctPay" 
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :itemsSourceChanged="onFlexLedgrColctPayItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'상호'" :binding="'custr_nm'" :width="200" />
                              <wj-flex-grid-column :header="'구분'" :binding="'item_div_nm'" :width="60"/>
                              <wj-flex-grid-column :header="'담당자'" :binding="'chrgr_nm'" :width="150"/>
                              <wj-flex-grid-column :header="'연락처'" :binding="'chrgr_tel_no'" :width="150"/>
                              <wj-flex-grid-column :header="'이월금액'" :binding="'crovr_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'수금액'" :binding="'colct_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'지급액'" :binding="'pay_sum_amt'" :width="150" />
                              <wj-flex-grid-column :header="'누계금액'" :binding="'acmlt_amt'" :width="150" :css-class="customColumnStyle"/>
                              <wj-flex-grid-column :header="'메모'" :binding="'rm'" :width="200" />
                            </wj-flex-grid>
                          </v-col>   
                        </v-row>         
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

// . 통계 정보
const qty = {inShipQty: 0, outShipQty: 0, inShipWegt: 0, outShipWegt: 0};
const amt = {salesAmt: 0, purchAmt: 0, colctAmt: 0, payAmt: 0, unColctAmt: 0, unColctPayAmt: 0};

export default {
  components: {datePicker},
  data(){
    return{
      compSno: '',
      userSno: 0,

      // 공통
      gridDiv: "sitem",   // sitem, day, month, salesPurch, colctPay

      custrList: [],
      
      // 품목별
      custrSnoBySitem: '',
      searchWordCustrBySitem: "",
      pendingWordCustrBySitem: "",

      srchBgnDtBySitem: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtBySitem: dayjs().format('YYYY-MM-DD'),

      dtlDiv: "sngl",
      dtlDivList: [
        {cd: "sngl", nm: "간단 조회"},
        {cd: "snglDtl", nm: "상세 조회"},
      ],

      statsBySitem: {...qty, ...amt},

      flexCustrBySitem: null,
      flexLedgrBySitem: null,
      ledgrListBySitem: [],

      // 일별
      custrSnoByDay: '',
      searchWordCustrByDay: "",
      pendingWordCustrByDay: "",

      srchBgnDtByDay: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtByDay: dayjs().format('YYYY-MM-DD'),

      statsByDay: {...amt},

      flexCustrByDay: null,
      flexLedgrByDay: null,
      ledgrListByDay: [],

      // 월별
      custrSnoByMonth: '',
      searchWordCustrByMonth: "",
      pendingWordCustrByMonth: "",

      // srchMmCnt: '12',
      priodDiv: "m12",
      priodDivList: [
        {cd: "m12", nm: "최근 12개월"},
        {cd: "m06", nm: "최근 6개월"},
        {cd: "m03", nm: "최근 3개월"},
        {cd: "sel", nm: "직접 선택"},
      ],

      srchBgnDtByMonth: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtByMonth: dayjs().format('YYYY-MM-DD'),

      statsByMonth: {...amt},

      flexCustrByMonth: null,
      flexLedgrByMonth: null,
      ledgrListByMonth: [],

      // 매출/매입
      srchBgnDtSalesPurch: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtSalesPurch: dayjs().format('YYYY-MM-DD'),

      searchWordCustrSalesPurch: "",
      pendingWordCustrSalesPurch: "",

      salesPurchDiv: "all",
      salesPurchDivList: [
        {cd: "all", nm: "전체"},  
        {cd: "sales", nm: "매출"},
        {cd: "purch", nm: "매입"},
      ],

      statsSalesPurch: {...amt},

      flexLedgrSalesPurch: null,
      ledgrListSalesPurch: [],

      // 수금/지급
      srchBgnDtColctPay: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtColctPay: dayjs().format('YYYY-MM-DD'),

      searchWordCustrColctPay: "",
      pendingWordCustrColctPay: "",

      colctPayDiv: "all",
      colctPayDivList: [
        {cd: "all", nm: "전체"},  
        {cd: "colct", nm: "수금"},
        {cd: "pay", nm: "지급"},
      ],

      statsColctPay: {...amt},

      flexLedgrColctPay: null,
      ledgrListColctPay: [],

    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.ledgrByCustr.code);
    },
    filteredCustrListBySitem() {
      return this.custrList.filter(({custr_nm}) => 
        (!this.searchWordCustrBySitem || this.searchWordCustrBySitem.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrBySitem.toUpperCase()))
      );
    },
    filteredCustrListByDay() {
      return this.custrList.filter(({custr_nm}) => 
        (!this.searchWordCustrByDay || this.searchWordCustrByDay.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrByDay.toUpperCase()))
      );
    },
    filteredCustrListByMonth() {
      return this.custrList.filter(({custr_nm}) => 
        (!this.searchWordCustrByMonth || this.searchWordCustrByMonth.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrByMonth.toUpperCase()))
      );
    },
    // filteredLedgrListBySitem() {
    //   return this.ledgrListBySitem.filter((e) => true);
    // },
    // filteredLedgrListByDay() {
    //   return this.ledgrListByDay.filter((e) => true);
    // },
    // filteredLedgrListByMonth() {
    //   return this.ledgrListByMonth.filter((e) => true);
    // },
    filteredLedgrListSalesPurch() {
      return this.ledgrListSalesPurch.filter(({custr_nm, sales_purch_div}) => 
        (!this.searchWordCustrSalesPurch || this.searchWordCustrSalesPurch.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrSalesPurch.toUpperCase())) && 
        (this.salesPurchDiv == 'all' || this.salesPurchDiv == sales_purch_div)
      );
    },
    filteredLedgrListColctPay() {
      return this.ledgrListColctPay.filter(({custr_nm, colct_pay_div}) => 
        (!this.searchWordCustrColctPay || this.searchWordCustrColctPay.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrColctPay.toUpperCase())) && 
        (this.colctPayDiv == 'all' || this.colctPayDiv == colct_pay_div)
      );
    },
    customColumnStyle() {
      return 'sum-bg-color';
    }
  },
  watch: {
    priodDiv: {
      immediate: true,
      handler: function () {
        // this.srchMmCnt = this.priodDiv.substring(1);
        if(this.priodDiv == 'sel') {
          this.srchBgnDtByMonth = dayjs().startOf('month').format('YYYY-MM-DD');
        } else {
          const srchMmCnt = this.priodDiv.substring(1) - 1;
          this.srchBgnDtByMonth = dayjs().subtract(srchMmCnt, 'month').startOf('month').format('YYYY-MM-DD');
        }        
        this.srchEndDtByMonth = dayjs().format('YYYY-MM-DD');
        // console.log("watch priodDiv : ", this.srchBgnDtByMonth, this.srchEndDtByMonth);

        this.loadLedgrListByMonth();
      },
    },
    gridDiv: {
      immediate: true,
      handler: function () {
        if(this.gridDiv == 'salesPurch') {
          this.loadLedgrListSalesPurch();
        } else if(this.gridDiv == 'colctPay') {
          this.loadLedgrListColctPay();
        }
      },
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    this.getCustrList();
  },
  methods: {
    // 품목별
    onFlexCustrBySitemInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.custrSnoBySitem = flex.getCellData(ht.row, 0);
        this.loadLedgrListBySitem();
      });

      this.flexCustrBySitem = flex;
    },
    onFlexCustrBySitemItemsSourceChanged(flex) {
      if(flex != null && this.filteredCustrListBySitem.length > 0) {
        this.custrSnoBySitem = flex.getCellData(0, 0);
        this.loadLedgrListBySitem();
      }
    },
    onFlexLedgrBySitemItemsSourceChanged(flex) {
      console.log("onFlexLedgrBySitemItemsSourceChanged");

      if(flex != null && this.ledgrListBySitem.length > 0) {
        // 통계 계산
        this.calculateStatsBySitem();
      }
    },

    // 일별
    onFlexCustrByDayInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.custrSnoByDay = flex.getCellData(ht.row, 0);
        this.loadLedgrListByDay();
      });

      this.flexCustrByDay = flex;
    },
    onFlexCustrByDayItemsSourceChanged(flex) {
      if(flex != null && this.filteredCustrListByDay.length > 0) {
        this.custrSnoByDay = flex.getCellData(0, 0);
        this.loadLedgrListByDay();
      }
    },
    onFlexLedgrByDayItemsSourceChanged(flex) {
      console.log("onFlexLedgrByDayItemsSourceChanged");

      if(flex != null && this.ledgrListByDay.length > 0) {
        // 통계 계산
        this.calculateStatsByDay();
      }
    },

    // 월별
    onFlexCustrByMonthInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.custrSnoByMonth = flex.getCellData(ht.row, 0);
        this.loadLedgrListByMonth();
      });

      this.flexCustrByMonth = flex;
    },
    onFlexCustrByMonthItemsSourceChanged(flex) {
      if(flex != null && this.filteredCustrListByMonth.length > 0) {
        this.custrSnoByMonth = flex.getCellData(0, 0);
        this.loadLedgrListByMonth();
      }
    },
    onFlexLedgrByMonthItemsSourceChanged(flex) {
      console.log("onFlexLedgrByMonthItemsSourceChanged");

      if(flex != null && this.ledgrListByMonth.length > 0) {
        // 통계 계산
        this.calculateStatsByMonth();
      }
    },
    // 매출/매입
    onFlexLedgrSalesPurchItemsSourceChanged(flex) {
      console.log("onFlexLedgrSalesPurchItemsSourceChanged");

      if(flex != null && this.ledgrListSalesPurch.length > 0) {
        // 통계 계산
        this.calculateStatsSalesPurch();
      }
    },
    // 수금/지급
    onFlexLedgrColctPayItemsSourceChanged(flex) {
      console.log("onFlexLedgrColctPayItemsSourceChanged");

      if(flex != null && this.ledgrListColctPay.length > 0) {
        // 통계 계산
        this.calculateStatsColctPay();
      }
    },

    // 조회 버튼 클릭
    onLedgrInqry() {
      if(this.gridDiv == 'sitem') {
        this.loadLedgrListBySitem();
      } else if(this.gridDiv == 'day') {
        this.loadLedgrListByDay();
      } else if(this.gridDiv == 'month') {
        this.loadLedgrListByMonth();
      } else if(this.gridDiv == 'salesPurch') {
        this.loadLedgrListSalesPurch();
      } else if(this.gridDiv == 'colctPay') {
        this.loadLedgrListColctPay();
      }
    },
    // 출력 버튼 클릭

    // 거래처 목록 조회
    getCustrList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getCustrList", params).then((res) => {
        console.log("getCustrList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.custrList = res.data.custrList;
      }).catch((error) => {
          console.log(error);
          alert(error.message);
        }
      )
    },

    // (원장) 거래처별 > 품목별 현황 목록 조회
    async loadLedgrListBySitem() {
      if(this.custrSnoBySitem == null || this.custrSnoBySitem == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("custr_sno", this.custrSnoBySitem);
      params.append("srch_bgn_dt", this.srchBgnDtBySitem);
      params.append("srch_end_dt", this.srchEndDtBySitem);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByCustrSitem', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListBySitem = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrListBySitem: ${e}`);
      }
    },
    // (원장) 거래처별 > 일별 현황 목록 조회
    async loadLedgrListByDay() {
      if(this.custrSnoByDay == null || this.custrSnoByDay == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("custr_sno", this.custrSnoByDay);
      params.append("srch_bgn_dt", this.srchBgnDtByDay);
      params.append("srch_end_dt", this.srchEndDtByDay);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByCustrDay', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListByDay = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrListByDay: ${e}`);
      }
    },
    // (원장) 거래처별 > 월별 현황 목록 조회
    async loadLedgrListByMonth() {
      if(this.custrSnoByMonth == null || this.custrSnoByMonth == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("custr_sno", this.custrSnoByMonth);
      // params.append("srch_mm_cnt", this.srchMmCnt);
      params.append("srch_bgn_dt", this.srchBgnDtByMonth);
      params.append("srch_end_dt", this.srchEndDtByMonth);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByCustrMonth', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListByMonth = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrListByMonth: ${e}`);
      }
    },
    // (원장) 거래처별 > 매출/매입 현황 목록 조회
    async loadLedgrListSalesPurch() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtSalesPurch);
      params.append("srch_end_dt", this.srchEndDtSalesPurch);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByCustrSalesPurch', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListSalesPurch = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrListSalesPurch: ${e}`);
      }
    },
    // (원장) 거래처별 > 수금/지급 현황 목록 조회
    async loadLedgrListColctPay() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtColctPay);
      params.append("srch_end_dt", this.srchEndDtColctPay);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByCustrColctPay', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListColctPay = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrListColctPay: ${e}`);
      }
    },
    // 품목별 통계 계산
    calculateStatsBySitem() {
      let inShipQty = 0;
      let outShipQty = 0;
      let inShipWegt = 0;
      let outShipWegt = 0;
      let salesAmt = 0;
      let purchAmt = 0;
      let colctAmt = 0;
      let payAmt = 0;
      let unColctPayAmt = this.ledgrListBySitem[this.ledgrListBySitem.length - 1].acmlt_amt;
      // console.log("ledgrListBySitem:", this.ledgrListBySitem);

      this.ledgrListBySitem.forEach((e) => {
        if(e.item_div == 'sales') {
          outShipQty += e.qty;
          outShipWegt += e.wegt;
          salesAmt += e.sum_amt;
        } else if(e.item_div == 'purch') {
          inShipQty += e.qty;
          inShipWegt += e.wegt;
          purchAmt += e.sum_amt;
        } else if(e.item_div == 'colct') {
          colctAmt += e.sum_amt;
        } else if(e.item_div == 'pay') {
          payAmt += e.sum_amt;
        }
      });

      this.statsBySitem.inShipQty = this.$setNumberComma(Math.round(inShipQty, 0));
      this.statsBySitem.outShipQty = this.$setNumberComma(Math.round(outShipQty, 0));
      this.statsBySitem.inShipWegt = this.$setNumberComma(Math.round(inShipWegt, 0));
      this.statsBySitem.outShipWegt = this.$setNumberComma(Math.round(outShipWegt, 0));
      this.statsBySitem.salesAmt = this.$setNumberComma(Math.round(salesAmt, 0));
      this.statsBySitem.purchAmt = this.$setNumberComma(Math.round(purchAmt, 0));
      this.statsBySitem.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.statsBySitem.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
      this.statsBySitem.unColctPayAmt = this.$setNumberComma(Math.round(unColctPayAmt, 0));
    },
    // 일별 통계 계산
    calculateStatsByDay() {
      let salesAmt = 0;
      let purchAmt = 0;
      let colctAmt = 0;
      let payAmt = 0;
      let unColctPayAmt = this.ledgrListByDay[this.ledgrListByDay.length - 1].acmlt_amt;

      this.ledgrListByDay.forEach((e) => {
        salesAmt += e.sales_amt;
        purchAmt += e.purch_amt;
        colctAmt += e.colct_amt;
        payAmt += e.pay_amt;
      });

      this.statsByDay.salesAmt = this.$setNumberComma(Math.round(salesAmt, 0));
      this.statsByDay.purchAmt = this.$setNumberComma(Math.round(purchAmt, 0));
      this.statsByDay.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.statsByDay.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
      this.statsByDay.unColctPayAmt = this.$setNumberComma(Math.round(unColctPayAmt, 0));
    },
    // 월별 통계 계산
    calculateStatsByMonth() {
      let salesAmt = 0;
      let purchAmt = 0;
      let colctAmt = 0;
      let payAmt = 0;
      let unColctPayAmt = this.ledgrListByMonth[this.ledgrListByMonth.length - 1].acmlt_amt;

      this.ledgrListByMonth.forEach((e) => {
        salesAmt += e.sales_amt;
        purchAmt += e.purch_amt;
        colctAmt += e.colct_amt;
        payAmt += e.pay_amt;
      });

      this.statsByMonth.salesAmt = this.$setNumberComma(Math.round(salesAmt, 0));
      this.statsByMonth.purchAmt = this.$setNumberComma(Math.round(purchAmt, 0));
      this.statsByMonth.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.statsByMonth.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
      this.statsByMonth.unColctPayAmt = this.$setNumberComma(Math.round(unColctPayAmt, 0));
    },
    // 매출/매입 통계 계산
    calculateStatsSalesPurch() {
      let salesAmt = 0;
      let purchAmt = 0;

      this.filteredLedgrListSalesPurch.forEach((e) => {
        salesAmt += e.sales_sum_amt;
        purchAmt += e.purch_sum_amt;
      });

      this.statsSalesPurch.salesAmt = this.$setNumberComma(Math.round(salesAmt, 0));
      this.statsSalesPurch.purchAmt = this.$setNumberComma(Math.round(purchAmt, 0));
    },
    // 수금/지급 통계 계산
    calculateStatsColctPay() {
      let colctAmt = 0;
      let payAmt = 0;

      this.ledgrListColctPay.forEach((e) => {
        colctAmt += e.colct_sum_amt;
        payAmt += e.pay_sum_amt;
      });

      this.statsColctPay.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.statsColctPay.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
    },
  }
}
</script>

<style>

</style>