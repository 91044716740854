<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>거래처 조회</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>
        <v-row dense align="center" class="d-flex align-center">
          <v-col cols="1"><div><span>조회수 : {{ filteredCustrs.length }} 건</span></div></v-col>
          <v-col cols="2">
            <div class="d-flex">
              <span class="pr-2">거래처</span>
              <v-text-field
                class=""
                hide-details
                dense
                outlined
                v-model="pendingWordCustr"
                @focusout="searchWordCustr = pendingWordCustr"
                @keyup.enter="searchWordCustr = pendingWordCustr"
                clearable
                @click:clear="searchWordCustr = ''"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="mt-0">
          <v-col cols="3">
            <v-row dense class="">
              <v-col cols="12">
                <wj-flex-grid 
                  :isReadOnly=true
                  :itemsSource="filteredCustrs"
                  :selectionMode="'ListBox'"
                  style="height:650px;"
                  :initialized="flexInitialized"
                  :itemsSourceChanged="itemsSourceChanged"
                  :headersVisibility="'Column'"
                >
                  <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                  <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="6*" />
                  <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="3*" align="center"/>
                  <wj-flex-grid-column :header="'대표자'" :binding="'repr_nm'" width="4*" align="center"/>
                </wj-flex-grid>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row dense class="bgcolor-grey1">
              <v-col cols="12" class="">
                <v-card class="bgcolor-grey2 pa-1" outlined>
                  <v-row dense>
                    <v-col cols="1">
                      <h4>사업자 정보</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>거래처명</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="custr.custr_nm"
                        class=""
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>구분</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        v-model="custr.custr_div_nm"
                        class=""
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>대표자</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        v-model="custr.repr_nm"
                        class=""
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>대표전화</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="custr.tel_no"
                        class=""
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>사업자번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.biz_rno"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>법인번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.corp_rno"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>팩스번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="custr.fax_no"
                        class=""
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>사업장 우편번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.biz_plc_zpcd"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
          
                    </v-col><v-col cols="1" align="right" class=""><div class=""><span>사업장 주소</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.biz_plc_addr"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>배송지 우편번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.dlv_pnt_zpcd"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>배송지 주소</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.dlv_pnt_addr"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                  
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>종사업장번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.sub_biz_plc_no"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>업태</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.biz_type"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>종목</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="custr.biz_item"
                        hide-details
                        dense
                        outlined
                        readonly disabled background-color="grey lighten-2"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
        
                  <v-row dense class="hei-80p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>메모</span></div></v-col>
                    <v-col cols="6" class="" >
                      <v-textarea
                        class=""
                        v-model="custr.memo"
                        hide-details
                        dense
                        outlined
                        no-resize
                        height="60px"
                        readonly disabled background-color="grey lighten-2"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>특이사항</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-textarea
                        class=""
                        v-model="custr.spcl_note"
                        hide-details
                        dense
                        outlined
                        no-resize
                        height="60px"
                        readonly disabled background-color="grey lighten-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>										
                </v-card>
              </v-col>

              <v-col cols="12" class="">
                <v-card class="pa-1 mt-2 bgcolor-grey2" outlined>
                  <v-row dense>
                    <v-col cols="2">
                      <h4>거래 분석</h4>
                    </v-col>
                    <v-spacer></v-spacer>                     
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>월평균 매출액</span></div></v-col>
                    <v-col cols="1" class="" >
                      <numeric-text-field v-model="avgSalesAmt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>월평균 입금액</span></div></v-col>
                    <v-col cols="1" class="" >
                      <numeric-text-field v-model="avgColctAmt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>월평균 잔액</span></div></v-col>
                    <v-col cols="1" class="" >
                      <numeric-text-field disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>채권 회전일수</span></div></v-col>
                    <v-col cols="1" class="" >
                      <numeric-text-field disabled bg-color="grey lighten-2">></numeric-text-field>
                    </v-col>                      
                    <v-col cols="1" align="right" class=""><div class=""><span>채권 회전율</span></div></v-col>
                    <v-col cols="1" class="" >
                      <numeric-text-field disabled bg-color="grey lighten-2">></numeric-text-field>
                    </v-col>  
                  </v-row> 
                  <v-row>
                    <v-col cols="12">
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="salesAmtList" 
                        :selectionMode="'ListBox'"
                        style="height:62px;"
                        :initialized="onFlexSalesInit"
                        :headersVisibility="'Column'"
                        >
                        <wj-flex-grid-column :header="mmSalesHeader.h00" :binding="'un_colct_amt'" width="1.1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h01" :binding="'m01'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h02" :binding="'m02'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h03" :binding="'m03'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h04" :binding="'m04'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h05" :binding="'m05'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h06" :binding="'m06'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h07" :binding="'m07'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h08" :binding="'m08'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h09" :binding="'m09'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h10" :binding="'m10'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h11" :binding="'m11'" width="1*" />
                        <wj-flex-grid-column :header="mmSalesHeader.h12" :binding="'m12'" width="1*" />
                      </wj-flex-grid>
                    </v-col>
                  </v-row>                  
                </v-card>
              </v-col>
              
              <v-col cols="12" class="">
                <v-card class="pa-1 mt-2 bgcolor-grey2" outlined>
                  <v-row >
                    <v-col cols="2">
                      <h4>주요 거래 품목</h4>
                    </v-col>
                    <v-spacer></v-spacer>                     
                  </v-row>
                  <v-row  class="mt-0" align="center">
                    <v-col cols="12">
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="primSitems" 
                        :selectionMode="'ListBox'"
                        style="height:230px;"
                        :initialized="onFlexPrimInit"
                        :headersVisibility="'Column'"
                        >
                        <wj-flex-grid-column :header="'품목코드'" :binding="'sitem_cd'" width="1*" />
                        <wj-flex-grid-column :header="'품목명'" :binding="'sitem_nm'" width="2*" />
                        <wj-flex-grid-column :header="'규격'" :binding="'stnd'" width="1*" />
                        <wj-flex-grid-column :header="'평균수량'" :binding="'avg_qty'" width="1*" />
                        <wj-flex-grid-column :header="'평균중량'" :binding="'avg_wegt'" width="1*" />
                        <wj-flex-grid-column :header="'평균단가'" :binding="'avg_unit_price'" width="1*" />
                        <wj-flex-grid-column :header="'최근 1년 매출액'" :binding="'sales_amt'" width="1*" />
                      </wj-flex-grid>
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';
import {SUB_TAB} from "@/common/constant";

const DUMMY_CUSTR_SNO = '__dummy__';

const genEmptyCustr = () => ({
  // 거래처 정보
  custr_sno: null,
  // 사업자 정보
  custr_nm: null,
  custr_div: null,
  repr_nm: null,
  tel_no: null,
  fax_no: null,
  biz_rno: null,
  corp_rno: null,
  biz_plc_addr: null,
  biz_plc_zpcd: null,
  dlv_pnt_addr: null,
  dlv_pnt_zpcd: null,
  sub_biz_plc_no: null,
  biz_type: null,
  biz_item: null,
  memo: null,
  spcl_note: null,
  // 담당자 정보
  chrgr_nm: null,
  chrgr_tel_no: null,
  chrgr_cel_no: null,
  chrgr_email: null,
});

export default {
  components: { numericTextField },

  data() {
    return {
      compSno: null,
      userSno: null,

      searchWordCustr: "",
      pendingWordCustr: "",

      // filteredCustrs: [],   // 그리드에 표시되는 filter된 목록
      custrs: [],  // 서버에서 조회된 원본 목록
      custrSno: DUMMY_CUSTR_SNO,

      flexGrid: null,
      selectedRow: 0,
      selectedCol: 0,

      avgSalesAmt: "",
      avgColctAmt: "",
      unColctAmt: "",
      salesAmtList: [],

      flexSales: null,
      mmSalesHeader: {
        h00: "누적 잔액",
        h01: "당월 매출",
        h02: "02",
        h03: "03",
        h04: "04",
        h05: "05",
        h06: "06",
        h07: "07",
        h08: "08",
        h09: "09",
        h10: "10",
        h11: "11",
        h12: "12",
      },

      flexPrim: null,
      primSitems: [],

      custr: {},
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.custrInqry.code);
    },
    filteredCustrs() {
      return this.custrs.filter(({custr_nm}) =>
          (!this.searchWordCustr || this.searchWordCustr.trim() === '' ||
            custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustr.toUpperCase()))
      );
    },
  },
  watch: {
    async filteredCustrs(newValue) {
      // console.log("watch filteredSkinds:", newValue);
      const [first] = newValue;
      const custrSno = first && first.custr_sno || DUMMY_CUSTR_SNO;
      await this.getCustrAnyls(custrSno);
      
      // 첫번째 row 선택 표시
      // console.log("flexSkind.selectedRows:", this.flexSkind.selectedRows);
      // this.flexSkind.selectedRows.splice(0, this.flexSkind.selectedRows.length);
      // this.flexSkind.selectionMode = 'Row';
      this.flexGrid.select(0, this.selectedCol);
      // this.flexSkind.selectionMode = 'ListBox';
    },
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);
    // console.log("created:", this.isFavorited);

    this.getCustrList();
  },
  methods: {
	  flexInitialized(flex) {
      this.flexGrid = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("flexDownEvent:", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);       
        if(ht.cellType == 1 && ht.row >= 0) {

          this.selectedRow = ht.row;
          this.selectedCol = ht.col;
          this.custrSno = this.flexGrid.getCellData(ht.row, 0);

          this.getCustrAnyls(this.custrSno);
        }
      });
    },
    itemsSourceChanged(flex) {
      // console.log("itemsSourceChanged:", this.selectedRow);

    },
    onFlexSalesInit(flex) {

    },
    onFlexPrimInit(flex) {

    },

    getCustrList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getCustrList", params).then((res) => {
        // console.log("res.data:", res.data);
        this.custrs = res.data.custrList;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    // 거래처 분석 정보 조회
    async getCustrAnyls(custrSno) {
      if (custrSno === DUMMY_CUSTR_SNO) {
        return;
      }

      const params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", custrSno);

      this.$http.post("/sales/getCustrSalesDetail", params).then((res) => {
        console.log("res.data:", res.data);
        this.custr = res.data.custr;                // 거래처
        this.avgSalesAmt = res.data.avgSalesAmt;    // 평균매출액
        this.avgColctAmt = res.data.avgColctAmt;    // 평균수금액
        this.unColctAmt = res.data.unColctAmt;      // 잔액(미수금)
        this.primSitems = res.data.primSitemList;   // 주요거래품목

        // 월별 매출액 헤더 설정
        this.mmSalesHeader.h02 = res.data.salesMonthList[1].mm.substring(4) + '월';
        this.mmSalesHeader.h03 = res.data.salesMonthList[2].mm.substring(4) + '월';
        this.mmSalesHeader.h04 = res.data.salesMonthList[3].mm.substring(4) + '월';
        this.mmSalesHeader.h05 = res.data.salesMonthList[4].mm.substring(4) + '월';
        this.mmSalesHeader.h06 = res.data.salesMonthList[5].mm.substring(4) + '월';
        this.mmSalesHeader.h07 = res.data.salesMonthList[6].mm.substring(4) + '월';
        this.mmSalesHeader.h08 = res.data.salesMonthList[7].mm.substring(4) + '월';
        this.mmSalesHeader.h09 = res.data.salesMonthList[8].mm.substring(4) + '월';
        this.mmSalesHeader.h10 = res.data.salesMonthList[9].mm.substring(4) + '월';
        this.mmSalesHeader.h11 = res.data.salesMonthList[10].mm.substring(4) + '월';
        this.mmSalesHeader.h12 = res.data.salesMonthList[11].mm.substring(4) + '월';
        // console.log("mmSalesHeader:", this.mmSalesHeader);

        const salesAmt = {un_colct_amt: res.data.unColctAmt, ...res.data.salesAmt};  // 월별매출액
        // console.log("salesAmt:", salesAmt);
        this.salesAmtList = [{...salesAmt}];
        // console.log("salesAmtList:", this.salesAmtList);
      }).catch((error) => {
        console.log(error);
        alert(error.message);
      });
    },

  }
}
</script>

	   

<style>


</style>