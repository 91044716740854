<template>
  <v-dialog fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-card class="container">
      <div
          class="import-wrapper" :class="{'d-block': !cropImageSrc, 'd-none': cropImageSrc}"
          @dragover.prevent="onDragOver"
          @dragenter.prevent="onDragEnter"
          @dragleave.prevent="onDragLeave"
          @drop.prevent="onDragDrop"
      >
        <div key="empty-box" :class="['empty-box', 'justify-center', 'align-center', 'flex-column', {'d-flex': !imageSrc, 'd-none': imageSrc}]">
          <p>
            이미지를 드래그&드롭 하거나
            <label>여기...<input ref="inputFile" type="file" accept="image/*" class="d-none sr-only" @change="onFileChange"></label>를 눌러 등록하세요.
          </p>
          <p>
            <label>취소<input type="button" class="d-none sr-only" @click="onClose"></label>
          </p>
        </div>
        <div key="image-box" :class="['image-box', {'d-block': imageSrc, 'd-none': !imageSrc}]">
          <img ref="image" :src="imageSrc" alt="" style="display: block;max-width: 100%">
          <div class="image-toolbar">
            <button title="Move" @click="onMove"><v-icon class="tool-icon">mdi-cursor-move</v-icon></button>
            <button title="Crop" @click="onCrop"><v-icon class="tool-icon">mdi-crop</v-icon></button>
            <button title="Zoom In" @click="onZoomIn"><v-icon class="tool-icon">mdi-magnify-plus-outline</v-icon></button>
            <button title="Zoom Out" @click="onZoomOut"><v-icon class="tool-icon">mdi-magnify-minus-outline</v-icon></button>
            <button title="Rotate Left" @click="onRotateLeft"><v-icon class="tool-icon">mdi-restore</v-icon></button>
            <button title="Rotate Right" @click="onRotateRight"><v-icon class="tool-icon">mdi-reload</v-icon></button>
            <button title="Flip Vertical" @click="onFlipVertical"><v-icon class="tool-icon">mdi-arrow-up-down</v-icon></button>
            <button title="Flip Horizontal" @click="onFlipHorizontal"><v-icon class="tool-icon">mdi-arrow-left-right</v-icon></button>
            <button title="Confirm" :disabled="!hasCropRect" @click="onConfirm"><v-icon class="tool-icon confirm">mdi-check</v-icon></button>
            <button title="Cancel" @click="onClose"><v-icon class="tool-icon cancel">mdi-close</v-icon></button>
          </div>
        </div>
      </div>
      <v-card-text key="ocr-content" :class="['ocr-content', {'d-block': cropImageSrc, 'd-none': !cropImageSrc}]">
        <div class="image-layout">
          <img :src="cropImageSrc" alt="">
          <div class="overlay"></div>
        </div>

        <wj-flex-grid
            :itemsSource="gridData"
            style="height: 400px;"
            selectionMode="MultiRange"
            :showMarquee="true"
            :allowDelete="true"
            :allowSorting="false"
            :initialized="onItemGridInit"
            :itemsSourceChanged="onItemGridChanged"
            headersVisibility="Column"
            :cellEditEnding="onItemEditEnding"
            :cellEditEnded="onItemEditEnded"
        >
          <wj-flex-grid-column :key="header" :header="header" :binding="header" :width="200" v-for="header in gridHeaders"/>

        </wj-flex-grid>
      </v-card-text>
      <v-card-actions v-if="cropImageSrc" @click="onClear">
        <v-spacer></v-spacer>
        <v-btn>취소</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

.container {height: 100%;}
.import-wrapper {height: 100%;}
.empty-box {height: 100%;font-size: 1.2rem;}
.empty-box label {color: blue;}
.image-box {height: 100%;}

.image-toolbar {position: absolute;left: 50%;bottom: 4rem;margin-left: -10rem;background: black;}
.image-toolbar > button {width: 2rem;height: 2rem;}
.image-toolbar > button:hover {background: deepskyblue;}
.tool-icon {color: white;text-align: center;}
.tool-icon.confirm {color: green;}
.tool-icon.cancel {color: red;}
button:disabled .tool-icon {color: grey;}

.image-layout {position: relative;}
.image-layout img {width: 100%;}
.image-layout .overlay {position: absolute;top: 0;right: 0;bottom: 0;left: 0;}
</style>

<style>
.cropper-bg {background-repeat: repeat;}
</style>

<script>
import 'cropperjs/dist/cropper.css';
import {defineComponent} from 'vue';
import Cropper from 'cropperjs';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import ocrSampleTable from '@/assets/ocr-sample-table.json';

export default defineComponent({
  name: "ocr",
  props: {
    value: {type: Boolean, defaultValue: false},
  },
  beforeDestroy() {
    this.cropper && this.cropper.destroy();
  },
  data() {
    return {
      dialog: false,
      cropper: null,
      importBox: false,
      imageSrc: null,
      cropImageSrc: null,
      isHorizontalFlipped: false,
      isVerticalFlipped: false,
      cropRect: null,
      itemGrid: null,
      gridData: [],
      gridHeaders: [],
    }
  },
  computed: {
    hasCropRect() {
      const cropRect = this.cropRect;
      if (!cropRect) return false;
      return 0 < cropRect.width && 0 < cropRect.height;
    },
  },
  methods: {
    onDragOver(event) {
    },
    onDragEnter(event) {
      console.log('onDragEnter');
      this.importBox = true;
    },
    onDragLeave(event) {
      console.log('onDragLeave');
      this.importBox = false;
    },
    onDragDrop(event) {
      console.log('onDragDrop');
      this.importBox = false;

      const files = event.dataTransfer.files;
      const [file] = files || [];
      if (!file) return;

      this.imageSrc = URL.createObjectURL(file);
    },
    onFileChange(event) {
      const files = event.target.files;
      const [file] = files || [];
      if (!file) return;

      this.imageSrc = URL.createObjectURL(file);
    },
    onMove() {
      this.cropper.setDragMode('move');
    },
    onCrop() {
      this.cropper.setDragMode('crop');
    },
    onZoomIn() {
      this.cropper.zoom(0.1);
    },
    onZoomOut() {
      this.cropper.zoom(-0.1);
    },
    onRotateLeft() {
      this.cropper.rotate(-90);
    },
    onRotateRight() {
      this.cropper.rotate(90);
    },
    onFlipVertical() {
      this.isVerticalFlipped = !this.isVerticalFlipped;
      this.cropper.scale(this.isHorizontalFlipped ? -1 : 1, this.isVerticalFlipped ? -1 : 1);
    },
    onFlipHorizontal() {
      this.isHorizontalFlipped = !this.isHorizontalFlipped;
      this.cropper.scale(this.isHorizontalFlipped ? -1 : 1, this.isVerticalFlipped ? -1 : 1);
    },
    async onConfirm() {
      const blob = await new Promise(r => this.cropper.getCroppedCanvas().toBlob(r, 'image/png'));
      const filename = moment().format('YYYYMMDDHHmmss_S.png');

      this.cropImageSrc = URL.createObjectURL(blob);

      const formData = new FormData();
      formData.append('file', blob, filename);
      formData.append('message', JSON.stringify({
        version: 'V2',
        requestId: uuidv4(),
        timestamp: moment().valueOf(),
        lang: 'ko',
        images: [{
          format: 'png', name: filename,
        }],
        enableTableDetection: true,
      }));
      try {
        const res = await this.$http.post(
            'https://289vnpvbdd.apigw.ntruss.com/custom/v1/23641/56a1b04e50be71a0ed1d7b2b2482393b4365750de19a9262e365a00121dd53eb/general',
            formData,
            {
              headers: {
                'X-OCR-SECRET': 'WkNoeHRqV3FWT0lsbGlkbXNoYkdmRGpleHZqdWRKQkU=',
              },
            }
        );

        const retrieved = this.retrieveOcrData(res.data);
        if (!retrieved) {
          alert('표가 확인되지 않았습니다.');
          return;
        }

        const [headers, data] = retrieved;
        this.gridHeaders = headers;
        this.gridData = data;

      } catch (e) {
        console.log(`Error on function onConfirm: ${e}`);
      }
    },
    onClose() {
      if (this.hasCropRect) return this.cropper.clear();
      this.onClear();
      this.$emit('input', false);
    },
    onItemGridInit(flex) {
      this.itemGrid = flex;
    },
    onItemGridChanged() {

    },
    onItemEditEnding() {

    },
    onItemEditEnded() {

    },
    onClear() {
      this.$refs.inputFile.value = null;
      this.cropImageSrc = null;
      this.imageSrc = null;
    },
    retrieveOcrData(response) {
      const [image] = response.images;
      const [table] = image.tables;
      if (!table) return null;
      const [lastRow, lastColumn] = table.cells.reduce((acc, cur) => {
        const [row, column] = acc;
        return [
          Math.max(row, cur.rowIndex + cur.rowSpan - 1),
          Math.max(column, cur.columnIndex + cur.columnSpan - 1),
        ];
      }, [0, 0]);
      const grid = Array.from(Array(lastRow + 1).keys()).map(() =>
          Array.from(Array(lastColumn + 1).keys()).map(() => null)
      );
      table.cells.forEach(c => {
        const words = c.cellTextLines.flatMap(({cellWords}) => cellWords).map(({inferText}) => inferText);

        for (let row = c.rowIndex; row < c.rowIndex + c.rowSpan; row++) {
          for (let column = c.columnIndex; column < c.columnIndex + c.columnSpan; column++) {
            grid[row][column] = {
              copied: 1 < c.rowSpan || 1 < c.columnSpan,
              words: words,
            };
          }
        }
      });
      // const columnLength = grid.reduce((acc, cur) => cur.map((col, index) => Math.max(col.length, acc[index])), Array.from(Array.keys(lastColumn)).map(() => 0));

      const headers = Array.from(Array(lastColumn + 1).keys()).map(index => `col-${index}`);
      const data = grid.map(columns => columns.reduce((acc, cur, index) => {
        const id = headers[index];
        const text = cur.words && 0 < cur.words.length && cur.words.join(' ') || null;
        return {...acc, [id]: text};
      }, {}));
      return [headers, data];
    }
  },
  watch: {
    imageSrc(newValue, oldValue) {
      this.$nextTick(() => {
        if (this.cropper) {
          this.cropper && this.cropper.replace(this.imageSrc);
          return;
        }
        const image = this.$refs.image;
        this.cropper = new Cropper(image, {
          viewMode: 1,
          preview: this.$refs.cropPreview,
          // zoomOnWheel: false,
          background: true,
          guides: false,
          autoCrop: false,
          // toggleDragModeOnDbclick
        });
        image.addEventListener('crop', event => {
          this.cropRect = {
            width: event.detail.width, height: event.detail.height
          };
        });
        image.addEventListener('reset', event => {
          this.cropRect = null;
        });
      });
      if (oldValue)
        URL.revokeObjectURL(oldValue);
    },
    cropImageSrc(newValue, oldValue) {
      if (oldValue)
        URL.revokeObjectURL(oldValue);
    },
  }
});

</script>

