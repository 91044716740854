<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>금전출납부</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">

              <v-col cols="1"><div class=""><span>조회수 : {{ cashLedgrs.length - 2 }} 건</span></div></v-col>
              <v-col cols="9" class="d-flex justify-end align-center hei-40p">
                <v-row dense align="center">
                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>

                  <v-col cols="4">
                    <v-row dense align="center">
                      <v-col cols="5">
                        <date-picker v-model="srchBgnDt"></date-picker>
                      </v-col>
                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                      <v-col cols="5">
                        <date-picker v-model="srchEndDt"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" @click="onCashLedgerInqry">조회</v-btn>
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>

            <v-row dense align="center">              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="cashLedgrs" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexLedgrInitialized"
                    :itemsSourceChanged="onFlexLedgrItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :scrollPositionChanged="onFlexLedgrScrollPositionChanged"
                    >
                    <wj-flex-grid-column :header="'일자'" :binding="'colct_pay_dt'" :width="150" align="center"/>
                    <wj-flex-grid-column :header="'입출금구분'" :binding="'colct_pay_div'" :width="120" align="center"/>
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="300" />
                    <wj-flex-grid-column :header="'비고'" :binding="'rm'" :width="300" />
                    <wj-flex-grid-column :header="'입금액'" :binding="'colct_amt'" :width="200" align="right"/>
                    <wj-flex-grid-column :header="'출금액'" :binding="'pay_amt'" :width="200" align="right"/>
                    <wj-flex-grid-column :header="'잔액'" :binding="'remn_amt'" :width="200" align="right"/>
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB, SUM_BG_COLOR} from "@/common/constant";

export default {
  components: {datePicker},
  data(){
    return{
      srchBgnDt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      cashLedgrs: [],

      flexLedgr: null,    // 금전출납부
      wjGrid: wjGrid,
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.cashLedger.code);
    },
  },
  watch: {
    // srchBgnDt: {
    //   immediate: true,
    //   handler: function () {
    //     // console.log("watch srchDt -> loadInvnt");
    //     this.loadInvnt(this.skindCd);
    //   },
    // },
  },
  created() {
    
  },
  mounted() {
    this.loadCashLedgers();
    // this.flexLedgr.formatItem.addHandler((s, e) => {
    //   if(e.panel == s.cells) {
    //     for(let r = 0; r < this.cashLedgrs.length; r++) {
    //       if(this.cashLedgrs[r].custr_nm == "[이월잔액]" || this.cashLedgrs[r].custr_nm == "[합계]"){
    //         e.cell.style.backgroundColor = "#7C3E66";
    //         e.cell.style.color = "white";
    //       }
    //     }
    //   }
    // });
  },
  methods: {
    onFlexLedgrInitialized(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // flex.select(ht.row, ht.col);
      });

      this.flexLedgr = flex;

      // flex.itemFormatter = (panel, r, c, cell) => {
      //   console.log("onFlexLedgrItemsSourceChanged", r, c, panel);
      //   if (panel === flex.cells) {
      //     if(this.cashLedgrs[r].custr_nm == "[이월잔액]" || this.cashLedgrs[r].custr_nm == "[합계]"){
      //       cell.style.backgroundColor = "#7C3E66";
      //       cell.style.color = "white";
      //     }
      //   }
      // }
    },
    onFlexLedgrItemsSourceChanged(flex) {
      if(flex == null || this.cashLedgrs.length == 0)  return;

      flex.itemFormatter = (panel, r, c, cell) => {
        // console.log("onFlexLedgrItemsSourceChanged", r, c, panel);
        if (panel === flex.cells) {
          if(this.cashLedgrs[r].custr_nm == "[이월잔액]" || this.cashLedgrs[r].custr_nm == "[합계]"){
            cell.style.backgroundColor = SUM_BG_COLOR;
            cell.style.color = "white";
            cell.style.fontWeight = 'bold';
          }
        }
      }

    },
    onFlexLedgrScrollPositionChanged(flex) {
      // console.log("onFlexLedgrScrollPositionChanged");
      // 이전 설정을 초기화하고 새로 설정함, 스크롤 시 헤더가 깜빡임
      flex.itemFormatter = (panel, r, c, cell) => {
        // console.log("onFlexLedgrItemsSourceChanged", r, c, panel);
        if (panel === flex.cells) {
          if(this.cashLedgrs[r].custr_nm == "[이월잔액]" || this.cashLedgrs[r].custr_nm == "[합계]"){
            cell.style.backgroundColor = SUM_BG_COLOR;
            cell.style.color = "white";
            cell.style.fontWeight = 'bold';
          }
        }
      }
    },
    // 매출 명세 조회
    async loadCashLedgers() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/accounting/getCashLedgrList', params);
        const ledgrs = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.cashLedgrs = ledgrs;
      } catch (e) {
        console.log(`Error on function loadSales: ${e}`);
      }
    },
    // 조회 버튼 클릭
    onCashLedgerInqry() {
      this.loadCashLedgers();
    },

  }
}
</script>

<style>

</style>