<template>
  <!--[[[[[[[[[[[[[[[        계정 검색 모달        ]]]]]]]]]]]]]]]-->
  <v-dialog
    v-model="showDialog"
    width="400px"
    height="800px"
    @keydown.esc="close"
    @click:outside="close"
    >
    <v-container>
      <v-card style="text-align:center;" class="pb-3">
        <v-toolbar 
          color="indigo darken-2"
          class="elevation-0"
          height="50px">
          <v-toolbar-title
            class="white--text">
            계정 선택
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div class="pa-3" style="overflow:auto;">
          <v-row dense class="" align="center">
            <v-col cols="2" align="right"><div class=""><span>계정</span></div></v-col>
            <v-col cols="10">
              <v-text-field
                class="f-l pa-0 ma-0 wid-100 input-hei-d"
                v-model="search"
                outlined
                dense
                hide-details
                background-color="white">
              </v-text-field>
            </v-col>
          </v-row>
          <div class="pt-3" style="overflow:auto;">
            <wj-flex-grid
              :isReadOnly=true
              :itemsSource="gridList"
              :selectionMode="'Row'"
              style="height:500px;"
              :initialized="flexInitialized"
              :headersVisibility="'Column'"
              >
              <wj-flex-grid-column :header="'번호'" :binding="'acct_cd'" width="3*" />
              <wj-flex-grid-column :header="'계정명'" :binding="'acct_nm'" width="6*" />
              <wj-flex-grid-column :header="'구분'" :binding="'acct_div_nm'" width="2*" />
            </wj-flex-grid>
          </div>
        </div>
        <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="$emit('close')">취소</v-btn>
        <v-btn small color="indigo darken-3" class="white--text" width="70" @click="setSelected">선택</v-btn>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: ["p_compSno", "p_acctDiv"],
  data() {
    return {
      showDialog: true,
      compSno: this.p_compSno,
      acctDiv: this.p_acctDiv,

      gridList: [],
      selectedItem: null,

      flexGrid: null,
      selectedRow: 0,

      search: "",
    };
  },
  created() {

  },
  mounted() {
    console.log("searchAcctPopup mounted.", this.compSno, this.acctDiv);

    this.getAcctList();
  },
  methods: {
    flexInitialized(flexGrid) {
      this.flexGrid = flexGrid;

      flexGrid.addEventListener(flexGrid.hostElement, "mousedown", e => {
        let ht = flexGrid.hitTest(e);
        if(ht.cellType == 1 && ht.row >= 0) {
          this.selectedRow = ht.row;
        }
      });

      flexGrid.addEventListener(flexGrid.hostElement, "dblclick", e => {
        let ht = flexGrid.hitTest(e);
        if(ht.cellType == 1 && ht.row >= 0) {
          this.selectedRow = ht.row;
          this.setSelected();
        }
      });
    },
    getAcctList() {
      const params = new FormData();
      params.append("comp_sno", this.compSno);
      params.append("acct_div", this.acctDiv);

      this.$http.post("/base/getAcctList", params).then((res) => {
        this.gridList = res.data.acctList;
        // this.gridList = res.data.acctList.filter((e) => e.acct_div === "cmn" || e.acct_div === this.acctDiv);
      }).catch(function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    setSelected() {
      const acctCd = this.flexGrid.getCellData(this.selectedRow, 0);

      for(let i = 0; i < this.gridList.length; i++) {
        if(acctCd === this.gridList[i].acct_cd) {
          this.selectedItem = this.gridList[i];
          break;
        }
      }

      this.$emit('selected', this.selectedItem.acct_cd, this.selectedItem.acct_nm);
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>
