import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import appView from "@/layouts/admin/appView"
import home from "@/components/home"
import ocr from "@/views/ocr"
import login from "@/views/main/login"

export default new Router({
  mode: 'history',
  routes: [
  {
    path: '/',
    component: appView,
  }, 
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/ocr",
    name: "ocr",
    component: ocr,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  }
]
})
