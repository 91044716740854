<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>총계정 집계표</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">
              
              <v-col cols="4" class="d-flex justify-end align-center hei-40p">
                <v-row dense align="center">
                  <v-col cols="2"><div class=""><span>조회기간</span></div></v-col>
                  <v-col cols="4">
                    <date-picker v-model="srchBgnDt"></date-picker>
                  </v-col>
                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                  <v-col cols="4">
                    <date-picker v-model="srchEndDt"></date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6"></v-col>    
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" @click="onStmtInqry">조회</v-btn>
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>

            <!-- 총계정 집계표 -->
            <v-row dense align="center" >              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="trialBlnce" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexBlnceInitialized"
                    :itemsSourceChanged="onFlexBlnceItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    :allowMerging="'ColumnHeaders'"
                    :autoGenerateColumns="false"
                    >
                    <wj-flex-grid-column :header="'계정코드'" :binding="'acct_cd'" :allowMerging="true" :width="120" align="center"/>
                    <wj-flex-grid-column :header="'계정명'" :binding="'acct_nm'" :width="200" />
                    <wj-flex-grid-column :header="'입금액'" :binding="'colct_amt'" :width="150" />
                    <wj-flex-grid-column :header="'출금액'" :binding="'pay_amt'" :width="150" />
                    <wj-flex-grid-column :header="'차액'" :binding="'diff_amt'" :width="150" />
                  </wj-flex-grid>
                </div>
              </v-col>
              
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
// import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB, SUM_BG_COLOR} from "@/common/constant";

export default {
  components: {datePicker},
  data(){
    return{

      srchBgnDt: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      trialBlnce: [],

      flexBlnce: null,    // 총계정 집계표
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.trialBalance.code);
    },
    
  },
  watch: {
    // srchDtByDay: {
    //   immediate: true,
    //   handler: function () {
    //     this.loadByDayStmts();
    //   },
    // },
  },
  created() {
    
  },
  mounted() {
    // this.loadByDayStmts(); // watch에서 날짜 설정 시 조회됨
    this.loadTrialBlnce();
  },
  methods: {
    onFlexBlnceInitialized(flex) {
      this.flexBlnce = flex;
    },
    onFlexBlnceItemsSourceChanged(flex) {
      flex.itemFormatter = (panel, r, c, cell) => {
        // console.log("onFlexLedgrItemsSourceChanged", r, c, panel);
        if (panel === flex.cells) {
          if(this.trialBlnce[r].acct_nm == "[합  계]"){
            cell.style.backgroundColor = SUM_BG_COLOR;
            cell.style.color = "white";
            cell.style.fontWeight = 'bold';
          }
        }
      }
    },
    
    // 총계정 집계표 조회
    async loadTrialBlnce() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/accounting/getTrialBlnceList', params);
        
        const trialBlnce = res.data.blnceList.map(stmt => ({
          ...stmt,
        }));

        this.trialBlnce = trialBlnce;
        console.log("loadTrialBlnce:", this.trialBlnce);

      } catch (e) {
        console.log(`Error on function loadTrialBlnce: ${e}`);
      }
    },
    // 조회 버튼 클릭
    onStmtInqry() {
      this.loadTrialBlnce();
    },

  }
}
</script>

<style>

</style>