<template>
  <!--[[[[[[[[[[[[[[[        단계(수주, 입출고) 처리 모달        ]]]]]]]]]]]]]]]-->
  <v-dialog
    v-model="showDialog"
    width="350px"
    height="350px"
    @keydown.esc="close"
    @click:outside="close"
    >
    <v-container>
      <v-card style="text-align:center;" class="pb-3">
        <v-toolbar 
            color="indigo darken-2"
            class="elevation-0"
            height="50px">
          <v-toolbar-title
            class="white--text">
            {{ procTypeNm }} 처리
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-3" style="overflow:auto;">
          <div class="pa-3"><span>아래의 내용으로 {{ procTypeNm }} 처리하시겠습니까?</span><br/>
            <span>{{ procTypeNm }} 처리할 경우, {{ tblNm }}관리에 등록됩니다.</span></div>
          <!-- <div class="pa-3"><span>{{ procTypeNm }} 처리할 경우, {{ tblNm }}관리에 등록됩니다.</span></div> -->
          <v-row dense class="" align="center">
            <v-col cols="4" align="right"><div class=""><span>{{ trgtDtNm }}일자</span></div></v-col>
            <v-col cols="7">
              <date-picker v-model="trgtDt" :is-checked="false"></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="" align="center" v-if="procType == 'salesOrd'">
            <v-col cols="4" align="right"><div class=""><span>{{ scdulTypeNm }}예정일</span></div></v-col>
            <v-col cols="7">
              <date-picker v-model="scdulDt" :is-checked="false"></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="" align="center" v-if="procType != 'salesOrd'">
            <v-col cols="4" align="right"><div class=""><span>결제방법</span></div></v-col>
            <v-col cols="7">
              <v-select
                class=""
                v-model="payMthd"
                :items="payMthdList"
                item-value="cmn_cd"
                item-text="cmn_cd_nm"
                hide-details dense outlined
            ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="$emit('close')">취소</v-btn>
        <v-btn small color="indigo darken-3" class="white--text" width="70" @click="onProcess">{{ procTypeNm }}처리</v-btn>
      </v-card>
    </v-container>	
  </v-dialog>
</template>

<script>
import datePicker from "@/components/common/datePicker.vue";

export default {
  components: {datePicker},
  props: ["p_procType", "p_trgtDt", "p_scdulDt", "p_payMthd"],

  data() {
    return {
      showDialog: true,

      procType: this.p_procType,
      procTypeNm: this.p_procType == 'salesOrd' ? "수주" : this.p_procType == 'sales' ? "출고" : this.p_procType == 'purch' ? "입고" : "",
      tblNm: this.p_procType == 'salesOrd' ? "수주" : this.p_procType == 'sales' ? "매출" : this.p_procType == 'purch' ? "매입" : "",
      trgtDtNm: this.p_procType == 'salesOrd' ? "수주" : "전표",
      scdulTypeNm: this.p_procType == 'salesOrd' ? "출고" : "",
      payMthd: this.p_payMthd ? this.p_payMthd : "crdt",

      trgtDt: this.p_trgtDt,
      scdulDt: this.p_scdulDt
    };
  },
  computed: {
    payMthdList() {
      return this.$store.state.commonCode.pay_mthd
    }
  },
  created() {
    console.log("stepProcessPopup > created : ", this.trgtDt, this.scdulDt);
    this.$store.dispatch('refreshCommonCode', 'pay_mthd');
  },
  mounted() {

  },
  methods: {
    onProcess() {    
      console.log("onProcess:");
      this.$emit('stepProcess', this.procType, this.trgtDt, this.scdulDt, this.payMthd);
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>