<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>명세서 조회</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">

              <v-col cols="1"><div class=""><span>조회수 : {{filteredSales.length}} 건</span></div></v-col>
              <v-col cols="9" class="d-flex justify-end align-center hei-40p">
                <v-row dense align="center">
                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>

                  <v-col cols="4">
                    <v-row dense align="center">
                      <v-col cols="5">
                        <date-picker v-model="srchBgnDt"></date-picker>
                      </v-col>
                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                      <v-col cols="5">
                        <date-picker v-model="srchEndDt"></date-picker>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="1"><div class="f-r"><span>거래처</span></div></v-col>
                  <v-col cols="2">
                    <v-text-field
                      class=""
                      hide-details
                      dense
                      outlined
                      v-model="pendingWordCustr"
                      @focusout="searchWordCustr = pendingWordCustr"
                      @keyup.enter="searchWordCustr = pendingWordCustr"
                      clearable
                      @click:clear="searchWordCustr = ''"
                      ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              
              <!-- <v-col cols="1"></v-col> -->
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" @click="onSalesInqry">조회</v-btn>
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="2"><div class=""><span>선택 건수 : {{selectedCount}} 건</span></div></v-col>
              <v-col cols="2"><div class=""><span>공급가액 : {{suplyAmt}} 원</span></div></v-col>
              <v-col cols="2"><div class=""><span>부가세액 : {{vatAmt}} 원</span></div></v-col>
              <v-col cols="2"><div class=""><span>합계금액 : {{sumAmt}} 원</span></div></v-col>
            </v-row>
            <v-row dense align="center">              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredSales" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexSalesInitialized"
                    :itemsSourceChanged="onFlexSalesItemsSourceChanged"
                    :headersVisibility="'Column'"
                    >
                    <wj-flex-grid-column :header="'매출번호'" :binding="'sales_purch_no'" width="2*" />
                    <wj-flex-grid-column :header="'매출일자'" :binding="'sales_purch_dt'" width="2*" />
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" width="3*" />
                    <wj-flex-grid-column :header="'건수'" :binding="'dtl_cnt'" width="1*" />
                    <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" width="2*" />
                    <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" width="2*" />
                    <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" width="2*" />
                    <wj-flex-grid-column :header="'비고'" :binding="'rm'" width="5*" />
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

const regexp = /\B(?=(\d{3})+(?!\d))/g;

export default {
  components: {datePicker},
  data(){
    return{
      srchBgnDt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      searchWordCustr: "",
      pendingWordCustr: "",
      
      sales: [],

      flexSales: null,    // 매출
      selectedItems: [],
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.salesInqry.code);
    },
    filteredSales() {
      return this.sales.filter(({custr_nm}) =>
          (!this.searchWordCustr || this.searchWordCustr.trim() === '' ||
            custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustr.toUpperCase()))
      );
    },
    selectedCount() {
      // console.log("selectedCount:", this.flexSales);
      return this.flexSales == null ? 0 : this.selectedItems.length;
    },
    suplyAmt() {
      return String(this.selectedItems.reduce((acc, {suply_amt}) => suply_amt ? acc + suply_amt : acc, 0)).replace(regexp, ",");
    },
    vatAmt() {
      return String(this.selectedItems.reduce((acc, {vat_amt}) => vat_amt ? acc + vat_amt : acc, 0)).replace(regexp, ",");
    },
    sumAmt() {
      return String(this.selectedItems.reduce((acc, {sum_amt}) => sum_amt ? acc + sum_amt : acc, 0)).replace(regexp, ",");
    }
  },
  watch: {
    // srchBgnDt: {
    //   immediate: true,
    //   handler: function () {
    //     // console.log("watch srchDt -> loadInvnt");
    //     this.loadInvnt(this.skindCd);
    //   },
    // },
  },
  created() {
    
  },
  mounted() {
    this.loadSales();
  },
  methods: {
    onFlexSalesInitialized(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mouseup', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // flex.select(ht.row, ht.col);

        // this.selectedItems 설정
        this.selectedItems = flex.selectedItems;
      });

      this.flexSales = flex;
    },
    onFlexSalesItemsSourceChanged(flex) {
      // this.selectedItems 설정
      this.selectedItems = flex.selectedItems;
    },
    // 매출 명세 조회
    async loadSales() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/sales/getSalesDtlstList', params);
        const sales = res.data.salesList.map(sales => ({
          ...sales,
        }));

        this.sales = sales;
      } catch (e) {
        console.log(`Error on function loadSales: ${e}`);
      }
    },
    // 조회 버튼 클릭
    onSalesInqry() {
      this.loadSales();
    },

  }
}
</script>

<style>

</style>