<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>재고 조회</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">
              <v-col cols="1"><div><span>조회수 : {{filteredSkinds.length}}건</span></div></v-col>
              <v-col cols="2">
                <v-row dense >
                  <v-col cols="1"></v-col>
                  <v-col cols="2">
                    <div class=""><span class="pr-2">강종</span></div>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      class=""
                      hide-details
                      dense
                      outlined
                      v-model="pendingWordSkind"
                      @focusout="searchWordSkind = pendingWordSkind"
                      @keyup.enter="searchWordSkind = pendingWordSkind"
                      clearable
                      @click:clear="searchWordSkind = ''"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="1"><div class="pt-1"><span>조회수 : {{filteredInvnts.length}} 건</span></div></v-col>
              <v-col cols="6" class="d-flex justify-end align-center hei-40p">
                <v-row dense align="center">
                  <v-col cols="1"><div class="pt-1 f-r"><span>조회일자</span></div></v-col>
                  <v-col cols="2">
                    <date-picker v-model="srchDt" :is-checked="false"></date-picker>
                    <!-- <v-text-field
                      class=""
                      hide-details
                      dense
                      outlined
                      append-icon="mdi-calendar"
                      ></v-text-field> -->
                  </v-col>
                  <v-col cols="1"><div class="pt-1 f-r"><span>조회옵션</span></div></v-col>
                  <v-col cols="2">
                    <v-select
                      class=""
                      hide-details
                      dense
                      outlined
                      v-model="excldZero"
                      :items="srchOptnList"
                      item-value="excld_zero"
                      item-text="optn_nm"
                      ></v-select>
                  </v-col>
                  <v-col cols="1"><div class="pt-1 f-r"><span>규격</span></div></v-col>
                  <v-col cols="2">
                    <v-text-field
                      class=""
                      hide-details
                      dense
                      outlined
                      v-model="pendingWordStnd"
                      @focusout="searchWordStnd = pendingWordStnd"
                      @keyup.enter="searchWordStnd = pendingWordStnd"
                      clearable
                      @click:clear="searchWordStnd = ''"
                      ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              
              <!-- <v-col cols="1"></v-col> -->
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <!-- <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold">조회</v-btn> -->
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>
            <v-row dense align="center">
              <v-col cols="3">
                <v-row dense align="center">
                  <v-col cols="12">
                    <div>
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="filteredSkinds" 
                        :selectionMode="'ListBox'"
                        style="height:650px;"
                        :initialized="onFlexSkindInit"
                        :headersVisibility="'Column'"
                        :alternatingRowStep="0"
                        >
                        <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="1*" />
                        <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="2*" />
                      </wj-flex-grid>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col cols="12">
                    <div>
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="filteredInvnts" 
                        :selectionMode="'ListBox'"
                        style="height:650px;"
                        :initialized="onFlexInvntInit"
                        :headersVisibility="'Column'"
                        :alternatingRowStep="0"
                        >
                        <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" width="1*" />
                        <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" width="2*" />
                        <wj-flex-grid-column :header="'규격'" :binding="'stnd'" width="1*" />
                        <wj-flex-grid-column :header="'가로/길이'" :binding="'width_len'" width="1*" />
                        <wj-flex-grid-column :header="'세로/Ø'" :binding="'depth_phi'" width="1*" />
                        <wj-flex-grid-column :header="'수량재고'" :binding="'qty_invnt'" width="1*" :css-class="customColumnStyle"/>
                        <wj-flex-grid-column :header="'중량재고'" :binding="'wegt_invnt'" width="1*" :css-class="customColumnStyle"/>
                      </wj-flex-grid>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

const DUMMY_SKIND_CD = '__dummy__';

export default {
  components: {datePicker},
  data(){
    return{
      srchDt: dayjs().format('YYYY-MM-DD'),
      srchOptnList: [{excld_zero: "N", optn_nm: "0값 포함"}, {excld_zero: "Y", optn_nm: "0값 제외"}],
      excldZero: 'N',

      searchWordSkind: "",
      searchWordStnd: "",
      pendingWordSkind: "",
      pendingWordStnd: "",

      skindCd: DUMMY_SKIND_CD,
      
      skinds: [],
      invnts: [],

      flexSkind: null,  // 강종
      flexInvnt: null,    // 재고

      isOnGettingInvnt: false,
      flexSkindSelectedCol: 0,
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.invntInqry.code);
    },
    filteredSkinds() {
      return this.skinds.filter(({skind_nm}) =>
          (!this.searchWordSkind || this.searchWordSkind.trim() === '' ||
            (skind_nm && skind_nm.toUpperCase().includes(this.searchWordSkind.toUpperCase())))
      );
    },
    filteredInvnts() {
      return this.invnts.filter(({stnd}) =>
          (!this.searchWordStnd || this.searchWordStnd.trim() === '' ||
            stnd && stnd.toUpperCase().includes(this.searchWordStnd.toUpperCase()))
      );
    },
    customColumnStyle() {
      return 'sum-bg-color';
    }
  },
  watch: {
    async filteredSkinds(newValue) {
      // console.log("watch filteredSkinds:", newValue);
      const [first] = newValue;
      const skindCd = first && first.skind_cd || DUMMY_SKIND_CD;
      await this.loadInvnt(skindCd);
      
      // 첫번째 row 선택 표시
      // console.log("flexSkind.selectedRows:", this.flexSkind.selectedRows);
      // this.flexSkind.selectedRows.splice(0, this.flexSkind.selectedRows.length);
      // this.flexSkind.selectionMode = 'Row';
      this.flexSkind.select(0, this.flexSkindSelectedCol);
      // this.flexSkind.selectionMode = 'ListBox';
    },
    srchDt: {
      immediate: true,
      handler: function () {
        // console.log("watch srchDt -> loadInvnt", this.skindCd);
        this.loadInvnt(this.skindCd);
      },
    },
    excldZero: {
      immediate: true,
      handler: function () {
        // console.log("watch excldZero -> loadInvnt", this.skindCd);
        this.loadInvnt(this.skindCd);
      },
    }
  },
  created() {

  },
  mounted() {
    this.loadSkinds();
  },
  methods: {
    onFlexSkindInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // console.log("flexSkind mousedown:", ht.row, ht.col);
        // if (this.isOnPendingInvnt) return;

        this.skindCd = flex.getCellData(ht.row, 0);
        // console.log("mousedown > skindCd:", this.skindCd);
        // if (skindCd !== this.pendingSkind.skind_cd)
        await this.loadInvnt(this.skindCd);

        flex.select(ht.row, ht.col);
        this.flexSkindSelectedCol = ht.col;
      });

      this.flexSkind = flex;
    },
    onFlexInvntInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        // if (this.isOnPendingEstm) return;

        // const sitemCd = flex.getCellData(ht.row, 0);
        // if (estmSno !== this.pendingEstm.estm_sno)
        //   await this.loadEstm(estmSno);
      });

      this.flexInvnt = flex;
    },

    async loadSkinds() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", this.srchDt);

      try {
        const res = await this.$http.post('/base/getSteelKindList', params);
        const skinds = res.data.skindList.map(skind => ({
          ...skind,
        }));

        this.skinds = skinds;
      } catch (e) {
        console.log(`Error on function loadSkinds: ${e}`);
      }
    },
    async loadInvnt(skindCd) {
      if (skindCd === DUMMY_SKIND_CD || this.isOnGettingInvnt) {
        // console.log("loadInvnt > isOnGettingInvnt");
        return;
      }

      this.skindCd = skindCd;

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      params.append("comp_sno", compSno);
      params.append("skind_cd", skindCd);
      params.append("srch_dt", this.srchDt);
      if(this.excldZero == "Y") params.append("excld_zero", this.excldZero);

      try {
        // console.log("loadInvnt set isOnGettingInvnt true");
        // this.isOnGettingInvnt = true;

        const res = await this.$http.post('/sales/getInvntList', params);
        // this.$http.post('/sales/getInvntList', params).then((res) => {
          // console.log("loadInvnt:", res);
          // console.log("loadInvnt set isOnGettingInvnt false");
          // this.isOnGettingInvnt = false;

          const invnts = res.data.invntList.map(invnt => ({
            ...invnt,
          }));

          this.invnts = invnts;
        // })     
      } catch (e) {
        console.log(`Error on function loadEstm: ${e}`);
        // console.log("loadInvnt set isOnGettingInvnt false");
        // this.isOnGettingInvnt = false;
      }
    },
  }
}
</script>

<style>
  /* .custom-column-style {
    background-color: #B0AB51;
  } */
</style>