<template>
  <v-container fluid class=" hei-100vh-auto d-flex theme-blue-bg1">
      <template>
        <v-container class="hei-80vh-auto d-flex">
          <v-row class="ma-0">
            <v-col cols="12" class="d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="d-flex justify-center mb-3">
                  <h1>METAL NOTE</h1>
                </div>
                <div class="wid-100  d-flex justify-center mb-4">
                  <div class="wid-150p hei-100p ">
                    <img class="wid-150p hei-100p image-default-size" src="https://img.freepik.com/free-photo/shanghai-aerial-view-with-urban-architecture-sunset_649448-2470.jpg?size=626&ext=jpg">
                  </div>
                </div>
                <v-card class="hei-400p wid-100 d-flex justify-center align-center" outlined >
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="">
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          v-model="login_id"
                          prepend-inner-icon="mdi-account"
                          hide-details
                          outlined>
                          
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          v-model="pwd"
                          prepend-inner-icon="mdi-lock"
                          hide-details
                          outlined
                          type="password"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="ma-1" v-show="errorMsg">
                        <div class="wid-100 hei-20p">
                          <span class="body-2 text-red">
                            {{ errorMsg }}
                            <!-- 아이디 혹은 비밀번호가 일치하지 않습니다. 입력한 내용을 다시 확인해주세요. -->
                          </span>
                        </div>
                      </v-col>
                      <v-col cols="12" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-blue-bg5 white--text"
                          @click="loginClicked"
                        >로그인
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-row dense class="" align="center">
                          <!-- <v-col cols="5">
                            <v-checkbox
                              hide-details
                              label="로그인 상태 유지">
                            </v-checkbox>
                          </v-col>
                          <v-col cols="7" class="d-flex justify-end">
                            <v-btn
                              x-small
                              text>
                              아이디/비밀번호 찾기
                            </v-btn>
                            <v-icon>mdi-power-on</v-icon>
                            <v-btn
                              x-small
                              text>
                              회원가입
                            </v-btn>
                          </v-col> -->
                          <v-col cols="6">
                            <v-btn
                              small
                              text>
                              비밀번호 찾기
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="d-flex justify-end">
                            <v-btn
                              small
                              text>
                              아이디 찾기
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center pt-5">
                        <span class="caption">
                          로그인 할 때 주의할 점이나 담당자 연락처 등이 들어갈 수 있습니다.
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col>


            <!-- <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="wid-100  d-flex justify-center mb-7">
                  <span class="fontSize-6 font-weight-bold">아이디</span>
                  <span class="fontSize-6 ml-3">찾기</span>
                </div>
                <v-card class="hei-400p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="mb-3 d-flex justify-center">
                        <span class="text-red font-weight-bold">
                          등록하신 이메일로 아이디 정보를 보내드립니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-3">
                        <h4>이름</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>이메일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey">
                        
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="bg-white">
                          비밀번호 찾기
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          확인
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          아이디를 찾지 못하셨다면 help@apub.co.kr로 문의해주세요.
                        </span>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          아직 회원이 아니라면 회원가입을 해주세요.
                        </span>
                        <v-btn
                          small
                          text
                          class="text-red">
                          바로가기
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col> -->


            <!-- <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="wid-100  d-flex justify-center mb-7">
                  <span class="fontSize-6 font-weight-bold">비밀번호</span>
                  <span class="fontSize-6 ml-3">찾기</span>
                </div>
                <v-card class="hei-400p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="mb-3 d-flex justify-center">
                        <span class="text-red font-weight-bold">
                          등록하신 이메일로 임시 비밀번호를 보내드립니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-3">
                        <h4>아이디</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>이메일</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey">
                        
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="bg-white">
                          아이디 찾기
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          확인
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          비밀번호를 찾지 못하셨다면 help@apub.co.kr로 문의해주세요.
                        </span>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          아직 회원이 아니라면 회원가입을 해주세요.
                        </span>
                        <v-btn
                          small
                          text
                          class="text-red">
                          바로가기
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col> -->


            <!-- <v-col cols="12" class=" d-flex justify-center" style="align-content: center;">
              <div class=" wid-40 mt-10 pt-10">
                <div class="wid-100  d-flex justify-center mb-7">
                  <span class="fontSize-6 font-weight-bold">비밀번호</span>
                  <span class="fontSize-6 ml-3">재설정</span>
                </div>
                <v-card class="hei-500p wid-100 bg-lightgrey-2 d-flex justify-center align-center" outlined>
                  <div class="wid-60">
                    <v-row align="center" dense class="">
                      <v-col cols="12" class="d-flex justify-center">
                        <span class="text-red font-weight-bold">
                          hong@academy.co.kr (홍길동)님의 본인확인이 완료되었습니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-3 d-flex justify-center">
                        <span class="text-red font-weight-bold">
                          사용하실 비밀번호를 입력해 주세요.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mb-3">
                        <h4>비밀번호</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <h4>비밀번호 확인</h4>
                        <v-text-field
                          class="bg-white"
                          height="40px"
                          append-icon="mdi-eye"
                          hide-details
                          outlined>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" class="">
                        <span class="caption text-red">
                          비밀번호 일치하지 않습니다.
                        </span>
                      </v-col>
                      <v-col cols="12" class="mt-3 mb-5 border-b-darkgrey">
                        
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="theme-color-1 white--text">
                          비밀번호 재설정
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="hei-40p mb-3">
                        <v-btn
                          block
                          tile
                          outlined
                          height="40px"
                          class="bg-darkgrey white--text">
                          취소
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          비밀번호를 찾지 못하셨다면 help@apub.co.kr로 문의해주세요.
                        </span>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-icon x-small color="#ff7451">mdi-slash-forward</v-icon>
                        <span class="caption">
                          아직 회원이 아니라면 회원가입을 해주세요.
                        </span>
                        <v-btn
                          small
                          text
                          class="text-red">
                          바로가기
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </div>
            </v-col> -->

          </v-row>
        </v-container>
      </template>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      login_id: '',
      pwd: '',
      errorMsg: null,
    }
  },
  created() {
    // alert(this.loginYn);
  },
  methods : {
    loginClicked() {
      if(this.validCheck()) {
        const params = new FormData();
        params.append("login_id", this.login_id);
        params.append("pwd", this.pwd);

        this.$http.post("/user/login", params).then((res) => {
          console.log("res.data:", res.data);
          if(res.data.code < 0) {
            // alert(this.$erroCdMsg(res.data.code));
            this.errorMsg = this.$erroCdMsg(res.data.code);
            return;
          } else {
            const userMap = res.data.userMap;
            const test = {compSno: userMap.comp_sno, userSno: userMap.user_sno};
            this.$store.commit('updateSession', test);    
            this.$router.push("/");
          }
        }).catch(function (error) {
            console.log(error);
          }
        )
      }
    },//loginClicked end
    validCheck() {
      let valid = true;
      if(!this.login_id || this.login_id.trim == '') {
        // alert('아이디 입력해주세요.');
        this.errorMsg = '아이디를 입력해주세요.';
        valid = false;
      } else if(!this.pwd || this.pwd.trim == '') {
        // alert('비밀번호 입력해주세요.');
        this.errorMsg = '비밀번호를 입력해주세요.';
        valid = false;
      }
      return valid;
    },//validCheck end
  }
}
</script>

<style scoped>
.v-text-field--enclosed /deep/ .v-input__prepend-inner{
  margin-top: 10px;
}
.v-text-field--enclosed /deep/ .v-input__append-inner{
  margin-top: 10px;
}
.v-input--selection-controls /deep/ {
  margin-top:0px;
}

</style>