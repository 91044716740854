<template>
  <div class="container">
    <canvas ref="canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing" @mouseout="stopDrawing"></canvas></div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "StatementCanvas",
  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext('2d');
  },
  data() {
    return ({
      canvas: null,
      ctx: null,
      isDrawing: false});
  },
  methods: {
    startDrawing(e) {
      this.isDrawing = true;
      this.ctx.beginPath();
      this.ctx.moveTo(e.clientX - this.canvas.offsetLeft, e.clientY - this.canvas.offsetTop);
    },
    draw(e) {
      if (!this.isDrawing) return;
      this.ctx.lineTo(e.clientX - this.canvas.offsetLeft, e.clientY - this.canvas.offsetTop);
      this.ctx.stroke();
    },
    stopDrawing() {
      this.isDrawing = false;
    }
  },
  computed: {},
})
</script>

<style scoped>
.container {height: 400px;background: #00bcd4;}

</style>
