<template>
  <!--[[[[[[[[[[[[[[[        즐겨찾기 관리 팝업        ]]]]]]]]]]]]]]]-->
  <v-dialog
    v-model="showDialog"
    min-width="500px"
    max-width="500px"
    height="800px">
    <v-container>
      <v-card style="text-align:center;" class="pb-3">
        <v-toolbar 
          color="indigo darken-2"
          class="elevation-0"
          height="50px">
          <v-toolbar-title
            class="white--text"
            >즐겨찾기 관리
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>        

        <div class="pa-3" style="overflow:auto; height:700px;">
          <v-list>
            <v-list-item
              v-for="(item, index) in gridList"
              :key="index">
              <v-list-item-title
                class="tborder mb-1"
                appendIcon="mdi-close">
                <v-row dense align="center">
                  <v-col cols="10" class="pl-5">
                    <span class="float-left">{{ item.favor_nm }}</span>
                  </v-col>
                  <v-col cols="2">
                    <v-btn 
                      icon 
                      class="ma-0 pa-0"
                      @click="deleteRowBtnClicked(item, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div class="pt-3">
          <v-btn color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="$emit('close')">취소</v-btn>
          <v-btn color="indigo darken-3" class="white--text" width="70" @click="saveBtnClicked">저장</v-btn>
        </div>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: ["p_soctySno"],
  data() {
    return {
      showDialog: true,
      userSno: null,

      gridHeader: [
        { text: '즐겨찾기명', value: 'favor_nm', width: "80%" },
        { text: '삭제', value: 'delete', width: "20%" },
      ],
      gridList: [],
      deleteList: [],
    };
  },
  created() {
    console.log("memberSearchPopup created.");
    this.userSno = this.$store.state.session.userSno;

    this.getFavorList();
  },
  methods: {
    getFavorList() {
      let params = new FormData();

      params.append("user_sno", this.$store.state.session.userSno);

      this.$http.post("/common/getFavorList", params).then((res) => {
        // console.log("getFavorList res.data:", res.data);
        this.gridList = res.data.favorList;
      }).catch(function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    deleteRowBtnClicked(item, index) {
      console.log("deleteRowBtnClicked:", item, index);
      this.deleteList.push({user_sno:item.user_sno, odr:item.odr});
      this.gridList.splice(index, 1);
    },
    saveBtnClicked() {
      console.log("deleteList:", this.deleteList);
      let params = new URLSearchParams();
      params.append("deleteList", JSON.stringify(this.deleteList));

      this.$http.post("/common/deleteFavorList", params).then((res) => {
        console.log(res.data);
        alert("저장되었습니다.");
        this.$emit('close');
      })
      .catch(function (error) {
          console.log(error);
          alert(error.message);
        }
      );
    },
  },
};
</script>
<style scoped>
/deep/ .v-dialog > .v-card > .v-toolbar {
  position: sticky !important;
  top: 0;
  z-index: 999;
}
</style>