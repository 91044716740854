<template>
  <v-container fluid class="d-flex flex-column" style="height: 100vh;">
    <v-app-bar class="flex-grow-0 elevation-0 theme-blue-bg3" height="60">
      <v-row class="align-center">
        <v-col cols="2" class="white--text">
          <span><h2>Metal Note</h2></span>
        </v-col>
        <v-col class="d-flex align-center justify-center height-d">
          <v-menu v-for="(menu, index) in menu" :key="index" offset-y class="">
            <template v-slot:activator="{ on }">
              <v-btn class="textSize-1 font-weight-bold white--text" text v-on="on">{{ menu.tabName }}</v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(sub1, index) in menu.subItems" :key="index" @click="addTab(sub1.subTabName, sub1.subContent)">
                <v-list-item-title>{{ sub1.subTabName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="2" class="d-flex justify-end align-center height-d">
          <v-btn icon color="white"><v-icon>mdi-account-circle</v-icon></v-btn>
          <v-btn icon color="white"><v-icon>mdi-cog</v-icon></v-btn>
          <v-btn icon color="white" @click="logoutClicked"><v-icon>mdi-logout</v-icon></v-btn>
          <!-- <v-btn icon color="white"><v-icon>mdi-apps</v-icon></v-btn> -->
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row class="favbar-theme-blue-bg5 d-flex flex-grow-0">
      <v-col>
        <v-btn text tile small class="yellow--text body-1" style="font-weight: bold;" @click="favorMngBtnClicked">즐겨찾기 관리</v-btn>
        <v-btn text tile small class="white--text body-1" v-for="(item, index) in favorList" :key="index" @click="favorBtnClicked(item)">
          {{ item.favor_nm }}
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs class="tab-wrapper d-flex flex-column" height="30" background-color="blue lighten-5" v-model="activeTab">
      <v-tab v-for="(t, index) of subTabs" :key="t.subTabName" exact>
        {{ t.subTabName }}
        <v-btn icon @click="removeTab(index, t.subContent)">
          <v-icon x-small>mdi-close</v-icon>
        </v-btn>
      </v-tab>

      <v-tabs-items class="tab-content" v-model="activeTab">
        <v-divider></v-divider>
        <v-tab-item
            class="tab-item pl-3 pr-3 pt-1"
            v-for="item in subTabs" :key="item.subTabName">
          <component
              :is="item.subContent"
              :pIsFavorited="item.isFavorited"
              @addFavorite="addFavorite"
              @deleteFavorite="deleteFavorite"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <favor-mng-popup v-if="favorMngPopup" @close="closeFavorMngPopup"></favor-mng-popup>
  </v-container>
</template>

<script>
import FavorMngPopup from "@/components/favorMngPopup.vue";

import custrInqry from "@/views/sales/custrInqry";
import custrLabel from "@/views/sales/custrLabel";
import invntInqry from "@/views/sales/invntInqry.vue";
import salesInqry from "@/views/sales/salesInqry.vue";
import vuetest from "@/views/sales/vuetest.vue";
import estimate from "@/views/sales/estimate.vue";
import order from "@/views/sales/order.vue";
import sales from "@/views/sales/sales.vue";

import collectPay from "@/views/account/collectPay.vue";
import unCollectPay from "@/views/account/unCollectPay.vue";
import cashLedger from "@/views/account/cashLedger.vue";
import byDayByMonthStmt from "@/views/account/byDayByMonthStmt.vue";
import incomeStmt from "@/views/account/incomeStmt.vue";
import trialBalance from "@/views/account/trialBalance.vue";
import ocr from "@/views/ocr.vue";

import ledgrByCustr from "@/views/ledger/ledgrByCustr.vue";
import ledgrBySitem from "@/views/ledger/ledgrBySitem.vue";
import ledgrByAcct from "@/views/ledger/ledgrByAcct.vue";
import ledgrByDay from "@/views/ledger/ledgrByDay.vue";
import invntStus from "@/views/ledger/invntStus.vue";

import customer from "@/views/base/customer.vue";
import steelKind from "@/views/base/steelKind.vue";
import steelItem from "@/views/base/steelItem.vue";
import account from "@/views/base/account.vue";
import sitemUnitPrice from "@/views/base/sitemUnitPrice.vue";
import gravity from "@/views/base/gravity.vue";
import mchngUnitPrice from "@/views/base/mchngUnitPrice.vue";
import {SUB_TAB} from "@/common/constant";

export default {
  components: {
    FavorMngPopup,
    custrInqry,
    custrLabel,
    invntInqry,
    salesInqry,
    vuetest,
    estimate,
    order,
    sales,
    collectPay,
    unCollectPay,
    cashLedger,
    byDayByMonthStmt,
    incomeStmt,
    trialBalance,
    ocr,
    ledgrByCustr,
    ledgrBySitem,
    ledgrByAcct,
    ledgrByDay,
    invntStus,
    customer,
    steelKind,
    steelItem,
    account,
    sitemUnitPrice,
    mchngUnitPrice,
    gravity,
  },
  data() {
    return {
      compSno: null,
      userSno: null,

      activeTab: null,
      selectedTab: "",
      tabCount: 0,
      subTabs: [],
      favorList: [], // 즐겨찾기 목록
      favorMngPopup: false,

      menu: [
        {
          tabName: "영업관리",
          subItems: [
            { subTabName: SUB_TAB.vuetest.name, subContent: SUB_TAB.vuetest.code},
            { subTabName: SUB_TAB.estimate.name, subContent: SUB_TAB.estimate.code},
            { subTabName: SUB_TAB.custrInqry.name, subContent: SUB_TAB.custrInqry.code},
            { subTabName: SUB_TAB.order.name, subContent: SUB_TAB.order.code},
            { subTabName: SUB_TAB.sales.name, subContent: SUB_TAB.sales.code},
            { subTabName: SUB_TAB.invntInqry.name, subContent: SUB_TAB.invntInqry.code},
            { subTabName: SUB_TAB.salesInqry.name, subContent: SUB_TAB.salesInqry.code},
            { subTabName: SUB_TAB.custrInqry.name, subContent: SUB_TAB.custrInqry.code},
            { subTabName: SUB_TAB.custrLabel.name, subContent: SUB_TAB.custrLabel.code},
          ],
        },
        { tabName: "회계관리",
          subItems: [
            {subTabName: SUB_TAB.collectPay.name, subContent: SUB_TAB.collectPay.code},
            {subTabName: SUB_TAB.unCollectPay.name, subContent: SUB_TAB.unCollectPay.code},
            {subTabName: SUB_TAB.cashLedger.name, subContent: SUB_TAB.cashLedger.code},
            {subTabName: SUB_TAB.byDayByMonthStmt.name, subContent: SUB_TAB.byDayByMonthStmt.code},
            {subTabName: SUB_TAB.incomeStmt.name, subContent: SUB_TAB.incomeStmt.code},
            {subTabName: SUB_TAB.trialBalance.name, subContent: SUB_TAB.trialBalance.code},
            {subTabName: SUB_TAB.ocr.name, subContent: SUB_TAB.ocr.code},
          ],
        },
        { tabName: "대장조회",
          subItems: [
            {subTabName: SUB_TAB.ledgrByCustr.name, subContent: SUB_TAB.ledgrByCustr.code},
            {subTabName: SUB_TAB.ledgrBySitem.name, subContent: SUB_TAB.ledgrBySitem.code},
            {subTabName: SUB_TAB.ledgrByAcct.name, subContent: SUB_TAB.ledgrByAcct.code},
            {subTabName: SUB_TAB.ledgrByDay.name, subContent: SUB_TAB.ledgrByDay.code},
            {subTabName: SUB_TAB.invntStus.name, subContent: SUB_TAB.invntStus.code},
          ],
        },
        { tabName: "결산/분석" },
        {
          tabName: "기초자료",
          subItems: [
            { subTabName: SUB_TAB.customer.name, subContent: SUB_TAB.customer.code},
            { subTabName: SUB_TAB.steelKind.name, subContent: SUB_TAB.steelKind.code},
            { subTabName: SUB_TAB.steelItem.name, subContent: SUB_TAB.steelItem.code},
            { subTabName: SUB_TAB.account.name, subContent: SUB_TAB.account.code},
            { subTabName: SUB_TAB.sitemUnitPrice.name, subContent: SUB_TAB.sitemUnitPrice.code},
            { subTabName: SUB_TAB.gravity.name, subContent: SUB_TAB.gravity.code},
            { subTabName: SUB_TAB.mchngUnitPrice.name, subContent: SUB_TAB.mchngUnitPrice.code},
          ],
        },
        { tabName: "추가기능" },
        { tabName: "도움말" },
      ],
    };
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;

    this.getFavorList();
  },
  methods: {
    addTab(title, subTitle) {
      // 열려 있는 지 확인 -> 있으면 해당 탭 활성화
      const openedIndex = this.subTabs.findIndex(({subContent}) => subContent === subTitle);
      if (0 <= openedIndex) {
        this.activeTab = openedIndex;
        return;
      }

      this.activeTab = this.tabCount++;
      const isFavorite = this.favorList.some(({favor_path}) => favor_path === subTitle) ? 'Y' : 'N';

      this.subTabs.push({
        subTabName: title,
        subContent: subTitle,
        isFavorited: isFavorite,
      });

      // this.$nextTick(() => {
      //   this.selectedTab = newTabIndex;
      // });
    },
    removeTab(index, subContent) {
      // console.log("removeTab index:", index, subContent);
      if(this.$store.state.isOnPending.includes(subContent)) {
        if(!confirm("저장되지 않은 데이터가 있습니다. 종료하시겠습니까?")) {
          return;
        }
      }

      this.subTabs.splice(index, 1);
      this.tabCount--;
    },
    async getFavorList() {
      await this.$store.dispatch('refreshFavorites');
      const userSno = this.$store.state.session.userSno ? this.$store.state.session.userSno : '';
      const params = new FormData();
      params.append("user_sno", userSno);
      try {
        const res = await this.$http.post('/common/getFavorList', params)
        this.favorList = res.data.favorList;

      } catch (e) {
        console.log(`Error on function getFavorList: ${e}`);
      }
    },
    async addFavorite() {
      const params = new FormData();
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('favor_nm', this.subTabs[this.activeTab].subTabName);
      params.append('favor_path', this.subTabs[this.activeTab].subContent);
      try {
        const res = await this.$http.post('/common/insertFavor', params)
        if (0 === res.data.code)
          alert('즐겨찾기에 추가되었습니다.');
        await this.getFavorList();

      } catch (e) {
        console.log(`Error on function addFavorite: ${e}`);
      }
    },
    async deleteFavorite() {
      const params = new FormData();
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('favor_nm', this.subTabs[this.activeTab].subTabName);
      params.append('favor_path', this.subTabs[this.activeTab].subContent);
      try {
        const res = await this.$http.post('/common/deleteFavor', params)
        if (0 === res.data.code)
          alert('즐겨찾기에서 삭제되었습니다.');
        await this.getFavorList();

      } catch (e) {
        console.log(`Error on function addFavorite: ${e}`);
      }
    },
    favorMngBtnClicked() {
      this.favorMngPopup = true;
    },
    closeFavorMngPopup() {
      this.favorMngPopup = false;
      this.getFavorList();
    },
    favorBtnClicked(item) {
      // console.log("favorBtnClicked:", item);
      this.addTab(item.favor_nm, item.favor_path);
    },
    logoutClicked() {
      if(confirm('로그아웃 하시겠습니까?')) {
        this.$http.get("/user/logout").then((res) => {
          localStorage.clear();
          this.$router.push('/login');
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
      
    },//logoutClicked end
  },
};
</script>

<style>
.tab-content > .v-window__container {
  width: 100%;
  flex-grow: 1;
}
.tab-wrapper {flex: 1;overflow-y: hidden;}
.tab-content {position: relative;display: flex;flex-grow: 1;min-width: 1024px;overflow-y: hidden;}
.tab-item {height: 100%;overflow-y: scroll;}

.whiteCC {
  color: white !important;
}
.blueCC {
  background-color: white;
  color: blue !important;
}
</style>
