<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="1">
            <div class="">
              <h2>비중 관리</h2>
            </div>
          </v-col>
          <v-col cols="11">
            <v-btn small color="blue darken-2" class="white--text" v-show="!isFavorited" @click="addFavorite">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="" v-show="isFavorited" @click="deleteFavorite">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>
            <v-row class="mt-0">
              <v-col cols="3">
                <v-row class="">
                  <v-col cols="4"><div><span>조회수 : {{ custrCount }}건</span></div></v-col>
                  <v-col cols="7">
                    <div class="d-flex">
                      <span class="pr-2">거래처</span>
                      <v-text-field
                        class=""
                        v-model="searchWordCustr"
                        hide-details
                        dense
                        outlined
                        height="25"
                        @keyup="searchWordCustrKeyUp"
                        :readonly="isOnPendingSpgrv"
                        @click="onSearchWordClick"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0 pr-5">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="gridCustrList"
                      :selectionMode="'ListBox'"
                      style="height:650px;"
                      :initialized="flexCustrInitialized"
                      :itemsSourceChanging="custrItemsSourceChanging"
                      :itemsSourceChanged="custrItemsSourceChanged"
                      :headersVisibility="'Column'"
                    >
                    <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                    <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*" />
                    <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="4*" />
                    <wj-flex-grid-column :header="'비중설정'" :binding="'set_spgrv_yn'" width="5*" :visible="false"/>
                    </wj-flex-grid>
                  </v-col>
                  <!-- <v-col cols="1" class="" style="padding-top:60%;">
                    <v-icon large style="">
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="3">
                <v-row class="">
                  <v-col cols="4"><div><span>조회수 : {{ skindCount }}건</span></div></v-col>
                  <v-col cols="7">
                    <div class="d-flex">
                      <span class="pr-2">강종</span>
                      <v-text-field
                        class=""
                        v-model="searchWordSkind"
                        hide-details
                        dense
                        outlined
                        height="25"
                        @keyup="searchWordSkindKeyUp"
                        :readonly="isOnPendingSpgrv"
                        @click="onSearchWordClick"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0 pr-5">
                    <wj-flex-grid
                      :isReadOnly=true
                      :itemsSource="gridSkindList"
                      :selectionMode="'ListBox'"
                      style="height:650px;"
                      :initialized="flexSkindInitialized"
                      :itemsSourceChanging="skindItemsSourceChanging"
                      :itemsSourceChanged="skindItemsSourceChanged"
                      :headersVisibility="'Column'"
                    >
                      <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="2*" />
                      <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="5*" />
                      <wj-flex-grid-column :header="'비중설정'" :binding="'set_spgrv_yn'" width="2*" />
                    </wj-flex-grid>
                  </v-col>
                  <!-- <v-col cols="1" class="" style="padding-top:60%;">
                    <v-icon large style="">
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row dense class="bgcolor-grey1">
                  <v-col cols="2" class="d-flex justify-start align-center hei-40p" >
                    <h4>비중정보</h4>
                  </v-col>
                  <v-col cols="8" class="d-flex justify-start align-center hei-40p" >
                    <div><span>* 미설정인 경우 품목에 설정된 비중을 기본값으로 사용합니다.</span></div>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end align-center hei-40p" >
                    <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingSpgrv">취소</v-btn>
                    <v-btn small color="indigo darken-3" class="white--text font-weight-bold" width="70" @click="saveBtnClicked" :disabled="!isOnPendingSpgrv && setSpgrvYn =='변경값'">저장</v-btn> 
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid
                      :itemsSource="pendingSpgrvList"
                      :selectionMode="'MultiRange'"
                      :showMarquee="true"
                      style="height:650px;"
                      :initialized="flexSpgrvInitialized"
                      :itemsSourceChanging="spgrvItemsSourceChanging"
                      :itemsSourceChanged="spgrvItemsSourceChanged"
                      :headersVisibility="'Column'"
                    >
                      <wj-flex-grid-column :header="'품목코드'" :binding="'sitem_cd'" :width="100" :isReadOnly="true"/>
                      <wj-flex-grid-column :header="'품목명'" :binding="'sitem_nm'" :width="200" :isReadOnly="true"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'stnd'" :width="100" :isReadOnly="true"/>
                      <wj-flex-grid-column :header="'비중(기본값)'" :binding="'sitem_spgrv'" :width="100" :isReadOnly="true"/>
                      <wj-flex-grid-column :header="'비중(변경값)'" :binding="'spgrv'" :width="100" />
                    </wj-flex-grid>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import * as wjcCore from "@grapecity/wijmo";
export default {
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == 'Y' ? true : false,
      getListMode: 'select',  // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false,   // 항목 변경 여부


      isItemsSourceChanging: false, // 목록 조회 중. getOOO에서 설정 -> itemsSourceChanged에서 해제
      gridCustrList: [],   // 그리드에 표시되는 filter된 목록
      custrList: [],
      custrCount: 0,
      custrSno: null,
      selectedCustrRow: 0,
      selectedCustrCol: 1,

      // isSkindItemsSourceChanging: false,
      gridSkindList: [],
      skindList: [],
      skindCount: 0,
      skindCd: null,
      skindNm: null,
      selectedSkindRow: 0,
      selectedSkindCol: 0,

      // isSpgrvItemsSourceChanging: false,
      pendingSpgrvList: [],
      spgrvList: [],
      selectedSpgrvRow: 0,
      selectedSpgrvCol: 0,
      
      // searchWord: null, // 검색어
      searchWordCustr: null,  // 거래처 검색어
      searchWordSkind: null,  // 강종 검색어

      flexCustr: null,
      flecSkind: null,

      setSpgrvYn: "변경값",

    }
  },
  computed: {
    isOnPendingSpgrv() {
      console.log("isOnPendingSpgrv:", this.spgrvList, this.pendingSpgrvList);
      return JSON.stringify(this.spgrvList) !== JSON.stringify(this.pendingSpgrvList);
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    this.getListMode = 'select';
    this.getCustrList();
    // this.getSkindList();
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.isDestroying = true;
  },
  methods: {
	  // 거래처 flexgrid 생성죔
	  flexCustrInitialized(flex) {
      this.flexCustr = flex;
      // console.log("flexCustrInitialized:", this.flexCustr);

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("flexDownEvent:", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);       
        if(ht.cellType == 1 && ht.row >= 0) {
          let isConfirmed = true;

          if(this.isOnPendingSpgrv) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);
            }
          }

          if(isConfirmed) {
            this.selectedCustrRow = ht.row;
            this.selectedCustrCol = ht.col;
            this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);

            this.getListMode = "select";
            this.getSkindList();

            // if(this.skindCd != null) {
            //   this.getSitemSpgrvList();
            // }
          }
        }
      });

      // "미설정" color 설정
      flex.formatItem.addHandler((flexgrid, event) => {
        // console.log("flex.formatItem.addHandler:", flexgrid, event);
        if(event.panel == flexgrid.cells) {
          // console.log("e.panel == s.cells:", e.panel, s.cells);
          let col = flexgrid.columns[event.col];
          if(event.row >= 0 && col.binding == "set_spgrv_yn") {
            let set_spgrv_yn = flexgrid.getCellData(event.row, event.col);
            // console.log("set_spgrv_yn:", set_spgrv_yn, event.row, event.col);
            if(set_spgrv_yn == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      }); 
    },
    // 거래처 flexgrid 데이터 변경 전
    custrItemsSourceChanging() {
      if(this.flexCustr && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexCustr);
      }
    },
    // 거래처 목록 그리드(flexCustr) 데이터 변경됨
    custrItemsSourceChanged() {
      // console.log("custrItemsSourceChanged:", this.flexCustr, this.selectedCustrRow, this.gridCustrList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexCustr && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexCustr);
      }

      if(this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0) {
        this.flexCustr.select(-1, 0);
        this.flexCustr.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedCustrRow = 0;
          this.selectedCustrCol = 1;
        } else if(this.getListMode == "save") {
          // console.log("getListMode:", this.getListMode);
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0 && this.selectedCustrRow >= 0) {
        this.flexCustr.rows[0].isSelected = false;
        this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);

        this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);
        // console.log("custrItemsSourceChanged custrSno:", this.custrSno);
        
        this.isItemsSourceChanging = false;       
        this.getSkindList();

        if(this.skindCd != null) {
          this.getSitemSpgrvList();
        }
      }
    },

    // 강종 그리드(flexSkind) 생성됨
    flexSkindInitialized(flexSkind) {
      this.flexSkind = flexSkind;

      flexSkind.addEventListener(flexSkind.hostElement, "mousedown", e => {
        // console.log("e", e);
        let ht = flexSkind.hitTest(e);    
        // console.log("ht:", ht, ht.cellType, ht.row); 
        // if(ht.row >= 0)  console.log("CellData:", this.flexGrid.getCellData(ht.row, 0));
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if(this.isOnPendingSpgrv) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
            }
          }

          if(isConfirmed) {
            this.selectedSkindRow = ht.row;
            this.selectedSkindCol = ht.col;
            this.skindCd = this.flexSkind.getCellData(this.selectedSkindRow, 0);
            this.setSpgrvYn = this.flexSkind.getCellData(this.selectedSkindRow, 2);

            if(this.custrSno != null) {
              this.getSitemSpgrvList();
            }
          }
        }
      });

      // "미설정" color 설정
      flexSkind.formatItem.addHandler((flexgrid, event) => {
        if(event.panel == flexgrid.cells) {
          const col = flexgrid.columns[event.col];
          if(event.row >= 0 && col.binding == "set_spgrv_yn") {
            // const set_spgrv_yn = flexgrid.getCellData(event.row, event.col);
            if(flexgrid.getCellData(event.row, event.col) == "변경값") {
              event.cell.style.color = 'blue';
            } else {
              event.cell.style.color = 'black';
            }
          }
          // 가운데 정렬
          if(event.row >= 0 && col.binding == "skind_cd") {
            // let html = '<div class="justify-center"><span>{val}</span></div>'
            let html = '<span class="justify-center">{val}</span>'
            let val = flexgrid.getCellData(event.row, event.col);
            event.cell.innerHTML = html.replace("{val}", wjcCore.Globalize.format(val, col.format));
          }
        }
      }); 
    },
    // 강종 flexgrid 데이터 변경 전
    skindItemsSourceChanging() {
      if(this.flexSkind && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSkind);
      }
    },
    // 강종 flexgrid 데이터 변경됨
    skindItemsSourceChanged() {
      // console.log("skindItemsSourceChanged:", this.flexSkind, this.selectedSkindRow, this.gridSkindList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexSkind && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexSkind);
      }

      if(this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0) {
        this.flexSkind.select(-1, 0);
        this.flexSkind.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedSkindRow = 0;
          this.selectedSkindCol = 0;
        } else if(this.getListMode == "save") {
          // console.log("getListMode:", this.getListMode);
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0 && this.selectedSkindRow >= 0) {
        this.flexSkind.rows[0].isSelected = false;
        this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
        this.skindCd = this.flexSkind.getCellData(this.selectedSkindRow, 0);
        
        this.isItemsSourceChanging = false;
        
        if(this.custrSno != null) {
          this.getSitemSpgrvList();
        }
      }
    },

    // 비중 그리드(flexSpgrv) 생성됨
    flexSpgrvInitialized(flexSpgrv) {
      this.flexSpgrv = flexSpgrv;

      flexSpgrv.addEventListener(flexSpgrv.hostElement, "mousedown", e => {
        // console.log("e", e);
        let ht = flexSpgrv.hitTest(e);    
        // console.log("ht:", ht, ht.cellType, ht.row); 
        // if(ht.row >= 0)  console.log("CellData:", this.flexSpgrv.getCellData(ht.row, 0));
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)

        }
      });

      // 글자색 지정
      flexSpgrv.itemFormatter = (panel, r, c, cell) => {
        if (panel === flexSpgrv.cells) {
          cell.style.color = "black";
        }
      }
    },
    // 비중 flexgrid 데이터 변경 전
    spgrvItemsSourceChanging() {
      if(this.flexSpgrv && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSpgrv);
      }
    },
    // 비중 그리드(flexDtl) 데이터 변경됨
    spgrvItemsSourceChanged() {
      // console.log("spgrvItemsSourceChanged:", this.flexPrice, this.pricelList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexSpgrv && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexSpgrv);
      }
    },
    

    addFavorite() {
      this.$emit('addFavorite');
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit('deleteFavorite');
      this.isFavorited = false;
    },
    searchWordCustrKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridCustrList = this.custrList.filter((e) => {
        return (e.custr_nm.indexOf(this.searchWordCustr) > -1 || e.custr_div_nm.indexOf(this.searchWordCustr) > -1);
      });
    },
    searchWordSkindKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSkindList = this.skindList.filter((e) => {
        return (e.skind_nm.indexOf(this.searchWordSkind) > -1 || e.skind_cd.indexOf(this.searchWordSkind) > -1);
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingSpgrv) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
    // itemChanged() {
    //   this.isItemChanged = true;
    // },


    getCustrList() {
      this.isItemsSourceChanging = true;
      // console.log("getCustrList:", this.compSno);

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("sitem_spgrv", "Y");

      this.$http.post("/base/getCustrList", params).then((res) => {
        console.log("getCustrList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.custrList = res.data.custrList;
        this.gridCustrList = res.data.custrList;
        this.custrCount = this.custrList.length;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getSkindList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", this.custrSno);
      params.append("sitem_spgrv", "Y");

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        console.log("getSteelKindList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.skindList.forEach((e) => {
          e.skind_nm_disp = e.skind_cd + " - " + e.skind_nm;
        });

        this.skindList = res.data.skindList;
        this.gridSkindList = res.data.skindList;
        this.skindCount = this.skindList.length;

        if(this.skindList.length > 0) {
          // 첫번 째 강종 선택
          // this.skindCd = this.skindList[0].skind_cd;
          // this.skindNm = this.skindList[0].skind_nm;
          // this.getListMode = 'select';
          // this.getSitemList();
        } else {
          alert("등록된 강종이 없습니다.")
        }
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },

    getSitemSpgrvList() {
      // console.log("getSitemSpgrvList called.", this.compSno, this.custrSno, this.skindCd);
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", this.custrSno);
      params.append("skind_cd", this.skindCd);

      this.$http.post("/base/getSitemSpgrvList", params).then((res) => {
        console.log("getSitemSpgrvList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        // res.data.spgrvList.forEach((e) => {
        //   e.new_yn = 'N';
        //   e.select_yn = false;
        // });

        this.spgrvList = JSON.parse(JSON.stringify(res.data.spgrvList));
        this.pendingSpgrvList = JSON.parse(JSON.stringify(this.spgrvList));
      }).catch(
        function (error) {
          console.log(error);
        }
      )
    },
    // 단가정보 입력 데이터 검증
    validateInput() {
      // // 가로(폭)
      // if(this.$refs.estm_dt.date == null || this.$refs.estm_dt.date == "") {
      //   alert('가로(폭) 필수 입력값입니다.');
      //   return false;
      // }

      return true;
    },
    // 단가정보 저장 버튼 클릭됨
    saveBtnClicked() {
      // console.log("saveBtnClicked:", this.pendingSpgrvList);
      // 입력 데이터 검증
      const isValid = this.validateInput();
      
      if(isValid) {
      // if(confirm("저장하시겠습니까?")) {
        const params = new URLSearchParams();

        // params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);
        params.append("custr_sno", this.custrSno);
        params.append("sitem_cd", this.sitemCd);

        params.append("spgrvList", JSON.stringify(this.pendingSpgrvList));
        
        this.$http.post("/base/upsertSitemSpgrvList", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");

          // this.getSitemSpgrvList();
          this.getListMode = "save";
          this.getCustrList();
          this.getSkindList();

          this.isNew = false;
          // this.isItemChanged = false;
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingSpgrv) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        this.getSitemSpgrvList();
      }
    },

    
  }
}
</script>

	   

<style>
  .wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) .wj-cell-text-color-red {
    color: #ff0000;
  }
  .wj-flexgrid .wj-cell.wj-align-right {
    justify-content: flex-end;
  }
  .wj-flexgrid .wj-cell.wj-align-center{
    justify-content: center;
  }
  .wj-flexgrid .wj-cell .justify-center{
    justify-content: center !important; 
  }
</style>