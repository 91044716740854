import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/plugins/axios';

Vue.use(Vuex)

const store =  new Vuex.Store({
  state: {
    session: localStorage.session && JSON.parse(localStorage.session) || {},
    // compSno: '',
    // userSno: '',
    commonCode: {
      estm_stus: [],
      ord_stus: [],
      ord_div: [],
      sales_purch_div: [],
      colct_pay_div: [],
      purch_ord_stus: [],
      sales_ord_stus: [],
      pay_mthd: [],
      dlv_cond: [],
      tax_div: [],
      sitem_unit: [],
      mchng_range: [],
      mchng_price_div: [],
      acct_type: [],
    },
    sitems: [],
    sitemUnitPriceCache: [],
    acctCache: [],
    favoriteTabs: [],
    flexGridState: null,
    gridFilter: {filterDefinition: null},

    // 화면 별 수정 중 여부
    isOnPending: [],
  },
  mutations: { 
    updateSession(state, value) {
      state.session = {...state.session, ...value};
      localStorage.session = JSON.stringify(state.session);
    },
    commonCode({commonCode}, {code, values}) {
      commonCode[code] = values;
    },
    updateValue(state, {name, value}) {
      state[name] = value;
    },
    updateIsOnPending(state, page) {
      console.log("updateIsOnPending:", page);
      if(page.status === 'on') {
        if(state.isOnPending.indexOf(page.name) < 0) {
          state.isOnPending.push(page.name);
        }
      } else {
        if(state.isOnPending.indexOf(page.name) >= 0) {
          state.isOnPending.splice(state.isOnPending.indexOf(page.name), 1);
        }
      }
      console.log("updateIsOnPending 2:", state.isOnPending);
    }
  },
  actions: {
    loadSession({commit}) {
      const session = localStorage.session && JSON.parse(localStorage.session) || {};
      commit('updateSession', session);
    },
    async refreshCommonCode({commit}, code) {
      const formData = new FormData();
      formData.append('cls_cd', code);

      const values = await (async () => {
        try {
          const res = await axios.post('/common/getCmnCdListByClsCd', formData);
          return res.data.result.filter(e => e.cmn_cd !== '00');
        } catch (error) {
          console.log(`Error on function refreshCommonCode: ${error}`);
        }
        return [];
      })();

      commit('commonCode', {code, values});
    },
    async refreshSitems({state,  commit}) {
      const formData = new FormData();
      console.log("refreshSitems", state);
      formData.append('comp_sno', state.session.compSno);

      const values = await (async () => {
        try {
          const res = await axios.post('/base/getSteelItemList', formData);
          return res.data && res.data.sitemList || [];
        } catch (error) {
          console.log(`Error on function refreshSitems: ${error}`);
        }
        return [];
      })();
      commit('updateValue', {name: 'sitems', value: values})
    },
    async refreshFavorites({state, commit}) {
      if(state.session.userSno) {
        const formData = new FormData();
        formData.append('user_sno', state.session.userSno);

        const values = await (async () => {
          try {
            const res = await axios.post('/common/getFavorList', formData);
            return res.data && res.data.favorList || [];
          } catch (error) {
            console.log(`Error on function refreshFavorites: ${error}`);
          }
          return [];
        })();
        commit('updateValue', {name: 'favoriteTabs', value: values})
      }
      
    },
    async cacheSitemUnitPrice({state, commit}, {sitemCd, custrSno}) {
      // 견적 등 > 품목 선택 시 캐시 안함
      // const sitemUnitPrice = state.sitemUnitPriceCache.find(([sitem_cd, custr_sno]) => sitem_cd === sitemCd && custr_sno === custrSno);
      const sitemUnitPrice = false;
      if (sitemUnitPrice) return sitemUnitPrice;
      const params = new FormData();
      const compSno = state.session.compSno;

      params.append('comp_sno', compSno);
      params.append('custr_sno', custrSno);
      params.append('sitem_cd', sitemCd);
      try {
        const res = await axios.post("/base/getEstmCalcInfo", params);
        const {sitem, sitemPriceList, mchngPriceList} = res.data;
        if (!sitemPriceList || 0 === sitemPriceList.length)
          return [];

        // 품목 단가 캐시에 추가 -> 캐시 안함
        const sitemUnitPrice = [sitemCd, custrSno, sitem, sitemPriceList, mchngPriceList];
        // commit('updateValue', {name: 'sitemUnitPriceCache', value: [...state.sitemUnitPriceCache, sitemUnitPrice]});
        return sitemUnitPrice;

      } catch (e) {
        console.log(`Error on function cacheSitemUnitPrice: ${e}`);
      }
      return [];
    },
    async cacheAcct({state, commit}, {acctCd}) {
      const acct = state.acctCache.find(({acct_cd}) => acct_cd === acctCd);
      if (acct) return acct;
      const params = new FormData();
      const compSno = state.session.compSno;

      params.append('comp_sno', compSno);
      params.append('acct_cd', acctCd);
      try {
        const res = await axios.post("/base/getAcctDetail", params);
        const {acct} = res.data;
        if (!acct)
          return null;

        // 계정 캐시에 추가
        commit('updateValue', {name: 'acctCache', value: [...state.acctCache, acct]});
        return acct;

      } catch (e) {
        console.log(`Error on function cacheAcct: ${e}`);
      }
      return [];
    },
  },
  getters: {
    // getCommonCode(state) {
    // },
  },
});

export default store;