<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>매출/매입 관리</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn-toggle tile v-model="itemDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="sales" :disabled="isOnPendingItem">매출 관리</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="purch" :disabled="isOnPendingItem">매입 관리</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense class="pt-0 mt-0" style="height:200px;" align="center">
          <v-col cols="1">
            <div class=""><span>조회수 : {{ filteredItems.length }} 건</span></div>
          </v-col>
          <v-col cols="1">
            <div class=" f-r"><span>조회일자</span></div>
          </v-col>
          <v-col cols="1">
            <date-picker v-model="content[itemDiv].srchDt" :disabled="isOnPendingItem"></date-picker>
          </v-col>
          <v-col cols="1">
            <div class="pt-1 f-r"><span>거래처</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field
                class="" v-model="content[itemDiv].pendingWord"
                hide-details dense outlined clearable :disabled="isOnPendingItem"
                @click:clear="content[itemDiv].searchWord = ''"
                @focusout="content[itemDiv].searchWord = content[itemDiv].pendingWord"
                @keyup.enter="content[itemDiv].searchWord = content[itemDiv].pendingWord"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="4" align="right">
            <!-- <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold">조회</v-btn> -->
            <v-btn small min-width="70px" color="blue darken-2" class="white--text font-weight-bold" :disabled="!content[itemDiv].pendingItem.sales_purch_sno" @click="onCreateItem">
              {{ itemDiv === 'sales' ? '매출 추가' : itemDiv === 'purch' ? '매입 추가' : '' }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <wj-flex-grid
                :isReadOnly=true
                :itemsSource="filteredItems"
                :selectionMode="isOnPendingItem ? 'None' : 'ListBox'"
                style="height:175px;"
                :initialized="onItemGridInit"
                headersVisibility="Column">
              <wj-flex-grid-column header="매입매출일련번호" binding="sales_purch_sno" width="2*" :visible="false" />
              <wj-flex-grid-column header="거래처일련번호" binding="custr_sno" width="4*" :visible="false"/>
              <wj-flex-grid-column header="일자" binding="sales_purch_dt" width="2*" align="center"/>
              <wj-flex-grid-column header="거래처명" binding="custr_nm" width="3.5*" align="left"/>
              <wj-flex-grid-column header="건수" binding="dtl_cnt" width="1*" align="right"/>
              <wj-flex-grid-column header="소재비" binding="sitem_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="가공비" binding="mchng_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="공급가액" binding="suply_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="부가세액" binding="vat_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="합계금액" binding="sum_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="거래" binding="txn_dtlst_yn" width="1*" align="center" dataType="Boolean"/>
              <wj-flex-grid-column header="출고" binding="ship_yn" width="1*" align="center" v-if="itemDiv==='sales'" dataType="Boolean"/>
              <wj-flex-grid-column header="입고" binding="ship_yn" width="1*" align="center" v-if="itemDiv==='purch'" dataType="Boolean"/>
              <wj-flex-grid-column header="세금" binding="txbil_yn" width="1*" align="center" dataType="Boolean"/>
              <wj-flex-grid-column header="카드" binding="card_pay_yn" width="1*" align="center" dataType="Boolean"/>
              <wj-flex-grid-column header="결제방법" binding="pay_mthd_nm" width="1.5*" align="center"/>
              <!-- <wj-flex-grid-column header="작성일자" binding="reg_dt" width="2*" align="center"/> -->
              <wj-flex-grid-column header="작성자" binding="reg_user_nm" width="1.5*" align="center"/>
              <wj-flex-grid-column header="비고사항" binding="rm" width="7*" />
            </wj-flex-grid>
          </v-col>
        </v-row>

        <v-card class="pa-1 bgcolor-grey1 mt-3 elevation-0" height="490px">
          <v-row dense align="center">
            <v-col cols="12" class="d-flex justify-end align-center hei-40p">
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 font-weight-bold" color="blue-grey lighten-5" v-show="content[itemDiv].pendingItem.sales_purch_sno && !isDummy" @click="onItemDelete">삭제</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem">거래현황</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem" v-if="itemDiv==='sales'">거래명세서</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem" v-if="itemDiv==='sales'">출고증</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem" v-if="itemDiv==='purch'">직송</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem">세금계산서</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[itemDiv].pendingItem.sales_purch_sno" :disabled="isDummy || isOnPendingItem" @click="onItemCopy">복사</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 font-weight-bold" color="blue-grey lighten-5" :disabled="isItemCancellationDisabled" @click="onItemCancel">취소</v-btn>
              <v-btn small min-width="70" class="white--text font-weight-bold" color="indigo darken-3" :disabled="isItemSaveDisabled" @click="onItemSave">저장</v-btn>
            </v-col>
          </v-row>
          <v-card class="pa-1 mt-1 bgcolor-grey2 mt1" outlined>
          <v-row dense style="height:30px;" class="pt-0">
            <v-col cols="12" class="">
              <h3>상세 내용</h3>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right">
              <div class=""><span>전표번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.sales_purch_no" hide-details dense outlined readonly disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span style="color:red;">* </span><span>전표일자</span></div>
            </v-col>
            <v-col cols="1">
              <date-picker v-model="content[itemDiv].pendingItem.sales_purch_dt" :disabled="isDummy"></date-picker>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>배송방법</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.dlv_cond" hide-details dense outlined background-color="white" :disabled="isDummy"></v-text-field>
            </v-col>
            <v-col cols="6"></v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right">
              <div class=""><span style="color:red;">* </span><span>거래처명</span></div>
            </v-col>
            <v-col cols="2">
              <v-text-field
                class=""
                v-model="content[itemDiv].pendingItem.custr_nm"
                hide-details dense outlined readonly
                background-color="grey lighten-2"
                append-icon="mdi-magnify"
                @click:append="onCustr"
                :disabled="isDummy"
              ></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>대표자</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.repr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>사업자번호</span></div>
            </v-col>
            <v-col cols="2">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.biz_rno" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>전화번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.tel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>팩스번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.fax_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right">
              <div class=""><span>업체담당자</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.chrgr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>휴대전화</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.chrgr_cel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>이메일</span></div>
            </v-col>
            <v-col cols="3">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.chrgr_email" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>주소</span></div>
            </v-col>
            <v-col cols="3">
              <v-text-field class="" v-model="content[itemDiv].pendingItem.biz_plc_addr" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>세액구분</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <v-select
                class=""
                v-model="content[itemDiv].pendingItem.tax_div"
                :items="$store.state.commonCode.tax_div"
                item-value="cmn_cd"
                item-text="cmn_cd_nm"
                hide-details
                outlined
                dense
                background-color="white"
                :disabled="isDummy"
              ></v-select>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>공급가액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="content[itemDiv].pendingItem.suply_amt" :disabled="true"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>부가세액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="content[itemDiv].pendingItem.vat_amt" :disabled="content[itemDiv].pendingItem.tax_div != 'tax' || isDummy"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>합계금액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="sumAmt" :disabled="true"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>비고사항</span></div>
            </v-col>
            <v-col cols="3">
              <v-row align="center">
                <v-col cols="12">
                  <v-textarea
                    class=""
                    v-model="content[itemDiv].pendingItem.rm"
                    hide-details
                    dense
                    outlined
                    no-resize
                    rows="2"
                    row-height="20"
                    background-color="white"
                    :disabled="isDummy"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right" class="pb-2"><div class=""><span>결제 방법</span></div></v-col>
            <v-col cols="10">
              <v-radio-group
                v-model="content[itemDiv].pendingItem.pay_mthd"
                row
                hide-details
                class="pa-0 ma-0"
                dense
                :disabled="isDummy"
              >
                <v-radio :key="item.cmn_cd" :value="item.cmn_cd" :label="`${item.cmn_cd_nm}`" class="pr-5" v-for="item in payMthd"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="1" v-if="itemDiv==='sales'"><span>매출항목</span></v-col>
            <v-col cols="1" v-if="itemDiv==='purch'"><span>매입항목</span></v-col>
            <v-col cols="1"><span>조회수 : {{ content[itemDiv].pendingDtls.length }}</span></v-col>
            <v-col cols="1"><span>중량 : {{ dtlsWegt }}</span></v-col>
            <v-col cols="1"><span>수량 : {{ dtlsQty }}</span></v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="d-flex justify-end align-center">
              <v-btn small min-width="70px" class="pa-0 ma-0 mr-3 font-weight-bold" :disabled="isDummy" @click="onImportOcr">OCR</v-btn>
              <v-btn small min-width="70px" class="pa-0 ma-0 mr-3 font-weight-bold" :disabled="isItemDtlDeleteDisabled" @click="onItemDtlDelete">항목삭제</v-btn>
              <v-btn small min-width="70px" color="blue darken-4" class="white--text font-weight-bold" :disabled="isDummy" @click="onItemDtlAdd">항목추가</v-btn>
            </v-col>
            <v-col cols="12">
              <div>
                <wj-flex-grid
                    :itemsSource="content[itemDiv].pendingDtls"
                    style="height: 180px;"
                    selectionMode="MultiRange"
                    :deferResizing="true"
                    :alternatingRowStep="0"
                    :showMarquee="true"
                    :allowDelete="true"
                    :allowSorting="false"
                    :initialized="onItemDtlGridInit"
                    :itemsSourceChanged="onItemDtlGridChanged"
                    headersVisibility="All"
                    :cellEditEnding="onItemDtlEditEnding"
                    :cellEditEnded="onItemDtlEditEnded"
                >
                  <!-- <wj-flex-grid-column header="□" binding="select_yn" :width="50" dataType="Boolean" /> -->
                  <wj-flex-grid-column header="품목" binding="" :width="50" :cellTemplate="createSitemTemplate"/>
                  <wj-flex-grid-column header="* 품목코드" binding="sitem_cd" :width="90" :isRequired="true"/>
                  <wj-flex-grid-column header="품목명" binding="sitem_nm" :width="150" :isReadOnly="true"/>
                  <wj-flex-grid-column header="규격" binding="spec" :width="120" :isReadOnly="true"/>
                  <wj-flex-grid-column header="단위" binding="unit" :width="60" :isReadOnly="true" :dataMap="sitemUnits"/>
                  <wj-flex-grid-column header="가공범위" binding="mchng_range" :width="130" data-type="String" :dataMap="mchngRanges"/>
                  <wj-flex-grid-column header="소재두께" binding="thck" :width="80" :isReadOnly="true" format="N1" align="right"/>
                  <wj-flex-grid-column header="가공두께" binding="mchng_thck" :width="80"  format="N1" align="right"/>
                  <wj-flex-grid-column header="로스" binding="thck_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                  <wj-flex-grid-column header="가로/길이" binding="width_len" :width="80" format="N1" align="right"/>
                  <wj-flex-grid-column header="로스" binding="width_len_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                  <wj-flex-grid-column header="세로/Ø" binding="depth_phi" :width="80" format="N1" align="right"/>
                  <wj-flex-grid-column header="로스" binding="depth_phi_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                  <wj-flex-grid-column header="수량" binding="qty" :width="50" format="N0" align="right"/>
                  <wj-flex-grid-column header="중량" binding="wegt" :width="60" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="소재단가" binding="sitem_unit_price" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="소재비" binding="sitem_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="면가공비" binding="face_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="각가공비" binding="side_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="스퀘어가공비" binding="sqre_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="연마비" binding="grind_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="기타가공비" binding="etc_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="합계금액" binding="sum_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                  <wj-flex-grid-column header="비고" binding="rm" :width="200" />
                  <wj-flex-grid-column header="도번" binding="item_nm" :width="200" />
                  <wj-flex-grid-column header="품번" binding="custr_item_nm" :width="200" />
                  <wj-flex-grid-column header="비중" binding="spgrv" :width="60" :isReadOnly="true"/>
                </wj-flex-grid>
              </div>
            </v-col>
          </v-row>
          </v-card>
        </v-card>
      </div>
    </v-layout>
    <search-custr-popup
        v-if="popup.custr.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_salesPurchDiv="itemDiv"
        @close="popup.custr.onClose && popup.custr.onClose()"
        @selected="custrSno => popup.custr.onClose && popup.custr.onClose(custrSno)"
    ></search-custr-popup>
    <search-sitem-popup
        v-if="popup.sitem.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_custrSno="content[itemDiv].pendingItem.custr_sno"
        @close="popup.sitem.onClose && popup.sitem.onClose()"
        @selected="(...param) => popup.sitem.onClose && popup.sitem.onClose(...param)"
    ></search-sitem-popup>
    <confirm-copy-popup
      v-if="isCopyOpened"
      p_copyType="salesPurch"
      :p_trgtDt="content[itemDiv].pendingItem.sales_purch_dt"
      :p_payMthd="content[itemDiv].pendingItem.pay_mthd"
      @close="isCopyOpened = false"
      @copyConfirmed="copyConfirmed"
    ></confirm-copy-popup>
    <import-ocr-image
        v-model="popup.ocr.isOpenned"
    ></import-ocr-image>
  </v-container>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Selector} from '@grapecity/wijmo.grid.selector';
import datePicker from "@/components/common/datePicker.vue";
import searchCustrPopup from '@/components/common/searchCustrPopup.vue';
import SearchSitemPopup from '@/components/common/searchSitemPopup.vue';
import numericTextField from '@/components/common/numericTextField.vue';
import importOcrImage from '@/components/importOcrImage.vue';
import {SUB_TAB} from "@/common/constant";
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import ConfirmCopyPopup from '@/components/common/confirmCopyPopup.vue';

const DUMMY_ITEM_SNO = '__dummy__';

const genEmptyItem = ({
  sales_purch_sno = null,
  sales_purch_dt = moment().format('YYYY-MM-DD'),
  sales_purch_div= 'sales',
}) => ({
  sales_purch_sno: sales_purch_sno,
  comp_sno: null,
  estm_sno: null,
  ord_sno: null,
  sales_purch_no: null,
  sales_purch_dt: sales_purch_dt,
  sales_purch_div: sales_purch_div,
  pay_mthd: 'cash',
  dlv_cond: null,
  tax_div: 'tax',
  suply_amt: null,
  vat_amt: null,
  sum_amt: null,
  rm: '',
  custr_sno: null,
  custr_nm: null,
  repr_nm: null,
  biz_rno: null,
  tel_no: null,
  fax_no: null,
  biz_plc_addr: null,
  chrgr_nm: null,
  chrgr_cel_no: null,
  chrgr_email: null,
});

const genEmptyItemDtlPrice = () => ({
  sitem_unit_price: null,
  sitem_amt: null,
  wegt: null,
  face_mchng_amt: null,
  side_mchng_amt: null,
  sqre_mchng_amt: null,
  grind_mchng_amt: null,
  etc_mchng_amt: null,
  sum_amt: null,
});

const genEmptyItemDtl = () => ({
  uuid: uuidv4(),
  odr: null,
  select_yn: null,
  sitem_cd: null,
  sitem_nm: null,
  spec: null,
  unit: null,
  mchng_range: null,
  thck: null,
  mchng_thck: null,
  thck_loss: null,
  width_len: null,
  width_len_loss: null,
  depth_phi: null,
  depth_phi_loss: null,
  qty: null,
  spgrv: null,
  ...genEmptyItemDtlPrice(),
  rm: null,
  item_nm: null,
  custr_item_nm: null,
  chamf_mchng_yn: null,
  thck_mchng_yn: null,
});

export default {
  components: {datePicker, searchCustrPopup, SearchSitemPopup, numericTextField, ConfirmCopyPopup, importOcrImage},
  created() {
    this.$store.dispatch('refreshCommonCode', 'sales_purch_div');
    this.$store.dispatch('refreshCommonCode', 'pay_mthd');
    this.$store.dispatch('refreshCommonCode', 'dlv_cond');
    this.$store.dispatch('refreshCommonCode', 'tax_div');
    this.$store.dispatch('refreshCommonCode', 'sitem_unit');
    this.$store.dispatch('refreshCommonCode', 'mchng_range');
    this.$store.dispatch('refreshCommonCode', 'mchng_price_div');
    this.$store.dispatch('refreshSitems');
  },
  data() {
    return {
      itemDiv: 'sales',

      selector: null,   // 그리드 체크박스

      itemGrid: null,
      itemDtlGrid: null,

      isDtlGridChanging: false,

      content: {
        sales: {
          srchDt: moment().format('YYYY-MM-DD'),
          pendingWord: '',
          searchWord: '',
          items: [],
          item: null,
          pendingItem: genEmptyItem({sales_purch_sno: DUMMY_ITEM_SNO}),
          restoreItem: null,
          itemDtls: [],
          pendingDtls: [],
          restoreDtls: null,
          restoreDtl: null,
        },
        purch: {
          srchDt: moment().format('YYYY-MM-DD'),
          pendingWord: '',
          searchWord: '',
          items: [],
          item: null,
          pendingItem: genEmptyItem({sales_purch_sno: DUMMY_ITEM_SNO}),
          restoreItem: null,
          itemDtls: [],
          pendingDtls: [],
          restoreDtls: null,
          restoreDtl: null,
        },
      },

      popup: {
        custr: {
          isOpened: false,
          onClose: null,
        },
        sitem: {
          isOpened: false,
          onClose: null,
        },
        ocr: {
          isOpened: false,
          onClose: null,
        },
      },

      isCopyOpened: false,  // 복사 팝업

      createSitemTemplate: CellMaker.makeButton({
        text: '선택',
        click: (e, ctx) => this.onSitemClicked(e, ctx),
      }),
    };
  },
  methods:{
    async loadItems() {
      let params = new FormData();
      const target = this.content[this.itemDiv];
      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", target.srchDt);
      try {
        const res = await this.$http.post('/sales/getSalesPurchList', params);
        const items = res.data.salesPurchList.map(item => ({
          ...item,
          sales_purch_sno: item.sales_purch_sno != null && `${item.sales_purch_sno}`,
          fax: item.fax_yn === 'Y',
          email: item.email_yn === 'Y',
        }));

        const itemDivs = this.$store.state.commonCode.sales_purch_div.map(({cmn_cd}) => cmn_cd);
        itemDivs.forEach(div => {
          const target = this.content[div];
          target.items = items.filter(({sales_purch_div}) => sales_purch_div === div);
        });

      } catch (e) {
        console.log(`Error on function loadItems: ${e}`);
      }
    },
    async loadItem(itemSno) {
      const target = this.content[this.itemDiv];

      if (itemSno === DUMMY_ITEM_SNO) {
        const item = genEmptyItem({
          sales_purch_sno: DUMMY_ITEM_SNO,
          sales_purch_div: this.itemDiv,
          sales_purch_dt: this.srchDt,
        });
        const itemDtls = [];
        target.item = JSON.parse(JSON.stringify(item));
        target.pendingItem = JSON.parse(JSON.stringify(item));
        target.restoreItem = null;
        target.itemDtls = JSON.parse(JSON.stringify(itemDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(itemDtls));
        target.restoreDtls = null;
        return;
      }

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      params.append("comp_sno", compSno);
      params.append("sales_purch_sno", itemSno);
      try {
        this.isDtlGridChanging = true;

        const res = await this.$http.post('/sales/getSalesPurch', params);
        const item = {
          ...res.data.salesPurch,
          sales_purch_sno: `${res.data.salesPurch.sales_purch_sno}`,
          vat_amt: res.data.salesPurch.vat_amt + '',
        };
        const itemDtls = res.data.salesPurchDtlList.map(dtl => ({
          ...dtl,
          uuid: uuidv4(),
          select_yn: false,
          spec: this.makeItemDtlSpec(dtl),
          chamf_mchng_yn: dtl.chamf_mchng_yn === 'Y',
          thck_mchng_yn: dtl.thck_mchng_yn === 'Y',
        }));
        target.item = JSON.parse(JSON.stringify(item));
        target.pendingItem = JSON.parse(JSON.stringify(item));
        target.restoreItem = null;
        target.itemDtls = JSON.parse(JSON.stringify(itemDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(itemDtls));
        target.restoreDtls = null;

        await Promise.all(itemDtls.filter(dtl => dtl.sitem_cd).map(({sitem_cd}) =>
            this.$store.dispatch('cacheSitemUnitPrice', {
              sitemCd: sitem_cd,
              custrSno: item.custr_sno,
            })
        ));

      } catch (e) {
        console.log(`Error on function loadItem: ${e}`);
      }
    },
    onItemGridInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        if (this.isOnPendingItem) return;

        const target = this.content[this.itemDiv];
        const itemSno = flex.getCellData(ht.row, 0);
        if (itemSno !== target.pendingItem.sales_purch_sno)
          await this.loadItem(itemSno);
      });
      this.itemGrid = flex;
    },
    onItemGridChanging() {
    },
    onItemGridChanged() {
    },
    onItemDtlGridInit(flex) {
      this.itemDtlGrid = flex;

      this.selector = new Selector(flex, {
        itemChecked: () => {
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
        }
      });

      flex.selectionMode = "MultiRange";
    },
    onItemDtlGridChanged() {
      if(this.content[this.itemDiv].pendingItem.suply_amt != null) {
        this.isDtlGridChanging = false;
      }
    },
    onItemDtlEditEnding(flex, event) {
      const target = this.content[this.itemDiv];
      const pendingDtl = target.pendingDtls[event.row];
      target.restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
    },
    async onItemDtlEditEnded(flex, event) {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      const pendingDtl = target.pendingDtls[event.row];
      const restoreDtl = target.restoreDtl;
      target.restoreDtl = null;
      const column = flex.columns[event.col];

      await this.refreshItemDtl(column.binding, pendingItem, pendingDtl, restoreDtl);
    },
    async refreshItemDtl(binding, pendingItem, pendingDtl, restoreDtl) {
      if ('select_yn' !== binding && !pendingItem.custr_sno) {
        alert('거래처를 선택하세요.');
        Object.assign(pendingDtl, restoreDtl);
        return this.itemDtlGrid.invalidate();
      }
      if ('sitem_cd' === binding) {
        const sitem = await this.makeItemDtlSitem(pendingItem, pendingDtl);
        if (!sitem) {
          alert('품목이 존재하지 않습니다.');
          Object.assign(pendingDtl, restoreDtl);
          return this.itemDtlGrid.invalidate();
        }
        Object.assign(pendingDtl, sitem);
        pendingDtl.width_len = null;
        pendingDtl.depth_phi = null;
        pendingDtl.spec = this.makeItemDtlSpec(pendingDtl);
        const loss = await this.makeItemDtlLoss(pendingItem, pendingDtl);
        Object.assign(pendingDtl, loss);
      }
      if ('mchng_range' === binding) {
        const price = await this.makeItemDtlPrice(pendingItem, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('width_len' === binding) {
        pendingDtl.spec = this.makeItemDtlSpec(pendingDtl);
        const loss = await this.makeItemDtlLoss(pendingItem, pendingDtl);
        Object.assign(pendingDtl, loss);
        const price = await this.makeItemDtlPrice(pendingItem, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('depth_phi' === binding) {
        pendingDtl.spec = this.makeItemDtlSpec(pendingDtl);
        const price = await this.makeItemDtlPrice(pendingItem, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('qty' === binding) {
        const price = await this.makeItemDtlPrice(pendingItem, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      this.itemDtlGrid.invalidate();
      // target.pendingDtls = target.pendingDtls.map((dtl, index) => index !== event.row ? dtl : pendingDtl)
    },
    async onSitemClicked(event, context) {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      const pendingDtl = target.pendingDtls[context.row.index];

      if (!pendingItem.custr_sno)
        return alert('거래처가 설정되지 않았습니다.');

      const [sitemCd] = await new Promise(r => this.popup.sitem = {
        isOpened: true,
        onClose: (...param) => r(param)
      });
      this.popup.sitem.isOpened = false;
      if (!sitemCd) return;

      const restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
      pendingDtl.sitem_cd = sitemCd;
      await this.refreshItemDtl('sitem_cd', pendingItem, pendingDtl, restoreDtl);
    },
    async makeItemDtlSitem(item, itemDtl) {
      const [,, sitem] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: itemDtl.sitem_cd,
        custrSno: item.custr_sno,
      });
      if (!sitem) return;
      return {
        sitem_cd: sitem.sitem_cd,
        sitem_nm: sitem.sitem_nm,
        unit: sitem.unit,
        thck: sitem.thck,
        mchng_thck: sitem.thck,
        spgrv: sitem.spgrv,
      };
    },
    makeItemDtlSpec(itemDtl) {
      return `${itemDtl.thck || '-'}T*${itemDtl.width_len || '-'}*${itemDtl.depth_phi || '-'}`;
    },
    async makeItemDtlLoss(item, itemDtl) {
      if (!itemDtl.sitem_cd) return {};
      const [,,, sitemPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: itemDtl.sitem_cd,
        custrSno: item.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width - l.width);
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width <= itemDtl.width_len);
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice || {};
      itemDtl.thck_loss = itemPrice.thck_loss;
      itemDtl.width_len_loss = itemPrice.width_len_loss;
      itemDtl.depth_phi_loss = itemPrice.depth_phi_loss;

      return {
        thck_loss: itemPrice.thck_loss,
        width_len_loss: itemPrice.width_len_loss,
        depth_phi_loss: itemPrice.depth_phi_loss,
      };
    },
    async makeItemDtlPrice(item, itemDtl) {
      if (!itemDtl.sitem_cd || !itemDtl.spgrv || !itemDtl.width_len || !itemDtl.depth_phi || !itemDtl.qty)
        return genEmptyItemDtlPrice();
      const [,,, sitemPrices, mchngPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: itemDtl.sitem_cd,
        custrSno: item.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width < l.width);
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width < itemDtl.width_len);
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice;
      if (!itemPrice) return genEmptyItemDtlPrice();

      const width_len = Math.floor(Number(itemDtl.width_len) * 100) / 100;
      const width_len_loss = Math.floor(Number(itemDtl.width_len_loss) * 100) / 100;
      const depth_phi = Math.floor(Number(itemDtl.depth_phi) * 100) / 100;
      const depth_phi_loss = Math.floor(Number(itemDtl.depth_phi_loss) * 100) / 100;
      const thck = Math.floor(Number(itemDtl.thck) * 100) / 100;
      const thck_loss = Math.floor(Number(itemDtl.thck_loss) * 100) / 100;

      const widthSum = width_len + width_len_loss; // 가로 mm
      const depthPhiSum = depth_phi + depth_phi_loss; // 세로 mm
      const thckSum = thck + thck_loss; // 두께 mm
      const face = widthSum * depthPhiSum; // 윗면 mm2
      const side = depthPhiSum * thckSum; // 옆면 mm2
      const front = widthSum * thckSum; // 옆면 mm2

      // 소재단가 = 가로, 세로 단가
      const sitem_unit_price = itemDtl.depth_phi < 1000 ? itemPrice.price1 : itemPrice.price2;
      // 중량 = (두께mm + 두께로스mm) * (가로mm + 가로로스mm) * (세로mm + 세로로스mm) * 수량 * 비중 / 1_000_000
      const wegtCalc = widthSum /* mm */ * depthPhiSum /* mm */ * thckSum /* mm */ *
          itemDtl.qty * itemDtl.spgrv / 1_000_000; // 10 cm2 == 1kg
      const _wegt = Math.max(wegtCalc, itemPrice.min_wegt);
      const wegt = Math.floor(_wegt * 100_000) / 100_000;
      // 품목비 = 품목단가 * 중량
      const sitem_amt = Math.floor(sitem_unit_price * wegt);

      const [
        face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt
      ] = (() => {
        // if (!itemDtl.mchng_range) return [];
        if (!itemDtl.mchng_range) return [null, null, null, null, null, sitem_amt];

        // 가공단가
        const mchngMin = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'min');
        const mchngCalc = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'calc');

        const mchngItems = itemDtl.mchng_range.split('_');
        // 면 가공비
        const face_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f1': face,
          'f2': face * 2,
          // 'f6': face * 2 + front * 2 + side * 2
        }[cur] || 0), 0) / 100;
        const _face_mchng_amt = 0 < face_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.face_price * face_mchng_unit_calc, mchngMin && mchngMin.face_price || 0) * itemDtl.qty
            : 0;
        const face_mchng_amt = Math.floor(_face_mchng_amt);
        // 각 가공비
        const side_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          's2': front * 2,
          's4': front * 2 + side * 2,
        }[cur] || 0), 0) / 100;
        const _side_mchng_amt = 0 < side_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.side_price * side_mchng_unit_calc, mchngMin && mchngMin.side_price || 0) * itemDtl.qty
            : 0;
        const side_mchng_amt = Math.floor(_side_mchng_amt);
        // 스퀘어 가공비
        const sqre_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f6': face * 2 + front * 2 + side * 2
        }[cur] || 0), 0) / 100;
        const _sqre_mchng_amt = 0 < sqre_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.sqre_price * sqre_mchng_unit_calc, mchngMin && mchngMin.sqre_price || 0) * itemDtl.qty
            : 0;
        const sqre_mchng_amt = Math.floor(_sqre_mchng_amt);
        // 연마비
        const _grind_mchng_amt = mchngItems.reduce((acc, cur) => acc + ({
          'g': Math.max(mchngCalc && mchngCalc.grind_price, mchngMin && mchngMin.grind_price || 0)
        }[cur] || 0), 0) * itemDtl.qty;
        const grind_mchng_amt = Math.floor(_grind_mchng_amt);
        // 기타 가공비
        const etc_mchng_amt = Math.max(mchngCalc && mchngCalc.etc_price, mchngMin && mchngMin.etc_price || 0) * itemDtl.qty;
        // 합계
        const sum_amt = sitem_amt + face_mchng_amt + side_mchng_amt + sqre_mchng_amt + grind_mchng_amt + etc_mchng_amt;

        return [face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt];
      })();

      return {
        sitem_unit_price, wegt, sitem_amt,
        face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt,
        sum_amt,
      };
    },
    onCreateItem() {
      const isOnPendingItem = this.isOnPendingItem;
      if (isOnPendingItem && !confirm('저장되지 않은 데이터가 있습니다. 새 주문을 추가하시겠습니까?'))
        return;

      const target = this.content[this.itemDiv];
      const canRestore = target.item && !!target.item.sales_purch_sno;
      const restoreItem = canRestore ? JSON.parse(JSON.stringify(target.item)) : null;
      const restoreDtls = canRestore ? JSON.parse(JSON.stringify(target.itemDtls)) : null;

      const item = genEmptyItem({
        sales_purch_dt: this.srchDt,
        sales_purch_div: this.itemDiv,
      });
      target.item = JSON.parse(JSON.stringify(item));
      target.pendingItem = JSON.parse(JSON.stringify(item));
      target.restoreItem = restoreItem;

      target.itemDtls = [];
      target.pendingDtls = [];
      target.restoreDtls = restoreDtls;
    },
    verifyItem() {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      if (!pendingItem.sales_purch_dt) {
        alert('전표일자는 필수 입력값입니다.');
        return false;
      }
      if (!pendingItem.custr_sno) {
        alert('거래처는 필수 입력값입니다.');
        return false;
      }
      // if (!pendingItem.tax_div) {
      //   alert('세액구분은 필수 입력값입니다.');
      //   return false;
      // }
      return true;
    },
    async onItemSave() {
      if (!this.verifyItem()) return;

      const params = new URLSearchParams();
      const target = this.content[this.itemDiv];
      params.append('new_yn', !target.pendingItem.sales_purch_sno ? 'Y' : 'N');
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('comp_sno', this.$store.state.session.compSno);
      params.append('custr_sno', target.pendingItem.custr_sno);
      params.append('sales_purch_sno', target.pendingItem.sales_purch_sno);
      params.append('sales_purch_div', target.pendingItem.sales_purch_div);
      params.append('sales_purch_dt', target.pendingItem.sales_purch_dt);
      params.append('tax_div', target.pendingItem.tax_div);

      if (null != target.pendingItem.pay_mthd) params.append('pay_mthd', target.pendingItem.pay_mthd);
      if (null != target.pendingItem.dlv_cond) params.append('dlv_cond', target.pendingItem.dlv_cond);
      params.append('suply_amt', `${this.suplyAmt}`);
      params.append('vat_amt', `${this.vatAmt}`);
      params.append('sum_amt', `${this.sumAmt}`);
      if (null != target.pendingItem.rm) params.append('rm', target.pendingItem.rm);

      const itemDtls = target.pendingDtls.filter(({sitem_cd}) => sitem_cd).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('salesPurchDtlList', JSON.stringify(itemDtls));
      const deletedDtls = target.itemDtls.filter(({uuid}) => target.pendingDtls.every(pending => pending.uuid !== uuid)).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('deletedDtlList', JSON.stringify(deletedDtls));

      try {
        const res = await this.$http.post('/sales/upsertSalesPurch', params);
        if (res.data.code === 0)
          await this.loadItem(res.data.sales_purch_sno);
        await this.loadItems();

      } catch (e) {
        console.log(`Error on function onItemSave: ${e}`);
      }
    },
    async onItemDelete() {
      if (!confirm('삭제하시겠습니까?')) return;

      const target = this.content[this.itemDiv];
      const params = new FormData();
      params.append('sales_purch_sno', target.pendingItem.sales_purch_sno);

      await this.$http.post('/sales/deleteSalesPurch', params);
      alert('삭제되었습니다.');

      target.pendingItem = genEmptyItem({
        sales_purch_sno: DUMMY_ITEM_SNO,
        sales_purch_div: this.itemDiv,
        sales_purch_dt: this.srchDt,
      });

      await this.loadItems();
    },
    onItemCancel() {
      if (this.isOnPendingItem && !confirm('편집을 취소하시겠습니까?'))
        return;

        this.isDtlGridChanging = true;

      const target = this.content[this.itemDiv];

      const restoreItem = target.restoreItem || target.item;
      const restoreDtls = target.restoreDtls || target.itemDtls;
      target.item = JSON.parse(JSON.stringify(restoreItem));
      target.itemDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.pendingItem = JSON.parse(JSON.stringify(restoreItem));
      target.pendingDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.restoreItem = null;
      target.restoreDtls = null;
    },
    async onCustr() {
      const custrSno = await new Promise(r => {
        this.popup.custr = {
          isOpened: true, onClose: r
        }
      });
      if (custrSno) {
        const params = new FormData();
        params.append("custr_sno", custrSno);

        try {
          const res = await this.$http.post('/base/getCustrDetail', params)
          const custr = res.data.custr;

          const target = this.content[this.itemDiv];
          target.pendingItem.custr_sno = custr.custr_sno;
          target.pendingItem.custr_nm = custr.custr_nm;
          target.pendingItem.repr_nm = custr.repr_nm;
          target.pendingItem.tel_no = custr.tel_no;
          target.pendingItem.fax_no = custr.fax_no;
          target.pendingItem.biz_rno = custr.biz_rno;
          target.pendingItem.biz_plc_addr = custr.biz_plc_addr;
          target.pendingItem.chrgr_nm = custr.chrgr_nm;
          target.pendingItem.chrgr_cel_no = custr.chrgr_cel_no;
          target.pendingItem.chrgr_email = custr.chrgr_email;

        } catch (e) {
          console.log(`Error on function onCustr: ${e}`);
        }
      }
      this.popup.custr = {isOpened: false};
    },
    onImportOcr() {
      this.popup.ocr = {
        isOpenned: true,
        onClose: null,
      };
    },
    onItemDtlAdd() {
      const target = this.content[this.itemDiv];
      const nextOdr = target.pendingDtls.reduce((acc, {odr}) => odr < acc ? acc : odr, 0) + 1;
      target.pendingDtls = [
        ...target.pendingDtls, {
          ...genEmptyItemDtl(),
          uuid: uuidv4(),
          new_yn: true,
          odr: nextOdr,
          select_yn: false,
          chamf_mchng_yn: false,
          thck_mchng_yn: false,
        }
      ];
    },
    onItemDtlDelete() {
      const target = this.content[this.itemDiv];
      target.pendingDtls = target.pendingDtls.filter(({select_yn}) => !select_yn);
    },
    // 복사 버튼
    onItemCopy() {
      this.isCopyOpened = true;
    },
    copyConfirmed(trgtDt, scdulDt, payMthd) {
      console.log("copyConfirmed : ", trgtDt, payMthd);
      this.isCopyOpened = false;

      this.copyItem(trgtDt, payMthd);
    },
    async copyItem(trgtDt, payMthd) {
      const params = new FormData();
      const target = this.content[this.itemDiv];

      params.append('user_sno', this.$store.state.session.userSno);
      params.append("sales_purch_sno", target.pendingItem.sales_purch_sno);
      params.append("trgt_dt", trgtDt);
      params.append("pay_mthd", payMthd);

      try {
        const res = await this.$http.post('/sales/copySalesPurch', params);
        if (res.data.code === 0) {
          alert("복사되었습니다.");
          
          this.loadItems();
        }
      } catch (e) {
        console.log(`Error on function copyItem: ${e}`);
      }
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.sales.code);
    },
    filteredItems() {
      const target = this.content[this.itemDiv];
      return target.items.filter(({custr_nm}) =>
          !target.searchWord || target.searchWord.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(target.searchWord.toUpperCase())
      );
    },
    isItemCancellationDisabled() {
      if (this.isOnPendingItem) return false

      const target = this.content[this.itemDiv];
      if  (target.restoreItem) return false

      return true;
    },
    isItemSaveDisabled() {
      const target = this.content[this.itemDiv];
      if (!target.pendingItem.sales_purch_sno) return false;
      if (this.isOnPendingItem) return false

      return true;
    },
    isOnPendingItem() {
      const target = this.content[this.itemDiv];
      return !this.isDummy &&
          ( JSON.stringify(target.item) !== JSON.stringify(target.pendingItem) ||
            JSON.stringify(target.itemDtls.map(dtl => ({...dtl, select_yn: false}))) !== JSON.stringify(target.pendingDtls.map(dtl => ({...dtl, select_yn: false}))) );
    },
    isDummy() {
      const target = this.content[this.itemDiv];
      return target.pendingItem && target.pendingItem.sales_purch_sno == DUMMY_ITEM_SNO ? true : false;
    },
    payMthd() {
      return this.$store.state.commonCode.pay_mthd
    },
    dtlsWegt() {
      const target = this.content[this.itemDiv];
      return Math.round(target.pendingDtls.reduce((acc, {wegt}) => wegt ? acc + wegt : acc, 0), 2);
    },
    dtlsQty() {
      const target = this.content[this.itemDiv];
      return target.pendingDtls.reduce((acc, {qty}) => qty ? acc + qty : acc, 0);
    },
    sitemUnits() {
      return new wjGrid.DataMap(this.$store.state.commonCode.sitem_unit, 'cmn_cd', 'cmn_cd_nm');
    },
    mchngRanges() {
      return new wjGrid.DataMap(this.$store.state.commonCode.mchng_range, 'cmn_cd', 'cmn_cd_nm');
    },
    // Dtl 그리드에서 계산된 값
    _suplyAmt() {
      const target = this.content[this.itemDiv];
      const amt = target.pendingDtls.reduce((acc, {sum_amt}) => acc + (null != sum_amt ? Number(sum_amt) : 0), 0);
      return Math.floor(amt);
    },
    _vatAmt() {
      const target = this.content[this.itemDiv];
      const amt = target.pendingItem.tax_div === 'tax' ? Number(this.suplyAmt) / 10 : 0;
      return Math.floor(amt);
    },
    // 화면에 표시되는 값
    suplyAmt() {
      return Number(this.content[this.itemDiv].pendingItem.suply_amt);
    },
    vatAmt() {
      return Number(this.content[this.itemDiv].pendingItem.vat_amt);
    },
    sumAmt() {
      return Number(this.suplyAmt) + Number(this.vatAmt);
    },
    isItemDtlDeleteDisabled() {
      const target = this.content[this.itemDiv];
      return !target.pendingDtls.some(({select_yn}) => select_yn);
    },
    searchDate() {
      const itemDivs = this.$store.state.commonCode.sales_purch_div.map(({cmn_cd}) => cmn_cd);
      return itemDivs.map(div => ({div, srchDt: this.content[div].srchDt}));
    },
  },
  watch: {
    async filteredItems(newValue) {
      const [first] = newValue;
      const sno = first && first.sales_purch_sno || DUMMY_ITEM_SNO;
      await this.loadItem(sno);
    },
    async searchDate() {
      await this.loadItems();
    },
    _suplyAmt: {
      immediate: true,
      handler: function () {
        // console.log("watch _suplyAmt:", this.isDtlGridChanging, this.content[this.itemDiv].pendingItem.suply_amt);
        if(this.isDtlGridChanging) {
          return;
        }
        this.content[this.itemDiv].pendingItem.suply_amt = this._suplyAmt;
      },
    },
    _vatAmt: {
      immediate: true,
      handler: function () {
        if(this.isDtlGridChanging) {
          return;
        }
        this.content[this.itemDiv].pendingItem.vat_amt = this._vatAmt;
      },
    }
  }
}
</script>

<style>
/* .wj-colheaders .wj-header{
  background: #97f5ff;
} */

</style>
