<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="1">
            <div class="">
              <h2>품목 단가 관리</h2>
            </div>
          </v-col>
          <v-col cols="11">
            <!-- <v-btn small color="blue darken-2" class="white--text" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn> -->
            <v-btn
              small
              color="blue darken-2"
              class="white--text"
              v-show="!isFavorited"
              @click="addFavorite"
              >즐겨찾기 추가</v-btn
            >
            <v-btn
              small
              color="blue-grey lighten-5"
              class=""
              v-show="isFavorited"
              @click="deleteFavorite"
              >즐겨찾기 삭제</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="6" class="">
            <v-row dense>
              <v-col cols="4" class="pr-5">
                <v-row class="">
                  <!-- <v-col cols="4"><div><span>총 건수 : {{ custrCount }} 건</span></div></v-col> -->
                  <v-col cols="12">
                    <v-row dense class="" align="center">
                      <v-col cols="3" align="right"
                        ><div class=""><span class="">거래처</span></div></v-col
                      >
                      <v-col cols="9">
                        <div class="d-flex">
                          <v-text-field
                            class=""
                            v-model="searchWordCustr"
                            hide-details
                            dense
                            outlined
                            height="25"
                            @keyup="searchWordCustrKeyUp"
                            :readonly="isOnPendingPrice"
                            @click="onSearchWordClick"
                          >
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <wj-flex-grid
                      :isReadOnly="true"
                      :itemsSource="gridCustrList"
                      :selectionMode="'ListBox'"
                      style="height: 650px"
                      :initialized="flexCustrInitialized"
                      :itemsSourceChanging="custrItemsSourceChanging"
                      :itemsSourceChanged="custrItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                    >
                      <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                      <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="13*"/>
                      <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="5*"/>
                      <wj-flex-grid-column :header="'단가'" :binding="'set_price_yn'" width="5*"/>
                    </wj-flex-grid>
                  </v-col>
                  <!-- <v-col cols="1" class="f-l pr-0 pl-0" style="padding-top:60%;">
                    <v-icon large style="">
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-col> -->
                </v-row>
              </v-col>

              <v-col cols="3" class="">
                <v-row class="">
                  <!-- <v-col cols="4"><div><span>총 건수 : {{ custrCount }} 건</span></div></v-col> -->
                  <v-col cols="12">
                    <v-row dense class="" align="center">
                      <v-col cols="2" align="right"
                        ><div class=""><span class="">강종</span></div></v-col
                      >
                      <v-col cols="10">
                        <div class="d-flex">
                          <v-text-field
                            class=""
                            v-model="searchWordSkind"
                            hide-details
                            dense
                            outlined
                            height="25"
                            @keyup="searchWordSkindKeyUp"
                            :readonly="isOnPendingPrice"
                            @click="onSearchWordClick"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <wj-flex-grid
                      :isReadOnly="true"
                      :itemsSource="gridSkindList"
                      :selectionMode="'ListBox'"
                      style="height: 650px"
                      :initialized="flexSkindInitialized"
                      :itemsSourceChanging="skindItemsSourceChanging"
                      :itemsSourceChanged="skindItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                    >
                      <wj-flex-grid-column :header="'강종코드'" :binding="'skind_cd'" width="1*" :visible="false"/>
                      <wj-flex-grid-column :header="'강종'" :binding="'skind_nm_disp'" width="5*"/>
                      <wj-flex-grid-column :header="'단가'" :binding="'set_price_yn'" width="2*"/>
                    </wj-flex-grid>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="5" class="pl-0 pr-2">
                <v-row class="">
                  <!-- <v-col cols="4"><div><span>총 건수 : 555건</span></div></v-col> -->
                  <v-col cols="12">
                    <v-row dense class="" align="center">
                      <v-col cols="2" align="right"
                        ><div class=""><span class="">품목</span></div></v-col
                      >
                      <v-col cols="10">
                        <div class="d-flex">
                          <v-text-field
                            class=""
                            v-model="searchWordSitem"
                            hide-details
                            dense
                            outlined
                            height="25"
                            @keyup="searchWordSitemKeyUp"
                            :readonly="isOnPendingPrice"
                            @click="onSearchWordClick"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <wj-flex-grid
                      :isReadOnly="true"
                      :itemsSource="gridSitemList"
                      :selectionMode="'ListBox'"
                      style="height: 650px"
                      :initialized="flexSitemInitialized"
                      :itemsSourceChanging="sitemItemsSourceChanging"
                      :itemsSourceChanged="sitemItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                    >
                      <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" width="5*"/>
                      <wj-flex-grid-column :header="'품목명'" :binding="'sitem_nm'" width="10*"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'stnd'" width="4*"/>
                      <wj-flex-grid-column :header="'단가'" :binding="'set_price_yn'" width="4*"/>
                    </wj-flex-grid>
                  </v-col>
                  <!-- <v-col cols="1" class="pl-0 pr-0" style="padding-top:60%;">
                    <v-icon large style="">
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-row dense class="bgcolor-grey1">
              <v-col cols="4" class="d-flex justify-start align-center hei-40p">
                <h4>단가정보</h4>
              </v-col>
              <v-col cols="8" class="d-flex justify-end align-center hei-40p">
                <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingPrice">취소</v-btn>
                <v-btn small color="indigo darken-3" class="white--text font-weight-bold" width="70" @click="saveBtnClicked" :disabled="!isOnPendingPrice && applyAllSitemYn == false" >저장</v-btn
                >
              </v-col>
              <v-col cols="">
                <v-checkbox
                  class="pa-0 ma-0"
                  v-model="applyAllSitemYn"
                  hide-details
                  dense
                  label="강종전체적용"
                ></v-checkbox>
              </v-col>
              <v-col cols="5">
                <span style="white-space: pre;">{{ unitPriceComment }}</span>
              </v-col>
              <v-col cols="4" class="d-flex justify-end align-center hei-40p">
                <v-btn
                  small
                  color="blue-grey lighten-5"
                  class="pa-0 ma-0 mr-3 font-weight-bold"
                  min-width="70"
                  :disabled="isUnitPriceDeleteDisabled"
                  @click="deletePriceRow"
                  >항목삭제</v-btn
                >
                <v-btn
                  small
                  color="blue darken-2"
                  class="white--text font-weight-bold"
                  min-width="70"
                  @click="addPriceRow"
                  >항목추가</v-btn
                >
              </v-col>
              <v-col cols="12">
                <wj-flex-grid
                  :itemsSource="pendingPriceList"
                  :selectionMode="'MultiRange'"
                  :showMarquee="true"
                  style="height: 600px"
                  :allowSorting="false"
                  :initialized="flexPriceInitialized"
                  :itemsSourceChanging="priceItemsSourceChanging"
                  :itemsSourceChanged="priceItemsSourceChanged"
                  :deferResizing="true"
                  :alternatingRowStep="0"
                  :headersVisibility="'All'"
                >
                  <!-- <wj-flex-grid-column :header="'□'" :binding="'select_yn'" width="2*" dataType="Boolean" /> -->
                  <wj-flex-grid-column :header="'가로(폭)'" :binding="'width'" width="3*" />
                  <wj-flex-grid-column :header="'범위'" :binding="'range'" width="3*" :dataMap="rangeDataMap"/>
                  <wj-flex-grid-column :header="'두께로스'" :binding="'thck_loss'" width="3*" />
                  <wj-flex-grid-column :header="'가로로스'" :binding="'width_len_loss'" width="3*" />
                  <wj-flex-grid-column :header="'세로로스'" :binding="'depth_phi_loss'" width="3*" />
                  <wj-flex-grid-column :header="'단가1'" :binding="'price1'" width="4*" />
                  <wj-flex-grid-column :header="'단가2'" :binding="'price2'" width="4*" />
                  <wj-flex-grid-column :header="'최소중량'" :binding="'min_wegt'" width="3*" />
                </wj-flex-grid>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import * as wjGrid from '@grapecity/wijmo.grid';
import {Selector} from '@grapecity/wijmo.grid.selector';

export default {
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == "Y" ? true : false,
      getListMode: "select", // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false, // 항목 변경 여부

      isItemsSourceChanging: false, // 목록 조회 중. getOOO에서 설정 -> itemsSourceChanged에서 해제
      gridCustrList: [], // 그리드에 표시되는 filter된 목록
      custrList: [],
      custrCount: 0,
      custrSno: null,
      selectedCustrRow: 0,
      selectedCustrCol: 1,

      // isSkindItemsSourceChanging: false,
      gridSkindList: [],
      skindList: [],
      skindCount: 0,
      skindCd: null,
      skindNm: null,
      selectedSkindRow: 0,
      selectedSkindCol: 1,

      // isSitemItemsSourceChanging: false,
      gridSitemList: [],
      sitemList: [],
      sitemCount: 0,
      sitemCd: null,
      selectedSitemRow: 0,
      selectedSitemCol: 0,

      selector: null,   // 그리드 체크박스
      // isPriceItemsSourceChanging: false,
      priceList: [],
      pendingPriceList: [],
      selectedPriceRow: 0,
      selectedPriceCol: 0,
      rangeList: [],
      rangeDataMap: null,
      deletedPriceList: [],

      searchWordCustr: null, // 거래처 검색어
      searchWordSkind: null, // 강종 검색어
      searchWordSitem: null, // 품목 검색어

      // flexGrid 없이 flexCustr 사용할 경우 this.$saveFlexGridState 실행 시 collectionView 관련 오류(undefined) 발생함
      // flexGrid: null,     // 거래처 flexgrid
      flexCustr: null, // 거래처 flexgrid
      flexSkind: null, // 강종 flexgrid
      flexSitem: null, // 품목 flexgrid
      flexPrice: null, // 단가정보 flexgrid

      applyAllSitemYn: false, // 강종 전체 전체 품목 적용 여부

      unitPriceComment: "*단가1 (세로 < 1,000)    *단가2 (세로 >= 1,000)",
    };
  },
  computed: {
    isOnPendingPrice() {
      // console.log("isOnPendingPrice:", this.priceList, this.pendingPriceList);
      return JSON.stringify(this.priceList.map((e) => ({...e, select_yn:false}))) !== JSON.stringify(this.pendingPriceList.map((e) => ({...e, select_yn:false})));
    },
    isUnitPriceDeleteDisabled() {
      console.log("isUnitPriceDeleteDisabled", this.pendingPriceList);
      return !this.pendingPriceList.some(({select_yn}) => select_yn);
    },
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);

    this.getRangeList();

    this.getListMode = "select";
    this.getCustrList();
    // this.getSkindList();
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.isDestroying = true;
  },
  methods: {
    // 거래처 flexgrid 생성죔
    flexCustrInitialized(flexCustr) {
      this.flexCustr = flexCustr;
      // console.log("flexCustrInitialized:", this.flexCustr);

      flexCustr.addEventListener(flexCustr.hostElement, "mousedown", (e) => {
        // console.log("flexDownEvent:", e);
        let ht = flexCustr.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);
        if (ht.cellType == 1 && ht.row >= 0) {
          let isConfirmed = true;

          if (this.isOnPendingPrice) {
            if (!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);
            }
          }

          if (isConfirmed) {
            this.selectedCustrRow = ht.row;
            this.selectedCustrCol = ht.col;
            this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);

            this.getSkindList();

            if (this.sitemCd != null) {
              this.getSitemUnitPriceList();
            }
          }
        }
      });

      // "미설정" color 설정
      flexCustr.formatItem.addHandler((flexgrid, event) => {
        if (event.panel == flexgrid.cells) {
          const col = flexgrid.columns[event.col];

          if (event.row >= 0 && col.binding == "set_price_yn") {
            if (flexgrid.getCellData(event.row, event.col) == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      });
    },
    // 거래처 flexgrid 데이터 변경 전
    custrItemsSourceChanging() {
      if (this.flexCustr && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexCustr);
      }
    },
    // 거래처 목록 그리드(flexCustr) 데이터 변경됨
    custrItemsSourceChanged() {
      // console.log("custrItemsSourceChanged:", this.flexCustr, this.selectedCustrRow, this.gridCustrList.length, this.isItemsSourceChanging, this.getListMode);
      if (this.flexCustr && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexCustr);
      }

      if (this.gridCustrList.length == 0) {
        // this.initSitemInfo();
      }

      if (this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0 && this.flexCustr.collectionView.itemCount > 0) {
        this.flexCustr.select(-1, 0);
        this.flexCustr.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if (this.getListMode == "select") {
          // 첫 항목
          this.selectedCustrRow = 0;
          this.selectedCustrCol = 1;
        }
      }

      if (this.isItemsSourceChanging && this.flexCustr != null && !this.isDestroying && this.gridCustrList.length > 0 && this.selectedCustrRow >= 0 && this.flexCustr.collectionView.itemCount > 0) {
        this.flexCustr.rows[0].isSelected = false;
        this.flexCustr.select(this.selectedCustrRow, this.selectedCustrCol);

        this.custrSno = this.flexCustr.getCellData(this.selectedCustrRow, 0);
        // console.log("custrItemsSourceChanged custrSno:", this.custrSno);

        this.isItemsSourceChanging = false;
        this.getSkindList();

        if (this.sitemCd != null) {
          this.getSitemUnitPriceList();
        }
      }
    },

    // 강종 그리드(flexSkind) 생성됨
    flexSkindInitialized(flex) {
      this.flexSkind = flex;

      flex.addEventListener(flex.hostElement, "mousedown", (e) => {
        // console.log("e", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht, ht.cellType, ht.row);
        // if(ht.row >= 0)  console.log("CellData:", this.flexGrid.getCellData(ht.row, 0));
        if (ht.cellType == 1 && ht.row >= 0) {
          // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if (this.isOnPendingPrice) {
            if (!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
            }
          }

          if (isConfirmed) {
            this.selectedSkindRow = ht.row;
            this.selectedSkindCol = ht.col;
            this.skindCd = this.flexSkind.getCellData(this.selectedSkindRow, 0);

            this.getSitemList();
          }
        }
      });

      // "미설정" color 설정
      flex.formatItem.addHandler((flexgrid, event) => {
        if (event.panel == flexgrid.cells) {
          const col = flexgrid.columns[event.col];

          if (event.row >= 0 && col.binding == "set_price_yn") {
            if (flexgrid.getCellData(event.row, event.col) == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      });
    },
    // 강종 flexgrid 데이터 변경 전
    skindItemsSourceChanging() {
      if (this.flexSkind && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSkind);
      }
    },
    // 강종 flexgrid 데이터 변경됨
    skindItemsSourceChanged() {
      // console.log("skindItemsSourceChanged:", this.flexSkind, this.selectedSkindRow, this.gridSkindList.length, this.isItemsSourceChanging, this.getListMode);
      if (this.flexSkind && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexSkind);
      }

      if (this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0 && this.flexSkind.collectionView.itemCount > 0) {
        this.flexSkind.select(-1, 0);
        this.flexSkind.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if (this.getListMode == "select") {
          // 첫 항목
          this.selectedSkindRow = 0;
          this.selectedSkindCol = 1;
        }
      }

      if (this.isItemsSourceChanging && this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0 && this.selectedSkindRow >= 0 && this.flexSkind.collectionView.itemCount > 0) {
        this.flexSkind.rows[0].isSelected = false;
        this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
        this.skindCd = this.flexSkind.getCellData(this.selectedSkindRow, 0);

        this.isItemsSourceChanging = false;
        this.getSitemList();

        // this.$restoreFlexGridState(this.flexSkind);
        // console.log("flexSkind restoreFlexGridState")
      }
    },

    // 품목 그리드(flexSitem) 생성됨
    flexSitemInitialized(flex) {
      this.flexSitem = flex;

      flex.addEventListener(flex.hostElement, "mousedown", (e) => {
        // console.log("e", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht, ht.cellType, ht.row);
        // if(ht.row >= 0)  console.log("CellData:", this.flexSitem.getCellData(ht.row, 0));
        if (ht.cellType == 1 && ht.row >= 0) {
          // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if (this.isOnPendingPrice) {
            if (!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
            }
          }

          if (isConfirmed) {
            this.selectedSitemRow = ht.row;
            this.selectedSitemCol = ht.col;
            this.sitemCd = this.flexSitem.getCellData(this.selectedSitemRow, 0);

            this.getSitemUnitPriceList();
          }
        }
      });

      // "미설정" color 설정
      flex.formatItem.addHandler((flexgrid, event) => {
        if (event.panel == flexgrid.cells) {
          const col = flexgrid.columns[event.col];

          if (event.row >= 0 && col.binding == "set_price_yn") {
            if (flexgrid.getCellData(event.row, event.col) == "미설정") {
              event.cell.innerHTML = '<div class="wj-cell-text-color-red"><span>미설정</span>';
            }
          }
        }
      });
    },
    // 품목 flexgrid 데이터 변경 전
    sitemItemsSourceChanging() {
      if (this.flexSitem && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSitem);
      }
    },
    // 품목 flexgrid 데이터 변경됨
    sitemItemsSourceChanged() {
      // console.log("sitemItemsSourceChanged:", this.flexSitem, this.selectedSitemRow, this.gridSitemList.length, this.isItemsSourceChanging, this.getListMode);
      if (this.flexSitem && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexSitem);
      }

      if (this.flexSitem != null && !this.isDestroying && this.gridSitemList.length > 0 && this.flexSitem.collectionView.itemCount > 0) {
        this.flexSitem.select(-1, 0);
        this.flexSitem.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if (this.getListMode == "select") {
          // 첫 항목
          this.selectedSitemRow = 0;
          this.selectedSitemCol = 0;
        }
      }

      if (this.isItemsSourceChanging && this.flexSitem != null && !this.isDestroying && this.gridSitemList.length > 0 && this.selectedSitemRow >= 0 && this.flexSitem.collectionView.itemCount > 0) {
        this.flexSitem.rows[0].isSelected = false;
        this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
        this.sitemCd = this.flexSitem.getCellData(this.selectedSitemRow, 0);

        this.isItemsSourceChanging = false;
        this.getSitemUnitPriceList();
      }
    },

    // 단가 그리드(flexDtl) 생성됨
    flexPriceInitialized(flex) {
      this.flexPrice = flex;

      flex.addEventListener(flex.hostElement, "mousedown", (e) => {
        // console.log("e", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht, ht.cellType, ht.row);
        // if(ht.row >= 0)  console.log("CellData:", this.flexPrice.getCellData(ht.row, 0));
        if (ht.cellType == 1 && ht.row >= 0) {
          // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
        }
      });

      this.selector = new Selector(flex, {
        itemChecked: () => {
          // this.selectedItems = flex.rows.filter(r => r.isSelected);
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
          console.log("onEstmDtlGridInit this.selector itemChecked", flex);
        }
      });

      // 글자색 지정
      flex.itemFormatter = (panel, r, c, cell) => {
        if (panel === flex.cells /*&& panel.rows[r].isSelected*/) {
          cell.style.color = "black";
        }
      }

      flex.selectionMode = "MultiRange";
    },
    // 품목 flexgrid 데이터 변경 전
    priceItemsSourceChanging() {
      if (this.flexPrice && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexPrice);
      }
    },
    // 단가 그리드(flexDtl) 데이터 변경됨
    priceItemsSourceChanged() {
      // console.log("priceItemsSourceChanged:", this.flexPrice, this.pendingPriceList.length, this.isItemsSourceChanging, this.getListMode);
      if (this.flexPrice && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexPrice);

        // dataMap 복원
        let column = this.flexPrice.getColumn("range");
        column.dataMap = this.rangeDataMap;
      }
    },

    addFavorite() {
      this.$emit("addFavorite");
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit("deleteFavorite");
      this.isFavorited = false;
    },
    searchWordCustrKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridCustrList = this.custrList.filter((e) => {
        return (
          e.custr_nm.indexOf(this.searchWordCustr) > -1 ||
          e.custr_div_nm.indexOf(this.searchWordCustr) > -1
        );
      });
    },
    searchWordSkindKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSkindList = this.skindList.filter((e) => {
        return e.skind_nm_disp.indexOf(this.searchWordSkind) > -1;
      });
    },
    searchWordSitemKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSitemList = this.sitemList.filter((e) => {
        return (
          e.sitem_cd.indexOf(this.searchWordSitem) > -1 ||
          e.sitem_nm.indexOf(this.searchWordSitem) > -1 ||
          e.stnd.indexOf(this.searchWordSitem) > -1
        );
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingPrice) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
    // 여기까지 공통
    ///////////////

    async getRangeList() {
      this.rangeList = await this.$getCmnCdList("range");

      // flexGrid용으로 복사
      let rangeDataMap = [];

      this.rangeList.forEach((e) => {
        rangeDataMap.push({id: e.cmn_cd, name: e.cmn_cd_nm});
      });

      this.rangeDataMap = new wjGrid.DataMap(rangeDataMap, 'id', 'name');
    },
    getCustrList() {
      // this.isItemsSourceChanging = true;
      // console.log("getCustrList:", this.compSno);
      // this.$saveFlexGridState(this.flexGrid);

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("sitem_price", "Y");

      this.$http.post("/base/getCustrList", params).then((res) => {
        // console.log("getCustrList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.custrList = res.data.custrList;
        this.gridCustrList = res.data.custrList;
        this.custrCount = this.custrList.length;
      })
      .catch(function (error) {
        console.log(error);
        alert(error.message);
      });
    },
    getSkindList() {
      // this.isSkindItemsSourceChanging = true;
      // this.$saveFlexGridState(this.flexSkind);

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", this.custrSno);
      params.append("sitem_price", "Y");

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        // console.log("getSteelKindList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.skindList.forEach((e) => {
          e.skind_nm_disp = e.skind_cd + " - " + e.skind_nm;
        });

        this.skindList = res.data.skindList;
        this.gridSkindList = res.data.skindList;
        this.skindCount = this.skindList.length;

        if (this.skindList.length > 0) {
          // 첫번 째 강종 선택
          // this.skindCd = this.skindList[0].skind_cd;
          // this.skindNm = this.skindList[0].skind_nm;
          // this.getListMode = 'select';
          // this.getSitemList();
        } else {
          alert("등록된 강종이 없습니다.");
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error.message);
      });
    },
    // skindCdChanged() {
    //   // for(let i = 0; i < this.skindList.length; i++) {
    //   //   if(this.skindList[i].skind_cd == this.skindCd) {
    //   //     this.skindNm = this.skindList[i].skind_nm;
    //   //     break;
    //   //   }
    //   // }

    //   // this.getSitemList();
    // },
    getSitemList() {
      // console.log("getSitemList", this.skindCd);
      // this.isItemsSourceChanging = true;
      // this.$saveFlexGridState(this.flexSitem);

      this.priceList = [];
      this.pendingPriceList = [];

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("skind_cd", this.skindCd);
      params.append("custr_sno", this.custrSno);
      params.append("sitem_price", "Y");

      this.$http.post("/base/getSteelItemList", params).then((res) => {
        // console.log("getSteelItemList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.sitemList = res.data.sitemList;
        this.gridSitemList = res.data.sitemList;
        this.sitemCount = this.sitemList.length;
      })
      .catch(function (error) {
        console.log(error);
        alert(error.message);
      });
    },
    getSitemUnitPriceList() {
      // console.log("getSitemUnitPriceList called.", this.compSno, this.custrSno, this.sitemCd);
      // this.isPriceItemsSourceChanging = true;
      // this.$saveFlexGridState(this.flexPrice);

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("custr_sno", this.custrSno);
      params.append("sitem_cd", this.sitemCd);

      this.$http.post("/base/getSitemUnitPriceList", params).then((res) => {
        // console.log("getSitemUnitPriceList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.priceList.forEach((e) => {
          e.new_yn = "N";
          e.select_yn = false;
        });

        this.priceList = JSON.parse(JSON.stringify(res.data.priceList));
        this.pendingPriceList = JSON.parse(JSON.stringify(this.priceList));
        this.deletedPriceList = [];
      })
      .catch(function (error) {
        console.log(error);
        alert(error.message);
      });
    },
    // 단가정보 입력 데이터 검증
    validateInput() {
      // // 가로(폭)
      // if(this.$refs.estm_dt.date == null || this.$refs.estm_dt.date == "") {
      //   alert('가로(폭) 필수 입력값입니다.');
      //   return false;
      // }

      return true;
    },
    // 단가정보 저장 버튼 클릭됨
    saveBtnClicked() {
      // console.log("saveBtnClicked:", this.pendingPriceList, this.deletedPriceList);
      // 입력 데이터 검증
      const isValid = this.validateInput();

      if(!isValid) {
        return;
      }

      // 강종 전체 적용
      if(this.applyAllSitemYn) {
        // 강종 내 품목단가 설정 정보가 있는 지 조회
        const params = new URLSearchParams();

        params.append("comp_sno", this.compSno);
        params.append("custr_sno", this.custrSno);
        params.append("skind_cd", this.skindCd);

        this.$http.post("/base/getExistSitemUnitPriceBySkind", params).then((res) => {
          console.log("upsertSitemUnitPrice res.code:", res.data.code);
          // console.log("res.data:", res.data);

          let isConfirmed = true;

          if(res.data.existYn.exist_yn == 'Y') {
            isConfirmed = confirm('해당 공종 내에 기존 설정된 품목 단가도 수정됩니다. 전체 적용하시겠습니까?');
          }

          if(isConfirmed) {
            console.log("saveSitemUnitPrice");
            this.saveSitemUnitPrice();
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
        });        
      } 
      // 단일 품목 적용
      else {
        this.saveSitemUnitPrice();
      }
    },
    saveSitemUnitPrice() {
      // if (isValid && isConfirmed) {
        const params = new URLSearchParams();

        // params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);
        params.append("custr_sno", this.custrSno);
        params.append("sitem_cd", this.sitemCd);
        params.append("skind_cd", this.skindCd);
        params.append("apply_all_sitem_yn", this.applyAllSitemYn ? 'Y' : 'N');  // 강종 전체 적용 여부

        this.pendingPriceList.forEach((e) => {
          for (let i = 0; i < this.rangeList.length; i++) {
            if (e.range_nm == this.rangeList[i].cmn_cd_nm) {
              e.range = this.rangeList[i].cmn_cd;
            }
          }
        });

        params.append("priceList", JSON.stringify(this.pendingPriceList));
        params.append("deletedPriceList", JSON.stringify(this.deletedPriceList));

        this.$http.post("/base/upsertSitemUnitPrice", params).then((res) => {
          console.log("upsertSitemUnitPrice res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");

          this.getSitemUnitPriceList();
          this.isNew = false;
          // this.isItemChanged = false;

          // 거래처, 강종, 품목 그리드의 단가 미설정 -> 설정 업데이트
          this.flexCustr.setCellData(this.selectedCustrRow, "set_price_yn", "설정");
          this.flexSkind.setCellData(this.selectedSkindRow, "set_price_yn", "설정");
          this.flexSitem.setCellData(this.selectedSitemRow, "set_price_yn", "설정");
        })
        .catch((error) => {
          console.log(error);
          alert(error.message);
        });
      // }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingPrice) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        this.getSitemUnitPriceList();
      }
    },
    addPriceRow() {
      // console.log("addPriceRow:", this.flexPrice.collectionView);

      let max_odr = 0;
      this.pendingPriceList.forEach((e) => {
        if(max_odr < e.odr) {
          max_odr = e.odr;
        }
      });

      this.pendingPriceList = [
        ...this.pendingPriceList,
        {
          new_yn: 'Y',
          select_yn: false,
          odr: max_odr + 1,
          comp_sno: this.compSno,
          custr_sno: this.custrSno,
          sitem_cd: this.sitemCd,
          range: 'geq',
          range_nm: '이상'
        }
      ]
      
      // this.flexPrice.collectionView.addNew();
      // const row = this.pendingPriceList.length - 1;
      // this.pendingPriceList[row].new_yn = "Y";
      // this.pendingPriceList[row].select_yn = false;
      // this.pendingPriceList[row].odr = row == 0 ? 1 : max_odr + 1;
      // this.pendingPriceList[row].comp_sno = this.compSno;
      // this.pendingPriceList[row].custr_sno = this.custrSno;
      // this.pendingPriceList[row].sitem_cd = this.sitemCd;
      // this.pendingPriceList[row].range = "geq";
      // this.pendingPriceList[row].range_nm = "이상";
      // console.log("addPriceRow:", this.pendingPriceList);
    },
    deletePriceRow() {
      // console.log("deletePriceRow:", this.pendingPriceList);
      for (let i = 0; i < this.pendingPriceList.length; i++) {
        if (this.pendingPriceList[i].select_yn) {
          if (this.pendingPriceList[i].new_yn == "N") {
            this.deletedPriceList.push(this.pendingPriceList[i]);
            // this.deletedPriceList = [...this.deletedPriceList, this.pendingPriceList[i]];
          }
          // this.pendingPriceList.splice(i, 1);
          this.flexPrice.collectionView.removeAt(i);
          i--;
        }
      }
      // console.log("deletePriceRow:", this.pendingPriceList, this.deletedPriceList);
    },
  },
};
</script>

	   

<style>
</style>