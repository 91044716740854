<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>품목별 현황</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <!-- 조회 조건 row -->
            <v-row dense align="center">
                  
              <!-- 품목별 -->
              <v-col cols="12">
                <v-row dense align="center" class="">
                  <v-col cols="1"><div class=""><span>조회수 : {{ inqryCnt }} 건</span></div></v-col>
                  <v-col cols="9" class="">
                    <v-row dense align="center">
                      <v-col cols="4">
                        <v-row dense align="center">
                          <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col>
                          <v-col cols="4">
                            <date-picker v-model="srchBgnDt"></date-picker>
                          </v-col>
                          <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                          <v-col cols="4">
                            <date-picker v-model="srchEndDt"></date-picker>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="5">
                        <v-row dense align="center">
                          <v-col cols="2" align="right" class="">
                            <div class=""><span>품목</span></div>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              v-model="sitemNm"
                              class=""
                              hide-details dense outlined readonly
                              background-color="grey
                              lighten-2"
                              append-icon="mdi-magnify"
                              @click:append="onFindSitem"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2" align="right" class="">
                            <div class=""><span>규격</span></div>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              v-model="sitemStnd"
                              class=""
                              hide-details dense outlined readonly
                              background-color="grey
                              lighten-2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- <v-col cols="1">
                        <v-checkbox class="pa-0 ma-0" v-model="incldVat" hide-details dense label="부가세포함"></v-checkbox>
                      </v-col> -->
                    </v-row>
                  </v-col>

                  <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                  </v-col>
                </v-row>
              </v-col>
                  
            </v-row>

            <!-- 통계 row -->
            <v-row dense align="center">
              <v-col cols="12">
                <v-row dense align="center" class="">
                  <v-col cols="1"><div class=""><span style="color:blue;">출고수량 : {{ stats.outShipQty }} 건</span></div></v-col>
                  <v-col cols="1"><div class=""><span style="color:blue;">입고수량 : {{ stats.inShipQty }} 건</span></div></v-col>
                  <v-col cols="6">
                    <v-row dense align="center">
                      <v-col cols="3"><div class=""><span style="color:blue;">출고중량 : {{ stats.outShipWegt }} kg</span></div></v-col>
                      <v-col cols="3"><div class=""><span style="color:blue;">입고중량 : {{ stats.inShipWegt }} kg</span></div></v-col>
                      <v-col cols="3"><div class=""><span style="color:blue;">매출액 : {{ stats.salesAmt }} 원</span></div></v-col>
                      <v-col cols="3"><div class=""><span style="color:blue;">매입액 : {{ stats.purchAmt }} 원</span></div></v-col>
                    </v-row>
                  </v-col>                      
                </v-row>
              </v-col>
              <v-col cols="12">
                <wj-flex-grid 
                  :isReadOnly=true
                  :itemsSource="filteredLedgrList" 
                  :selectionMode="'ListBox'"
                  style="height:650px;"
                  :initialized="onFlexLedgrInit"
                  :itemsSourceChanged="onFlexLedgrItemsSourceChanged"
                  :headersVisibility="'Column'"
                  :alternatingRowStep="0"
                  >
                  <wj-flex-grid-column :header="'일자'" :binding="'sales_purch_dt'" :width="90" align="center"/>
                  <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                  <wj-flex-grid-column :header="'구분'" :binding="'sales_purch_div_nm'" :width="50" align="center"/>
                  <wj-flex-grid-column :header="'두께'" :binding="'thck'" :width="90" align="right"/>
                  <wj-flex-grid-column :header="'가로/길이'" :binding="'width_len'" :width="80" align="right"/>
                  <wj-flex-grid-column :header="'세로'" :binding="'depth_phi'" :width="80" align="right"/>
                  <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="50" align="right"/>
                  <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="60" align="right"/>
                  <wj-flex-grid-column :header="'단가'" :binding="'sitem_unit_price'" :width="80" align="right"/>
                  <wj-flex-grid-column :header="'소재비'" :binding="'sitem_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'가공비'" :binding="'mchng_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'매출액(+VAT)'" :binding="'sales_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'매입액(+VAT)'" :binding="'purch_amt'" :width="120" align="right"/>
                  <wj-flex-grid-column :header="'재고량'" :binding="'acmlt_wegt'" :width="120" align="right"/>
                </wj-flex-grid>
              </v-col>
            </v-row>

          </v-card>
        </div>
      </div>
    </v-layout>
    <search-sitem-popup
        v-if="isSitemPopupOpened"
        :p_compSno="compSno"
        @close="isSitemPopupOpened = false"
        @selected="sitemSelected"
    ></search-sitem-popup>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";
import SearchSitemPopup from '@/components/common/searchSitemPopup.vue';

// . 통계 정보
const qty = {inShipQty: 0, outShipQty: 0, inShipWegt: 0, outShipWegt: 0};
const amt = {salesAmt: 0, purchAmt: 0, colctAmt: 0, payAmt: 0, unColctAmt: 0, unPayAmt: 0};

export default {
  components: {datePicker, SearchSitemPopup},
  data(){
    return{
      compSno: '',
      userSno: 0,

      srchBgnDt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      isSitemPopupOpened: false,

      sitemCd: '',
      sitemNm: '',
      sitemStnd: '',

      // incldVat: true,

      stats: {...qty, ...amt},

      flexLedgr: null,    // 품목별 현황 그리드

      ledgrList: [],
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.ledgrBySitem.code);
    },
    filteredLedgrList() {
      return this.ledgrList.filter((e) => true);
    },
    inqryCnt() {
      return this.filteredLedgrList.length == 0 ? 0 : this.filteredLedgrList.length - 1;
    }
  },
  watch: {
    sitemCd: {
      immediate: true,
      handler: function () {
        this.loadLedgrBySitem();
      },
    },
    // filteredLedgrList: {
    //   immediate: true,
    //   handler: function () {
    //     this.loadLedgrBySitem();
    //   },
    // },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    // this.loadLedgrBySitem();
  },
  methods: {
    onFlexLedgrInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }
      });

      this.flexMonth = flex;
    },
    onFlexLedgrItemsSourceChanged(flex) {
      console.log("onFlexLedgrItemsSourceChanged");

      // 통계 계산
      this.calculateStats();
    },
    // 품목 검색 버튼 클릭
    onFindSitem() {
      this.isSitemPopupOpened = true;
    },
    sitemSelected(sitemCd, sitemNm, sitemStnd) {
      this.sitemCd = sitemCd;
      this.sitemNm = sitemNm;
      this.sitemStnd = sitemStnd;

      this.isSitemPopupOpened = false;
    },
    // 품목별 현황 조회
    async loadLedgrBySitem() {
      if(this.sitemCd == null || this.sitemCd == "") {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);
      params.append("sitem_cd", this.sitemCd);

      try {
        const res = await this.$http.post('/ledger/getLedgrListBySitem', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrList = ledgrList;
      } catch (e) {
        console.log(`Error on function loadLedgrBySitem: ${e}`);
      }
    },
    // 통계 계산
    calculateStats() {
      let inShipQty = 0;
      let outShipQty = 0;
      let inShipWegt = 0;
      let outShipWegt = 0;
      let salesAmt = 0;
      let purchAmt = 0;

      this.filteredLedgrList.forEach((e) => {
        if(e.sales_purch_div == 'sales') {
          outShipQty += e.qty;
          outShipWegt += e.wegt;
          salesAmt += e.sales_amt;
        } else {
          inShipQty += e.qty;
          inShipWegt += e.wegt;
          purchAmt += e.purch_amt;
        }
      });

      this.stats.inShipQty = this.$setNumberComma(Math.round(inShipQty, 0));
      this.stats.outShipQty = this.$setNumberComma(Math.round(outShipQty, 0));
      this.stats.inShipWegt = this.$setNumberComma(Math.round(inShipWegt, 0));
      this.stats.outShipWegt = this.$setNumberComma(Math.round(outShipWegt, 0));
      this.stats.salesAmt = this.$setNumberComma(Math.round(salesAmt, 0));
      this.stats.purchAmt = this.$setNumberComma(Math.round(purchAmt, 0));
    },
    // 조회 버튼 클릭
    onLedgrInqry() {
      if(this.sitemCd == null || this.sitemCd == '') {
        alert("품목이 선택되지 않았습니다.");
        return;
      }
      
      this.loadLedgrBySitem();
    },
    
  }
}
</script>

<style>

</style>