<template>
  <v-dialog
    width="290px"
    persistent
    v-model="popup"
    ref="dialog"
    :return-value.sync="date"
    @keydown.esc="popup = false"
    @click:outside="popup = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        hide-details dense outlined :disabled="disabled" :readonly="readonly"
        :background-color="bgColor"
        @focusout="onFocusOut"
        @click="onEditClick"
      >
        <template v-slot:append>
          <v-icon v-bind="attrs" v-on="on" :disabled="disabled || readonly">mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable @dblclick:date="onOk(date)">
      <v-spacer></v-spacer>
      <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="popup = false">취소</v-btn>
      <v-btn small color="indigo darken-3" class="white--text" width="70" @click="onOk(date)">선택</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
// import moment from 'moment';
import dayjs from 'dayjs';

export default {
  name: "datePicker",
  props: ['value', 'disabled', 'readonly', "p_bgColor"],

  data() {
    return {
      date: this.value,
      popup: false,
      bgColor: this.p_bgColor == null ? "white" : this.p_bgColor,
    };
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    isValid(date) {
      // return /^\d{4}-\d{2}-\d{2}$/.test(date) && moment(date, 'YYYY-MM-DD', true).isValid();
      return /^\d{4}-\d{2}-\d{2}$/.test(date) && dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === date;
    },
    onOk(date) {
      this.$refs.dialog.save(date);
      this.popup = false;
      this.$emit('input', date);
    },
    onFocusOut() {
      const date = !this.date ? null : this.isValid(this.date) ? this.date : this.value;
      this.date = date;
      this.$emit('input', date);
    },
    onEditClick() {
      // console.log("datePicker onIconClick", this.readonly);
      if(this.readonly) {
        alert("수정 중일 때는 사용할 수 없습니다.");
      }
    }
  },
  computed: {

  },
  watch: {
    value(newValue) {
      // prop 입력값 날짜 형식 검증
      if (this.isValid(newValue)) {
        this.date = newValue;
      } else {
        this.date = null;
      }
    },
  },
}
</script>
