<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>거래처 관리</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text" v-show="!isFavorited" @click="addFavorite">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3" v-show="isFavorited" @click="deleteFavorite">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="1"><div><span>조회수 : {{ custrCount }} 건</span></div></v-col>
          <v-col cols="2">
            <div class="d-flex">
              <span class="pr-2">거래처</span>
              <v-text-field
                v-model="searchWord"
                class=""
                hide-details
                dense
                outlined
                @keyup="searchWordKeyUp"
                :readonly="isOnPendingCustr"
                @click="onSearchWordClick"
              ></v-text-field>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1" align="right">
            <v-btn 
              small 
              width="100px" 
              color="blue darken-2" 
              class="white--text"
              :disabled="isNew"
              @click="addCustrBtnClicked"
              >거래처 추가
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="3">
            <!-- <v-row>
              <v-col cols="4"><span>거래처명</span></v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="searchWord"
                  class=""
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn small width="100px" color="orange lighten-3">조회</v-btn>
              </v-col>
            </v-row> -->
            <v-row class="">
              <v-col cols="12">
                <!-- <v-data-table
                  :headers="gridHeaders"
                  :items="gridList"
                  :search="searchWord"
                  hide-default-footer
                  class="elevation-1"
                  @click:row="rowClicked"
                ></v-data-table> -->
                
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="gridList"
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="flexInitialized"
                    :itemsSourceChanging="itemsSourceChanging"
                    :itemsSourceChanged="itemsSourceChanged"
                    :headersVisibility="'Column'"
                  >
                    <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                    <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="6*" />
                    <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="3*" />
                    <wj-flex-grid-column :header="'대표자'" :binding="'repr_nm'" width="4*" />
                  </wj-flex-grid>
                

              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row dense class="bgcolor-grey1">
              <!-- <v-row class="pb-3">
                <v-col cols="12" align="right">
                  <v-btn small width="100px" color="blue lighten-3" class="white--text"
                    @click="addCustrBtnClicked"
                  >거래처 추가</v-btn></v-col>
              </v-row> 
            -->
              <v-col cols="12" class="d-flex justify-end align-center hei-40p" >
                <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" v-show="!isNew" @click="deleteBtnClicked">삭제</v-btn>
                <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text" width="70" v-show="!isNew" :disabled="isOnPendingCustr">출력</v-btn>
                <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text" width="70" v-show="!isNew" :disabled="isOnPendingCustr">복사</v-btn>
                <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingCustr && !isNew">취소</v-btn>
                <v-btn small color="indigo darken-3" class="white--text" width="70" @click="saveBtnClicked" :disabled="!isOnPendingCustr">저장</v-btn>  
              </v-col>
              <v-col cols="12" class="">
                <v-card class="bgcolor-grey2 pa-1" outlined>
                  <v-row dense>
                    <v-col cols="1">
                      <h4>사업자 정보</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>거래처명</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="pendingCustr.custr_nm"
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>구분</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-select
                        class=""
                        v-model="pendingCustr.custr_div"
                        :items="custrDivList"
                        item-value="cmn_cd"
                        item-text="cmn_cd_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        height="10"
                      ></v-select>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>대표자</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        v-model="pendingCustr.repr_nm"
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>대표전화</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="pendingCustr.tel_no"
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>사업자번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.biz_rno"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>법인번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.corp_rno"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>팩스번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        v-model="pendingCustr.fax_no"
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>사업장 우편번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.biz_plc_zpcd"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        append-icon="mdi-home-search"
                        @click:append="findZipcodeBtnClicked('biz_plc')"
                      ></v-text-field>
          
                    </v-col><v-col cols="1" align="right" class=""><div class=""><span>사업장 주소</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.biz_plc_addr"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>배송지 우편번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.dlv_pnt_zpcd"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        append-icon="mdi-home-search"
                        @click:append="findZipcodeBtnClicked('dlv_pnt')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>배송지 주소</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.dlv_pnt_addr"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                                            
                    <v-col cols="2" class="" >
                      <v-checkbox
                        v-model="pendingCustr.same_as_biz_plc"
                        hide-details
                        dense
                        class="pa-0 ma-0"
                        label="사업장 주소와 동일"
                        @change="sameAsBizPlcChanged"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>종사업장번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.sub_biz_plc_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>업태</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.biz_type"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1"></v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>종목</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.biz_item"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>
        
                  <v-row dense class="hei-80p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>메모</span></div></v-col>
                    <v-col cols="6" class="" >
                      <v-textarea
                        class=""
                        v-model="pendingCustr.memo"
                        hide-details
                        dense
                        outlined
                        no-resize
                        height="60px"
                        background-color="white"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>특이사항</span></div></v-col>
                    <v-col cols="4" class="" >
                      <v-textarea
                        class=""
                        v-model="pendingCustr.spcl_note"
                        hide-details
                        dense
                        outlined
                        no-resize
                        height="60px"
                        background-color="white"
                      ></v-textarea>
                    </v-col>
                  </v-row>										
                </v-card>
              </v-col>

              <v-col cols="12" class="">
                <v-card class="pa-1 mt-2 bgcolor-grey2" outlined>
                  <v-row dense>
                    <v-col cols="1">
                      <h4>담당자 정보</h4>
                    </v-col>
                    <v-spacer></v-spacer>                     
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>담당자</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.chrgr_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>전화번호</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.chrgr_tel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>휴대전화</span></div></v-col>
                    <v-col cols="2" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.chrgr_cel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>이메일</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.chrgr_email"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>                      
                  </v-row>                   
                </v-card>
              </v-col>
              
              <v-col cols="12" class="">
                <v-card class="pa-1 mt-2 bgcolor-grey2" outlined>
                  <v-row dense>
                    <v-col cols="1">
                      <h4>결제 정보</h4>
                    </v-col>
                    <v-spacer></v-spacer>                     
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>여신금액</span></div></v-col>
                    <v-col cols="2" class="" >
                      <!-- <v-text-field
                        class=""
                        hide-details
                        dense
                        outlined
                        disabled
                        background-color="grey lighten-4"
                      ></v-text-field> -->
                      <numeric-text-field v-model="pendingCustr.loan_amt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>이월잔액</span></div></v-col>
                    <v-col cols="2" class="" >
                      <!-- <v-text-field
                        class=""
                        hide-details
                        dense
                        outlined
                        disabled
                        background-color="grey lighten-4"
                      ></v-text-field> -->
                      <numeric-text-field v-model="pendingCustr.crovr_bamt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>전표잔액</span></div></v-col>
                    <v-col cols="2" class="" >
                      <!-- <v-text-field
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field> -->
                      <numeric-text-field v-model="pendingCustr.slip_bamt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>현재잔액</span></div></v-col>
                    <v-col cols="2" class="" >
                      <!-- <v-text-field
                        class=""
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field> -->
                      <numeric-text-field v-model="pendingCustr.curr_bamt" disabled bg-color="grey lighten-2"></numeric-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>사용여부</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-checkbox
                        v-model="pendingCustr.use_tf"
                        hide-details
                        dense
                        class="pa-0 ma-0"
                        label="사용함"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>할증여부</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-checkbox
                        v-model="pendingCustr.exch_aply_tf"
                        hide-details
                        dense
                        class="pa-0 ma-0"
                        label="적용함"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>결제방법</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-select
                        class=""
                        v-model="pendingCustr.pay_mthd"
                        :items="payMthdList"
                        item-value="cmn_cd"
                        item-text="cmn_cd_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        height="10"
                      ></v-select>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>담당자</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-select
                        class=""
                        v-model="pendingCustr.custr_chrg_user_sno"
                        :items="userList"
                        item-value="user_sno"
                        item-text="user_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        height="10"
                      ></v-select>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>마감일자</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.clos_dt"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>결제조건</span></div></v-col>
                    <v-col cols="1" class="" >
                      <!-- <v-text-field
                        class=""
                        v-model="pay_cond"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                        @change="itemChanged"
                      ></v-text-field> -->
                      <numeric-text-field v-model="pendingCustr.pay_cond"></numeric-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12" class="">
                <v-card class="pa-1 mt-2 bgcolor-grey2" outlined>
                  <v-row dense>
                    <v-col cols="3">
                      <h4>세금계산서 담당자 정보</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>담당자(정)</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr1_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>전화번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr1_tel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>팩스번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr1_fax_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>휴대전화</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr1_cel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>이메일</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr1_email"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense class="hei-40p mt-0" align="center">
                    <v-col cols="1" align="right" class=""><div class=""><span>담당자(부)</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr2_nm"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>전화번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr2_tel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>팩스번호</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr2_fax_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>휴대전화</span></div></v-col>
                    <v-col cols="1" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr2_cel_no"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1" align="right" class=""><div class=""><span>이메일</span></div></v-col>
                    <v-col cols="3" class="" >
                      <v-text-field
                        class=""
                        v-model="pendingCustr.txbil_chrgr2_email"
                        hide-details
                        dense
                        outlined
                        background-color="white"
                      ></v-text-field>
                    </v-col>
                  </v-row>                   
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';

const genEmptyCustr = () => ({
  // 거래처 정보
  custr_sno: null,
  // 사업자 정보
  custr_nm: null,
  custr_div: null,
  repr_nm: null,
  tel_no: null,
  fax_no: null,
  biz_rno: null,
  corp_rno: null,
  biz_plc_addr: null,
  biz_plc_zpcd: null,
  dlv_pnt_addr: null,
  dlv_pnt_zpcd: null,
  sub_biz_plc_no: null,
  biz_type: null,
  biz_item: null,
  memo: null,
  spcl_note: null,
  // 담당자 정보
  chrgr_nm: null,
  chrgr_tel_no: null,
  chrgr_cel_no: null,
  chrgr_email: null,
  // 결제 정보
  loan_amt: null,     // 여신금액
  crovr_bamt: null,   // 이월잔액
  slip_bamt: null,    // 전표잔액
  curr_bamt: null,    // 현재잔액
  clos_dt: null,
  use_tf: false,
  exch_aply_tf: false,
  use_yn: null,
  exch_aply_yn: null,
  pay_mthd: null,
  pay_cond: null,
  custr_chrg_user_sno: null,
  // 세금계산서 담당자 정보
  txbil_chrgr1_nm: null,
  txbil_chrgr1_tel_no: null,
  txbil_chrgr1_fax_no: null,
  txbil_chrgr1_cel_no: null,
  txbil_chrgr1_email: null,
  txbil_chrgr2_nm: null,
  txbil_chrgr2_tel_no: null,
  txbil_chrgr2_fax_no: null,
  txbil_chrgr2_cel_no: null,
  txbil_chrgr2_email: null,
});

export default {
  components: { numericTextField },
  props: ["pIsFavorited"],
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == 'Y' ? true : false,
      getListMode: 'select',  // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false,   // 항목 변경 여부

      gridList: [],   // 그리드에 표시되는 filter된 목록
      custrList: [],  // 서버에서 조회된 원본 목록
      custrCount: 0,
      custrSno: null,

      flexGrid: null,
      isItemsSourceChanging: false,
      selectedRow: 0,
      selectedCol: 0,

      searchWord: null, // 검색어

      custrDivList: [],
      payMthdList: [],
      userList: [],

      same_as_biz_plc: false,   // 사업장 주소와 동일

      custr: {},
      pendingCustr: {},
      // restoreCustr: {},
      
      // // 거래처 정보
      // custr_sno: null,
      // // 사업자 정보
      // custr_nm: null,
      // custr_div: null,
      // repr_nm: null,
      // tel_no: null,
      // fax_no: null,
      // biz_rno: null,
      // corp_rno: null,
      // biz_plc_addr: null,
      // biz_plc_zpcd: null,
      // dlv_pnt_addr: null,
      // dlv_pnt_zpcd: null,
      // sub_biz_plc_no: null,
      // biz_type: null,
      // biz_item: null,
      // memo: null,
      // spcl_note: null,
      // // 담당자 정보
      // chrgr_nm: null,
      // chrgr_tel_no: null,
      // chrgr_cel_no: null,
      // chrgr_email: null,
      // // 결제 정보
      // loan_amt: null,     // 여신금액
      // crovr_bamt: null,   // 이월잔액
      // slip_bamt: null,    // 전표잔액
      // curr_bamt: null,    // 현재잔액
      // clos_dt: null,
      // use_tf: false,
      // exch_aply_tf: false,
      // pay_mthd: null,
      // pay_cond: null,
      // custr_chrg_user_sno: null,
      // // 세금계산서 담당자 정보
      // txbil_chrgr1_nm: null,
      // txbil_chrgr1_tel_no: null,
      // txbil_chrgr1_fax_no: null,
      // txbil_chrgr1_cel_no: null,
      // txbil_chrgr1_email: null,
      // txbil_chrgr2_nm: null,
      // txbil_chrgr2_tel_no: null,
      // txbil_chrgr2_fax_no: null,
      // txbil_chrgr2_cel_no: null,
      // txbil_chrgr2_email: null,

      // testData: this.getData(),

    }
  },
  computed: {
    isOnPendingCustr() {
      // console.log("isOnPendingCustr memo:", this.custr, this.pendingCustr);
      return JSON.stringify(this.custr) !== JSON.stringify(this.pendingCustr);
    },
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    // alert(this.compSno);
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);
    // console.log("created:", this.isFavorited);

    this.getCustrDivList();
    this.getPayMthdList();
    this.getUserList();

    this.getListMode = 'select';
    this.getCustrList();

    // this.gridFilter = new wjGridFilter.FlexGridFilter(this.flexGrid);
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.isDestroying = true;
  },
  methods: {
	  flexInitialized(flex) {
      this.flexGrid = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("flexDownEvent:", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);       
        if(ht.cellType == 1 && ht.row >= 0) {
          let isConfirmed = true;

          if(this.isOnPendingCustr) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexGrid.select(this.selectedRow, this.selectedCol);
            }
          }

          if(isConfirmed) {
            // this.flexGrid.rows[this.selectedRow].isSelected = false;  // 이전 Row 선택 해제
            this.selectedRow = ht.row;
            this.selectedCol = ht.col;
            this.custrSno = this.flexGrid.getCellData(ht.row, 0);
            // this.flexGrid.rows[this.selectedRow].isSelected = true;  // 클릭된 Row 선택 설정
            this.getCustrDetail();
          }
        }
      });
    },
    // 거래처 flexgrid 데이터 변경 전
    itemsSourceChanging() {
      // console.log("custr > itemsSourceChanging");
      if(this.flexGrid && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexGrid);
      }
    },
    itemsSourceChanged() {
      // console.log("itemsSourceChanged:", this.selectedRow);
      if(this.flexGrid && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexGrid);
      }

      if(this.gridList.length == 0) {
        this.isNew = true;
        this.initCustrInfo();
      }

      if(this.flexGrid != null && !this.isDestroying && this.gridList.length > 0 && this.flexGrid.collectionView.itemCount > 0) {
        this.flexGrid.select(-1, 0);
        this.flexGrid.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        console.log("getListMode:", this.getListMode);
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedRow = 0;
          this.selectedCol = 0;
        } else if(this.getListMode == "insert" || this.getListMode == "update") {
          // 추가된 항목 
          for(let i = 0; i < this.gridList.length; i++) {
            if(this.gridList[i].custr_sno == this.custrSno) {
              this.selectedRow = i;
              break;
            }
          }
        } else if(this.getListMode == "delete") {
          // 다음 항목. 마지막인 경우 이전 항목
          this.selectedRow = this.selectedRow < this.gridList.length ? this.selectedRow : this.selectedRow - 1;
        }
      } 

      if(this.isItemsSourceChanging && this.flexGrid != null && !this.isDestroying && this.selectedRow >= 0 && this.flexGrid.collectionView.itemCount > 0) {
        // console.log("itemsSourceChanged - 1:", this.selectedRow);
        this.flexGrid.rows[0].isSelected = false;
        this.flexGrid.select(this.selectedRow, this.selectedCol);
        this.custrSno = this.flexGrid.getCellData(this.selectedRow, 0); 
        // this.selectionMode.text = "Row";
        // this.flexGrid.rows[this.selectedRow].isSelected = true;
        this.isItemsSourceChanging = false;
        this.getCustrDetail();
      }
    },

    addFavorite() {
      this.$emit('addFavorite');
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit('deleteFavorite');
      this.isFavorited = false;
    },
    searchWordKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridList = this.custrList.filter((e) => {
        return e.custr_nm.indexOf(this.searchWord) > -1;
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingCustr) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
    // itemChanged() {
    //   this.isItemChanged = true;
    // },

    async getCustrDivList() {
      this.custrDivList = await this.$getCmnCdList('custr_div');
      // console.log("custrDivList:", this.custrDivList);
    },
    async getPayMthdList() {
      this.payMthdList = await this.$getCmnCdList('pay_mthd');
      // console.log("payMthdList:", this.payMthdList);
    },
    initCustrInfo() {
      const custr = genEmptyCustr();
      this.custr = JSON.parse(JSON.stringify(custr, function(k, v) {return v == null ? "" : v}));
      this.pendingCustr = JSON.parse(JSON.stringify(this.custr));
      // console.log("initCustrInfo:", this.custr, this.pendingCustr);

      // // 사업자 정보
      // this.custr_nm = null;
      // this.custr_div = null;
      // this.repr_nm = null;
      // this.tel_no = null;
      // this.fax_no = null;
      // this.biz_rno = null;
      // this.corp_rno = null;
      // this.biz_plc_addr = null;
      // this.biz_plc_zpcd = null;
      // this.dlv_pnt_addr = null;
      // this.dlv_pnt_zpcd = null;
      // this.sub_biz_plc_no = null;
      // this.biz_type = null;
      // this.biz_item = null;
      // this.memo = null;
      // this.spcl_note = null;
      // // 담당자 정보
      // this.chrgr_nm = null;
      // this.chrgr_tel_no = null;
      // this.chrgr_cel_no = null;
      // this.chrgr_email = null;
      // // 결제 정보
      // this.loan_amt = null;     // 여신금액
      // this.crovr_bamt = null;   // 이월잔액
      // this.slip_bamt = null;    // 전표잔액
      // this.curr_bamt = null;    // 현재잔액
      // this.clos_dt = null;
      // this.use_tf = false;
      // this.exch_aply_tf = false;
      // this.pay_mthd = null;
      // this.pay_cond = null;
      // this.custr_chrg_user_sno = null;
      // // 세금계산서 담당자 정보
      // this.txbil_chrgr1_nm = null;
      // this.txbil_chrgr1_tel_no = null;
      // this.txbil_chrgr1_fax_no = null;
      // this.txbil_chrgr1_cel_no = null;
      // this.txbil_chrgr1_email = null;
      // this.txbil_chrgr2_nm = null;
      // this.txbil_chrgr2_tel_no = null;
      // this.txbil_chrgr2_fax_no = null;
      // this.txbil_chrgr2_cel_no = null;
      // this.txbil_chrgr2_email = null;

      // // this.isNew = true;
      // // this.isItemChanged = false;
    },
    getUserList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getUserList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);

        this.userList = res.data.userList;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getCustrList() {
      // this.isItemsSourceChanging = true;
      // this.$saveFlexGridState(this.flexGrid);

      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getCustrList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.custrList = res.data.custrList;
        this.gridList = res.data.custrList;
        this.custrCount = this.custrList.length;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getCustrDetail() {
      const params = new FormData();

      params.append("custr_sno", this.custrSno);

      this.$http.post("/base/getCustrDetail", params).then((res) => {
        // console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        const custr = res.data.custr;

        custr.use_tf = custr.use_yn == 'Y' ? true : false;
        custr.exch_aply_tf = custr.exch_aply_yn == 'Y' ? true : false;

        this.custr = JSON.parse(JSON.stringify(custr, function(k, v) {return v == null ? "" : v}));
        this.pendingCustr = JSON.parse(JSON.stringify(this.custr));
        // this.restoreCustr = null;
        // console.log("getCustrDetail:", this.custr === this.pendingCustr, this.custr.memo, this.pendingCustr.memo, this.custr, this.pendingCustr);

        this.isNew = false;
        // this.isItemChanged = false;

        // const custr = res.data.custr;

        // // 사업자 정보
        // this.custr_sno = custr.custr_sno;
        // this.custr_nm = custr.custr_nm;
        // this.custr_div = custr.custr_div;
        // this.repr_nm = custr.repr_nm;
        // this.tel_no = custr.tel_no;
        // this.fax_no = custr.fax_no;
        // this.biz_rno = custr.biz_rno;
        // this.corp_rno = custr.corp_rno;
        // this.biz_plc_addr = custr.biz_plc_addr;
        // this.biz_plc_zpcd = custr.biz_plc_zpcd;
        // this.dlv_pnt_addr = custr.dlv_pnt_addr;
        // this.dlv_pnt_zpcd = custr.dlv_pnt_zpcd;
        // this.sub_biz_plc_no = custr.sub_biz_plc_no;
        // this.biz_type = custr.biz_type;
        // this.biz_item = custr.biz_item;
        // this.memo = custr.memo;
        // this.spcl_note = custr.spcl_note;
        // // 담당자 정보
        // this.chrgr_nm = custr.chrgr_nm;
        // this.chrgr_tel_no = custr.chrgr_tel_no;
        // this.chrgr_cel_no = custr.chrgr_cel_no;
        // this.chrgr_email = custr.chrgr_email;
        // // 결제정보
        // this.loan_amt = 0;
        // this.crovr_bamt = 0;
        // this.slip_bamt = 0;
        // this.curr_bamt = 0;
        // // this.$refs.loan_amt.value = null;
        // // this.$refs.crovr_bamt.value = null;
        // // this.$refs.slip_bamt.value = null;
        // // this.$refs.slip_bamt.setValue(10000);
        // // this.$refs.curr_bamt.value = null;
        // this.clos_dt = custr.clos_dt;
        // this.use_tf = custr.use_yn == 'Y' ? true : false;
        // this.exch_aply_tf = custr.exch_aply_yn == 'Y' ? true : false;
        // this.pay_mthd = custr.pay_mthd;
        // this.pay_cond = custr.pay_cond;
        // this.custr_chrg_user_sno = custr.custr_chrg_user_sno;
        // // 세금계산서 담당자 정보
        // this.txbil_chrgr1_nm = custr.txbil_chrgr1_nm;
        // this.txbil_chrgr1_tel_no = custr.txbil_chrgr1_tel_no;
        // this.txbil_chrgr1_fax_no = custr.txbil_chrgr1_fax_no;
        // this.txbil_chrgr1_cel_no = custr.txbil_chrgr1_cel_no;
        // this.txbil_chrgr1_email = custr.txbil_chrgr1_email;
        // this.txbil_chrgr2_nm = custr.txbil_chrgr2_nm;
        // this.txbil_chrgr2_tel_no = custr.txbil_chrgr2_tel_no;
        // this.txbil_chrgr2_fax_no = custr.txbil_chrgr2_fax_no;
        // this.txbil_chrgr2_cel_no = custr.txbil_chrgr2_cel_no;
        // this.txbil_chrgr2_email = custr.txbil_chrgr2_email;

        // this.isNew = false;
        // this.isItemChanged = false;
      }).catch((error) => {
        console.log(error);
        alert(error.message);
      });
    },
    // rowClicked(item) {
    //   console.log("item:", item);

    //   this.getCustrDetail(item.custr_sno);
    // },
    addCustrBtnClicked() {
      // console.log("selectedRow:", this.selectedRow);
      let isConfirmed = true;

      if(this.isOnPendingCustr) {
        if(!confirm("저장되지 않은 데이터가 있습니다. 새 거래처를 추가하시겠습니까?")) {
          isConfirmed = false;
          this.flexGrid.select(this.selectedRow, this.selectedCol);
        }
      }

      if(isConfirmed) {
        this.flexGrid.select(-1, 0);
        this.flexGrid.rows[this.selectedRow].isSelected = false;
        // this.selectedRow = -1;

        // this.restoreCustr = JSON.parse(JSON.stringify(this.custr));

        this.isNew = true;
        this.initCustrInfo();
      } 
    },
    validateInput() {
      // 거래처명
      if(this.pendingCustr.custr_nm == null || this.pendingCustr.custr_nm == "") {
        alert('거래처명은 필수 입력값입니다.');
        return false;
      }
      // 구분
      if(this.pendingCustr.custr_div == null || this.pendingCustr.custr_div == "") {
        alert('구분은 필수 입력값입니다.');
        return false;
      }
      // 사업자번호
      // 담당자 이메일
      if((this.pendingCustr.chrgr_email != null && this.pendingCustr.chrgr_email != "") && (!this.$validateEmail(this.pendingCustr.chrgr_email))) {
        alert('담당자 이메일이 이메일 형식이 아닙니다.');
        return false;
      }
      // 세금계산서 담당자1 이메일
      if((this.pendingCustr.txbil_chrgr1_email != null && this.pendingCustr.txbil_chrgr1_email != "") && (!this.$validateEmail(this.pendingCustr.txbil_chrgr1_email))) {
        alert('세금계산서 담당자1 이메일이 이메일 형식이 아닙니다.');
        return false;
      }
      // 세금계산서 담당자2 이메일
      if((this.pendingCustr.txbil_chrgr2_email != null && this.pendingCustr.txbil_chrgr2_email != "") && (!this.$validateEmail(this.pendingCustr.txbil_chrgr2_email))) {
        alert('세금계산서 담당자2 이메일이 이메일 형식이 아닙니다.');
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // 입력 데이터 검증
      const isValid = this.validateInput();
      
      if(isValid) {
      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);

        this.pendingCustr.use_yn = this.pendingCustr.use_tf ? 'Y' : 'N';
        this.pendingCustr.exch_aply_yn = this.pendingCustr.exch_aply_tf ? 'Y' : 'N';

        params.append('custr', JSON.stringify(this.pendingCustr));

        // params.append("custr_sno", this.pendingCustr.custr_sno);
        // params.append("custr_nm", this.pendingCustr.custr_nm);

        // if(this.pendingCustr.custr_div != null) params.append("custr_div", this.pendingCustr.custr_div);
        // if(this.pendingCustr.repr_nm != null) params.append("repr_nm", this.pendingCustr.repr_nm);
        // if(this.pendingCustr.tel_no != null) params.append("tel_no", this.pendingCustr.tel_no);
        // if(this.pendingCustr.fax_no != null) params.append("fax_no", this.pendingCustr.fax_no);
        // if(this.pendingCustr.biz_rno != null) params.append("biz_rno", this.pendingCustr.biz_rno);
        // if(this.pendingCustr.corp_rno != null) params.append("corp_rno", this.pendingCustr.corp_rno);
        // if(this.pendingCustr.biz_plc_addr != null) params.append("biz_plc_addr", this.pendingCustr.biz_plc_addr);
        // if(this.pendingCustr.biz_plc_zpcd != null) params.append("biz_plc_zpcd", this.pendingCustr.biz_plc_zpcd);
        // if(this.pendingCustr.dlv_pnt_addr != null) params.append("dlv_pnt_addr", this.pendingCustr.dlv_pnt_addr);
        // if(this.pendingCustr.dlv_pnt_zpcd != null) params.append("dlv_pnt_zpcd", this.pendingCustr.dlv_pnt_zpcd);
        // if(this.pendingCustr.sub_biz_plc_no != null) params.append("sub_biz_plc_no", this.pendingCustr.sub_biz_plc_no);
        // if(this.pendingCustr.biz_type != null) params.append("biz_type", this.pendingCustr.biz_type);
        // if(this.pendingCustr.biz_item != null) params.append("biz_item", this.pendingCustr.biz_item);
        // if(this.pendingCustr.memo != null) params.append("memo", this.pendingCustr.memo);
        // if(this.pendingCustr.spcl_note != null) params.append("spcl_note", this.pendingCustr.spcl_note);
        // if(this.pendingCustr.chrgr_nm != null) params.append("chrgr_nm", this.pendingCustr.chrgr_nm);
        // if(this.pendingCustr.chrgr_tel_no != null) params.append("chrgr_tel_no", this.pendingCustr.chrgr_tel_no);
        // if(this.pendingCustr.chrgr_cel_no != null) params.append("chrgr_cel_no", this.pendingCustr.chrgr_cel_no);
        // if(this.pendingCustr.chrgr_email != null) params.append("chrgr_email", this.pendingCustr.chrgr_email);
        
        // // const slip_bamt_value = this.$refs.slip_bamt.getValue();
        // // console.log("slip_bamt_value:", slip_bamt_value);

        // if(this.pendingCustr.slip_bamt != null) params.append("slip_bamt", this.pendingCustr.slip_bamt);
        // if(this.pendingCustr.curr_bamt != null) params.append("curr_bamt", this.pendingCustr.curr_bamt);
        // // if(this.$refs.slip_bamt.getValue() != null) params.append("slip_bamt", this.$refs.slip_bamt.getValue());
        // // if(this.$refs.curr_bamt.getValue() != null) params.append("curr_bamt", this.$refs.curr_bamt.getValue());

        // if(this.pendingCustr.clos_dt != null) params.append("clos_dt", this.pendingCustr.clos_dt);
        // if(this.pendingCustr.use_tf != null) params.append("use_yn", this.pendingCustr.use_tf == true ? 'Y' : 'N');
        // if(this.pendingCustr.exch_aply_tf != null) params.append("exch_aply_yn", this.pendingCustr.exch_aply_tf == true ? 'Y' : 'N');
        // if(this.pendingCustr.pay_mthd != null) params.append("pay_mthd", this.pendingCustr.pay_mthd);
        // if(this.pendingCustr.pay_cond != null) params.append("pay_cond", this.pendingCustr.pay_cond);
        // if(this.pendingCustr.custr_chrg_user_sno != null) params.append("custr_chrg_user_sno", this.pendingCustr.custr_chrg_user_sno);

        // if(this.pendingCustr.txbil_chrgr1_nm != null) params.append("txbil_chrgr1_nm", this.pendingCustr.txbil_chrgr1_nm);
        // if(this.pendingCustr.txbil_chrgr1_tel_no != null) params.append("txbil_chrgr1_tel_no", this.pendingCustr.txbil_chrgr1_tel_no);
        // if(this.pendingCustr.txbil_chrgr1_fax_no != null) params.append("txbil_chrgr1_fax_no", this.pendingCustr.txbil_chrgr1_fax_no);
        // if(this.pendingCustr.txbil_chrgr1_cel_no != null) params.append("txbil_chrgr1_cel_no", this.pendingCustr.txbil_chrgr1_cel_no);
        // if(this.pendingCustr.txbil_chrgr1_email != null) params.append("txbil_chrgr1_email", this.pendingCustr.txbil_chrgr1_email);
        // if(this.pendingCustr.txbil_chrgr2_nm != null) params.append("txbil_chrgr2_nm", this.pendingCustr.txbil_chrgr2_nm);
        // if(this.pendingCustr.txbil_chrgr2_tel_no != null) params.append("txbil_chrgr2_tel_no", this.pendingCustr.txbil_chrgr2_tel_no);
        // if(this.pendingCustr.txbil_chrgr2_fax_no != null) params.append("txbil_chrgr2_fax_no", this.pendingCustr.txbil_chrgr2_fax_no);
        // if(this.pendingCustr.txbil_chrgr2_cel_no != null) params.append("txbil_chrgr2_cel_no", this.pendingCustr.txbil_chrgr2_cel_no);
        // if(this.pendingCustr.txbil_chrgr2_email != null) params.append("txbil_chrgr2_email", this.pendingCustr.txbil_chrgr2_email);

        this.$http.post("/base/upsertCustr", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");
          
          if(this.isNew) {
            this.getListMode = 'insert';
          } else {
            this.getListMode = 'update';
          }

          this.getCustrList();

          this.isNew = false;
          // this.isItemChanged = false;
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    deleteBtnClicked() {
      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("user_sno", this.userSno);
        params.append("custr_sno", this.custrSno);
        
        this.$http.post("/base/deleteCustr", params).then((res) => {
          console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
          alert("삭제되었습니다.");
          
          this.getListMode = 'delete';

          this.getCustrList();
          // this.initCustrInfo();
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    findZipcodeBtnClicked(div) {
      new window.daum.Postcode({
				oncomplete: (data) => {
          if(div == "biz_plc") {
            this.pendingCustr.biz_plc_zpcd = data.zonecode;
            this.pendingCustr.biz_plc_addr = data.roadAddress;
          } else {
            this.pendingCustr.dlv_pnt_zpcd = data.zonecode;
            this.pendingCustr.dlv_pnt_addr = data.roadAddress;
          }

          // this.itemChanged();
				},
			}).open();
    },
    // 사업장 주소와 동일 체크 박스 변경됨
    sameAsBizPlcChanged() {
      // console.log("sameAsBizPlcChanged:", this.same_as_biz_plc);
      if(this.pendingCustr.same_as_biz_plc) {
        this.pendingCustr.dlv_pnt_zpcd = this.pendingCustr.biz_plc_zpcd;
        this.pendingCustr.dlv_pnt_addr = this.pendingCustr.biz_plc_addr;
      }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingCustr) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        console.log("isConfirmed:", this.selectedRow, this.selectedCol);
        this.flexGrid.select(this.selectedRow, this.selectedCol);
        this.getCustrDetail(this.flexGrid.getCellData(this.selectedRow, 0));
      }
    },
  }
}
</script>

	   

<style>


</style>