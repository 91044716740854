<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>미수금/미지급 조회</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="gridDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="period">기간별</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="month">월별</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center">
              <v-col cols="12" v-show="gridDiv == 'period'">
                <v-row dense align="center" class="">
                  <v-col cols="1"><div class=""><span>조회수 : {{filteredUnColctPays.length}} 건</span></div></v-col>
                  <v-col cols="9" class="d-flex justify-end align-center hei-40p">
                    <v-row dense align="center">
                      <v-col cols="2">
                        <v-row delse align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">거래처</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustr"
                              @focusout="searchWordCustr = pendingWordCustr"
                              @keyup.enter="searchWordCustr = pendingWordCustr"
                              clearable
                              @click:clear="searchWordCustr = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1" align="right" class="">
                        <div class=""><span>조회구분</span></div>
                      </v-col>
                      <v-col cols="4">
                        <v-row dense align="center">
                          <v-col cols="4" class="">
                            <v-select
                                class=""
                                v-model="custrDiv"
                                :items="custrDivList"
                                item-value="cd"
                                item-text="nm"
                                hide-details dense outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="4" class="">
                            <v-select
                                class=""
                                v-model="userSno"
                                :items="userList"
                                item-value="user_sno"
                                item-text="user_nm"
                                hide-details dense outlined
                            ></v-select>
                          </v-col>
                          <v-col cols="4" class="">
                            <v-checkbox
                                class="pa-0 ma-0"
                                v-model="incld_zero"
                                hide-details dense
                                label="잔액 0원 포함"
                            ></v-checkbox>
                          </v-col>                                           
                        </v-row>
                      </v-col>

                      <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                      <v-col cols="4">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <date-picker v-model="srchBgnDt"></date-picker>
                          </v-col>
                          <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                          <v-col cols="4">
                            <date-picker v-model="srchEndDt"></date-picker>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  
                  <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onUnColctPayInqry">조회</v-btn>
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-show="gridDiv == 'month'">
                <v-row dense align="center" class="">
                  <v-col cols="1"><div class=""><span>조회수 : {{filteredUnColctPaysMonth.length}} 건</span></div></v-col>
                  <v-col cols="9" class="d-flex justify-end align-center hei-40p">
                    <v-row dense align="center">
                      
                      <v-col cols="2" class="" >
                        <v-radio-group
                          v-model="unColctPayDiv"
                          row
                          hide-details
                          dense
                          class="ma-0 pa-0"
                          :readonly="isOnPendingUnColctPaysMonth"
                          >
                          <v-radio 
                            :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                            v-for="item in unColctPayDivList"
                            @click="onSrchCondItemClicked"
                            >
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="2">
                        <v-row delse align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">거래처</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordCustrMonth"
                              @focusout="searchWordCustrMonth = pendingWordCustrMonth"
                              @keyup.enter="searchWordCustrMonth = pendingWordCustrMonth"
                              clearable
                              @click:clear="searchWordCustrMonth = ''"
                              :readonly="isOnPendingUnColctPaysMonth"
                              @click="onSrchCondItemClicked"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1" align="right" class="">
                        <div class=""><span>조회구분</span></div>
                      </v-col>
                      <v-col cols="3">
                        <v-row dense align="center">
                          <!-- <v-col cols="4" class="">
                            <v-select
                                class=""
                                v-model="unColctPayDiv"
                                :items="unColctPayDivList"
                                item-value="cd"
                                item-text="nm"
                                hide-details dense outlined
                            ></v-select>
                          </v-col> -->
                          <v-col cols="6" class="" @click="onSrchCondItemClicked">
                            <v-select
                                class=""
                                v-model="custrDivMonth"
                                :items="custrDivMonthList"
                                item-value="cd"
                                item-text="nm"
                                hide-details dense outlined
                                :readonly="isOnPendingUnColctPaysMonth"
                            ></v-select>
                          </v-col>
                          <v-col cols="5" class="" @click="onSrchCondItemClicked">
                            <v-select
                                class=""
                                v-model="userSnoMonth"
                                :items="userList"
                                item-value="user_sno"
                                item-text="user_nm"
                                hide-details dense outlined
                                :readonly="isOnPendingUnColctPaysMonth"
                            ></v-select>
                          </v-col>                                         
                        </v-row>
                      </v-col>

                      <!-- <v-col cols="1"><div class="f-r"><span>조회일자</span></div></v-col> -->
                      <v-col cols="4">
                        <v-row dense align="center">
                          <v-col cols="2"><div class="" style="text-align: center;"><span>조회일자</span></div></v-col>
                          <v-col cols="4">
                            <date-picker 
                              v-model="srchDt"
                              :readonly="isOnPendingUnColctPaysMonth"
                              >
                            </date-picker>
                          </v-col>
                          <v-col cols="2"><div class="" style="text-align: center;"><span>조회기간</span></div></v-col>
                          <v-col cols="4" @click="onSrchCondItemClicked">
                            <v-select
                                class=""
                                v-model="srchTerm"
                                :items="srchTermList"
                                item-value="cd"
                                item-text="nm"
                                hide-details dense outlined
                                :readonly="isOnPendingUnColctPaysMonth"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                    <!-- <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onUnColctPayInqry">조회</v-btn> -->
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                    <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3 font-weight-bold" min-width="70" :disabled="!isOnPendingUnColctPaysMonth" @click="onCancel">취소</v-btn>
                    <v-btn small color="indigo darken-3" class="white--text font-weight-bold" min-width="70" :disabled="!isOnPendingUnColctPaysMonth" @click="onSave">저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            
            

            <v-row dense align="center">              
              <v-col cols="12" v-show="gridDiv == 'period'">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredUnColctPays" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexGridInit"
                    :itemsSourceChanged="onFlexGridItemsSourceChanged"
                    :headersVisibility="'Column'"
                    >
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="200" />
                    <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" :width="80" align="center"/>
                    <wj-flex-grid-column :header="'연락처'" :binding="'tel_no'" :width="120" />
                    <wj-flex-grid-column :header="'이월미수금액'" :binding="'crovr_un_colct_amt'" :width="120" />
                    <wj-flex-grid-column :header="'이월미지급액'" :binding="'crovr_un_pay_amt'" :width="120" />
                    <wj-flex-grid-column :header="'매출금액(+VAT)'" :binding="'sales_amt'" :width="120" />
                    <wj-flex-grid-column :header="'매입금액(+VAT)'" :binding="'purch_amt'" :width="120" />
                    <wj-flex-grid-column :header="'수금액'" :binding="'colct_amt'" :width="120" />
                    <wj-flex-grid-column :header="'지급액'" :binding="'pay_amt'" :width="120" />
                    <wj-flex-grid-column :header="'미수금액'" :binding="'un_colct_amt'" :width="120" />
                    <wj-flex-grid-column :header="'미지급액'" :binding="'un_pay_amt'" :width="120" />
                  </wj-flex-grid>
                </div>
              </v-col>
              <v-col cols="12" v-show="gridDiv == 'month'">
                <div>
                  <wj-flex-grid 
                    :itemsSource="filteredUnColctPaysMonth" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :showMarquee="true"
                    :initialized="onFlexMonthInit"
                    :itemsSourceChanged="onFlexMonthItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :cellEditEnded="onFlexMonthItemEditEnded"
                    >
                    <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="200" :isReadOnly="true"/>
                    <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" :width="80" :isReadOnly="true" align="center"/>
                    <wj-flex-grid-column :header="'연락처'" :binding="'tel_no'" :width="120" :isReadOnly="true"/>
                    <wj-flex-grid-column :header="mmHeader.un_colct_pay_amt_m00" :binding="'un_colct_pay_amt_m00'" :width="120" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.sales_purch_amt_m00" :binding="'sales_purch_amt_m00'" :width="140" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.colct_pay_amt_m00" :binding="'colct_pay_amt_m00'" :width="120" :isReadOnly="true"/>
                    <wj-flex-grid-column :header="mmHeader.un_colct_pay_amt_m01" :binding="'un_colct_pay_amt_m01'" :width="120" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h01" :binding="'m01'" :width="110" :isReadOnly="true" />                   
                    <wj-flex-grid-column :header="mmHeader.h02" :binding="'m02'" :width="110" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h03" :binding="'m03'" :width="110" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h04" :binding="'m04'" :width="110" :visible="srchTerm == 'twelve' || srchTerm == 'six'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h05" :binding="'m05'" :width="110" :visible="srchTerm == 'twelve' || srchTerm == 'six'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h06" :binding="'m06'" :width="110" :visible="srchTerm == 'twelve' || srchTerm == 'six'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h07" :binding="'m07'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h08" :binding="'m08'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h09" :binding="'m09'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h10" :binding="'m10'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h11" :binding="'m11'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true" />
                    <wj-flex-grid-column :header="mmHeader.h12" :binding="'m12'" :width="110" :visible="srchTerm == 'twelve'" :isReadOnly="true"/>
                    <wj-flex-grid-column :header="mmHeader.last_colct_pay_dt" :binding="'last_colct_pay_dt'" :width="120" :isReadOnly="true" align="center"/>
                    <wj-flex-grid-column :header="'요주업체'" :binding="'need_atent_yn'" :width="80" dataType="Boolean" align="center"/>
                    <wj-flex-grid-column :header="'메모'" :binding="'memo'" :width="400" />
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

export default {
  components: {datePicker},
  data(){
    return{
      // 공통
      gridDiv: "period",

      userList: [],

      // 기간별
      searchWordCustr: "",
      pendingWordCustr: "",

      srchBgnDt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      custrDiv: "all",
      custrDivList: [
        {cd: "all", nm: "전체거래처"},  
        {cd: "sales", nm: "매출처"},
        {cd: "purch", nm: "매입처"},
        {cd: "gen_sales", nm: "일반_매출처"},
        {cd: "gen_purch", nm: "일반_매입처"},
      ],

      userSno: 0,
      
      incld_zero: true,

      flexGrid: null,    // 기간 그리드
      unColctPays: [],

      // 월별
      searchWordCustrMonth: "",
      pendingWordCustrMonth: "",

      unColctPayDiv: "un_colct",
      unColctPayDivList: [
        {cd: "un_colct", nm: "미수금"},
        {cd: "un_pay", nm: "미지급"},
      ],

      custrDivMonth: "all",
      custrDivMonthList: [
        {cd: "all", nm: "전체거래처"},  
        {cd: "curr_month", nm: "당월거래처"},
        {cd: "un_colct_pay", nm: "잔액존재거래처"},
      ],

      userSnoMonth: 0,

      srchDt: dayjs().format('YYYY-MM-DD'),  
      srchTerm: "three",
      srchTermList: [
        {cd: "three", nm: "이전 3개월"},  
        {cd: "six", nm: "이전 6개월"},
        {cd: "twelve", nm: "이전 12개월"},
      ],

      flexMonth: null,    // 월별 그리드
      unColctPaysMonth: [],
      pendingUnColctPaysMonth: [],
      modifiedRows: [],

      mmHeader: {
        un_colct_pay_amt_m00: "현미수금액",
        sales_purch_amt_m00: "당월매출액",
        colct_pay_amt_m00: "당월수금액",
        un_colct_pay_amt_m01: "전월미수금액",
        last_colct_pay_dt: "최근수금일",
        h01: "M01",
        h02: "M02",
        h03: "M03",
        h04: "M04",
        h05: "M05",
        h06: "M06",
        h07: "M07",
        h08: "M08",
        h09: "M09",
        h10: "M10",
        h11: "M11",
        h12: "M12",
      },
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.unCollectPay.code);
    },
    filteredUnColctPays() {
      return this.unColctPays.filter(({custr_nm, custr_div, custr_chrg_user_sno, un_colct_amt, un_pay_amt}) =>
          // (this.unColctPayDiv == 'all' || this.unColctPayDiv === un_colct_pay_div) &&
          (!this.searchWordCustr || this.searchWordCustr.trim() === '' ||
            custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustr.toUpperCase())) &&
          (this.custrDiv == 'all' || this.custrDiv === custr_div) &&
          (this.userSno == 0 || this.userSno === custr_chrg_user_sno) &&
          (this.incld_zero || (un_colct_amt != 0 || un_pay_amt != 0))
      );
    },
    filteredUnColctPaysMonth() {
      return this.pendingUnColctPaysMonth.filter(({custr_nm, un_colct_pay_div, un_colct_pay_amt_m00, sales_purch_amt_m00, custr_chrg_user_sno}) =>
          (!this.searchWordCustrMonth || this.searchWordCustrMonth.trim() === '' ||
            custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustrMonth.toUpperCase())) &&
          (this.unColctPayDiv == 'all' || this.unColctPayDiv === un_colct_pay_div) &&
          (this.custrDivMonth == 'all' || (this.custrDivMonth === 'curr_month' && sales_purch_amt_m00 > 0) || (this.custrDivMonth === 'un_colct_pay' && un_colct_pay_amt_m00 != 0)) &&
          (this.userSnoMonth == 0 || this.userSnoMonth === custr_chrg_user_sno) 
      );
    },
    isOnPendingUnColctPaysMonth() {
      const isOnPending = JSON.stringify(this.unColctPaysMonth) !== JSON.stringify(this.pendingUnColctPaysMonth);
      this.$store.commit('updateIsOnPending', {name:'unCollectPay', status: isOnPending ? 'on' : 'off'});     
      return isOnPending;
    },
  },
  watch: {
    srchDt: {
      immediate: true,
      handler: function () {
        this.onUnColctPayInqry();
      },
    },
    unColctPayDiv: {
      immediate: true,
      handler: function () {
        this.onUnColctPayDivChanged();
      },
    }
  },
  created() {
    
  },
  mounted() {
    this.getUserList();

    this.loadUnColctPays();
    this.loadUnColctPaysMonth();
  },
  methods: {
    onFlexGridInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }
      });

      this.flexGrid = flex;
    },
    onFlexGridItemsSourceChanged(flex) {

    },
    onFlexMonthInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }
      });

      this.flexMonth = flex;
    },
    onFlexMonthItemsSourceChanged(flex) {
      console.log("onFlexMonthItemsSourceChanged");
      this.modifiedRows = [];
    },
    onFlexMonthItemEditEnded(flex) {
      console.log("onFlexMonthItemEditEnded", flex);
      const custr_sno = flex.collectionView.currentItem.custr_sno;

      if (!this.modifiedRows.includes(custr_sno)) {
        this.modifiedRows.push(custr_sno);
      }
      console.log("onFlexMonthItemEditEnded", this.modifiedRows);
    },
    getUserList() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);

      this.$http.post("/base/getUserList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.userList.unshift({user_sno: 0, user_nm: "전체담당자"})
        this.userList = res.data.userList;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    // 미수금/미지급 목록 조회 : 기간
    async loadUnColctPays() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/accounting/getUnColctPayList', params);
        const unColctPays = res.data.unColctPayList.map(unColctPay => ({
          ...unColctPay,
        }));

        this.unColctPays = unColctPays;
      } catch (e) {
        console.log(`Error on function loadUnColctPays: ${e}`);
      }
    },
    // 미수금/미지급 목록 조회 : 월별
    async loadUnColctPaysMonth() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", this.srchDt);
      params.append("srch_term", this.srchTerm);

      try {
        const res = await this.$http.post('/accounting/getUnColctPayMonthList', params);
        const unColctPays = res.data.unColctPayList.map(unColctPay => ({
          ...unColctPay,
          need_atent_yn: unColctPay.need_atent_yn == 'Y' ? true : false,
        }));

        // 월별 매출액 헤더 설정
        this.mmHeader.h01 = res.data.monthList[1].mm.substring(4) + '월';
        this.mmHeader.h02 = res.data.monthList[2].mm.substring(4) + '월';
        this.mmHeader.h03 = res.data.monthList[3].mm.substring(4) + '월';
        this.mmHeader.h04 = res.data.monthList[4].mm.substring(4) + '월';
        this.mmHeader.h05 = res.data.monthList[5].mm.substring(4) + '월';
        this.mmHeader.h06 = res.data.monthList[6].mm.substring(4) + '월';
        this.mmHeader.h07 = res.data.monthList[7].mm.substring(4) + '월';
        this.mmHeader.h08 = res.data.monthList[8].mm.substring(4) + '월';
        this.mmHeader.h09 = res.data.monthList[9].mm.substring(4) + '월';
        this.mmHeader.h10 = res.data.monthList[10].mm.substring(4) + '월';
        this.mmHeader.h11 = res.data.monthList[11].mm.substring(4) + '월';
        this.mmHeader.h12 = res.data.monthList[12].mm.substring(4) + '월';

        this.unColctPaysMonth = JSON.parse(JSON.stringify(unColctPays));
        this.pendingUnColctPaysMonth = JSON.parse(JSON.stringify(unColctPays));
      } catch (e) {
        console.log(`Error on function loadUnColctPaysMonth: ${e}`);
      }
    },
    onSrchCondItemClicked() {
      console.log("onSrchCondItemClicked");
      if(this.isOnPendingUnColctPaysMonth) {
        alert('수정 중에는 사용할 수 없습니다.');
      }
    },
    // 조회 버튼 클릭
    onUnColctPayInqry() {
      if(this.gridDiv == "period")
        this.loadUnColctPays();
      else 
        this.loadUnColctPaysMonth();
    },
    onUnColctPayDivChanged() {
      if(this.unColctPayDiv === 'un_colct') {
        this.mmHeader.un_colct_pay_amt_m00 = "현미수금액";
        this.mmHeader.sales_purch_amt_m00 = "당월매출액(+VAT)";
        this.mmHeader.colct_pay_amt_m00 = "당월수금액";
        this.mmHeader.un_colct_pay_amt_m01 = "전월미수금액";
        this.mmHeader.last_colct_pay_dt = "최근수금일";
      } else if(this.unColctPayDiv === 'un_pay') {
        this.mmHeader.un_colct_pay_amt_m00 = "현미지급액";
        this.mmHeader.sales_purch_amt_m00 = "당월매입액(+VAT)";
        this.mmHeader.colct_pay_amt_m00 = "당월지급액";
        this.mmHeader.un_colct_pay_amt_m01 = "전월미지급액";
        this.mmHeader.last_colct_pay_dt = "최근지급일";
      }
    },
    onCancel() {
      if (this.isOnPendingUnColctPaysMonth && !confirm('편집을 취소하시겠습니까?'))
        return;

      this.pendingUnColctPaysMonth = JSON.parse(JSON.stringify(this.unColctPaysMonth));
    },
    async onSave() {
      console.log("onSave modifiedRows:", this.modifiedRows, this.flexMonth);

      const params = new URLSearchParams();

      params.append('user_sno', this.$store.state.session.userSno);

      const modifiedList = this.pendingUnColctPaysMonth.filter(({custr_sno}) => this.modifiedRows.includes(custr_sno)).map(unColctPay => ({
        custr_sno: unColctPay.custr_sno,
        memo: unColctPay.memo,
        need_atent_yn: unColctPay.need_atent_yn ? 'Y' : 'N',
      }));
      console.log("onSave modifiedList:", modifiedList);
      params.append('modifiedList', JSON.stringify(modifiedList));

      try {
        const res = await this.$http.post('/accounting/updateUnColctPayCustr', params);

        if (res.data.code === 0) {
          await this.loadUnColctPaysMonth();
        }
      } catch (e) {
        console.log(`Error on function onSave: ${e}`);
      }
    }
  }
}
</script>

<style>

</style>