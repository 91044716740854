<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>손익 계산서</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="">
              
              <v-col cols="4" class="d-flex justify-end align-center hei-40p">
                <v-row dense align="center">
                  <v-col cols="2"><div class=""><span>조회기간</span></div></v-col>
                  <v-col cols="4">
                    <date-picker v-model="srchBgnDt"></date-picker>
                  </v-col>
                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                  <v-col cols="4">
                    <date-picker v-model="srchEndDt"></date-picker>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6"></v-col>    
              <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" @click="onStmtInqry">조회</v-btn>
                <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 white--text font-weight-bold">출력</v-btn>
              </v-col>
            </v-row>

            <!-- 손익계산서 -->
            <v-row dense align="center" >              
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="incmStmts" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexIncmInitialized"
                    :itemsSourceChanged="onFlexIncmItemsSourceChanged"
                    :headersVisibility="'Column'"
                    :alternatingRowStep="0"
                    :allowMerging="'ColumnHeaders'"
                    :autoGenerateColumns="false"
                    >
                    <wj-flex-grid-column :header="'항목'" :binding="'item_nm'" :allowMerging="true" width="2*" align="center"/>
                    <wj-flex-grid-column :header="'금액'" :binding="'curr_priod_sum_amt'" width="1*" />
                    <wj-flex-grid-column :header="'금액'" :binding="'prior_priod_sum_amt'" width="1*" />
                    <wj-flex-grid-column :header="'산출근거'" :binding="'calc_basis'" width="8*" />
                  </wj-flex-grid>
                </div>
              </v-col>
              
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

export default {
  components: {datePicker},
  data(){
    return{
      // 월계표
      srchBgnDt: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      srchEndDt: dayjs().format('YYYY-MM-DD'),

      incmStmts: [],

      flexIncm: null,    // 손익계산서
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.incomeStmt.code);
    },
    
  },
  watch: {
    // srchDtByDay: {
    //   immediate: true,
    //   handler: function () {
    //     this.loadByDayStmts();
    //   },
    // },
  },
  created() {
    
  },
  mounted() {
    // this.loadByDayStmts(); // watch에서 날짜 설정 시 조회됨
    this.loadIncmStmts();
  },
  methods: {
    onFlexIncmInitialized(flex) {
      // create extra header row
      let extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;
      //
      // add extra header row to the flex
      let panel = flex.columnHeaders;
      panel.rows.splice(0, 0, extraRow);
      //
      // populate the extra header row
      panel.setCellData(0, 1, "계(당기)");
      panel.setCellData(0, 2, "계(전기)");
      //
      // merge headers vertically
      ["item_nm", "calc_basis"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
      });
      //
      // center-align merged header cells
      flex.formatItem.addHandler(function(s, e) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
          let html = e.cell.innerHTML;
          e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
          // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
        }
      });

      this.flexByMonthSumry = flex;
    },
    onFlexIncmItemsSourceChanged(flex) {
      
    },
    
    // 손익계산서 조회
    async loadIncmStmts() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDt);
      params.append("srch_end_dt", this.srchEndDt);

      try {
        const res = await this.$http.post('/accounting/getIncmStmtList', params);
        
        const incmStmts = res.data.incmList.map(stmt => ({
          ...stmt,
        }));

        this.incmStmts = incmStmts;
        console.log("loadIncmStmts:", this.incmStmts);

      } catch (e) {
        console.log(`Error on function loadByMonthStmts: ${e}`);
      }
    },
    // 조회 버튼 클릭
    onStmtInqry() {
      this.loadIncmStmts();
    },

  }
}
</script>

<style>
.wj-cell .v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  white-space: pre-wrap;
}
</style>