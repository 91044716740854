<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>수주/발주 관리</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="ordDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="sales" :disabled="isOnPendingOrd">수주 관리</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="purch" :disabled="isOnPendingOrd">발주 관리</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row dense class="pt-0 mt-0" style="height:200px;" align="center">
          <v-col cols="1">
            <div class=""><span>조회수 : {{ filteredOrds.length }} 건</span></div>
          </v-col>
          <v-col cols="1">
            <div class=" f-r"><span>조회일자</span></div>
          </v-col>
          <v-col cols="1">
            <date-picker v-model="content[ordDiv].srchDt" :disabled="isOnPendingOrd" :is-checked="false"></date-picker>
          </v-col>
          <template v-if="ordDiv==='sales'">
            <v-col cols="1">
              <div class="f-r"><span>수주상태</span></div>
            </v-col>
            <v-col cols="1">
              <v-select
                  class=""
                  v-model="content[ordDiv].ordStus"
                  :items="outShipStusFilterCombo"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  hide-details dense outlined :disabled="isOnPendingOrd"
              ></v-select>
            </v-col>
          </template>
          <template v-if="ordDiv==='purch'">
            <v-col cols="1">
              <div class="f-r"><span>발주상태</span></div>
            </v-col>
            <v-col cols="1">
              <v-select
                  class=""
                  v-model="content[ordDiv].ordStus"
                  :items="inShipStusFilterCombo"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  hide-details dense outlined :disabled="isOnPendingOrd"
              ></v-select>
            </v-col>
          </template>
          <v-col cols="1">
            <div class=" f-r"><span>거래처</span></div>
          </v-col>
          <v-col cols="1">
            <v-text-field
                class="" v-model="content[ordDiv].pendingWord"
                hide-details dense outlined clearable :disabled="isOnPendingOrd"
                @click:clear="content[ordDiv].searchWord = ''"
                @focusout="content[ordDiv].searchWord = content[ordDiv].pendingWord"
                @keyup.enter="content[ordDiv].searchWord = content[ordDiv].pendingWord"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="4" align="right">
            <!-- <v-btn small min-width="70px" color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold">조회</v-btn> -->
            <v-btn small min-width="70px" color="blue darken-2" class="white--text font-weight-bold" :disabled="!content[ordDiv].pendingOrd.ord_sno" @click="onCreateOrd">
              {{ ordDiv === 'sales' ? '수주 추가' : ordDiv === 'purch' ? '발주 추가' : '' }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <wj-flex-grid
                :isReadOnly=true
                :itemsSource="filteredOrds"
                :selectionMode="isOnPendingOrd ? 'None' : 'ListBox'"
                style="height:175px;"
                :initialized="onOrdGridInit"
                headersVisibility="Column">
              <wj-flex-grid-column header="주문일련번호" binding="ord_sno" width="2*" :visible="false" />
              <wj-flex-grid-column header="거래처일련번호" binding="custr_sno" width="4*" :visible="false"/>
              <wj-flex-grid-column header="주문번호" binding="ord_no" width="4.5*" :visible="false"/>
              <wj-flex-grid-column header="일자" binding="ord_dt" width="2*" align="center"/>
              <wj-flex-grid-column header="거래처명" binding="custr_nm" width="3*" align="left"/>
              <wj-flex-grid-column header="건수" binding="dtl_cnt" width="1*" align="right"/>
              <wj-flex-grid-column header="소재비" binding="sitem_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="가공비" binding="mchng_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="공급가액" binding="suply_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="부가세액" binding="vat_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="합계금액" binding="sum_amt" width="2*" align="right"/>
              <wj-flex-grid-column header="작업지시" binding="work_ord_yn" width="1.5*" align="center" dataType="Boolean"/>
              <wj-flex-grid-column header="로스출력" binding="loss_prt_yn" width="1.5*" align="center" dataType="Boolean"/>
              <wj-flex-grid-column header="수주상태" binding="ord_stus_nm" width="1.5*" v-if="ordDiv==='sales'" align="center"/>
              <wj-flex-grid-column header="발주상태" binding="ord_stus_nm" width="1.5*" v-if="ordDiv==='purch'" align="center"/>
              <wj-flex-grid-column header="출고예정일" binding="out_ship_scdul_dt" width="2*" v-if="ordDiv==='sales'" align="center"/>
              <wj-flex-grid-column header="입고예정일" binding="out_ship_scdul_dt" width="2*" v-if="ordDiv==='purch'" align="center"/>
              <wj-flex-grid-column header="작성자" binding="reg_user_nm" width="2*" align="center"/>
              <wj-flex-grid-column header="비고사항" binding="rm" width="8*" align="left"/>
            </wj-flex-grid>
          </v-col>
        </v-row>

        <v-card class="pa-1 bgcolor-grey1 mt-3 elevation-0" height="490px">
          <v-row dense align="center">
            <v-col cols="12" class="d-flex justify-end align-center hei-40p">
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 font-weight-bold" color="blue-grey lighten-5" v-show="content[ordDiv].pendingOrd.ord_sno && !isDummy" @click="onOrdDelete">삭제</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd">작업지시서</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd" v-if="ordDiv==='sales'">견적서</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd" v-if="ordDiv==='purch'">발주서</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd">라벨지</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd || content[ordDiv].ord.ord_stus !== 'yet'" v-if="ordDiv==='sales'" @click="onOrdStep('sales')">출고처리</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd || content[ordDiv].ord.ord_stus !== 'yet'" v-if="ordDiv==='purch'" @click="onOrdStep('purch')">입고처리</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 white--text font-weight-bold" color="blue darken-2" v-show="content[ordDiv].pendingOrd.ord_sno" :disabled="isDummy || isOnPendingOrd" @click="onOrdCopy">복사</v-btn>
              <v-btn small min-width="70" class="pa-0 ma-0 mr-3 font-weight-bold" color="blue-grey lighten-5" :disabled="isOrdCancellationDisabled" @click="onOrdCancel">취소</v-btn>
              <v-btn small min-width="70" class="white--text font-weight-bold" color="indigo darken-3" :disabled="isOrdSaveDisabled" @click="onOrdSave">저장</v-btn>
            </v-col>
          </v-row>
          <v-card class="pa-1 mt-1 bgcolor-grey2 mt1" outlined>
          <v-row dense style="height:30px;" class="pt-0">
            <v-col cols="12" class="">
              <h3>상세 내용</h3>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right" v-if="ordDiv==='sales'">
              <div class=""><span>수주번호</span></div>
            </v-col>
            <v-col cols="1" align="right" v-if="ordDiv==='purch'">
              <div class=""><span>발주번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.ord_no" hide-details dense outlined readonly disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right" v-if="ordDiv==='sales'">
              <div class=""><span style="color:red;">* </span><span>수주일자</span></div>
            </v-col>
            <v-col cols="1" align="right" v-if="ordDiv==='purch'">
              <div class=""><span style="color:red;">* </span><span>발주일자</span></div>
            </v-col>
            <v-col cols="1">
              <date-picker v-model="content[ordDiv].pendingOrd.ord_dt" :disabled="isDummy"></date-picker>
            </v-col>
            <v-col cols="1" align="right" v-if="ordDiv==='sales'">
              <div class=""><span>출고예정일</span></div>
            </v-col>
            <v-col cols="1" align="right" v-if="ordDiv==='purch'">
              <div class=""><span>입고예정일</span></div>
            </v-col>
            <v-col cols="1">
              <date-picker v-model="content[ordDiv].pendingOrd.out_ship_scdul_dt" :disabled="isDummy"></date-picker>
            </v-col>
            <template v-if="ordDiv==='sales'">
              <v-col cols="1" align="right">
                <div class=""><span>수주상태</span></div>
              </v-col>
              <v-col cols="1">
                <v-select
                  class=""
                  v-model="content[ordDiv].pendingOrd.ord_stus"
                  :items="$store.state.commonCode.sales_ord_stus"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  hide-details dense outlined
                  background-color="white"
                  :disabled="isDummy"
                  >
                </v-select>
              </v-col>
            </template>
            <template v-if="ordDiv==='purch'">
              <v-col cols="1" align="right">
                <div class=""><span>발주상태</span></div>
              </v-col>
              <v-col cols="1">
                <v-select
                  class=""
                  v-model="content[ordDiv].pendingOrd.ord_stus"
                  :items="$store.state.commonCode.purch_ord_stus"
                  item-value="cmn_cd"
                  item-text="cmn_cd_nm"
                  hide-details dense outlined
                  background-color="white"
                  :disabled="isDummy"
                  >
                </v-select>
              </v-col>
            </template>
            <v-col col="4">
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right">
              <div class=""><span style="color:red;">* </span><span>거래처명</span></div>
            </v-col>
            <v-col cols="2">
              <v-text-field
                class=""
                v-model="content[ordDiv].pendingOrd.custr_nm"
                hide-details dense outlined readonly
                background-color="grey lighten-2"
                append-icon="mdi-magnify"
                @click:append="onCustr"
                :disabled="isDummy"
              ></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>대표자</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.repr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>사업자번호</span></div>
            </v-col>
            <v-col cols="2">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.biz_rno" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>전화번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.tel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>팩스번호</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.fax_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" align="right">
              <div class=""><span>업체담당자</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.chrgr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>휴대전화</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.chrgr_cel_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>이메일</span></div>
            </v-col>
            <v-col cols="3">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.chrgr_email" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>주소</span></div>
            </v-col>
            <v-col cols="3">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.biz_plc_addr" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>세액구분</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <v-select
                class=""
                v-model="content[ordDiv].pendingOrd.tax_div"
                :items="$store.state.commonCode.tax_div"
                item-value="cmn_cd"
                item-text="cmn_cd_nm"
                hide-details
                outlined
                dense
                background-color="white"
                :disabled="isDummy"
              ></v-select>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>공급가액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="content[ordDiv].pendingOrd.suply_amt" :disabled="true"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>부가세액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="content[ordDiv].pendingOrd.vat_amt" :disabled="content[ordDiv].pendingOrd.tax_div != 'tax' || isDummy"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>합계금액</span></div>
            </v-col>
            <v-col cols="1" class="pb-7">
              <numeric-text-field v-model="sumAmt" :disabled="true"></numeric-text-field>
            </v-col>
            <v-col cols="1" class="pb-7" align="right">
              <div class=""><span>비고사항</span></div>
            </v-col>
            <v-col cols="3">
              <v-row align="center">
                <v-col cols="12">
                  <v-textarea
                    class=""
                    v-model="content[ordDiv].pendingOrd.rm"
                    hide-details
                    dense
                    outlined
                    no-resize
                    rows="2"
                    row-height="20"
                    background-color="white"
                    :disabled="isDummy"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense style="height:40px;" align="center">
            <v-col cols="1"  align="right"><div class=""><span>납품조건</span></div></v-col>
            <v-col cols="1">
              <v-text-field
                class=""
                v-model="content[ordDiv].pendingOrd.dlv_cond"
                hide-details
                dense
                outlined
                background-color="white"
                :disabled="isDummy"
              ></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>결제조건</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field class="" v-model="content[ordDiv].pendingOrd.pay_cond" hide-details dense outlined background-color="white" :disabled="isDummy"></v-text-field>
            </v-col>
            <v-col cols="1" align="right">
              <div class=""><span>유효기한</span></div>
            </v-col>
            <v-col cols="1">
              <v-text-field
                class=""
                v-model="content[ordDiv].pendingOrd.valid_limit"
                hide-details
                dense
                outlined
                background-color="white"
                :disabled="isDummy"
              ></v-text-field>
              <!-- <date-picker v-model="content[ordDiv].pendingOrd.valid_limit"></date-picker> -->
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="1" v-if="ordDiv==='sales'"><span>수주항목</span></v-col>
            <v-col cols="1" v-if="ordDiv==='purch'"><span>발주항목</span></v-col>
            <v-col cols="1"><span>조회수 : {{ content[ordDiv].pendingDtls.length }}</span></v-col>
            <v-col cols="1"><span>중량 : {{ dtlsWegt }}</span></v-col>
            <v-col cols="1"><span>수량 : {{ dtlsQty }}</span></v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" align="right">
              <v-btn small min-width="70px" class="pa-0 ma-0 mr-3 font-weight-bold" :disabled="isOrdDtlDeleteDisabled" @click="onOrdDtlDelete">항목삭제</v-btn>
              <v-btn small min-width="70px" color="blue darken-4" class="white--text font-weight-bold" :disabled="isDummy" @click="onOrdDtlAdd">항목추가</v-btn>
            </v-col>
            <v-col cols="12">
              <wj-flex-grid
                  :itemsSource="content[ordDiv].pendingDtls"
                  style="height: 180px;"
                  selectionMode="MultiRange"
                  :deferResizing="true"
                  :alternatingRowStep="0"
                  :showMarquee="true"
                  :allowDelete="true"
                  :allowSorting="false"
                  :initialized="onOrdDtlGridInit"
                  :itemsSourceChanged="onOrdDtlGridChanged"
                  headersVisibility="All"
                  :cellEditEnding="onOrdDtlEditEnding"
                  :cellEditEnded="onOrdDtlEditEnded"
              >
                <!-- <wj-flex-grid-column header="□" binding="select_yn" :width="50" dataType="Boolean" /> -->
                <wj-flex-grid-column header="품목" binding="" :width="50" :cellTemplate="createSitemTemplate"/>
                <wj-flex-grid-column header="* 품목코드" binding="sitem_cd" :width="90" :isRequired="true"/>
                <wj-flex-grid-column header="품목명" binding="sitem_nm" :width="150" :isReadOnly="true"/>
                <wj-flex-grid-column header="규격" binding="spec" :width="120" :isReadOnly="true"/>
                <wj-flex-grid-column header="단위" binding="unit" :width="60" :isReadOnly="true" :dataMap="sitemUnits"/>
                <wj-flex-grid-column header="가공범위" binding="mchng_range" :width="130" data-type="String" :dataMap="mchngRanges"/>
                <wj-flex-grid-column header="소재두께" binding="thck" :width="80" :isReadOnly="true" format="N1" align="right"/>
                <wj-flex-grid-column header="가공두께" binding="mchng_thck" :width="80"  format="N1" align="right"/>
                <wj-flex-grid-column header="로스" binding="thck_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                <wj-flex-grid-column header="가로/길이" binding="width_len" :width="80" format="N1" align="right"/>
                <wj-flex-grid-column header="로스" binding="width_len_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                <wj-flex-grid-column header="세로/Ø" binding="depth_phi" :width="80" format="N1" align="right"/>
                <wj-flex-grid-column header="로스" binding="depth_phi_loss" :width="80" :isReadOnly="true" format="N1" align="right"/>
                <wj-flex-grid-column header="수량" binding="qty" :width="50" format="N0" align="right"/>
                <wj-flex-grid-column header="중량" binding="wegt" :width="60" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="소재단가" binding="sitem_unit_price" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="소재비" binding="sitem_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="면가공비" binding="face_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="각가공비" binding="side_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="스퀘어가공비" binding="sqre_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="연마비" binding="grind_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="기타가공비" binding="etc_mchng_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="합계금액" binding="sum_amt" :width="100" :isReadOnly="true" format="N0" align="right"/>
                <wj-flex-grid-column header="비고" binding="rm" :width="200" />
                <wj-flex-grid-column header="도번" binding="item_nm" :width="200" />
                <wj-flex-grid-column header="품번" binding="custr_item_nm" :width="200" />
                <wj-flex-grid-column header="비중" binding="spgrv" :width="60" :isReadOnly="true"/>
                <wj-flex-grid-column header="면취" binding="chamf_mchng_yn" :width="50" dataType="Boolean" />
                <wj-flex-grid-column header="T맞춤" binding="thck_mchng_yn" :width="60" dataType="Boolean" />
              </wj-flex-grid>
            </v-col>
          </v-row>
          </v-card>
        </v-card>
      </div>
    </v-layout>
    <search-custr-popup
        v-if="popup.custr.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_salesPurchDiv="ordDiv"
        @close="popup.custr.onClose && popup.custr.onClose()"
        @selected="custrSno => popup.custr.onClose && popup.custr.onClose(custrSno)"
    ></search-custr-popup>
    <search-sitem-popup
        v-if="popup.sitem.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_custrSno="content[ordDiv].pendingOrd.custr_sno"
        @close="popup.sitem.onClose && popup.sitem.onClose()"
        @selected="(...param) => popup.sitem.onClose && popup.sitem.onClose(...param)"
    ></search-sitem-popup>

    <confirm-copy-popup
      v-if="isCopyOpened"
      :p_copyType="ordDiv == 'sales' ? 'salesOrd' : 'purchOrd'"
      :p_trgtDt="content[ordDiv].pendingOrd.ord_dt"
      :p_scdulDt="content[ordDiv].pendingOrd.out_ship_scdul_dt"
      @close="isCopyOpened = false"
      @copyConfirmed="copyConfirmed"
    ></confirm-copy-popup>

    <step-process-popup
      v-if="isStepOpened"
      :p_procType="stepProcType"
      :p_trgtDt="content[ordDiv].pendingOrd.ord_dt"
      @close="isStepOpened = false"
      @stepProcess="stepProcess"
    ></step-process-popup>
  </v-container>
</template>

<script>
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Selector} from '@grapecity/wijmo.grid.selector';
import datePicker from "@/components/common/datePicker.vue";
import searchCustrPopup from '@/components/common/searchCustrPopup.vue';
import SearchSitemPopup from '@/components/common/searchSitemPopup.vue';
import numericTextField from '@/components/common/numericTextField.vue';
import {SUB_TAB} from "@/common/constant";
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import ConfirmCopyPopup from '@/components/common/confirmCopyPopup.vue';
import StepProcessPopup from '@/components/common/stepProcessPopup.vue';

const DUMMY_ORD_SNO = '__dummy__';

const genEmptyOrd = ({
  ord_sno = null,
  ord_dt = moment().format('YYYY-MM-DD'),
  ord_div = 'sales',
}) => ({
  ord_sno: ord_sno,
  comp_sno: null,
  estm_sno: null,
  ord_no: null,
  ord_nm: '',
  ord_dt: ord_dt,
  ord_div: ord_div,
  out_ship_scdul_dt: moment().format('YYYY-MM-DD'),
  ord_stus: 'yet',
  valid_limit: null,
  pay_cond: null,
  dlv_cond: null,
  tax_div: 'tax',
  suply_amt: null,
  vat_amt: null,
  sum_amt: null,
  rm: '',
  custr_sno: null,
  custr_nm: null,
  repr_nm: null,
  biz_rno: null,
  tel_no: null,
  fax_no: null,
  biz_plc_addr: null,
  chrgr_nm: null,
  chrgr_cel_no: null,
  chrgr_email: null,
});

const genEmptyOrdDtlPrice = () => ({
  sitem_unit_price: null,
  sitem_amt: null,
  wegt: null,
  face_mchng_amt: null,
  side_mchng_amt: null,
  sqre_mchng_amt: null,
  grind_mchng_amt: null,
  etc_mchng_amt: null,
  sum_amt: null,
});

const genEmptyOrdDtl = () => ({
  uuid: uuidv4(),
  odr: null,
  select_yn: null,
  sitem_cd: null,
  sitem_nm: null,
  spec: null,
  unit: null,
  mchng_range: null,
  thck: null,
  mchng_thck: null,
  thck_loss: null,
  width_len: null,
  width_len_loss: null,
  depth_phi: null,
  depth_phi_loss: null,
  qty: null,
  spgrv: null,
  ...genEmptyOrdDtlPrice(),
  rm: null,
  item_nm: null,
  custr_item_nm: null,
  chamf_mchng_yn: null,
  thck_mchng_yn: null,
});

export default {
  components: {datePicker, searchCustrPopup, SearchSitemPopup, numericTextField, ConfirmCopyPopup, StepProcessPopup},
  created() {
    this.$store.dispatch('refreshCommonCode', 'ord_div');
    this.$store.dispatch('refreshCommonCode', 'purch_ord_stus');
    this.$store.dispatch('refreshCommonCode', 'sales_ord_stus');
    this.$store.dispatch('refreshCommonCode', 'tax_div');
    this.$store.dispatch('refreshCommonCode', 'sitem_unit');
    this.$store.dispatch('refreshCommonCode', 'mchng_range');
    this.$store.dispatch('refreshCommonCode', 'mchng_price_div');
    this.$store.dispatch('refreshSitems');
  },
  data() {
    return {
      ordDiv: 'sales',

      selector: null,   // 그리드 체크박스

      ordGrid: null,
      ordDtlGrid: null,

      isDtlGridChanging: false,

      content: {
        sales: {
          ordStus: 'all',
          srchDt: moment().format('YYYY-MM-DD'),
          pendingWord: '',
          searchWord: '',
          ords: [],
          ord: null,
          pendingOrd: genEmptyOrd({ord_sno: DUMMY_ORD_SNO}),
          restoreOrd: null,
          ordDtls: [],
          pendingDtls: [],
          restoreDtls: null,
          restoreDtl: null,
        },
        purch: {
          ordStus: 'all',
          srchDt: moment().format('YYYY-MM-DD'),
          pendingWord: '',
          searchWord: '',
          ords: [],
          ord: null,
          pendingOrd: genEmptyOrd({ord_sno: DUMMY_ORD_SNO}),
          restoreOrd: null,
          ordDtls: [],
          pendingDtls: [],
          restoreDtls: null,
          restoreDtl: null,
        },
      },

      popup: {
        custr: {
          isOpened: false,
          onClose: null,
        },
        sitem: {
          isOpened: false,
          onClose: null,
        },
      },

      isCopyOpened: false,  // 복사 팝업
      isStepOpened: false,  // 입출고 처리 팝업
      stepProcType: "sales", // 입출고 처리 유형

      createSitemTemplate: CellMaker.makeButton({
        text: '선택',
        click: (e, ctx) => this.onSitemClicked(e, ctx),
      }),

    };
  },
  methods:{
    async loadOrds() {
      let params = new FormData();
      const target = this.content[this.ordDiv];
      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", target.srchDt);
      try {
        const res = await this.$http.post('/sales/getOrdList', params);
        const ords = res.data.ordList.map(ord => ({
          ...ord,
          ord_sno: ord.ord_sno != null && `${ord.ord_sno}`,
          fax: ord.fax_yn === 'Y',
          email: ord.email_yn === 'Y',
        }));

        const ordDivs = this.$store.state.commonCode.ord_div.map(({cmn_cd}) => cmn_cd);
        ordDivs.forEach(div => {
          const target = this.content[div];
          target.ords = ords.filter(({ord_div}) => ord_div === div);
        });

      } catch (e) {
        console.log(`Error on function loadOrds: ${e}`);
      }
    },
    async loadOrd(ordSno) {
      const target = this.content[this.ordDiv];

      if (ordSno === DUMMY_ORD_SNO) {
        const ord = genEmptyOrd({
          ord_sno: DUMMY_ORD_SNO,
          ord_div: this.ordDiv,
          ord_dt: this.srchDt,
        });
        const ordDtls = [];
        target.ord = JSON.parse(JSON.stringify(ord));
        target.pendingOrd = JSON.parse(JSON.stringify(ord));
        target.restoreOrd = null;
        target.ordDtls = JSON.parse(JSON.stringify(ordDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(ordDtls));
        target.restoreDtls = null;
        return;
      }

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      params.append("comp_sno", compSno);
      params.append("ord_sno", ordSno);
      try {
        // console.log("loadOrd");
        this.isDtlGridChanging = true;

        const res = await this.$http.post('/sales/getOrd', params);
        const ord = {
          ...res.data.ord,
          ord_sno: res.data.ord.ord_sno != null && `${res.data.ord.ord_sno}`,
          vat_amt: res.data.ord.vat_amt + '',
        };
        const ordDtls = res.data.ordDtlList.map(dtl => ({
          ...dtl,
          uuid: uuidv4(),
          select_yn: false,
          spec: this.makeOrdDtlSpec(dtl),
          chamf_mchng_yn: dtl.chamf_mchng_yn === 'Y',
          thck_mchng_yn: dtl.thck_mchng_yn === 'Y',
        }));

        target.ord = JSON.parse(JSON.stringify(ord));
        target.pendingOrd = JSON.parse(JSON.stringify(ord));
        target.restoreOrd = null;

        target.ordDtls = JSON.parse(JSON.stringify(ordDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(ordDtls));
        target.restoreDtls = null;
        
        await Promise.all(ordDtls.filter(dtl => dtl.sitem_cd).map(({sitem_cd}) =>
            this.$store.dispatch('cacheSitemUnitPrice', {
              sitemCd: sitem_cd,
              custrSno: ord.custr_sno,
            })
        ));

      } catch (e) {
        console.log(`Error on function loadOrd: ${e}`);
      }
    },
    onOrdGridInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        if (this.isOnPendingOrd) return;

        const target = this.content[this.ordDiv];
        const ordSno = flex.getCellData(ht.row, 0);
        if (ordSno !== target.pendingOrd.ord_sno)
          await this.loadOrd(ordSno);
      });
      this.ordGrid = flex;
    },
    onOrdGridChanging() {
    },
    onOrdGridChanged() {
    },
    onOrdDtlGridInit(flex) {
      this.ordDtlGrid = flex;

      this.selector = new Selector(flex, {
        itemChecked: () => {
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
        }
      });

      flex.selectionMode = "MultiRange";
    },
    onOrdDtlGridChanged() {
      // console.log("onOrdDtlGridChanged", this.content[this.ordDiv].pendingOrd);
      if(this.content[this.ordDiv].pendingOrd.suply_amt != null) {
        this.isDtlGridChanging = false;
      }
    },
    onOrdDtlEditEnding(flex, event) {
      // console.log("onOrdDtlEditEnding");
      const target = this.content[this.ordDiv];
      const pendingDtl = target.pendingDtls[event.row];
      target.restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
    },
    async onOrdDtlEditEnded(flex, event) {
      // console.log("onOrdDtlEditEnded");
      const target = this.content[this.ordDiv];
      const pendingOrd = target.pendingOrd;
      const pendingDtl = target.pendingDtls[event.row];
      const restoreDtl = target.restoreDtl;
      target.restoreDtl = null;
      const column = flex.columns[event.col];

      await this.refreshOrdDtl(column.binding, pendingOrd, pendingDtl, restoreDtl);
    },
    async refreshOrdDtl(binding, pendingOrd, pendingDtl, restoreDtl) {
      if ('select_yn' !== binding && !pendingOrd.custr_sno) {
        alert('거래처를 선택하세요.');
        Object.assign(pendingDtl, restoreDtl);
        return this.ordDtlGrid.invalidate();
      }
      if ('sitem_cd' === binding) {
        const sitem = await this.makeOrdDtlSitem(pendingOrd, pendingDtl);
        if (!sitem) {
          alert('품목이 존재하지 않습니다.');
          Object.assign(pendingDtl, restoreDtl);
          return this.ordDtlGrid.invalidate();
        }
        Object.assign(pendingDtl, sitem);
        pendingDtl.width_len = null;
        pendingDtl.depth_phi = null;
        pendingDtl.spec = this.makeOrdDtlSpec(pendingDtl);
        const loss = await this.makeOrdDtlLoss(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, loss);
      }
      if ('mchng_range' === binding) {
        const price = await this.makeOrdDtlPrice(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('width_len' === binding) {
        pendingDtl.spec = this.makeOrdDtlSpec(pendingDtl);
        const loss = await this.makeOrdDtlLoss(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, loss);
        const price = await this.makeOrdDtlPrice(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('depth_phi' === binding) {
        pendingDtl.spec = this.makeOrdDtlSpec(pendingDtl);
        const price = await this.makeOrdDtlPrice(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      if ('qty' === binding) {
        const price = await this.makeOrdDtlPrice(pendingOrd, pendingDtl);
        Object.assign(pendingDtl, price);
      }
      this.ordDtlGrid.invalidate();
      // target.pendingDtls = target.pendingDtls.map((dtl, index) => index !== event.row ? dtl : pendingDtl)
    },
    async onSitemClicked(event, context) {
      const target = this.content[this.ordDiv];
      const pendingOrd = target.pendingOrd;
      const pendingDtl = target.pendingDtls[context.row.index];

      if (!pendingOrd.custr_sno)
        return alert('거래처가 설정되지 않았습니다.');

      const [sitemCd] = await new Promise(r => this.popup.sitem = {
        isOpened: true,
        onClose: (...param) => r(param)
      });
      this.popup.sitem.isOpened = false;
      if (!sitemCd) return;

      const restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
      pendingDtl.sitem_cd = sitemCd;
      await this.refreshOrdDtl('sitem_cd', pendingOrd, pendingDtl, restoreDtl);
    },
    async makeOrdDtlSitem(ord, ordDtl) {
      const [,, sitem] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: ordDtl.sitem_cd,
        custrSno: ord.custr_sno,
      });
      if (!sitem) return;
      return {
        sitem_cd: sitem.sitem_cd,
        sitem_nm: sitem.sitem_nm,
        unit: sitem.unit,
        thck: sitem.thck,
        mchng_thck: sitem.thck,
        spgrv: sitem.spgrv,
      };
    },
    makeOrdDtlSpec(ordDtl) {
      return `${ordDtl.thck || '-'}T*${ordDtl.width_len || '-'}*${ordDtl.depth_phi || '-'}`;
    },
    async makeOrdDtlLoss(ord, ordDtl) {
      if (!ordDtl.sitem_cd) return {};
      const [,,, sitemPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: ordDtl.sitem_cd,
        custrSno: ord.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width - l.width);
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width <= ordDtl.width_len);
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice || {};
      ordDtl.thck_loss = itemPrice.thck_loss;
      ordDtl.width_len_loss = itemPrice.width_len_loss;
      ordDtl.depth_phi_loss = itemPrice.depth_phi_loss;

      return {
        thck_loss: itemPrice.thck_loss,
        width_len_loss: itemPrice.width_len_loss,
        depth_phi_loss: itemPrice.depth_phi_loss,
      };
    },
    async makeOrdDtlPrice(ord, ordDtl) {
      if (!ordDtl.sitem_cd || !ordDtl.spgrv || !ordDtl.width_len || !ordDtl.depth_phi || !ordDtl.qty)
        return genEmptyOrdDtlPrice();
      const [,,, sitemPrices, mchngPrices] = await this.$store.dispatch('cacheSitemUnitPrice', {
        sitemCd: ordDtl.sitem_cd,
        custrSno: ord.custr_sno,
      });

      const sortedItemPrices = sitemPrices.sort((l, r) => r.width < l.width);
      const retrievedItemPrice = sortedItemPrices.find(({width}) => width < ordDtl.width_len);
      const [firstItemPrice] = [...sortedItemPrices].reverse();
      const itemPrice = retrievedItemPrice || firstItemPrice;
      if (!itemPrice) return genEmptyOrdDtlPrice();

      const width_len = Math.floor(Number(ordDtl.width_len) * 100) / 100;
      const width_len_loss = Math.floor(Number(ordDtl.width_len_loss) * 100) / 100;
      const depth_phi = Math.floor(Number(ordDtl.depth_phi) * 100) / 100;
      const depth_phi_loss = Math.floor(Number(ordDtl.depth_phi_loss) * 100) / 100;
      const thck = Math.floor(Number(ordDtl.thck) * 100) / 100;
      const thck_loss = Math.floor(Number(ordDtl.thck_loss) * 100) / 100;

      const widthSum = width_len + width_len_loss; // 가로 mm
      const depthPhiSum = depth_phi + depth_phi_loss; // 세로 mm
      const thckSum = thck + thck_loss; // 두께 mm
      const face = widthSum * depthPhiSum; // 윗면 mm2
      const side = depthPhiSum * thckSum; // 옆면 mm2
      const front = widthSum * thckSum; // 옆면 mm2

      // 소재단가 = 가로, 세로 단가
      const sitem_unit_price = ordDtl.depth_phi < 1000 ? itemPrice.price1 : itemPrice.price2;
      // 중량 = (두께mm + 두께로스mm) * (가로mm + 가로로스mm) * (세로mm + 세로로스mm) * 수량 * 비중 / 1_000_000
      const wegtCalc = widthSum /* mm */ * depthPhiSum /* mm */ * thckSum /* mm */ *
          ordDtl.qty * ordDtl.spgrv / 1_000_000; // 10 cm2 == 1kg
      const _wegt = Math.max(wegtCalc, itemPrice.min_wegt);
      const wegt = Math.floor(_wegt * 100_000) / 100_000;
      // 품목비 = 품목단가 * 중량
      const sitem_amt = Math.floor(sitem_unit_price * wegt);

      const [
          face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt
      ] = (() => {
        // if (!ordDtl.mchng_range) return [];
        if (!ordDtl.mchng_range) return [null, null, null, null, null, sitem_amt];

        // 가공단가
        const mchngMin = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'min');
        const mchngCalc = mchngPrices.find(({mchng_price_div}) => mchng_price_div === 'calc');

        const mchngItems = ordDtl.mchng_range.split('_');
        // 면 가공비
        const face_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f1': face,
          'f2': face * 2,
          // 'f6': face * 2 + front * 2 + side * 2
        }[cur] || 0), 0) / 100;
        const _face_mchng_amt = 0 < face_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.face_price * face_mchng_unit_calc, mchngMin && mchngMin.face_price || 0) * ordDtl.qty
            : 0;
        const face_mchng_amt = Math.floor(_face_mchng_amt);
        // 각 가공비
        const side_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          's2': front * 2,
          's4': front * 2 + side * 2,
        }[cur] || 0), 0) / 100;
        const _side_mchng_amt = 0 < side_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.side_price * side_mchng_unit_calc, mchngMin && mchngMin.side_price || 0) * ordDtl.qty
            : 0;
        const side_mchng_amt = Math.floor(_side_mchng_amt);
        // 스퀘어 가공비
        const sqre_mchng_unit_calc = mchngItems.reduce((acc, cur) => acc + ({
          'f6': face * 2 + front * 2 + side * 2
        }[cur] || 0), 0) / 100;
        const _sqre_mchng_amt = 0 < sqre_mchng_unit_calc
            ? Math.max(mchngCalc && mchngCalc.sqre_price * sqre_mchng_unit_calc, mchngMin && mchngMin.sqre_price || 0) * ordDtl.qty
            : 0;
        const sqre_mchng_amt = Math.floor(_sqre_mchng_amt);
        // 연마비
        const _grind_mchng_amt = mchngItems.reduce((acc, cur) => acc + ({
          'g': Math.max(mchngCalc && mchngCalc.grind_price, mchngMin && mchngMin.grind_price || 0)
        }[cur] || 0), 0) * ordDtl.qty;
        const grind_mchng_amt = Math.floor(_grind_mchng_amt);
        // 기타 가공비
        const etc_mchng_amt = Math.max(mchngCalc && mchngCalc.etc_price, mchngMin && mchngMin.etc_price || 0) * ordDtl.qty;
        // 합계
        const sum_amt = sitem_amt + face_mchng_amt + side_mchng_amt + sqre_mchng_amt + grind_mchng_amt + etc_mchng_amt;

        return [face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt, sum_amt];
      })();

      return {
        sitem_unit_price, wegt, sitem_amt,
        face_mchng_amt, side_mchng_amt, sqre_mchng_amt, grind_mchng_amt, etc_mchng_amt,
        sum_amt,
      };
    },
    onCreateOrd() {
      const isOnPendingOrd = this.isOnPendingOrd;
      if (isOnPendingOrd && !confirm('저장되지 않은 데이터가 있습니다. 새 주문을 추가하시겠습니까?'))
        return;

      const target = this.content[this.ordDiv];
      const canRestore = target.ord && !!target.ord.ord_sno;
      const restoreOrd = canRestore ? JSON.parse(JSON.stringify(target.ord)) : null;
      const restoreDtls = canRestore ? JSON.parse(JSON.stringify(target.ordDtls)) : null;

      const ord = genEmptyOrd({
        ord_dt: this.srchDt,
        ord_div: this.ordDiv,
      });
      target.ord = JSON.parse(JSON.stringify(ord));
      target.pendingOrd = JSON.parse(JSON.stringify(ord));
      target.restoreOrd = restoreOrd;

      target.ordDtls = [];
      target.pendingDtls = [];
      target.restoreDtls = restoreDtls;
    },
    verifyOrd() {
      const target = this.content[this.ordDiv];
      const pendingOrd = target.pendingOrd;
      if (!pendingOrd.ord_dt) {
        const message = pendingOrd.ord_div === 'sales' ? '수주일자는 필수 입력값입니다.'
            : pendingOrd.ord_div === 'purch' ? '발주일자는 필수 입력값입니다.'
                : '주문일자는 필수 입력값입니다.'
        alert(message);
        return false;
      }
      // if (!pendingOrd.out_ship_scdul_dt) {
      //   const message = pendingOrd.ord_div === 'sales' ? '출고예정일은 필수 입력값입니다.'
      //       : pendingOrd.ord_div === 'purch' ? '입고예정일은 필수 입력값입니다.'
      //           : 'UNKNOWN 필수 입력값입니다.'
      //   alert(message);
      //   return false;
      // }
      // if (!pendingOrd.ord_stus) {
      //   const message = pendingOrd.ord_div === 'sales' ? '수주상태는 필수 입력값입니다.'
      //       : pendingOrd.ord_div === 'purch' ? '발주상태는 필수 입력값입니다.'
      //           : '상태는 필수 입력값입니다.'
      //   alert(message);
      //   return false;
      // }
      if (!pendingOrd.custr_sno) {
        alert('거래처는 필수 입력값입니다.');
        return false;
      }
      // if (!pendingOrd.tax_div) {
      //   alert('세액구분은 필수 입력값입니다.');
      //   return false;
      // }
      return true;
    },
    async onOrdSave() {
      if (!this.verifyOrd()) return;

      const params = new URLSearchParams();
      const target = this.content[this.ordDiv];
      params.append('new_yn', !target.pendingOrd.ord_sno ? 'Y' : 'N');
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('comp_sno', this.$store.state.session.compSno);
      params.append('custr_sno', target.pendingOrd.custr_sno);
      params.append('ord_sno', target.pendingOrd.ord_sno);
      params.append('ord_div', target.pendingOrd.ord_div);
      params.append('ord_dt', target.pendingOrd.ord_dt);
      params.append('out_ship_scdul_dt', target.pendingOrd.out_ship_scdul_dt);
      params.append('ord_stus', target.pendingOrd.ord_stus);
      params.append('tax_div', target.pendingOrd.tax_div);

      if (null != target.pendingOrd.valid_limit) params.append('valid_limit', target.pendingOrd.valid_limit);
      if (null != target.pendingOrd.pay_cond) params.append('pay_cond', target.pendingOrd.pay_cond);
      if (null != target.pendingOrd.dlv_cond) params.append('dlv_cond', target.pendingOrd.dlv_cond);
      params.append('suply_amt', `${this.suplyAmt}`);
      params.append('vat_amt', `${this.vatAmt}`);
      params.append('sum_amt', `${this.sumAmt}`);
      if (null != target.pendingOrd.rm) params.append('rm', target.pendingOrd.rm);

      console.log("===== onOrdSave > suply_amt, vat_amt, sum_amt : ", this.suplyAmt, this.vatAmt, this.sumAmt);

      const ordDtls = target.pendingDtls.filter(({sitem_cd}) => sitem_cd).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('ordDtlList', JSON.stringify(ordDtls));
      const deletedDtls = target.ordDtls.filter(({uuid}) => target.pendingDtls.every(pending => pending.uuid !== uuid)).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
        chamf_mchng_yn: dtl.chamf_mchng_yn ? 'Y' : 'N',
        thck_mchng_yn: dtl.thck_mchng_yn ? 'Y' : 'N',
      }));
      params.append('deletedDtlList', JSON.stringify(deletedDtls));

      try {
        const res = await this.$http.post('/sales/upsertOrd', params);
        if (res.data.code === 0)
          await this.loadOrd(res.data.ord_sno);
        await this.loadOrds();

      } catch (e) {
        console.log(`Error on function onOrdSave: ${e}`);
      }
    },
    async onOrdDelete() {
      if (!confirm('삭제하시겠습니까?')) return;

      const target = this.content[this.ordDiv];
      const params = new FormData();
      params.append('ord_sno', target.pendingOrd.ord_sno);

      await this.$http.post('/sales/deleteOrd', params);
      alert('삭제되었습니다.');

      target.pendingOrd = genEmptyOrd({
        ord_sno: DUMMY_ORD_SNO,
        ord_div: this.ordDiv,
        ord_dt: this.srchDt,
      });

      await this.loadOrds();
    },
    onOrdCancel() {
      if (this.isOnPendingOrd && !confirm('편집을 취소하시겠습니까?'))
        return;

      this.isDtlGridChanging = true;
      
      const target = this.content[this.ordDiv];

      const restoreOrd = target.restoreOrd || target.ord;
      const restoreDtls = target.restoreDtls || target.ordDtls;
      target.ord = JSON.parse(JSON.stringify(restoreOrd));
      target.ordDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.pendingOrd = JSON.parse(JSON.stringify(restoreOrd));
      target.pendingDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.restoreOrd = null;
      target.restoreDtls = null;
    },
    async onCustr() {
      const custrSno = await new Promise(r => {
        this.popup.custr = {
          isOpened: true, onClose: r
        }
      });
      if (custrSno) {
        const params = new FormData();
        params.append("custr_sno", custrSno);

        try {
          const res = await this.$http.post('/base/getCustrDetail', params)
          const custr = res.data.custr;

          const target = this.content[this.ordDiv];
          target.pendingOrd.custr_sno = custr.custr_sno;
          target.pendingOrd.custr_nm = custr.custr_nm;
          target.pendingOrd.repr_nm = custr.repr_nm;
          target.pendingOrd.tel_no = custr.tel_no;
          target.pendingOrd.fax_no = custr.fax_no;
          target.pendingOrd.biz_rno = custr.biz_rno;
          target.pendingOrd.biz_plc_addr = custr.biz_plc_addr;
          target.pendingOrd.chrgr_nm = custr.chrgr_nm;
          target.pendingOrd.chrgr_cel_no = custr.chrgr_cel_no;
          target.pendingOrd.chrgr_email = custr.chrgr_email;

        } catch (e) {
          console.log(`Error on function onCustr: ${e}`);
        }
      }
      this.popup.custr = {isOpened: false};
    },
    onOrdDtlAdd() {
      const target = this.content[this.ordDiv];
      const nextOdr = target.pendingDtls.reduce((acc, {odr}) => odr < acc ? acc : odr, 0) + 1;
      target.pendingDtls = [
        ...target.pendingDtls, {
          ...genEmptyOrdDtl(),
          uuid: uuidv4(),
          new_yn: true,
          sitem_cd: null,
          odr: nextOdr,
          select_yn: false,
          chamf_mchng_yn: false,
          thck_mchng_yn: false,
        }
      ];
    },
    onOrdDtlDelete() {
      const target = this.content[this.ordDiv];
      target.pendingDtls = target.pendingDtls.filter(({select_yn}) => !select_yn);
    },
    // 복사 버튼
    onOrdCopy() {
      this.isCopyOpened = true;
    },
    copyConfirmed(trgtDt, scdulDt) {
      console.log("copyConfirmed : ", trgtDt, scdulDt);
      this.isCopyOpened = false;

      this.copyOrd(trgtDt, scdulDt);
    },
    async copyOrd(trgtDt, scdulDt) {
      const params = new FormData();
      const target = this.content[this.ordDiv];

      params.append('user_sno', this.$store.state.session.userSno);
      params.append("ord_sno", target.pendingOrd.ord_sno);
      params.append("trgt_dt", trgtDt);
      params.append("scdul_dt", scdulDt);

      try {
        const res = await this.$http.post('/sales/copyOrd', params);
        if (res.data.code === 0) {
          alert("복사되었습니다.");
          // await this.loadEstm(res.data.estm_sno);
          this.loadOrds();
        }
      } catch (e) {
        console.log(`Error on function copyOrd: ${e}`);
      }
    },
    // 입출고 처리
    onOrdStep(procType) {
      this.stepProcType = procType;
      this.isStepOpened = true;
    },
    stepProcess(procType, trgtDt, scdulDt, payMthd) {
      console.log("stepProcess : ", procType, trgtDt, scdulDt, payMthd);
      this.isStepOpened = false;

      this.stepProcessOrd(procType, trgtDt, scdulDt, payMthd);
    },
    async stepProcessOrd(procType, trgtDt, scdulDt, payMthd) {
      const params = new FormData();
      const target = this.content[this.ordDiv];

      params.append('user_sno', this.$store.state.session.userSno);
      params.append("ord_sno", target.pendingOrd.ord_sno);
      params.append("trgt_dt", trgtDt);
      params.append("pay_mthd", payMthd);

      try {
        const res = await this.$http.post("/sales/stepProcOrdToSales", params);
        if (res.data.code === 0) {
          alert("처리되었습니다.");
          // await this.loadEstm(res.data.estm_sno);
          this.loadOrds();
        }
      } catch (e) {
        console.log(`Error on function stepProcessOrd: ${e}`);
      }
    },
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.order.code);
    },
    inShipStusFilterCombo() {
      return [
        {cls_cd: 'purch_ord_stus', cmn_cd: 'all', cmn_cd_nm: '전체', disp_odr: '0'},
        ...this.$store.state.commonCode.purch_ord_stus
      ];
    },
    outShipStusFilterCombo() {
      return [
        {cls_cd: 'sales_ord_stus', cmn_cd: 'all', cmn_cd_nm: '전체', disp_odr: '0'},
        ...this.$store.state.commonCode.sales_ord_stus
      ];
    },
    filteredOrds() {
      const target = this.content[this.ordDiv];
      return target.ords.filter(({custr_nm}) =>
          !target.searchWord || target.searchWord.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(target.searchWord.toUpperCase())
      );
    },
    isOrdCancellationDisabled() {
      if (this.isOnPendingOrd) return false

      const target = this.content[this.ordDiv];
      if  (target.restoreOrd) return false

      return true;
    },
    isOrdSaveDisabled() {
      const target = this.content[this.ordDiv];
      if (!target.pendingOrd.ord_sno) return false;
      if (this.isOnPendingOrd) return false

      return true;
    },
    isOnPendingOrd() {
      const target = this.content[this.ordDiv];
      return !this.isDummy && 
          ( JSON.stringify(target.ord) !== JSON.stringify(target.pendingOrd) ||
            JSON.stringify(target.ordDtls.map(dtl => ({...dtl, select_yn: false}))) !== JSON.stringify(target.pendingDtls.map(dtl => ({...dtl, select_yn: false}))) );
    },
    isDummy() {
      const target = this.content[this.ordDiv];
      return target.pendingOrd && target.pendingOrd.ord_sno == DUMMY_ORD_SNO ? true : false;
    },
    dtlsWegt() {
      const target = this.content[this.ordDiv];
      return Math.round(target.pendingDtls.reduce((acc, {wegt}) => wegt ? acc + wegt : acc, 0), 2);
    },
    dtlsQty() {
      const target = this.content[this.ordDiv];
      return target.pendingDtls.reduce((acc, {qty}) => qty ? acc + qty : acc, 0);
    },
    sitemUnits() {
      return new wjGrid.DataMap(this.$store.state.commonCode.sitem_unit, 'cmn_cd', 'cmn_cd_nm')
    },
    mchngRanges() {
      return new wjGrid.DataMap(this.$store.state.commonCode.mchng_range, 'cmn_cd', 'cmn_cd_nm')
    },
    // Dtl 그리드에서 계산된 값
    _suplyAmt() {
      const target = this.content[this.ordDiv];
      const amt = target.pendingDtls.reduce((acc, {sum_amt}) => acc + (null != sum_amt ? Number(sum_amt) : 0), 0);
      return Math.floor(amt);
    },
    _vatAmt() {
      const target = this.content[this.ordDiv];
      const amt = target.pendingOrd.tax_div === 'tax' ? Number(this.suplyAmt) / 10 : 0;
      return Math.floor(amt);
    },
    // 화면에 표시되는 값
    suplyAmt() {
      return Number(this.content[this.ordDiv].pendingOrd.suply_amt);
    },
    vatAmt() {
      return Number(this.content[this.ordDiv].pendingOrd.vat_amt);
    },
    sumAmt() {
      return Number(this.suplyAmt) + Number(this.vatAmt);
    },
    isOrdDtlDeleteDisabled() {
      const target = this.content[this.ordDiv];
      return !target.pendingDtls.some(({select_yn}) => select_yn);
    },
    searchDate() {
      const ordDivs = this.$store.state.commonCode.ord_div.map(({cmn_cd}) => cmn_cd);
      return ordDivs.map(div => ({div, srchDt: this.content[div].srchDt}));
    },
  },
  watch: {
    async filteredOrds(newValue) {
      const [first] = newValue;
      const sno = first && first.ord_sno || DUMMY_ORD_SNO;
      await this.loadOrd(sno);
    },
    async searchDate() {
      await this.loadOrds();
    },
    _suplyAmt: {
      immediate: true,
      handler: function () {
        if(this.isDtlGridChanging) {
          return;
        }
        this.content[this.ordDiv].pendingOrd.suply_amt = this._suplyAmt;
      },
    },
    _vatAmt: {
      immediate: true,
      handler: function () {
        if(this.isDtlGridChanging) {
          return;
        }
        this.content[this.ordDiv].pendingOrd.vat_amt = this._vatAmt;
      },
    }
  }
}
</script>

<style scoped>
/* .wj-colheaders .wj-header{
  background: #97f5ff;
} */
</style>
