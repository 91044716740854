import * as wijmo from '@grapecity/wijmo';
// import * as wjGridFilter from '@grapecity/wijmo.grid.filter'; // flexgrid 헤더 필터 추가

export default{
  install(Vue){
    Vue.prototype.$erroCdMsg = function(code){
      let msg = "";
      switch (code) 
      {
        case -9001:
          // alert('중복');
          msg = "중복된 아이디 입니다.";
          break;
        case -9002:
          // alert('중복');
          msg = "현재 비밀번호가 일치하지 않습니다.";
          break;
        case -9003:
          msg = "사용 중인 코드입니다. 삭제할 수 없습니다.";
          break;
        case -9005:
          msg = "존재하지 않는 계정입니다.";
          break;
    
        default:
          break;
      }
      return msg;
    }
    Vue.prototype.$getCmnCdList = async function(classCode) {
      const formData = new FormData();
      formData.append("cls_cd", classCode);

      try {
        const res = await this.$http.post("/common/getCmnCdListByClsCd", formData);
        const filtered = res.data.result.filter(e => e.cmn_cd != '00');
        return filtered;
      } catch (error) {
        console.log(error);
      }
      return [];
    }
    Vue.prototype.$includeHangle = function(value) {
			const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$includeAlphaChar = function(value) {
			const regex = /[a-zA-Z]/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$validateId = function(value) {
			const regex = /^[A-Za-z0-9]*$/;
			if (regex.test(value) === true) {
        return false;
      }
			return true;
		}

		Vue.prototype.$validateEmail = function(value) {
			const reg_email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

			if(!reg_email.test(value)) {      
				return false;         
			}
			return true;         
		}

    Vue.prototype.$setDateCalendarFormat = function(str) {
      return str.substring(0, 4) + "-" + str.substring(4, 6) + "-" + str.substring(6);
    }

    Vue.prototype.$setNumberComma = function(value) {
      // console.log("setNumberComma called.", value);
      if(value == null) {
        return null;
      }

      // split(.) -> join
      let splitVal = String(value).split('.');
      splitVal[0] = splitVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if(splitVal[1] == "") {
        return splitVal[0];
      } else {
        return splitVal.join(".");
      }
    },

    // Vue.prototype.$findZipcode = async function() {
    //   const res = {};
    //   new window.daum.Postcode({
		// 		oncomplete: (data) => {
    //       res.zpcd = data.zonecode;
    //       res.addr = data.roadAddress;
		// 		},
		// 	}).open();

    //   return res;
    // }

    // flexgrid 상태 저장
    Vue.prototype.$saveFlexGridState = function(flexgrid /*, showFilter*/) {
      // this.$store.state.gridFilter = new wjGridFilter.FlexGridFilter(flexgrid);  // flexgrid 헤더 필터 추가
      // console.log("saveFlexGridState > gridFilter:", this.$store.state.gridFilter);

      // if(showFilter != null && showFilter == 'N') {
      //   this.$store.state.gridFilter.showFilterIcons = false;
      // }

      let state = {
        columns: flexgrid.columnLayout,
        // filterDefinition: this.$store.state.gridFilter.filterDefinition,    // flexgrid 헤더 필터 추가
        sortDescriptions: flexgrid.collectionView.sortDescriptions.map(function (sortDesc) {
          return { property: sortDesc.property, ascending: sortDesc.ascending };
        })
      }
      this.$store.state.flexGridState = JSON.stringify(state);
      // console.log("$saveFlexGridState > this.$store.state.flexGridState:", this.$store.state.flexGridState);
    }

    // flexgrid 상태 복원
    Vue.prototype.$restoreFlexGridState = function(flexgrid) {
      let json = this.$store.state.flexGridState;
      // console.log("$restoreFlexGridState > this.$store.state.flexGridState:", this.$store.state.flexGridState);
      if (json) {
        let state = JSON.parse(json);
        
        flexgrid.columnLayout = state.columns;    // restore column layout (order/width)        
        // this.$store.state.gridFilter.filterDefinition = state.filterDefinition;   // restore filter definitions        // flexgrid 헤더 필터 추가
        let view = flexgrid.collectionView;       // restore sort state

        view.deferUpdate(function () {
          view.sortDescriptions.clear();
          for (let i = 0; i < state.sortDescriptions.length; i++) {
            let sortDesc = state.sortDescriptions[i];
            view.sortDescriptions.push(new wijmo.SortDescription(sortDesc.property, sortDesc.ascending));
          }
        });
      }
    }

  }
}
