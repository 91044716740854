<template>
  <!--[[[[[[[[[[[[[[[        복사 확인 모달        ]]]]]]]]]]]]]]]-->
  <v-dialog
    v-model="showDialog"
    width="350px"
    height="350px"
    @keydown.esc="close"
    @click:outside="close"
    >
    <v-container>
      <v-card style="text-align:center;" class="pb-3">
        <v-toolbar 
            color="indigo darken-2"
            class="elevation-0"
            height="50px">
          <v-toolbar-title
            class="white--text">
            {{ copyTypeNm }} 복사
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="pa-3" style="overflow:auto;">
          <div class="pa-3"><span>아래의 내용으로 복사하시겠습니까?</span></div>
          <v-row dense class="" align="center">
            <v-col cols="4" align="right"><div class=""><span>{{ copyTypeNm }}일자</span></div></v-col>
            <v-col cols="7">
              <date-picker v-model="trgtDt" :is-checked="false"></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="" align="center" v-if="copyType != 'salesPurch'">
            <v-col cols="4" align="right"><div class=""><span>{{ scdulTypeNm }}예정일</span></div></v-col>
            <v-col cols="7">
              <date-picker v-model="scdulDt" :is-checked="false"></date-picker>
            </v-col>
          </v-row>
          <v-row dense class="" align="center" v-if="copyType == 'salesPurch'">
            <v-col cols="4" align="right"><div class=""><span>결제방법</span></div></v-col>
            <v-col cols="7">
              <v-select
                class=""
                v-model="payMthd"
                :items="payMthdList"
                item-value="cmn_cd"
                item-text="cmn_cd_nm"
                hide-details dense outlined
            ></v-select>
            </v-col>
          </v-row>
        </div>
        <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="$emit('close')">취소</v-btn>
        <v-btn small color="indigo darken-3" class="white--text" width="70" @click="onCopy">복사</v-btn>
      </v-card>
    </v-container>	
  </v-dialog>
</template>

<script>
import datePicker from "@/components/common/datePicker.vue";

export default {
  components: {datePicker},
  props: ["p_copyType", "p_trgtDt", "p_scdulDt", "p_payMthd"],

  data() {
    return {
      showDialog: true,

      copyType: this.p_copyType,
      copyTypeNm: this.p_copyType == 'estm' ? "견적" : 
                  this.p_copyType == 'salesOrd' ? "수주" : this.p_copyType == 'purchOrd' ? "발주" : 
                  this.p_copyType == 'salesPurch' ? "전표" : "",
      scdulTypeNm: this.p_copyType == 'purchOrd' ? "입고" : "출고",
      payMthd: this.p_payMthd,

      trgtDt: this.p_trgtDt,
      scdulDt: this.p_scdulDt
    };
  },
  computed: {
    payMthdList() {
      return this.$store.state.commonCode.pay_mthd
    }
  },
  created() {
    console.log("confirmCopyPopup > created : ", this.trgtDt, this.scdulDt);
    this.$store.dispatch('refreshCommonCode', 'pay_mthd');
  },
  mounted() {

  },
  methods: {
    onCopy() {    
      console.log("onCopy:");
      this.$emit('copyConfirmed', this.trgtDt, this.scdulDt, this.payMthd);
    },
    close() {
      this.$emit('close');
    }
  },
};
</script>