import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import axios from '@/plugins/axios';
import common from '@/common/common.js';
import "@grapecity/wijmo.vue2.grid";
import store from '@/store/store'

Vue.use(common);

Vue.prototype.$http = axios;
Vue.config.productionTip = false;

if (document.location.hostname == 'localhost') {
  axios.defaults.baseURL = 'http://localhost:8078';
} else {
  axios.defaults.baseURL = document.location.origin;
}

// axios.interceptors.request.use(
//   function (config) {
//     // 요청을 보내기 전에 수행할 로직
//     // alert(2);
//     // console.log("config.url : "+config);
//     if(config.url != '/user/getLoginCheck') {
//       axios.get('/user/getLoginCheck').then((res) => {

//         if(res.data.loginYn == 'N') {
//           store.state.session.compSno = '';
//           store.state.session.userSno = '';
//           Vue.prototype.$session.clear();
//           router.push("/logintest");
//           return;
//         } else {
//           return config;
//         }

//         // Vue.prototype.$session.set('loginYn', 'Y');

//       }).catch(
//         function (error) {
//         console.log(error);
//         }
//       )
//     } else {
//       return config;
//     }



//   },
//   function (err) {
//   	// 오류를 리턴 해주기 전에 수행할 로직

//     return Promise.reject(err);
//   });

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
