<template>
  <v-container class="container">
    <div class="d-flex">
      <div style="width: 4rem;height: 1rem;margin: .5rem;background: #00bcd4;"></div>
      <div style="width: 4rem;height: 1rem;margin: .5rem;background: #00bcd4;"></div>
      <div style="width: 4rem;height: 1rem;margin: .5rem;background: #00bcd4;"></div>
      <div style="width: 4rem;height: 1rem;margin: .5rem;background: #00bcd4;"></div>
    </div>
    <v-row>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="2">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col cols="3">
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
      <v-col>
        <div style="height: 2rem;background: magenta;border: 1px solid darkmagenta;"></div>
      </v-col>
    </v-row>
    <v-btn @click="addRow">Add Row</v-btn>
    <statement-canvas class="mt-2">
    </statement-canvas>
  </v-container>
</template>

<script>
import {defineComponent} from 'vue'
import StatementCanvas from '@/components/common/StatementCanvas.vue';

export default defineComponent({
  name: "vuetest",
  components: {StatementCanvas},
  data() {
    return {
      text: '',
      grid: null,
      gridData: [
        { id: 1, name: 'John', age: 25 },
        { id: 2, name: 'Jane', age: 30 },
        { id: 3, name: 'Bob', age: 35 }
      ]
    };
  },
  methods: {
    onGrid(flex) {
      this.grid = flex;
    },
    addRow() {
      // 새로운 행 객체 생성
      const newRow = { id: 4, name: 'Alice', age: 28 };

      // itemsSource 배열에 새로운 행 객체 추가
      this.gridData = [...this.gridData, newRow];
    },
    async onEditEnded(flex, event) {
      flex.invalidate();
    }
  }
})
</script>

<style scoped>
</style>
