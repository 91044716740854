<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>일별 현황</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="gridDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="estm">견적</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="salesOrd">수주</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="purchOrd">발주</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="sales">매출</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="purch">매입</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="colctPay">수금/지급</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center">
                  
              <!-- 견적 -->
              <v-col cols="12" v-show="gridDiv == 'estm'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListEstm.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <!-- <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col> -->
                          <v-col cols="5">
                            <v-row dense align="center">
                              <!-- <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col> -->
                              <v-col cols="4">
                                <v-select
                                  class=""
                                  v-model="priodDivEstm"
                                  :items="priodDivListEstm"
                                  item-value="cd"
                                  item-text="nm"
                                  hide-details dense outlined
                                ></v-select>
                              </v-col>    
                              <v-col cols="8">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtEstm"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtEstm"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>
                          <v-col cols="2"><div class="f-r"><span>수주/미수주 구분</span></div></v-col>
                          <v-col cols="1">
                            <v-select
                              class=""
                              v-model="estmStusDiv"
                              :items="estmStusDivList"
                              item-value="cd"
                              item-text="nm"
                              hide-details dense outlined
                            ></v-select>
                          </v-col>    
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordEstm"
                              @focusout="searchWordEstm = pendingWordEstm"
                              @keyup.enter="searchWordEstm = pendingWordEstm"
                              clearable
                              @click:clear="searchWordEstm = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수주건수 : {{ statsEstm.doneCnt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">미수주건수 : {{ statsEstm.yetCnt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">합계금액 : {{ statsEstm.sumAmt }} 원</span></div></v-col>                 
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListEstm" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :initialized="onFlexLedgrEstmInit"
                      :itemsSourceChanged="onFlexLedgrEstmItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'견적번호'" :binding="'estm_no'" :width="120" align="center"/>
                      <wj-flex-grid-column :header="'견적일자'" :binding="'estm_dt'" :width="110" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'출고예정일자'" :binding="'out_ship_scdul_dt'" :width="110" align="center"/>
                      <wj-flex-grid-column :header="'수주여부'" :binding="'estm_stus_nm'" :width="80" align="center"/>
                      <wj-flex-grid-column :header="'유효기간'" :binding="'valid_limit'" :width="200" align="center"/>
                      <wj-flex-grid-column :header="'결제조건'" :binding="'pay_cond'" :width="200" align="center"/>
                      <wj-flex-grid-column :header="'납품조건'" :binding="'dlv_cond'" :width="200" align="center"/>
                      <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="130" align="right"/>
                      <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="130" align="right"/>
                      <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="130" align="right"/>
                      <wj-flex-grid-column :header="'비고'" :binding="'rm'" :width="300" align="right"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

              <!-- 수주 -->
              <v-col cols="12" v-show="gridDiv == 'salesOrd'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListSalesOrd.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <!-- <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col> -->
                          <v-col cols="5">
                            <v-row dense align="center">
                              <!-- <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col> -->
                              <v-col cols="4">
                                <v-select
                                  class=""
                                  v-model="priodDivSalesOrd"
                                  :items="priodDivListSalesOrd"
                                  item-value="cd"
                                  item-text="nm"
                                  hide-details dense outlined
                                ></v-select>
                              </v-col>    
                              <v-col cols="8">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtSalesOrd"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtSalesOrd"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>  
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSalesOrd"
                              @focusout="searchWordSalesOrd = pendingWordSalesOrd"
                              @keyup.enter="searchWordSalesOrd = pendingWordSalesOrd"
                              clearable
                              @click:clear="searchWordSalesOrd = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-row dense align="center">
                              <v-col cols="1"></v-col>
                              <v-col cols="6">
                                <v-row dense align="center">
                                  <v-radio-group
                                    v-model="smplDtlDivSalesOrd"
                                    hide-details
                                    row
                                    dense
                                    class="ma-0 pa-0"
                                    >
                                    <v-radio 
                                      :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                                      v-for="item in smplDtlDivListSalesOrd"
                                      >
                                    </v-radio>
                                  </v-radio-group>
                                </v-row>
                              </v-col> 
                              <v-col cols="2" v-show="smplDtlDivSalesOrd == 'dtl'"><div class="f-r"><span>처리 상태</span></div></v-col>
                              <v-col cols="2" v-show="smplDtlDivSalesOrd == 'dtl'">
                                <v-select
                                  class=""
                                  v-model="salesOrdProcStus"
                                  :items="salesOrdProcStusList"
                                  item-value="cd"
                                  item-text="nm"
                                  hide-details dense outlined
                                ></v-select>
                              </v-col>  
                            </v-row>
                          </v-col>                                
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수량 : {{ statsSalesOrd.qty }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">중량 : {{ statsSalesOrd.wegt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">소재비 : {{ statsSalesOrd.sitemAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">가공비 : {{ statsSalesOrd.mchngAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">합계금액 : {{ statsSalesOrd.sumAmt }} 원</span></div></v-col>    
                        </v-row>
                      </v-col>             
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListSalesOrd" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :itemsSourceChanged="onFlexLedgrSalesOrdItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'수주일자'" :binding="'ord_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'출고예정일자'" :binding="'out_ship_scdul_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="120" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" align="center" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="60" align="right"/>
                      <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'소재단가'" :binding="'sitem_unit_price'" :width="80" align="right" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'소재비'" :binding="'sitem_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'가공비'" :binding="'mchng_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'도번'" :binding="'item_nm'" :width="150" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'품번'" :binding="'custr_item_nm'" :width="150" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'구매'" :binding="'purch_yn'" :width="60" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'절단'" :binding="'cut_yn'" :width="60" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'1차'" :binding="'frst_yn'" :width="60" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'2차'" :binding="'scnd_yn'" :width="60" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'납품'" :binding="'dlv_yn'" :width="60" :visible="smplDtlDivSalesOrd == 'dtl'"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

              <!-- 발주 -->
              <v-col cols="12" v-show="gridDiv == 'purchOrd'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListPurchOrd.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <v-col cols="5">
                            <v-row dense align="center">
                              <!-- <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col> -->
                              <v-col cols="4">
                                <v-select
                                  class=""
                                  v-model="priodDivPurchOrd"
                                  :items="priodDivListPurchOrd"
                                  item-value="cd"
                                  item-text="nm"
                                  hide-details dense outlined
                                ></v-select>
                              </v-col>    
                              <v-col cols="8">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtPurchOrd"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtPurchOrd"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>  
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordPurchOrd"
                              @focusout="searchWordPurchOrd = pendingWordPurchOrd"
                              @keyup.enter="searchWordPurchOrd = pendingWordPurchOrd"
                              clearable
                              @click:clear="searchWordPurchOrd = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-row dense align="center">
                              <v-col cols="1"></v-col>
                              <v-col cols="6">
                                <v-row dense align="center">
                                  <v-radio-group
                                    v-model="smplDtlDivPurchOrd"
                                    hide-details
                                    row
                                    dense
                                    class="ma-0 pa-0"
                                    >
                                    <v-radio 
                                      :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                                      v-for="item in smplDtlDivListPurchOrd"
                                      >
                                    </v-radio>
                                  </v-radio-group>
                                </v-row>
                              </v-col>  
                            </v-row>
                          </v-col>    
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수량 : {{ statsPurchOrd.qty }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">중량 : {{ statsPurchOrd.wegt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">소재비 : {{ statsPurchOrd.sitemAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">가공비 : {{ statsPurchOrd.mchngAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">합계금액 : {{ statsPurchOrd.sumAmt }} 원</span></div></v-col>    
                        </v-row>
                      </v-col>             
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListPurchOrd" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :itemsSourceChanged="onFlexLedgrPurchOrdItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'발주일자'" :binding="'ord_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'입고예정일자'" :binding="'out_ship_scdul_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="120" :visible="smplDtlDivPurchOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" align="center" :visible="smplDtlDivPurchOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="60" align="right"/>
                      <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'소재단가'" :binding="'sitem_unit_price'" :width="80" align="right" :visible="smplDtlDivPurchOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'소재비'" :binding="'sitem_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'가공비'" :binding="'mchng_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'도번'" :binding="'item_nm'" :width="150" :visible="smplDtlDivPurchOrd == 'dtl'"/>
                      <wj-flex-grid-column :header="'품번'" :binding="'custr_item_nm'" :width="150" :visible="smplDtlDivPurchOrd == 'dtl'"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

              <!-- 매출 -->
              <v-col cols="12" v-show="gridDiv == 'sales'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListSales.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <v-row dense align="center">
                              <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col>
                              <v-col cols="9">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtSales"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtSales"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>  
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSales"
                              @focusout="searchWordSales = pendingWordSales"
                              @keyup.enter="searchWordSales = pendingWordSales"
                              clearable
                              @click:clear="searchWordSales = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-row dense align="center">
                              <v-col cols="1"></v-col>
                              <v-col cols="5">
                                <v-row dense align="center">
                                  <v-radio-group
                                    v-model="smplDtlDivSales"
                                    hide-details
                                    row
                                    dense
                                    class="ma-0 pa-0"
                                    >
                                    <v-radio 
                                      :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                                      v-for="item in smplDtlDivListSales"
                                      >
                                    </v-radio>
                                  </v-radio-group>
                                </v-row>
                              </v-col>  
                            </v-row>
                          </v-col>    
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수량 : {{ statsSales.qty }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">중량 : {{ statsSales.wegt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">소재비 : {{ statsSales.sitemAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">가공비 : {{ statsSales.mchngAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">합계금액 : {{ statsSales.sumAmt }} 원</span></div></v-col>    
                        </v-row>
                      </v-col>             
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListSales" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :itemsSourceChanged="onFlexLedgrSalesItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'매출일자'" :binding="'sales_purch_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="150" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'두께'" :binding="'thck'" :width="60" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'가로'" :binding="'width_len'" :width="60" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'세로'" :binding="'depth_phi'" :width="60" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'소재단가'" :binding="'sitem_unit_price'" :width="100" align="right" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'소재비'" :binding="'sitem_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'가공비'" :binding="'mchng_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'도번'" :binding="'item_nm'" :width="150" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'품번'" :binding="'custr_item_nm'" :width="150" :visible="smplDtlDivSales == 'dtl'"/>
                      <wj-flex-grid-column :header="'담당자'" :binding="'custr_item_nm'" :width="70" :visible="smplDtlDivSales == 'dtl'"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

              <!-- 매입 -->
              <v-col cols="12" v-show="gridDiv == 'purch'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListPurch.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <v-row dense align="center">
                              <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col>
                              <v-col cols="9">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtPurch"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtPurch"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>  
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordPurch"
                              @focusout="searchWordPurch = pendingWordPurch"
                              @keyup.enter="searchWordPurch = pendingWordPurch"
                              clearable
                              @click:clear="searchWordPurch = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-row dense align="center">
                              <v-col cols="1"></v-col>
                              <v-col cols="5">
                                <v-row dense align="center">
                                  <v-radio-group
                                    v-model="smplDtlDivPurch"
                                    hide-details
                                    row
                                    dense
                                    class="ma-0 pa-0"
                                    >
                                    <v-radio 
                                      :key="item.cd" :value="item.cd" :label="`${item.nm}`" class="pr-5" 
                                      v-for="item in smplDtlDivListPurch"
                                      >
                                    </v-radio>
                                  </v-radio-group>
                                </v-row>
                              </v-col>  
                            </v-row>
                          </v-col>    
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수량 : {{ statsPurch.qty }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">중량 : {{ statsPurch.wegt }} 건</span></div></v-col>
                          <v-col cols="2"><div class=""><span style="color:blue;">소재비 : {{ statsPurch.sitemAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">가공비 : {{ statsPurch.mchngAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">합계금액 : {{ statsPurch.sumAmt }} 원</span></div></v-col>    
                        </v-row>
                      </v-col>             
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListPurch" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :itemsSourceChanged="onFlexLedgrPurchItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'매입일자'" :binding="'sales_purch_dt'" :width="100" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                      <wj-flex-grid-column :header="'규격'" :binding="'spec'" :width="150" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'두께'" :binding="'thck'" :width="60" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'가로'" :binding="'width_len'" :width="60" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'세로'" :binding="'depth_phi'" :width="60" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'수량'" :binding="'qty'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'중량'" :binding="'wegt'" :width="70" align="right"/>
                      <wj-flex-grid-column :header="'소재단가'" :binding="'sitem_unit_price'" :width="100" align="right" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'소재비'" :binding="'sitem_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'가공비'" :binding="'mchng_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'공급가액'" :binding="'suply_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'부가세액'" :binding="'vat_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'합계금액'" :binding="'sum_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'도번'" :binding="'item_nm'" :width="150" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'품번'" :binding="'custr_item_nm'" :width="150" :visible="smplDtlDivPurch == 'dtl'"/>
                      <wj-flex-grid-column :header="'담당자'" :binding="'custr_item_nm'" :width="70" :visible="smplDtlDivPurch == 'dtl'"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

              <!-- 수금/지급 -->
              <v-col cols="12" v-show="gridDiv == 'colctPay'">
                <v-row dense align="center" class="">
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="1"><div class=""><span>조회수 : {{filteredLedgrListColctPay.length}} 건</span></div></v-col>
                      <v-col cols="9" class="">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <v-row dense align="center">
                              <v-col cols="3"><div class="f-r"><span>조회 기간</span></div></v-col>
                              <v-col cols="9">
                                <v-row dense align="center">
                                  <v-col cols="5">
                                    <date-picker v-model="srchBgnDtColctPay"></date-picker>
                                  </v-col>
                                  <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                  <v-col cols="5">
                                    <date-picker v-model="srchEndDtColctPay"></date-picker>
                                  </v-col>
                                </v-row>
                              </v-col>    
                            </v-row>
                          </v-col>  
                          <v-col cols="1"><div class="f-r"><span class="">거래처</span></div></v-col>
                          <v-col cols="1">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordColctPay"
                              @focusout="searchWordColctPay = pendingWordColctPay"
                              @keyup.enter="searchWordColctPay = pendingWordColctPay"
                              clearable
                              @click:clear="searchWordColctPay = ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1"><div class="f-r"><span>수금지급 구분</span></div></v-col>
                          <v-col cols="1">
                            <v-select
                              class=""
                              v-model="colctPayDiv"
                              :items="colctPayDivList"
                              item-value="cd"
                              item-text="nm"
                              hide-details dense outlined
                            ></v-select>
                          </v-col>    
                        </v-row>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                        <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>    
                  <!-- 통계 row -->       
                  <v-col cols="12">
                    <v-row dense align="center" class="">
                      <v-col cols="8">
                        <v-row dense align="center" class="">
                          <v-col cols="2"><div class=""><span style="color:blue;">수금액 : {{ statsColctPay.colctAmt }} 원</span></div></v-col>                 
                          <v-col cols="2"><div class=""><span style="color:blue;">지급액 : {{ statsColctPay.payAmt }} 원</span></div></v-col>                   
                        </v-row>
                      </v-col>             
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="filteredLedgrListColctPay" 
                      :selectionMode="'ListBox'"
                      style="height:615px;"
                      :itemsSourceChanged="onFlexLedgrColctPayItemsSourceChanged"
                      :headersVisibility="'Column'"
                      :alternatingRowStep="0"
                      >
                      <wj-flex-grid-column :header="'수금/지급일자'" :binding="'colct_pay_dt'" :width="125" align="center"/>
                      <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" :width="150" />
                      <wj-flex-grid-column :header="'계정'" :binding="'acct_nm'" :width="120" align="center"/>
                      <wj-flex-grid-column :header="'수금액'" :binding="'colct_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'지급액'" :binding="'pay_amt'" :width="120" align="right"/>
                      <wj-flex-grid-column :header="'비고'" :binding="'rm'" :width="400"/>
                    </wj-flex-grid>
                  </v-col>  
                </v-row>     
              </v-col>

            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

// . 통계 정보
const estm = {doneCnt: 0, yetCnt: 0, sumAmt: 0};
const salesPurch = {qty: 0, wegt: 0, sitemAmt: 0, mchngAmt: 0, sumAmt: 0};
const colctPay = {colctAmt: 0, payAmt: 0};

export default {
  components: {datePicker},
  data(){
    return{
      compSno: '',
      userSno: 0,

      // 공통
      gridDiv: "estm",   // estm, salesOrd, purchOrd, sales, purch, colctPay

      // 견적
      isEstmLoaded: false,
      searchWordEstm: "",
      pendingWordEstm: "",

      priodDivEstm: "estm",
      priodDivListEstm: [
        {cd: "estm", nm: "견적일자"},
        {cd: "ship", nm: "출고예정일자"},
      ],

      srchBgnDtEstm: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtEstm: dayjs().format('YYYY-MM-DD'),

      estmStusDiv: "all",
      estmStusDivList: [
        {cd: "all", nm: "전체"},
        {cd: "done", nm: "수주"},
        {cd: "yet", nm: "미수주"},
      ],

      statsEstm: {...estm},

      flexLedgrEstm: null,    // 견적 현황 그리드
      ledgrListEstm: [],

      // 수주
      isSalesOrdLoaded: false,
      searchWordSalesOrd: "",
      pendingWordSalesOrd: "",

      priodDivSalesOrd: "ord",
      priodDivListSalesOrd: [
        {cd: "ord", nm: "수주일자"},
        {cd: "ship", nm: "출고예정일자"},
      ],

      srchBgnDtSalesOrd: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtSalesOrd: dayjs().format('YYYY-MM-DD'),

      smplDtlDivSalesOrd: "smpl",
      smplDtlDivListSalesOrd: [
        {cd: "smpl", nm: "간단조회"},
        {cd: "dtl", nm: "상세조회"},
      ],

      salesOrdProcStus: "all",
      salesOrdProcStusList: [
        {cd: "all", nm: "전체"},
        {cd: "purch", nm: "구매"},
        {cd: "cut", nm: "절단"},
        {cd: "frst", nm: "1차"},
        {cd: "scnd", nm: "2차"},
        {cd: "dlv", nm: "납품"},
      ],

      statsSalesOrd: {...salesPurch},

      flexLedgrSalesOrd: null,    // 수주 현황 그리드
      ledgrListSalesOrd: [],

      // 발주
      isPurchOrdLoaded: false,
      searchWordPurchOrd: "",
      pendingWordPurchOrd: "",

      priodDivPurchOrd: "ord",
      priodDivListPurchOrd: [
        {cd: "ord", nm: "발주일자"},
        {cd: "ship", nm: "입고예정일자"},
      ],

      srchBgnDtPurchOrd: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtPurchOrd: dayjs().format('YYYY-MM-DD'),

      smplDtlDivPurchOrd: "smpl",
      smplDtlDivListPurchOrd: [
        {cd: "smpl", nm: "간단조회"},
        {cd: "dtl", nm: "상세조회"},
      ],

      statsPurchOrd: {...salesPurch},

      flexLedgrPurchOrd: null,    // 발주 현황 그리드
      ledgrListPurchOrd: [],

      // 매출
      isSalesLoaded: false,
      searchWordSales: "",
      pendingWordSales: "",

      srchBgnDtSales: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtSales: dayjs().format('YYYY-MM-DD'),

      smplDtlDivSales: "smpl",
      smplDtlDivListSales: [
        {cd: "smpl", nm: "간단조회"},
        {cd: "dtl", nm: "상세조회"},
      ],

      statsSales: {...salesPurch},

      flexLedgrSales: null,    // 매출 현황 그리드
      ledgrListSales: [],

      // 매입
      isPurchLoaded: false,
      searchWordPurch: "",
      pendingWordPurch: "",

      srchBgnDtPurch: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtPurch: dayjs().format('YYYY-MM-DD'),

      smplDtlDivPurch: "smpl",
      smplDtlDivListPurch: [
        {cd: "smpl", nm: "간단조회"},
        {cd: "dtl", nm: "상세조회"},
      ],

      statsPurch: {...salesPurch},

      flexLedgrPurch: null,    // 매출 현황 그리드
      ledgrListPurch: [],

      // 수금/지급
      isColctPayLoaded: false,
      searchWordColctPay: "",
      pendingWordColctPay: "",

      srchBgnDtColctPay: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtColctPay: dayjs().format('YYYY-MM-DD'),

      colctPayDiv: "all",
      colctPayDivList: [
        {cd: "all", nm: "전체"},
        {cd: "colct", nm: "수금"},
        {cd: "pay", nm: "지급"},
      ],

      statsColctPay: {...colctPay},

      flexLedgrColctPay: null,    // 매출 현황 그리드
      ledgrListColctPay: [],

    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.ledgrByDay.code);
    },
    filteredLedgrListEstm() {
      console.log("filteredLedgrListEstm: ", this.searchWordEstm, this.estmStusDiv);
      return this.ledgrListEstm.filter(({custr_nm, estm_stus}) => 
        (!this.searchWordEstm || this.searchWordEstm.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordEstm.toUpperCase())) && 
        (this.estmStusDiv == 'all' || this.estmStusDiv == estm_stus)
      );
    },
    filteredLedgrListSalesOrd() {
      return this.ledgrListSalesOrd.filter(({custr_nm, sales_ord_proc_stus}) => 
        (!this.searchWordSalesOrd || this.searchWordSalesOrd.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordSalesOrd.toUpperCase())) &&
        (this.smplDtlDivSalesOrd == 'smpl' || 
          (this.smplDtlDivSalesOrd == 'dtl' && (this.salesOrdProcStus == 'all' || this.salesOrdProcStus == sales_ord_proc_stus)))
      );
    },
    filteredLedgrListPurchOrd() {
      return this.ledgrListPurchOrd.filter(({custr_nm}) => 
        (!this.searchWordPurchOrd || this.searchWordPurchOrd.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordPurchOrd.toUpperCase())) 
      );
    },
    filteredLedgrListSales() {
      return this.ledgrListSales.filter(({custr_nm}) => 
        (!this.searchWordSales || this.searchWordSales.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordSales.toUpperCase())) 
      );
    },
    filteredLedgrListPurch() {
      return this.ledgrListPurch.filter(({custr_nm}) => 
        (!this.searchWordPurch || this.searchWordPurch.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordPurch.toUpperCase())) 
      );
    },
    filteredLedgrListColctPay() {
      return this.ledgrListColctPay.filter(({custr_nm, colct_pay_div}) => 
        (!this.searchWordColctPay || this.searchWordColctPay.trim() === '' ||
          custr_nm && custr_nm.toUpperCase().includes(this.searchWordColctPay.toUpperCase())) &&
        (this.colctPayDiv == 'all' || this.colctPayDiv == colct_pay_div)
      );
    },
  },
  watch: {
    gridDiv: {
      immediate: true,
      handler: function () {
        if(this.gridDiv == 'estm') {
          if(!this.isEstmLoaded) {
            this.loadLedgrListEstm();
          }
        } else if(this.gridDiv == 'salesOrd') {
          if(!this.isSalesOrdLoaded) {
            this.loadLedgrListSalesOrd();
          }
        } else if(this.gridDiv == 'purchOrd') {
          if(!this.isPurchOrdLoaded) {
            this.loadLedgrListPurchOrd();
          }
        } else if(this.gridDiv == 'sales') {
          if(!this.isSalesLoaded) {
            this.loadLedgrListSales();
          }
        } else if(this.gridDiv == 'purch') {
          if(!this.isPurchLoaded) {
            this.loadLedgrListPurch();
          }
        } else if(this.gridDiv == 'colctPay') {
          if(!this.isColctPayLoaded) {
            this.loadLedgrListColctPay();
          }
        }
      },
    },
    // 수주
    smplDtlDivSalesOrd: {
      immediate: true,
      handler: function () {
        this.loadLedgrListSalesOrd();
      },
    },
    priodDivSalesOrd: {
      immediate: true,
      handler: function () {
        this.loadLedgrListSalesOrd();
      },
    },
    // 발주
    smplDtlDivPurchOrd: {
      immediate: true,
      handler: function () {
        this.loadLedgrListPurchOrd();
      },
    },
    priodDivPurchOrd: {
      immediate: true,
      handler: function () {
        this.loadLedgrListPurchOrd();
      },
    },
    // 매출
    smplDtlDivSales: {
      immediate: true,
      handler: function () {
        this.loadLedgrListSales();
      },
    },
    // 매입
    smplDtlDivPurch: {
      immediate: true,
      handler: function () {
        this.loadLedgrListPurch();
      },
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    // this.loadLedgrBySitem();
  },
  methods: {
    onFlexLedgrEstmInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }
      });

      this.flexLedgrEstm = flex;
    },
    onFlexLedgrEstmItemsSourceChanged(flex) {
      console.log("onFlexLedgrEstmItemsSourceChanged");

      // 통계 계산
      this.calculateStatsEstm();
    },
    onFlexLedgrSalesOrdItemsSourceChanged(flex) {
      console.log("onFlexLedgrSalesOrdItemsSourceChanged");

      // 통계 계산
      this.calculateStatsSalesOrd();
    },
    onFlexLedgrPurchOrdItemsSourceChanged(flex) {
      console.log("onFlexLedgrPurchOrdItemsSourceChanged");

      // 통계 계산
      this.calculateStatsPurchOrd();
    },
    onFlexLedgrSalesItemsSourceChanged(flex) {
      console.log("onFlexLedgrSalesItemsSourceChanged");

      // 통계 계산
      this.calculateStatsSales();
    },
    onFlexLedgrPurchItemsSourceChanged(flex) {
      console.log("onFlexLedgrPurchItemsSourceChanged");

      // 통계 계산
      this.calculateStatsPurch();
    },
    onFlexLedgrColctPayItemsSourceChanged(flex) {
      console.log("onFlexLedgrColctPayItemsSourceChanged");

      // 통계 계산
      this.calculateStatsColctPay();
    },

    // 견적 현황 조회
    async loadLedgrListEstm() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtEstm);
      params.append("srch_end_dt", this.srchEndDtEstm);
      params.append("priod_div", this.priodDivEstm);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByDayEstm', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListEstm = ledgrList;
        this.isEstmLoaded = true;
        // console.log("loadLedgrListEstm > ledgrListEstm : ", this.ledgrListEstm);
      } catch (e) {
        console.log(`Error on function loadLedgrListEstm: ${e}`);
      }
    },
    // 수주 현황 조회
    async loadLedgrListSalesOrd() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtSalesOrd);
      params.append("srch_end_dt", this.srchEndDtSalesOrd);
      params.append("smpl_dtl_div", this.smplDtlDivSalesOrd);
      params.append("priod_div", this.priodDivSalesOrd);
      params.append("ord_div", "sales");

      try {
        const res = await this.$http.post('/ledger/getLedgrListByDayOrd', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
          purch_yn: ledgr.purch_yn == 'Y' ? true : false,
          cut_yn: ledgr.cut_yn == 'Y' ? true : false,
          frst_yn: ledgr.frst_yn == 'Y' ? true : false,
          scnd_yn: ledgr.scnd_yn == 'Y' ? true : false,
          dlv_yn: ledgr.dlv_yn == 'Y' ? true : false,
        }));

        this.ledgrListSalesOrd = ledgrList;
        this.isSalesOrdLoaded = true;
      } catch (e) {
        console.log(`Error on function loadLedgrListSalesOrd: ${e}`);
      }
    },
    // 발주 현황 조회
    async loadLedgrListPurchOrd() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtPurchOrd);
      params.append("srch_end_dt", this.srchEndDtPurchOrd);
      params.append("smpl_dtl_div", this.smplDtlDivPurchOrd);
      params.append("priod_div", this.priodDivPurchOrd);
      params.append("ord_div", "purch");

      try {
        const res = await this.$http.post('/ledger/getLedgrListByDayOrd', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListPurchOrd = ledgrList;
        this.isPurchOrdLoaded = true;
      } catch (e) {
        console.log(`Error on function loadLedgrListPurchOrd: ${e}`);
      }
    },
    // 매출 현황 조회
    async loadLedgrListSales() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtSales);
      params.append("srch_end_dt", this.srchEndDtSales);
      params.append("smpl_dtl_div", this.smplDtlDivSales);
      params.append("sales_purch_div", "sales");


      try {
        const res = await this.$http.post('/ledger/getLedgrListByDaySalesPurch', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListSales = ledgrList;
        this.isSalesLoaded = true;
      } catch (e) {
        console.log(`Error on function loadLedgrListSales: ${e}`);
      }
    },
    // 매입 현황 조회
    async loadLedgrListPurch() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtPurch);
      params.append("srch_end_dt", this.srchEndDtPurch);
      params.append("smpl_dtl_div", this.smplDtlDivPurch);
      params.append("sales_purch_div", "purch");

      try {
        const res = await this.$http.post('/ledger/getLedgrListByDaySalesPurch', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListPurch = ledgrList;
        this.isPurchLoaded = true;
      } catch (e) {
        console.log(`Error on function loadLedgrListPurch: ${e}`);
      }
    },
    // 수금/지급 현황 조회
    async loadLedgrListColctPay() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_bgn_dt", this.srchBgnDtColctPay);
      params.append("srch_end_dt", this.srchEndDtColctPay);

      try {
        const res = await this.$http.post('/ledger/getLedgrListByDayColctPay', params);
        const ledgrList = res.data.ledgrList.map(ledgr => ({
          ...ledgr,
        }));

        this.ledgrListColctPay = ledgrList;
        this.isColctPayLoaded = true;
      } catch (e) {
        console.log(`Error on function loadLedgrListColctPay: ${e}`);
      }
    },
    // 견적 통계 계산
    calculateStatsEstm() {
      let doneCnt = 0;
      let yetCnt = 0;
      let sumAmt = 0;

      this.filteredLedgrListEstm.forEach((e) => {
        doneCnt += e.estm_stus == 'done' ? 1 : 0;
        yetCnt += e.estm_stus == 'yet' ? 1 : 0;
        sumAmt += e.sum_amt;
      });

      this.statsEstm.doneCnt = this.$setNumberComma(Math.round(doneCnt, 0));
      this.statsEstm.yetCnt = this.$setNumberComma(Math.round(yetCnt, 0));
      this.statsEstm.sumAmt = this.$setNumberComma(Math.round(sumAmt, 0));
    },
    // 수주 통계 계산
    calculateStatsSalesOrd() {
      let qty = 0;
      let wegt = 0;
      let sitemAmt = 0;
      let mchngAmt = 0;
      let sumAmt = 0;

      this.filteredLedgrListSalesOrd.forEach((e) => {
        qty += e.qty;
        wegt += e.wegt;
        sitemAmt += e.sitem_amt;
        mchngAmt += e.mchng_amt;
        sumAmt += e.sum_amt;
      });

      this.statsSalesOrd.qty = this.$setNumberComma(Math.round(qty, 0));
      this.statsSalesOrd.wegt = this.$setNumberComma(Math.round(wegt, 0));
      this.statsSalesOrd.sitemAmt = this.$setNumberComma(Math.round(sitemAmt, 0));
      this.statsSalesOrd.mchngAmt = this.$setNumberComma(Math.round(mchngAmt, 0));
      this.statsSalesOrd.sumAmt = this.$setNumberComma(Math.round(sumAmt, 0));
    },
    // 발주 통계 계산
    calculateStatsPurchOrd() {
      let qty = 0;
      let wegt = 0;
      let sitemAmt = 0;
      let mchngAmt = 0;
      let sumAmt = 0;

      this.filteredLedgrListPurchOrd.forEach((e) => {
        qty += e.qty;
        wegt += e.wegt;
        sitemAmt += e.sitem_amt;
        mchngAmt += e.mchng_amt;
        sumAmt += e.sum_amt;
      });

      this.statsPurchOrd.qty = this.$setNumberComma(Math.round(qty, 0));
      this.statsPurchOrd.wegt = this.$setNumberComma(Math.round(wegt, 0));
      this.statsPurchOrd.sitemAmt = this.$setNumberComma(Math.round(sitemAmt, 0));
      this.statsPurchOrd.mchngAmt = this.$setNumberComma(Math.round(mchngAmt, 0));
      this.statsPurchOrd.sumAmt = this.$setNumberComma(Math.round(sumAmt, 0));

    },
    // 매출 통계 계산
    calculateStatsSales() {
      let qty = 0;
      let wegt = 0;
      let sitemAmt = 0;
      let mchngAmt = 0;
      let sumAmt = 0;

      this.filteredLedgrListSales.forEach((e) => {
        qty += e.qty;
        wegt += e.wegt;
        sitemAmt += e.sitem_amt;
        mchngAmt += e.mchng_amt;
        sumAmt += e.sum_amt;
      });

      this.statsSales.qty = this.$setNumberComma(Math.round(qty, 0));
      this.statsSales.wegt = this.$setNumberComma(Math.round(wegt, 0));
      this.statsSales.sitemAmt = this.$setNumberComma(Math.round(sitemAmt, 0));
      this.statsSales.mchngAmt = this.$setNumberComma(Math.round(mchngAmt, 0));
      this.statsSales.sumAmt = this.$setNumberComma(Math.round(sumAmt, 0));
    },
    // 매입 통계 계산
    calculateStatsPurch() {
      let qty = 0;
      let wegt = 0;
      let sitemAmt = 0;
      let mchngAmt = 0;
      let sumAmt = 0;

      this.filteredLedgrListPurch.forEach((e) => {
        qty += e.qty;
        wegt += e.wegt;
        sitemAmt += e.sitem_amt;
        mchngAmt += e.mchng_amt;
        sumAmt += e.sum_amt;
      });

      this.statsPurch.qty = this.$setNumberComma(Math.round(qty, 0));
      this.statsPurch.wegt = this.$setNumberComma(Math.round(wegt, 0));
      this.statsPurch.sitemAmt = this.$setNumberComma(Math.round(sitemAmt, 0));
      this.statsPurch.mchngAmt = this.$setNumberComma(Math.round(mchngAmt, 0));
      this.statsPurch.sumAmt = this.$setNumberComma(Math.round(sumAmt, 0));
    },
    // 수금/지급 통계 계산
    calculateStatsColctPay() {
      let colctAmt = 0;
      let payAmt = 0;

      this.filteredLedgrListColctPay.forEach((e) => {
        colctAmt += e.colct_amt;
        payAmt += e.pay_amt;
      });

      this.statsColctPay.colctAmt = this.$setNumberComma(Math.round(colctAmt, 0));
      this.statsColctPay.payAmt = this.$setNumberComma(Math.round(payAmt, 0));
    },
    // 조회 버튼 클릭
    onLedgrInqry() {
      if(this.gridDiv == 'estm') {
        this.loadLedgrListEstm();
      } else if(this.gridDiv == 'salesOrd') {
        this.loadLedgrListSalesOrd();
      } else if(this.gridDiv == 'purchOrd') {
        this.loadLedgrListPurchOrd();
      } else if(this.gridDiv == 'sales') {
        this.loadLedgrListSales();
      } else if(this.gridDiv == 'purch') {
        this.loadLedgrListPurch();
      } else if(this.gridDiv == 'colctPay') {
        this.loadLedgrListColctPay();
      }
    },
    
  }
}
</script>

<style>

</style>