<template>
    <div>
        <h1>서버 axios 테스트</h1>
        <v-btn @click="btnClicked">클릭</v-btn>
    </div>
</template>

<script>
export default {
    name: "home",
    methods: {
        btnClicked() {
            this.$http.get("/").then((res) => {        
                alert(res.data.code);
            }).catch((error) => {
                console.log(error);
            }
            )
        }
    }
}
</script>