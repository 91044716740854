<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense>
          <v-col cols="1">
            <div class="">
              <h2>품목 관리</h2>
            </div>
          </v-col>
          <v-col cols="11">
            <v-btn small color="blue darken-2" class="white--text" v-show="!isFavorited" @click="addFavorite">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="" v-show="isFavorited" @click="deleteFavorite">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>
          <div>
            <v-card class="pa-1 elevation-0" height="740px">
              <v-row class="d-flex align-center" >
                <!-- <v-col cols="1"><div><span>조회수 : {{sitemCount}}건</span></div></v-col> -->
                <v-col cols="2">
                  <v-row dense class="" align="center">
                    <v-col cols="3" align="right"><div class=""><span class="">강종</span></div></v-col>
                    <v-col cols="9">
                      <div class="d-flex">
                        <v-text-field
                          class=""
                          v-model="searchWordSkind"
                          hide-details
                          dense
                          outlined
                          @keyup="searchWordSkindKeyUp"
                          :readonly="isOnPendingSitem"
                          @click="onSearchWordClick"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                
                <v-col cols="3">
                  <v-row dense class="" align="center">                   
                    <v-col cols="3" align="right"><div class=""><span class="">품목</span></div></v-col>
                    <v-col cols="9">
                      <div class="d-flex">
                        <v-text-field
                          class=""
                          v-model="searchWordSitem"
                          hide-details
                          dense
                          outlined
                          @keyup="searchWordSitemKeyUp"
                          :readonly="isOnPendingSitem"
                          @click="onSearchWordClick"
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="1" align="right">
                  <v-btn 
                    small 
                    width="100px" 
                    color="blue darken-2" 
                    class="white--text"
                    :disabled="isNew"
                    @click="addSitemBtnClicked"
                    >품목 추가
                  </v-btn>
                </v-col>
              </v-row>

              <!-- flexgrid -->
              <v-row class="mt-0">
                <!-- 강종 -->
                <v-col cols="2">
                  <v-row class="">
                    <v-col cols="12" class="mt-0 pt-0">
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="gridSkindList"
                        :selectionMode="'ListBox'"
                        style="height:650px;"
                        :initialized="flexSkindInitialized"
                        :itemsSourceChanging="skindItemsSourceChanging"
                        :itemsSourceChanged="skindItemsSourceChanged"
                        :headersVisibility="'Column'"
                      >
                        <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="4*" />
                        <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="9*" />
                      </wj-flex-grid>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- 품목 -->
                <v-col cols="3">
                  <v-row class="">
                    <v-col cols="12" class="mt-0 pt-0">
                      <wj-flex-grid 
                        :isReadOnly=true
                        :itemsSource="gridSitemList"
                        :selectionMode="'ListBox'"
                        style="height:650px;"
                        :initialized="flexSitemInitialized"
                        :itemsSourceChanging="sitemItemsSourceChanging"
                        :itemsSourceChanged="sitemItemsSourceChanged"
                        :headersVisibility="'Column'"
                      >
                        <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" width="5*" />
                        <wj-flex-grid-column :header="'품목명'" :binding="'sitem_nm'" width="9*" />
                        <wj-flex-grid-column :header="'구분'" :binding="'sitem_div_nm'" width="5*" />
                        <wj-flex-grid-column :header="'규격'" :binding="'stnd'" width="5*" />
                        <wj-flex-grid-column :header="'사용'" :binding="'use_yn_disp'" width="5*" />
                      </wj-flex-grid>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- 품목 정보 -->
                <v-col cols="7">
                  <v-row dense class="bgcolor-grey1">
                    <v-col cols="12" class="d-flex justify-end align-center hei-40p" >
                      <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" v-show="!isNew" @click="deleteBtnClicked">삭제</v-btn>
                      <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text" width="70" v-show="!isNew" :disabled="isOnPendingSitem">출력</v-btn>
                      <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingSitem && !isNew">취소</v-btn>
                      <v-btn small color="indigo darken-3" class="white--text" width="70" @click="saveBtnClicked" :disabled="!isOnPendingSitem">저장</v-btn>  
                    </v-col>

                    <v-col cols="12" class="pt-0 mt-0">
                      <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                        <v-row dense>
                          <v-col cols="1">
                            <h4>기본정보</h4>
                          </v-col>
                          <v-spacer></v-spacer>                     
                        </v-row>
                        <v-row dense class="hei-40p mt-0" align="center">
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>품목코드</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-text-field
                              class=""
                              v-model="pendingSitem.sitem_cd"
                              hide-details
                              dense
                              outlined
                              :disabled="!isNew"
                              :background-color="isNew ? 'white' : 'grey lighten-2'"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>품목명</span></div></v-col>
                          <v-col cols="3" class="" >
                            <v-text-field
                              class=""
                              v-model="pendingSitem.sitem_nm"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>구분</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-select
                              class=""
                              v-model="pendingSitem.sitem_div"
                              :items="sitemDivList"
                              item-value="cmn_cd"
                              item-text="cmn_cd_nm"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-select>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>규격</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-text-field
                              class=""
                              v-model="pendingSitem.stnd"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>단위</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-select
                              class=""
                              v-model="pendingSitem.unit"
                              :items="sitemUnitList"
                              item-value="cmn_cd"
                              item-text="cmn_cd_nm"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row dense class="hei-40p mt-0" align="center">
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>두께/Ø</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.thck" 
                              bg-color="white"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>비중</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.spgrv" 
                              bg-color="white" 
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>가로/길이</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.width_len" 
                              bg-color="white" 
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>로스(가로)</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.width_len_loss" 
                              bg-color="white" 
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>세로</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.depth_phi" 
                              bg-color="white" 
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>로스(세로)</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.depth_phi_loss" 
                              bg-color="white" 
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense class="hei-40p mt-0" align="center">
                          <v-col cols="1" align="right" class=""><div class=""><span>코드 사용</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-checkbox
                              class="pa-0 ma-0"
                              v-model="pendingSitem.use_tf"
                              label="사용함"
                              hide-details
                              dense                              
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>품목 규격</span></div></v-col>
                          <v-col cols="3" class="" >
                            <v-radio-group
                              v-model="pendingSitem.stnd_item_yn"
                              row
                              hide-details
                              dense
                              class="ma-0 pa-0"
                            >
                            <v-radio
                              label="규격"
                              value="Y"
                              class="pr-5"
                            ></v-radio>
                            <v-radio
                              label="비규격"
                              value="N"
                              class="pr-5"
                            ></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>원장체크</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-checkbox
                              class="pa-0 ma-0"
                              v-model="pendingSitem.ledgr_chk_tf"
                              label="사용함"
                              hide-details
                              dense                            
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>원장무게</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.ledgr_wegt" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          
                          <v-col cols="1" align="right" class=""><div class=""><span>M/Kg</span></div></v-col>
                          <v-col cols="1" class="" >
                            <v-text-field
                              class=""
                              v-model="pendingSitem.m_kg"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-text-field>
                          </v-col>

                          
                        </v-row>

                        <v-row dense class="hei-40p mt-0" align="center">
                          <v-col cols="1" align="right" class=""><div class=""><span>매입단가</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.purch_unit_price" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>매출단가</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.sales_unit_price" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>이월재고</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.crovr_invnt" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>수량재고</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.qty_invnt" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                          <v-col cols="1" align="right" class=""><div class=""><span>중량재고</span></div></v-col>
                          <v-col cols="1" class="" >
                            <numeric-text-field 
                              v-model="pendingSitem.wegt_invnt" 
                              :disabled="true"
                              precision="2"
                              >
                            </numeric-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense class="hei-80p mt-0" align="center">
                          <v-col cols="4">
                            <v-row dense align="center">
                              <v-col cols="3" align="right" class=""><div class=""><span>면(%)</span></div></v-col>
                              <v-col cols="3" class="" >
                                <numeric-text-field 
                                  v-model="pendingSitem.face_pcrt" 
                                  bg-color="white" 
                                  precision="2"
                                  >
                                </numeric-text-field>
                              </v-col>
                              <v-col cols="3" align="right" class=""><div class=""><span>각(%)</span></div></v-col>
                              <v-col cols="3" class="" >
                                <numeric-text-field 
                                  v-model="pendingSitem.side_pcrt" 
                                  bg-color="white" 
                                  precision="2"
                                  >
                                </numeric-text-field>
                              </v-col>
                            </v-row>
                            <v-row dense align="center">
                              <v-col cols="3" align="right" class=""><div class=""><span>연마(%)</span></div></v-col>
                              <v-col cols="3" class="" >
                                <numeric-text-field 
                                  v-model="pendingSitem.grind_pcrt" 
                                  bg-color="white" 
                                  precision="2"
                                  >
                                </numeric-text-field>
                              </v-col>
                              <v-col cols="3" align="right" class=""><div class=""><span>기타(%)</span></div></v-col>
                              <v-col cols="3" class="" >
                                <numeric-text-field 
                                  v-model="pendingSitem.etc_pcrt" 
                                  bg-color="white" 
                                  precision="2"
                                  >
                                </numeric-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                              
                          <v-col cols="1" align="right" class=""><div class=""><span>비고</span></div></v-col>
                          <v-col cols="7" class="" >
                            <v-textarea
                              class=""
                              v-model="pendingSitem.rm"
                              no-resize
                              height="60"
                              hide-details
                              dense
                              outlined
                              background-color="white"
                            ></v-textarea>
                          </v-col>
                              
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="5">
                      <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                        <v-row dense>
                          <v-col cols="12">
                            <h4>입출고 현황</h4>
                          </v-col>               
                        </v-row>
                        <wj-flex-grid 
                          :isReadOnly=true
                          :itemsSource="statList"
                          :selectionMode="'MultiRange'"
                          style="height:295px;"
                          :initialized="flexStatInitialized"
                          :headersVisibility="'Column'"
                          :alternatingRowStep="0"
                          >
                          <wj-flex-grid-column :header="'구분'" :binding="'div_nm'" width="1.5*" />
                          <wj-flex-grid-column :header="'입고'" :binding="'purch_stat'" width="2*" align="right"/>
                          <wj-flex-grid-column :header="'출고'" :binding="'sales_stat'" width="2*" align="right"/>
                          <wj-flex-grid-column :header="'입고-출고'" :binding="'diff_purch_sales'" width="2*" align="right"/>
                        </wj-flex-grid>
                      </v-card>
                    </v-col>
                    <v-col cols="7">
                      <v-row dense align="center">
                        <v-col cols="6">
                          <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                            <v-row dense>
                              <v-col cols="12">
                                <h4>거래처별 단가(입고)</h4>
                              </v-col>
                            </v-row>
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="purchPriceList"
                              :selectionMode="'MultiRange'"
                              style="height:295px;"
                              :initialized="flexPurchInitialized"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" width="4.5*" />
                              <wj-flex-grid-column :header="'입고일자'" :binding="'sales_purch_dt'" width="3.5*" align="center"/>
                              <wj-flex-grid-column :header="'단가'" :binding="'sitem_unit_price'" width="2.5*" align="right"/>
                            </wj-flex-grid>
                          </v-card>
                        </v-col>
                        <v-col cols="6">
                          <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                            <v-row dense>
                              <v-col cols="12">
                                <h4>거래처별 단가(출고)</h4>
                              </v-col>
                            </v-row>
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="salesPriceList"
                              :selectionMode="'MultiRange'"
                              style="height:295px;"
                              :initialized="flexSalesInitialized"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'거래처'" :binding="'custr_nm'" width="4.5*" />
                              <wj-flex-grid-column :header="'출고일자'" :binding="'sales_purch_dt'" width="3.5*" align="center"/>
                              <wj-flex-grid-column :header="'단가'" :binding="'sitem_unit_price'" width="2.5*" align="right"/>
                            </wj-flex-grid>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                        
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';

const genEmptySitem = () => ({
  sitem_cd: null,
  sitem_nm: null,
  skind_cd: null,
  sitem_div: null,
  stnd: null,
  unit: null,
  thck: null,
  spgrv: null,
  width_len: null,
  width_len_loss: null,
  depth_phi: null,
  depth_phi_loss: null,
  ledgr_chk_yn: null,
  ledgr_chk_tf: false,
  ledgr_wegt: null,
  m_kg: null,
  purch_unit_price: null,
  sales_unit_price: null,
  crovr_invnt: null,
  qty_invnt: null,
  wegt_invnt: null,
  face_pcrt: null,
  side_pcrt: null,
  grind_pcrt: null,
  etc_pcrt: null,
  use_yn: null,
  use_tf: true,
  stnd_item_yn: 'N',
  rm: null,
});

export default {
  components: {numericTextField},
  props: ["pIsFavorited"],
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == 'Y' ? true : false,
      getListMode: 'select',  // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false,   // 항목 변경 여부

      isItemsSourceChanging: false,

      flexSkind: null,    // 강종
      gridSkindList: [],
      skindList: [],
      skindCd: null,
      skindNm: null,
      selectedSkindRow: 0,
      selectedSkindCol: 0,

      flexSitem: null,     // 품목
      gridSitemList: [],   // 그리드에 표시되는 filter된 목록
      sitemList: [],
      sitemCd: null,
      sitemCount: 0,
      selectedSitemRow: 0,
      selectedSitemCol: 0,

      sitemDivList: [],
      sitemUnitList: [],

      selectedRow: 0,
      selectedCol: 0,

      flexStat: null,     // 입출고 현황
      statList: [],
      flexPurch: null,    // 입고 단가
      purchPriceList: [],
      flexSales: null,    // 출고 단가
      salesPriceList: [],
      priceList: [],
      
      searchWordSkind: null,  // 검색어(강종)
      searchWordSitem: null,  // 검색어(품목)

      sitem: {},
      pendingSitem: {},

    }
  },
  computed: {
    isOnPendingSitem() {
      // console.log("isOnPendingSitem:", this.sitem, this.pendingSitem);
      return JSON.stringify(this.sitem) !== JSON.stringify(this.pendingSitem);
    },
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);

    this.getSitemDivList();
    this.getSitemUnitList();

    this.getSkindList();
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.isDestroying = true;
  },
  destroyed() {
    // console.log("destroyed");
  },
  methods: {
    flexSkindInitialized(flex) {
      this.flexSkind = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("e", e);
        let ht = flex.hitTest(e);    
        console.log("ht:", ht, ht.cellType, ht.row); 
        // if(ht.row >= 0)  console.log("CellData:", this.flexSkind.getCellData(ht.row, 0));
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if(this.isOnPendingSitem) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
            }
          }

          if(isConfirmed) {
            this.selectedSkindRow = ht.row;
            this.selectedSkindCol = ht.col;
            this.skindCd = this.flexSkind.getCellData(ht.row, 0);
            this.skindNm = this.flexSkind.getCellData(ht.row, 1);
            
            this.getSitemList();
          }
        }
      });
    },
    // 강종 flexgrid 데이터 변경 전
    skindItemsSourceChanging() {
      if(this.flexSkind && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSkind);
      }
    },
    // 강종 flexgrid 데이터 변경됨
    skindItemsSourceChanged() {
      // console.log("skindItemsSourceChanged:", this.flexSkind, this.selectedSkindRow, this.gridSkindList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexSkind && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexSkind);
      }

      if(this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0 && this.flexSkind.collectionView.itemCount > 0) {
        this.flexSkind.select(-1, 0);
        this.flexSkind.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedSkindRow = 0;
          this.selectedSkindCol = 0;
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexSkind != null && !this.isDestroying && this.gridSkindList.length > 0 && this.selectedSkindRow >= 0 && this.flexSkind.collectionView.itemCount > 0) {
        this.flexSkind.rows[0].isSelected = false;
        this.flexSkind.select(this.selectedSkindRow, this.selectedSkindCol);
        this.skindCd = this.flexSkind.getCellData(this.selectedSkindRow, 0);
        
        this.isItemsSourceChanging = false;
        this.getSitemList();
      }

      console.log("skindItemsSourceChanged");
    },
    
    // 품목 그리드 초기화됨
	  flexSitemInitialized(flex) {
      this.flexSitem = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("e", e);
        let ht = flex.hitTest(e);    
        // console.log("ht:", ht, ht.cellType, ht.row); 
        // if(ht.row >= 0)  console.log("CellData:", this.flexSitem.getCellData(ht.row, 0));
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if(this.isOnPendingSitem) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
            }
          }

          if(isConfirmed) {
            this.selectedSitemRow = ht.row;
            this.selectedSitemCol = ht.col;
            this.sitemCd = this.flexSitem.getCellData(ht.row, 0);

            this.getSitemDetail();
          }
        }
      });
    },
    // 품목 flexgrid 데이터 변경 전
    sitemItemsSourceChanging() {
      if(this.flexSitem) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexSitem);
      }
    },
    // 품목 flexgrid 데이터 변경됨
    sitemItemsSourceChanged() {
      // console.log("sitemItemsSourceChanged:", this.flexSitem, this.selectedSitemRow, this.gridSitemList.length, this.isItemsSourceChanging, this.getListMode);
      if(this.flexSitem) {
        this.$restoreFlexGridState(this.flexSitem);
      }

      if(this.gridSitemList.length == 0) {
        this.isNew = true;
        this.initSitemInfo();
      }

      if(this.flexSitem != null && !this.isDestroying && this.gridSitemList.length > 0 && this.flexSitem.collectionView.itemCount > 0) {
        this.flexSitem.select(-1, 0);
        this.flexSitem.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedSitemRow = 0;
          this.selectedSitemCol = 0;
        } else if(this.getListMode == "insert" || this.getListMode == "update") {
          // 추가된 항목 
          for(let i = 0; i < this.gridSitemList.length; i++) {
            if(this.gridSitemList[i].sitem_cd == this.sitemCd) {
              this.selectedSitemRow = i;
              break;
            }
          }
        } else if(this.getListMode == "delete") {
          // 다음 항목. 마지막인 경우 이전 항목
          this.selectedSitemRow = this.selectedSitemRow < this.gridSitemList.length ? this.selectedSitemRow : this.selectedSitemRow - 1;
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexSitem != null && !this.isDestroying && this.gridSitemList.length > 0 && this.selectedSitemRow >= 0 && this.flexSitem.collectionView.itemCount > 0) {
        this.flexSitem.rows[0].isSelected = false;
        this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
        this.sitemCd = this.flexSitem.getCellData(this.selectedSitemRow, 0);

        // row에 isSelected 설정 시 Ctrl + C -> isSelected row 가 복사됨, 마우스 드래그한 블럭 복사 안됨
        // flexgrid의 selectionMode를 'Row'로 설정할 경우 주석 처리해야 함
        // this.flexSitem.rows[this.selectedSitemRow].isSelected = true;    
        
        this.isItemsSourceChanging = false;
        this.getSitemDetail();
      }
    },

    flexStatInitialized(flex) {
      this.flexStat = flex;
    },
    flexPurchInitialized(flex) {
      this.flexPurch = flex;
    },
    flexSalesInitialized(flex) {
      this.flexSales = flex;
    },

    addFavorite() {
      this.$emit('addFavorite');
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit('deleteFavorite');
      this.isFavorited = false;
    },
    searchWordSkindKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSkindList = this.skindList.filter((e) => {
        return (
            !this.searchWordSkind ||
            e.skind_nm.toLowerCase().indexOf(this.searchWordSkind.toLowerCase()) > -1 ||
            e.skind_cd.toLowerCase().indexOf(this.searchWordSkind.toLowerCase()) > -1
        );
      });
    },
    searchWordSitemKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridSitemList = this.sitemList.filter((e) => {
        return !this.searchWordSitem ||
            e.sitem_nm.toLowerCase().indexOf(this.searchWordSitem.toLowerCase()) > -1 ||
            e.sitem_cd.toLowerCase().indexOf(this.searchWordSitem.toLowerCase()) > -1 ||
            e.stnd.toLowerCase().indexOf(this.searchWordSitem.toLowerCase()) > -1 ||
            e.sitem_div_nm.toLowerCase().indexOf(this.searchWordSitem.toLowerCase()) > -1 ||
            e.use_yn_disp.toLowerCase().indexOf(this.searchWordSitem.toLowerCase()) > -1
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingSitem) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
    // 여기까지 공통
    ///////////////

    getSkindList() {
      // this.isItemsSourceChanging = true;
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.skindList = res.data.skindList;
        this.gridSkindList = res.data.skindList;

        if(this.skindList.length > 0) {
          // 첫번 째 강종 선택
          this.skindCd = this.skindList[0].skind_cd;
          this.skindNm = this.skindList[0].skind_nm;
          // this.getListMode = 'select';
          // this.getSitemList();
        } else {
          alert("등록된 강종이 없습니다. 강종 등록 후 품목 등록할 수 있습니다.")
        }
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    async getSitemDivList() {
      this.sitemDivList = await this.$getCmnCdList('sitem_div');
      // console.log("sitemDivList:", this.sitemDivList);
    },
    async getSitemUnitList() {
      this.sitemUnitList = await this.$getCmnCdList('sitem_unit');
      // console.log("sitemUnitList:", this.sitemUnitList);
    },
    initSitemInfo() {
      const sitem = genEmptySitem();
      this.sitem = JSON.parse(JSON.stringify(sitem, function(k, v) {return v == null ? "" : v}));
      this.pendingSitem = JSON.parse(JSON.stringify(this.sitem));

      this.statList = [];
      this.priceList = [];
      this.salesPriceList = [];
      this.purchPriceList = [];

      // console.log("isNew:", this.isNew);
      // this.sitem_cd = this.isNew ? this.skindCd : null;
      // this.sitem_nm = this.isNew ? this.skindNm : null;
      // this.skind_cd = this.isNew ? this.skindCd : null;
		  // this.sitem_div = null;
		  // this.stnd = null;
		  // this.unit = "kg";
		  // this.thck = null;
		  // this.spgrv = null;
		  // this.width_len = null;
		  // this.width_len_loss = null;
		  // this.depth_phi = null;
		  // this.depth_phi_loss = null;
		  // this.ledgr_chk_yn = false;
		  // this.ledgr_wegt = null;
		  // this.m_kg = null;
		  // this.purch_unit_price = null;
		  // this.sales_unit_price = null;
		  // this.crovr_invnt = null;
		  // this.qty_invnt = null;
		  // this.wegt_invnt = null;
		  // this.face_pcrt = null;
		  // this.side_pcrt = null;
		  // this.grind_pcrt = null;
		  // this.etc_pcrt = null;
		  // this.use_yn = true;
      // this.stnd_item_yn = this.isNew ? 'N' : null,
		  // this.rm = null;

      // // this.isNew = true;
      // this.isItemChanged = false;
    },

    getSitemList() {
      // console.log("getSitemList");
      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("skind_cd", this.skindCd);
      params.append("all", 'Y');

      this.$http.post("/base/getSteelItemList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.sitemList.forEach((e) => {
          e.use_yn_disp = e.use_yn == 'Y' ? '사용' : '미사용';
        });

        this.sitemList = res.data.sitemList;
        this.gridSitemList = res.data.sitemList;
        this.sitemCount = this.sitemList.length;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getSitemDetail() {
      // console.log("getSitemDetail sitemCd:", this.sitemCd);
      const params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("sitem_cd", this.sitemCd);

      this.$http.post("/base/getSteelItemDetail", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        const sitem = res.data.sitem;

        sitem.ledgr_chk_tf = sitem.ledgr_chk_yn == 'Y' ? true : false;
        sitem.use_tf = sitem.use_yn == 'Y' ? true : false;
        
        this.sitem = JSON.parse(JSON.stringify(sitem, function(k, v) {return v == null ? "" : v}));
        this.pendingSitem = JSON.parse(JSON.stringify(this.sitem));

        this.statList = res.data.statList;
        this.priceList = res.data.priceList;
        this.salesPriceList = this.priceList.filter((e) => e.sales_purch_div ==='sales');
        this.purchPriceList = this.priceList.filter((e) => e.sales_purch_div ==='purch');

        this.isNew = false;

      }).catch((error) => {
        console.log(error);
        alert(error.message);
      });
    },

    addSitemBtnClicked() {
      // console.log("selectedSitemRow:", this.selectedSitemRow);
      let isConfirmed = true;

      if(this.isOnPendingSitem) {
        if(!confirm("저장되지 않은 데이터가 있습니다. 새 품목을 추가하시겠습니까?")) {
          isConfirmed = false;
          this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
        }
      }

      if(isConfirmed) {
        this.flexSitem.select(-1, 0);
        this.flexSitem.rows[this.selectedSitemRow].isSelected = false;
        // this.selectedSitemRow = -1;

        this.isNew = true;
        this.initSitemInfo();
      }
    },
    validateInput() {
      // 품목코드
      if(this.pendingSitem.sitem_cd == null || this.pendingSitem.sitem_cd == "") {
        alert('품목코드는 필수 입력값입니다.');
        return false;
      }

      const regex = /^[A-Za-z0-9]*$/;
			if (this.pendingSitem.sitem_cd.length < 2 || regex.test(this.pendingSitem.sitem_cd) == false) {
        alert('품목코드는 영문자, 숫자 포함 2자리 이상입니다.');
        return false;
      }
      
      // 품목명
      if(this.pendingSitem.sitem_nm == null || this.pendingSitem.sitem_nm == "") {
        alert('품목명은 필수 입력값입니다.');
        return false;
      }

      // 구분
      if(this.pendingSitem.sitem_div == null || this.pendingSitem.sitem_div == "") {
        alert('구분은 필수 입력값입니다.');
        return false;
      }
      // 규격
      if(this.pendingSitem.stnd == null || this.pendingSitem.stnd == "") {
        alert('규격은 필수 입력값입니다.');
        return false;
      }
      // 단위
      if(this.pendingSitem.unit == null || this.pendingSitem.unit == "") {
        alert('단위는 필수 입력값입니다.');
        return false;
      }
      // 두께
      if(this.pendingSitem.thck == null || this.pendingSitem.thck == "") {
        alert('두께는 필수 입력값입니다.');
        return false;
      }
      // 비중
      if(this.pendingSitem.spgrv == null || this.pendingSitem.spgrv == "") {
        alert('비중은 필수 입력값입니다.');
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // 입력 데이터 검증
      const isValid = this.validateInput();
      // const isValid = true;
      
      if(isValid) {
      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);
        params.append("skind_cd", this.skindCd);

        this.pendingSitem.ledgr_chk_yn = this.pendingSitem.ledgr_chk_tf ? 'Y' : 'N';
        this.pendingSitem.use_yn = this.pendingSitem.use_tf ? 'Y' : 'N';
        
        params.append('sitem', JSON.stringify(this.pendingSitem));

        this.$http.post("/base/upsertSteelItem", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");

          if(this.isNew) {
            this.getListMode = 'insert';
          } else {
            this.getListMode = 'update';
          }

          this.getSitemList();
          this.isNew = false;
          // this.isItemChanged = false;
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    deleteBtnClicked() {
      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("comp_sno", this.compSno);
        params.append("sitem_cd", this.sitemCd);
        
        this.$http.post("/base/deleteSteelItem", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("삭제되었습니다.");
          
          this.getListMode = 'delete';
          
          this.getSitemList();
          // this.initSitemInfo();
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingSitem) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        this.flexSitem.select(this.selectedSitemRow, this.selectedSitemCol);
        this.getSitemDetail();
      }
    },
  }
}
</script>

	   

<style>


</style>