<template>
  <v-container fluid class="d-flex flex-column" style="height: 100%;">
    <v-row class="flex-grow-0" dense>
      <v-col cols="12" class="d-flex">
        <div>
          <h2>수금/지급 관리</h2>
        </div>
        <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
        <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
      </v-col>
      <v-col cols="12">
        <v-btn-toggle tile v-model="itemDiv" mandatory color="indigo darken-4">
          <v-btn small color="grey lighten-3" class="font-weight-bold" value="colct" :disabled="isOnPendingItem">수금 관리</v-btn>
          <v-btn small color="grey lighten-3" class="font-weight-bold" value="pay" :disabled="isOnPendingItem">지급 관리</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row class="align-baseline flex-grow-0">
      <v-col cols="1">
        <span>조회수 : {{ filteredItems.length }}건</span>
      </v-col>
      <v-col cols="2" class="d-flex align-baseline">
        <span class="pr-2">조회일자</span>
        <date-picker v-model="content[itemDiv].srchDt" :disabled="isOnPendingItem"></date-picker>
      </v-col>
      <v-col cols="2" class="d-flex align-baseline">
        <span class="pr-2">거래처</span>
        <v-text-field
          class="" v-model="content[itemDiv].pendingWord"
          hide-details dense outlined clearable :disabled="isOnPendingItem"
          @click:clear="content[itemDiv].searchWord = ''"
          @focusout="content[itemDiv].searchWord = content[itemDiv].pendingWord"
          @keyup.enter="content[itemDiv].searchWord = content[itemDiv].pendingWord"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn small width="100" color="blue darken-2" class="white--text mr-5" :disabled="!content[itemDiv].pendingItem.item_sno || isDummy">출력</v-btn>
        <v-btn small width="100" color="blue darken-2" class="white--text" :disabled="!content[itemDiv].pendingItem.item_sno" @click="onCreateItem">
          {{ itemDiv === 'colct' ? '수금 추가' : itemDiv === 'pay' ? '지급 추가' : '' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="4" class="d-flex flex-column">
        <wj-flex-grid
            class="mt-1 flex-grow-1"
            style="height: 600px"
            :isReadOnly=true
            :itemsSource="filteredItems"
            :selectionMode="isOnPendingItem ? 'None' : 'ListBox'"
            :initialized="onItemGridInit"
            headersVisibility="Column"
        >
          <wj-flex-grid-column header="수금지급일련번호" binding="item_sno" width="1*" :visible="false" />
          <wj-flex-grid-column header="일자" binding="colct_pay_dt" width="12*" align="center"/>
          <wj-flex-grid-column header="거래처" binding="custr_nm" width="20*" />
          <wj-flex-grid-column header="금액" binding="sum_amt" width="15*" align="right"/>
          <wj-flex-grid-column header="비고사항" binding="rm" width="20*" />
          <wj-flex-grid-column header="작성자" binding="reg_user_nm" width="10*" />
        </wj-flex-grid>
      </v-col>
      <v-col cols="8" class="grow">
        <v-card class="mt-1 pa-1 elevation-0 bgcolor-grey1" style="background: magenta;">
          <v-row dense>
            <v-col cols="12" class="d-flex justify-end align-center">
              <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" v-show="content[itemDiv].pendingItem.item_sno && !isDummy" @click="onItemDelete">삭제</v-btn>
              <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3 font-weight-bold" width="70" :disabled="isItemCancellationDisabled" @click="onItemCancel">취소</v-btn>
              <v-btn small color="indigo darken-3" class="white--text font-weight-bold" width="70" :disabled="isItemSaveDisabled" @click="onItemSave">저장</v-btn>
            </v-col>
            <v-col cols="12">
              <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                <v-row dense class="align-center">
                  <v-col cols="12">
                    <h4> {{ itemDiv === 'colct' ? '수금 상세' : '지급 상세' }}</h4>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span>전표번호</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end pr-1">
                    <v-text-field v-model="content[itemDiv].pendingItem.colct_pay_no" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span style="color:red;">* </span><span>전표일자</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end pr-1">
                    <date-picker v-model="content[itemDiv].pendingItem.colct_pay_dt" :disabled="isDummy"></date-picker>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span style="color:red;">* </span><span>거래처명</span>
                  </v-col>
                  <v-col cols="2" class="d-flex justify-end pr-1">
                    <v-text-field
                      v-model="content[itemDiv].pendingItem.custr_nm"
                      class=""
                      hide-details dense outlined readonly
                      background-color="grey
                      lighten-2"
                      append-icon="mdi-magnify"
                      @click:append="onCustr"
                      :disabled="isDummy"
                      >
                    </v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span>대표자</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <v-text-field v-model="content[itemDiv].pendingItem.repr_nm" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
                  </v-col>
                  <v-col></v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span> {{ itemDiv === 'colct' ? '최근 매출일' : '최근 매입일' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <v-text-field v-model="content[itemDiv].pendingItem.last_sales_purch_dt" hide-details dense outlined disabled background-color="grey lighten-2"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span> {{ itemDiv === 'colct' ? '최근 매출액' : '최근 매입액' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field v-model="content[itemDiv].pendingItem.last_sales_purch_sum_amt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span>잔액</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field :value="content[itemDiv].pendingItem.un_colct_pay_amt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end align-center pr-1">
                    <span>비고사항</span>
                  </v-col>
                  <v-col cols="5" class="d-flex justify-end">
                    <v-text-field v-model="content[itemDiv].pendingItem.rm" dense hide-details outlined background-color="white" :disabled="isDummy"></v-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span> {{ itemDiv === 'colct' ? '현금 수금액' : '현금 지급액' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field :value="cashAmt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span> {{ itemDiv === 'colct' ? '어음 수금액' : '어음 지급액' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field :value="pnoteAmt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span> {{ itemDiv === 'colct' ? '카드 수금액' : '카드 지급액' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field :value="cardAmt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <span>{{ itemDiv === 'colct' ? '총 수금액' : '총 지급액' }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex justify-end pr-1">
                    <numeric-text-field :value="sumAmt" read-only bg-color="grey lighten-2"></numeric-text-field>
                  </v-col>
                </v-row>
                <v-row dense align="center">
                  <v-col cols="1" class="pr-1 d-flex align-center">
                    <span>{{ itemDiv === 'colct' ? '* 수금 항목' : '* 지급 항목' }}</span>
                  </v-col>
                  <v-col cols="1"><span>조회수 : {{ content[itemDiv].pendingDtls.length }}</span></v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" align="right">
                    <v-btn small min-width="70px" class="pa-0 ma-0 mr-3 font-weight-bold" :disabled="isItemDtlDeleteDisabled" @click="onItemDtlDelete">항목삭제</v-btn>
                    <v-btn small min-width="70px" color="blue darken-4" class="white--text font-weight-bold" :disabled="isDummy" @click="onItemDtlAdd">항목추가</v-btn>
                  </v-col>
                  <v-col cols="12">
                    <wj-flex-grid
                        class="mt-1 flex-grow-1"
                        style="height: 400px;"
                        :itemsSource="content[itemDiv].pendingDtls"
                        selectionMode="MultiRange"
                        :deferResizing="true"
                        :alternatingRowStep="0"
                        :showMarquee="true"
                        :allowDelete="true"
                        :allowSorting="false"
                        :initialized="onItemDtlGridInit"
                        :itemsSourceChanged="onItemDtlGridChanged"
                        headersVisibility="All"
                        :cellEditEnding="onItemDtlEditEnding"
                        :cellEditEnded="onItemDtlEditEnded"
                    >
                      <!-- <wj-flex-grid-column header="□" binding="select_yn" :width="50" dataType="Boolean" /> -->
                      <wj-flex-grid-column header="항목" binding="" :width="80" :cellTemplate="createAcctTemplate"/>
                      <wj-flex-grid-column header="코드" binding="acct_cd" :width="80" align="center" :isRequired="true"/>
                      <wj-flex-grid-column header="계정명" binding="acct_nm" :width="150" :isReadOnly="true" />
                      <wj-flex-grid-column header="금액" binding="colct_pay_amt" :width="150" format="N0" align="right"/>
                      <wj-flex-grid-column header="비고" binding="rm" width="1*" />
                    </wj-flex-grid>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <search-custr-popup
        v-if="popup.custr.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_salesPurchDiv="itemDiv === 'colct' ? 'sales' : 'purch'"
        @close="popup.custr.onClose && popup.custr.onClose()"
        @selected="custrSno => popup.custr.onClose && popup.custr.onClose(custrSno)"
    ></search-custr-popup>
    <search-acct-popup
        v-if="popup.acct.isOpened"
        :p_compSno="$store.state.session.compSno"
        :p_acctDiv="itemDiv === 'colct' ? 'incm' : 'expn'"
        @close="popup.acct.onClose && popup.acct.onClose()"
        @selected="acctSno => popup.acct.onClose && popup.acct.onClose(acctSno)"
    ></search-acct-popup>
  </v-container>
</template>

<script>
import * as wjGrid from '@grapecity/wijmo.grid';
import {CellMaker} from '@grapecity/wijmo.grid.cellmaker';
import {Selector} from '@grapecity/wijmo.grid.selector';
import {ACC_TYPE, COLCT_PAY_DIV, SUB_TAB} from "@/common/constant";
import moment from 'moment';
import searchAcctPopup from '@/components/common/searchAcctPopup.vue';
import datePicker from '@/components/common/datePicker.vue';
import numericTextField from "@/components/common/numericTextField.vue";
import {v4 as uuidv4} from "uuid";
import searchCustrPopup from "@/components/common/searchCustrPopup.vue";

const DUMMY_ITEM_SNO = '__dummy__';

const genEmptyItem = ({
  item_sno = null,
  colct_pay_dt = moment().format('YYYY-MM-DD'),
  colct_pay_div = COLCT_PAY_DIV.colct.code,
}) => ({
  item_sno: item_sno,
  colct_pay_sno: null,
  comp_sno: null,
  colct_pay_no: null,
  colct_pay_div: colct_pay_div,
  colct_pay_dt: colct_pay_dt,
  cash_amt: 0,
  pnote_amt: 0,
  sum_amt: 0,
  rm: null,
  custr_sno: null,
  custr_nm: null,
  repr_nm: null,
  reg_user_sno: null,
  reg_user_nm: null,
  biz_rno: null,
  // tel_no: null,
  // fax_no: null,
  // biz_plc_addr: null,
  // chrgr_nm: null,
  // chrgr_cel_no: null,
  // chrgr_email: null,
});

const genEmptyItemDtl = () => ({
  uuid: uuidv4(),
  odr: null,
  acct_cd: null,
  acct_type: null,
  colct_pay_amt: 0,
  rm: null,
  bank_acct_sno: null,
  user_sno: null,
});

export default {
  components: {searchCustrPopup, searchAcctPopup, datePicker, numericTextField},
  created() {
    this.$store.dispatch('refreshCommonCode', 'colct_pay_div');
    this.$store.dispatch('refreshCommonCode', 'acct_type');
  },
  data() {
    return {
      itemDiv: COLCT_PAY_DIV.colct.code,
	    selector: null,   // 그리드 체크박스
      itemGrid: null,
      itemDtlGrid: null,

      content: Object.values(COLCT_PAY_DIV).reduce((acc, {code}) => ({
        ...acc,
        [code]: {
          srchDt: moment().format('YYYY-MM-DD'),
          pendingWord: '',
          searchWord: '',
          items: [],
          item: null,
          pendingItem: genEmptyItem({
            item_sno: DUMMY_ITEM_SNO,
            colct_pay_div: code,
          }),
          restoreItem: null,
          itemDtls: [],
          pendingDtls: [],
          restoreDtls: null,
          restoreDtl: null,
        },
      }), {}),

      popup: {
        custr: {
          isOpened: false,
          onClose: null,
        },
        acct: {
          isOpened: false,
          onClose: null,
        },
        bankAcct: {
          isOpened: false,
          onClose: null,
        },
      },

      createAcctTemplate: CellMaker.makeButton({
        text: '선택',
        click: (e, ctx) => this.onAcctClicked(e, ctx),
      }),
    };
  },
  methods: {
    async loadItems() {
      let params = new FormData();
      const target = this.content[this.itemDiv];
      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("srch_dt", target.srchDt);
      try {
        const res = await this.$http.post('/accounting/getColctPayList', params);
        const items = res.data.colctPayList.map(item => ({
          ...item,
          item_sno: `${item.colct_pay_sno}`,
          colct_pay_sno: `${item.colct_pay_sno}`,
        }));

        const itemDivs = this.$store.state.commonCode.colct_pay_div.map(({cmn_cd}) => cmn_cd);
        itemDivs.forEach(div => {
          const target = this.content[div];
          target.items = items.filter(({colct_pay_div}) => colct_pay_div === div);
        });

      } catch (e) {
        console.log(`Error on function loadItems: ${e}`);
      }
    },
    async loadItem(itemSno) {
      const target = this.content[this.itemDiv];

      if (itemSno === DUMMY_ITEM_SNO) {
        const item = genEmptyItem({
          item_sno: DUMMY_ITEM_SNO,
          colct_pay_div: this.itemDiv,
          colct_pay_dt: this.srchDt,
        });
        const itemDtls = [];
        target.item = JSON.parse(JSON.stringify(item));
        target.pendingItem = JSON.parse(JSON.stringify(item));
        target.restoreItem = null;
        target.itemDtls = JSON.parse(JSON.stringify(itemDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(itemDtls));
        target.restoreDtls = null;
        return;
      }

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      const salesPurchDiv = {'colct': 'sales', 'pay': 'purch'}[this.itemDiv];

      params.append("comp_sno", compSno);
      params.append("colct_pay_sno", itemSno);
      params.append("sales_purch_div", salesPurchDiv);
      params.append("colct_pay_div", this.itemDiv);

      try {
        const res = await this.$http.post('/accounting/getColctPay', params);
        const item = {
          ...res.data.colctPay,
          item_sno: `${res.data.colctPay.colct_pay_sno}`,
          colct_pay_sno: `${res.data.colctPay.colct_pay_sno}`,
          last_sales_purch_dt: res.data.lastSalesPurch && res.data.lastSalesPurch.sales_purch_dt,
          last_sales_purch_sum_amt: res.data.lastSalesPurch && res.data.lastSalesPurch.sum_amt,
          un_colct_pay_amt: res.data.unColctPayAmt && res.data.unColctPayAmt.un_colct_pay_amt || 0,
        };
        const itemDtls = res.data.colctPayDtlList.map(dtl => ({
          ...dtl,
          uuid: uuidv4(),
          select_yn: false,
        }));
        target.item = JSON.parse(JSON.stringify(item));
        target.pendingItem = JSON.parse(JSON.stringify(item));
        target.restoreItem = null;
        target.itemDtls = JSON.parse(JSON.stringify(itemDtls));
        target.pendingDtls = JSON.parse(JSON.stringify(itemDtls));
        target.restoreDtls = null;

        await Promise.all(itemDtls.filter(dtl => dtl.acct_cd).map(({acct_cd}) =>
            this.$store.dispatch('cacheAcct', {
              acctCd: acct_cd,
            })
        ));

      } catch (e) {
        console.log(`Error on function loadItem: ${e}`);
      }
    },
    onItemGridInit(flex) {
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        if (this.isOnPendingItem) return;

        const target = this.content[this.itemDiv];
        const itemSno = flex.getCellData(ht.row, 0);
        if (itemSno !== target.pendingItem.item_sno)
          await this.loadItem(itemSno);
      });
      this.itemGrid = flex;
    },
    onItemGridChanging() {
    },
    onItemGridChanged() {
    },
    onItemDtlGridInit(flex) {
      this.itemDtlGrid = flex;

      this.selector = new Selector(flex, {
        itemChecked: () => {
          flex.rows.map((r) => r.dataItem.select_yn = r.isSelected);
        }
      });

      flex.selectionMode = "MultiRange";
    },
    onItemDtlGridChanged() {
    },
    onItemDtlEditEnding(flex, event) {
      const target = this.content[this.itemDiv];
      const pendingDtl = target.pendingDtls[event.row];
      target.restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
    },
    async onItemDtlEditEnded(flex, event) {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      const pendingDtl = target.pendingDtls[event.row];
      const restoreDtl = target.restoreDtl;
      target.restoreDtl = null;
      const column = flex.columns[event.col];

      await this.refreshItemDtl(column.binding, pendingItem, pendingDtl, restoreDtl);
    },
    async refreshItemDtl(binding, pendingItem, pendingDtl, restoreDtl) {
      if ('acct_cd' === binding) {
        const acct = await this.makeItemDtlAcct(pendingItem, pendingDtl);
        if (!acct) {
          alert('계정이 존재하지 않습니다.');
          Object.assign(pendingDtl, restoreDtl);
          return this.itemDtlGrid.invalidate();
        }
        Object.assign(pendingDtl, acct);
      }
      this.itemDtlGrid.invalidate();
      // target.pendingDtls = target.pendingDtls.map((dtl, index) => index !== event.row ? dtl : pendingDtl)
    },
    async onAcctClicked(event, context) {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      const pendingDtl = target.pendingDtls[context.row.index];

      const [acctCd] = await new Promise(r => this.popup.acct = {
        isOpened: true,
        onClose: (...param) => r(param)
      });
      this.popup.acct.isOpened = false;
      if (!acctCd) return;

      const restoreDtl = JSON.parse(JSON.stringify(pendingDtl));
      pendingDtl.acct_cd = acctCd;
      await this.refreshItemDtl('acct_cd', pendingItem, pendingDtl, restoreDtl);
    },
    async makeItemDtlAcct(item, itemDtl) {
      const acct = await this.$store.dispatch('cacheAcct', {
        acctCd: itemDtl.acct_cd,
      });
      if (!acct) return;
      return {
        acct_cd: acct.acct_cd,
        acct_nm: acct.acct_nm,
        acct_type: acct.acct_type,
      };
    },
    onCreateItem() {
      const isOnPendingItem = this.isOnPendingItem;
      if (isOnPendingItem && !confirm('저장되지 않은 데이터가 있습니다. 새 주문을 추가하시겠습니까?'))
        return;

      const target = this.content[this.itemDiv];
      const canRestore = target.item && !!target.item.item_sno;
      const restoreItem = canRestore ? JSON.parse(JSON.stringify(target.item)) : null;
      const restoreDtls = canRestore ? JSON.parse(JSON.stringify(target.itemDtls)) : null;

      const item = genEmptyItem({
        colct_pay_dt: this.srchDt,
        colct_pay_div: this.itemDiv,
      });
      target.item = JSON.parse(JSON.stringify(item));
      target.pendingItem = JSON.parse(JSON.stringify(item));
      target.restoreItem = restoreItem;

      target.itemDtls = [];
      target.pendingDtls = [];
      target.restoreDtls = restoreDtls;
    },
    verifyItem() {
      const target = this.content[this.itemDiv];
      const pendingItem = target.pendingItem;
      if (!pendingItem.colct_pay_dt) {
        alert('전표일자는 필수 입력값입니다.');
        return false;
      }
      if (!pendingItem.custr_sno) {
        alert('거래처는 필수 입력값입니다.');
        return false;
      }
      return true;
    },
    async onItemSave() {
      if (!this.verifyItem()) return;

      const params = new URLSearchParams();
      const target = this.content[this.itemDiv];
      params.append('new_yn', !target.pendingItem.item_sno ? 'Y' : 'N');
      params.append('comp_sno', this.$store.state.session.compSno);
      params.append('custr_sno', target.pendingItem.custr_sno);
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('colct_pay_sno', target.pendingItem.colct_pay_sno);
      params.append('colct_pay_div', this.itemDiv);
      params.append('colct_pay_dt', target.pendingItem.colct_pay_dt);
      params.append('cash_amt', `${this.cashAmt}`);
      params.append('pnote_amt', `${this.pnoteAmt}`);
      params.append('card_amt', `${this.cardAmt}`);
      params.append('sum_amt', `${this.sumAmt}`);
      if(target.pendingItem.rm != null)  params.append('rm', target.pendingItem.rm);
      if (target.pendingItem.item_sno) params.append('prev_sum_amt', target.item.sum_amt);

      const itemDtls = target.pendingDtls.filter(({acct_cd}) => acct_cd).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
      }));
      params.append('colctPayDtlList', JSON.stringify(itemDtls));
      const deletedDtls = target.itemDtls.filter(({uuid}) => target.pendingDtls.every(pending => pending.uuid !== uuid)).map(dtl => ({
        ...dtl,
        new_yn: dtl.new_yn ? 'Y' : 'N',
      }));
      params.append('deletedDtlList', JSON.stringify(deletedDtls));

      try {
        const res = await this.$http.post('/accounting/upsertColctPay', params);
        if (res.data.code === 0)
          await this.loadItem(res.data.colct_pay_sno);
        await this.loadItems();

      } catch (e) {
        console.log(`Error on function onItemSave: ${e}`);
      }
    },
    async onItemDelete() {
      if (!confirm('삭제하시겠습니까?')) return;

      const target = this.content[this.itemDiv];
      const params = new FormData();
      params.append('colct_pay_sno', target.pendingItem.item_sno);

      await this.$http.post('/accounting/deleteColctPay', params);
      alert('삭제되었습니다.');

      target.pendingItem = genEmptyItem({
        item_sno: DUMMY_ITEM_SNO,
        colct_pay_div: this.itemDiv,
        colct_pay_dt: this.srchDt,
      });

      await this.loadItems();
    },
    onItemCancel() {
      if (this.isOnPendingItem && !confirm('편집을 취소하시겠습니까?'))
        return;

      const target = this.content[this.itemDiv];

      const restoreItem = target.restoreItem || target.item;
      const restoreDtls = target.restoreDtls || target.itemDtls;
      target.item = JSON.parse(JSON.stringify(restoreItem));
      target.itemDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.pendingItem = JSON.parse(JSON.stringify(restoreItem));
      target.pendingDtls = JSON.parse(JSON.stringify(restoreDtls));
      target.restoreItem = null;
      target.restoreDtls = null;
    },
    async onCustr() {
      const custrSno = await new Promise(r => {
        this.popup.custr = {
          isOpened: true, onClose: r
        }
      });
      if (custrSno) {
        const compSno = this.$store.state.session.compSno;
        const salesPurchDiv = {'colct': 'sales', 'pay': 'purch'}[this.itemDiv];

        const params = new FormData();
        params.append("comp_sno", compSno);
        params.append("custr_sno", custrSno);
        params.append("sales_purch_div", salesPurchDiv);
        params.append("colct_pay_div", this.itemDiv);

        try {
          const custrRes = await this.$http.post('/base/getCustrDetail', params)
          const salesPurchRes = await this.$http.post('/accounting/getSalesPurchColctPayInfo', params);

          const target = this.content[this.itemDiv];
          target.pendingItem = {
            ...target.pendingItem,
            custr_sno: custrRes.data.custr.custr_sno,
            custr_nm: custrRes.data.custr.custr_nm,
            repr_nm: custrRes.data.custr.repr_nm,
            biz_rno: custrRes.data.custr.biz_rno,
            last_sales_purch_dt: salesPurchRes.data.lastSalesPurch && salesPurchRes.data.lastSalesPurch.sales_purch_dt,
            last_sales_purch_sum_amt: salesPurchRes.data.lastSalesPurch && salesPurchRes.data.lastSalesPurch.sum_amt,
            un_colct_pay_amt: salesPurchRes.data.unColctPayAmt && salesPurchRes.data.unColctPayAmt.un_colct_pay_amt || 0,
          };

        } catch (e) {
          console.log(`Error on function onCustr: ${e}`);
        }
      }
      this.popup.custr = {isOpened: false};
    },
    onItemDtlAdd() {
      const target = this.content[this.itemDiv];
      const nextOdr = target.pendingDtls.reduce((acc, {odr}) => odr < acc ? acc : odr, 0) + 1;
      target.pendingDtls = [
        ...target.pendingDtls, {
          ...genEmptyItemDtl(),
          uuid: uuidv4(),
          new_yn: true,
          odr: nextOdr,
          select_yn: false,
        }
      ];
    },
    onItemDtlDelete() {
      const target = this.content[this.itemDiv];
      target.pendingDtls = target.pendingDtls.filter(({select_yn}) => !select_yn);
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.collectPay.code);
    },
    filteredItems() {
      console.log("filteredItems");
      const target = this.content[this.itemDiv];
      return target.items.filter(({custr_nm}) =>
          !target.searchWord || target.searchWord.trim() === '' ||
          (custr_nm && custr_nm.toUpperCase().includes(target.searchWord.toUpperCase()))
      );
    },
    isItemCancellationDisabled() {
      if (this.isOnPendingItem) return false

      const target = this.content[this.itemDiv];
      if  (target.restoreItem) return false

      return true;
    },
    isItemSaveDisabled() {
      const target = this.content[this.itemDiv];
      if (!target.pendingItem.item_sno) return false;
      if (this.isOnPendingItem) return false

      return true;
    },
    isOnPendingItem() {
      const target = this.content[this.itemDiv];
      return !this.isDummy && 
          ( JSON.stringify(target.item) !== JSON.stringify(target.pendingItem) ||
            JSON.stringify(target.itemDtls.map(dtl => ({...dtl, select_yn: false})))
            !== JSON.stringify(target.pendingDtls.map(dtl => ({...dtl, select_yn: false}))) );
    },
    isDummy() {
      const target = this.content[this.itemDiv];
      return target.pendingItem && target.pendingItem.item_sno == DUMMY_ITEM_SNO ? true : false;
    },
    cashAmt() {
      const target = this.content[this.itemDiv];
      return Number(target.pendingDtls.filter(({acct_type}) => acct_type === ACC_TYPE.cash.code)
                    .reduce((acc, {colct_pay_amt}) => acc + (Number(colct_pay_amt) || 0), 0));
    },
    pnoteAmt() {
      const target = this.content[this.itemDiv];
      return Number(target.pendingDtls.filter(({acct_type}) => acct_type === ACC_TYPE.pnote.code)
                    .reduce((acc, {colct_pay_amt}) => acc + (Number(colct_pay_amt) || 0), 0));
    },
    cardAmt() {
      const target = this.content[this.itemDiv];
      return Number(target.pendingDtls.filter(({acct_type}) => acct_type === ACC_TYPE.card.code)
                    .reduce((acc, {colct_pay_amt}) => acc + (Number(colct_pay_amt) || 0), 0));
    },
    sumAmt() {
      return Number(this.cashAmt) + Number(this.pnoteAmt) + Number(this.cardAmt);
    },
    isItemDtlDeleteDisabled() {
      const target = this.content[this.itemDiv];
      return !target.pendingDtls.some(({select_yn}) => select_yn);
    },
    searchDate() {
      const itemDivs = this.$store.state.commonCode.colct_pay_div.map(({cmn_cd}) => cmn_cd);
      return itemDivs.map(div => ({div, srchDt: this.content[div].srchDt}));
    },
  },
  watch: {
    async filteredItems(newValue) {
      const [first] = newValue;
      const sno = first && first.colct_pay_sno || DUMMY_ITEM_SNO;
      await this.loadItem(sno);
    },
    async searchDate() {
      await this.loadItems();
    },
  }
}
</script>

<style>


</style>
