<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div>
              <h2>재고 현황</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn-toggle tile v-model="gridDiv" mandatory color="indigo darken-4">
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="invnt">재고현황</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="ship">수불현황</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="salesOrd">재고/발주</v-btn>
              <v-btn small color="grey lighten-3" class="font-weight-bold" value="optm">적정재고</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center">
              <!-- 재고현황 -->
              <v-col cols="12" v-show="gridDiv == 'invnt'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <!-- 강종 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">강종</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSkindInvnt"
                              @focusout="searchWordSkindInvnt = pendingWordSkindInvnt"
                              @keyup.enter="searchWordSkindInvnt = pendingWordSkindInvnt"
                              clearable
                              @click:clear="searchWordSkindInvnt = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center" class="">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ stusListInvnt.length }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="5">
                                    <v-row dense align="center">
                                      <v-col cols="4">
                                        <date-picker v-model="srchBgnDtInvnt"></date-picker>
                                      </v-col>
                                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                      <v-col cols="4">
                                        <date-picker v-model="srchEndDtInvnt"></date-picker>
                                      </v-col>
                                    </v-row>
                                  </v-col>                                  
                                </v-row>
                              </v-col>
                            </v-row>  
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <!-- 강종 그리드 row -->
                    <v-row dense align="center">              
                      <!-- 강종 그리드 -->
                      <v-col cols="2">
                        <wj-flex-grid 
                          :isReadOnly=true
                          :itemsSource="filteredSkindListInvnt"
                          :selectionMode="'ListBox'"
                          style="height:650px;"
                          :initialized="onFlexSkindInvntInit"
                          :itemsSourceChanged="onFlexSkindInvntItemsSourceChanged"
                          :headersVisibility="'Column'"
                          :alternatingRowStep="0"
                          >
                          <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="3*" align="center"/>
                          <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="10*" />
                        </wj-flex-grid>
                      </v-col>
                      <!-- 통계 영역 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <!-- 통계 row -->
                          <v-col cols="12">
                            <v-row dense align="center" class="">
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="6"><div class=""><span style="color:blue;">매출수량 : {{ statsInvnt.salesQty }} 개</span></div></v-col>
                                  <v-col cols="6"><div class=""><span style="color:blue;">매출중량 : {{ statsInvnt.salesWegt }} kg</span></div></v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="6"><div class=""><span style="color:blue;">매입수량 : {{ statsInvnt.purchQty }} 개</span></div></v-col>
                                  <v-col cols="6"><div class=""><span style="color:blue;">매입중량 : {{ statsInvnt.purchWegt }} kg</span></div></v-col>
                                </v-row>
                              </v-col>                 
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="6"><div class=""><span style="color:blue;">재고수량 : {{ statsInvnt.invntQty }} 개</span></div></v-col>
                                  <v-col cols="6"><div class=""><span style="color:blue;">재고중량 : {{ statsInvnt.invntWegt }} kg</span></div></v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="3">
                                <v-row dense align="center">
                                  <v-col cols="6"><div class=""><span style="color:blue;">재고금액 : {{ statsInvnt.invntAmt }} 원</span></div></v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <!-- 현황 그리드 -->
                          <v-col cols="12">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="stusListInvnt" 
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexStusInvntInitialized"
                              :itemsSourceChanged="onFlexStusInvntItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              :allowMerging="'ColumnHeaders'"
                              :autoGenerateColumns="false"
                              >
                              <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" :width="70" align="center"/>
                              <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150" />
                              <wj-flex-grid-column :header="'규격'" :binding="'stnd'" :width="60" align="center"/>
                              <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" align="center"/>
                              <wj-flex-grid-column :header="'가로/길이'" :binding="'width_len'" :width="80" align="center" :visible="false"/>
                              <wj-flex-grid-column :header="'세로'" :binding="'depth_phi'" :width="80" align="center" :visible="false"/>
                              <wj-flex-grid-column :header="'수량'" :binding="'crovr_qty'" :width="90" align="right"/>
                              <wj-flex-grid-column :header="'중량'" :binding="'crovr_wegt'" :width="90" align="right" :css-class="wegtColumnStyle"/>
                              <wj-flex-grid-column :header="'수량'" :binding="'sales_qty'" :width="90" align="right"/>
                              <wj-flex-grid-column :header="'중량'" :binding="'sales_wegt'" :width="90" align="right" :css-class="wegtColumnStyle"/>
                              <wj-flex-grid-column :header="'수량'" :binding="'purch_qty'" :width="90" align="right"/>
                              <wj-flex-grid-column :header="'중량'" :binding="'purch_wegt'" :width="90" align="right" :css-class="wegtColumnStyle"/>
                              <wj-flex-grid-column :header="'수량'" :binding="'invnt_qty'" :width="90" align="right"/>
                              <wj-flex-grid-column :header="'중량'" :binding="'invnt_wegt'" :width="90" align="right" :css-class="wegtColumnStyle"/>
                              <wj-flex-grid-column :header="'평균단가'" :binding="'avg_unit_price'" :width="120" align="right"/>
                              <wj-flex-grid-column :header="'재고금액'" :binding="'invnt_amt'" :width="150" align="right" format="N0" :css-class="customColumnStyle"/>
                            </wj-flex-grid>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 수불현황 -->
              <v-col cols="12" v-show="gridDiv == 'ship'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-row dense align="center">
                      <!-- 강종 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">강종</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSkindShip"
                              @focusout="searchWordSkindShip = pendingWordSkindShip"
                              @keyup.enter="searchWordSkindShip = pendingWordSkindShip"
                              clearable
                              @click:clear="searchWordSkindShip = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 row -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ stusListShip.length }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="5">
                                    <v-row dense align="center">
                                      <v-col cols="4">
                                        <date-picker v-model="srchBgnDtShip"></date-picker>
                                      </v-col>
                                      <v-col cols="1"><div class="" style="text-align: center;"><span>~</span></div></v-col>
                                      <v-col cols="4">
                                        <date-picker v-model="srchEndDtShip"></date-picker>
                                      </v-col>
                                    </v-row>
                                  </v-col>                          
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>  
                    </v-row>

                    <!-- 강종 그리드 row -->
                    <v-row dense align="center">              
                      <v-col cols="12">
                        <v-row dense align="center">
                          <!-- 강종 그리드 -->
                          <v-col cols="2">
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredSkindListShip"
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexSkindShipInit"
                              :itemsSourceChanged="onFlexSkindShipItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="3*" align="center"/>
                              <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="10*" />
                            </wj-flex-grid>
                          </v-col>
                          <v-col cols="10">
                            <!-- 통계 row -->
                            <v-row dense align="center">
                              <v-col cols="12">
                                <v-row dense align="center" class="">
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">이월재고수량 : {{ statsShip.crovrQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">이월재고중량 : {{ statsShip.crovrWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">증가수량 : {{ statsShip.incrQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">증가중량 : {{ statsShip.incrWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>                 
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">감소수량 : {{ statsShip.decrQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">감소중량 : {{ statsShip.decrWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">재고수량 : {{ statsShip.invntQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">재고중량 : {{ statsShip.invntWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- 현황 그리드 -->
                              <v-col cols="12">
                                <wj-flex-grid 
                                  :isReadOnly=true
                                  :itemsSource="stusListShip" 
                                  :selectionMode="'ListBox'"
                                  style="height:650px;"
                                  :initialized="onFlexStusShipInitialized"
                                  :itemsSourceChanged="onFlexStusShipItemsSourceChanged"
                                  :headersVisibility="'Column'"
                                  :alternatingRowStep="1"
                                  :allowMerging="'ColumnHeaders'"
                                  >
                                  <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" :width="70" align="center"/>
                                  <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                                  <wj-flex-grid-column :header="'규격'" :binding="'stnd'" :width="60" align="center"/>
                                  <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" align="center"/>
                                  <wj-flex-grid-column :header="'이월재고중량'" :binding="'crovr_qty_wegt'" :width="120" :allowSorting="false"/>
                                  <wj-flex-grid-column :header="'매입증가중량'" :binding="'incr_purch_qty_wegt'" :width="120" align="right" :allowSorting="false"/>
                                  <wj-flex-grid-column :header="'조정증가중량'" :binding="'incr_adjst_qty_wegt'" :width="120" align="right" :allowSorting="false"/>
                                  <!-- <wj-flex-grid-column :header="'생산중량'" :binding="'incr_prdtn_qty_wegt'" :width="100" align="right" :allowSorting="false"/> -->
                                  <wj-flex-grid-column :header="'증가중량 계'" :binding="'incr_sum_qty_wegt'" :width="120" align="right" :allowSorting="false"/>
                                  <wj-flex-grid-column :header="'매출감소중량'" :binding="'decr_sales_qty_wegt'" :width="120" align="right" :allowSorting="false"/>
                                  <!-- <wj-flex-grid-column :header="'투입중량'" :binding="'decr_input_qty_wegt'" :width="100" align="right" :allowSorting="false"/> -->
                                  <!-- <wj-flex-grid-column :header="'중량 계'" :binding="'decr_sum_qty_wegt'" :width="100" align="right" :allowSorting="false"/> -->
                                  <wj-flex-grid-column :header="'현재고중량'" :binding="'invnt_qty_wegt'" :width="120" align="right" :allowSorting="false" />
                                  <wj-flex-grid-column :header="'수량중량구분'" :binding="'qty_wegt_div'" :width="20" :visible="false"/>
                                </wj-flex-grid>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>               
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <!-- 재고/발주 -->
              <v-col cols="12" v-show="gridDiv == 'salesOrd'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- 조회 조건 row -->
                    <v-row dense align="center">
                      <!-- 강종 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">강종</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSkindSalesOrd"
                              @focusout="searchWordSkindSalesOrd = pendingWordSkindSalesOrd"
                              @keyup.enter="searchWordSkindSalesOrd = pendingWordSkindSalesOrd"
                              clearable
                              @click:clear="searchWordSkindSalesOrd = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center" class="">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ stusListSalesOrd.length }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회일자</span></div></v-col>
                                  <v-col cols="2">
                                    <date-picker v-model="srchEndDtSalesOrd"></date-picker>
                                  </v-col>        
                                  <v-col cols="1"><div class="f-r"><span>조회 기간</span></div></v-col>
                                  <v-col cols="2">
                                    <v-select
                                      class=""
                                      v-model="priodDiv"
                                      :items="priodDivList"
                                      item-value="cd"
                                      item-text="nm"
                                      hide-details dense outlined
                                    ></v-select>
                                  </v-col>                  
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>  
                    </v-row>

                    <!-- 강종 그리드 row -->
                    <v-row dense align="center">              
                      <v-col cols="12">
                        <v-row dense align="center">
                          <!-- 강종 그리드 -->
                          <v-col cols="2" >
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredSkindListSalesOrd"
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexSkindSalesOrdInit"
                              :itemsSourceChanged="onFlexSkindSalesOrdItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="3*" align="center"/>
                              <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="10*" />
                            </wj-flex-grid>
                          </v-col>
                          <v-col cols="10">
                            <!-- 통계 row -->
                            <v-row dense align="center">
                              <v-col cols="12">
                                <v-row dense align="center" class="">              
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">매출수량 : {{ statsSalesOrd.salesQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">매출중량 : {{ statsSalesOrd.salesWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">재고수량 : {{ statsSalesOrd.invntQty }} 개</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">재고중량 : {{ statsSalesOrd.invntWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- 현황 그리드 -->
                              <v-col cols="12">
                                <wj-flex-grid 
                                  :isReadOnly=true
                                  :itemsSource="stusListSalesOrd" 
                                  :selectionMode="'ListBox'"
                                  style="height:650px;"
                                  :initialized="onFlexStusSalesOrdInitialized"
                                  :itemsSourceChanged="onFlexStusSalesOrdItemsSourceChanged"
                                  :headersVisibility="'Column'"
                                  :alternatingRowStep="0"
                                  :allowMerging="'ColumnHeaders'"
                                  >
                                  <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" :width="70" align="center"/>
                                  <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="150"/>
                                  <wj-flex-grid-column :header="'규격'" :binding="'stnd'" :width="60" align="center"/>
                                  <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="60" align="center"/>
                                  <wj-flex-grid-column :header="'매출수량'" :binding="'priod_sales_qty'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'매출중량'" :binding="'priod_sales_wegt'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'매출수량'" :binding="'avg_sales_qty'" :width="120" align="right" :visible="priodDiv != 'm00'"/>
                                  <wj-flex-grid-column :header="'매출중량'" :binding="'avg_sales_wegt'" :width="120" align="right" :visible="priodDiv != 'm00'"/>
                                  <wj-flex-grid-column :header="'재고수량'" :binding="'invnt_qty'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'재고중량'" :binding="'invnt_wegt'" :width="120" align="right"/>
                                  <!-- <wj-flex-grid-column :header="'수량'" :binding="'purch_ord_qty'" :width="120" align="right"/>
                                  <wj-flex-grid-column :header="'중량'" :binding="'purch_ord_wegt'" :width="120" align="right" :css-class="customColumnStyle"/> -->
                                </wj-flex-grid>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>               
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <!-- 적정재고 -->
              <v-col cols="12" v-show="gridDiv == 'optm'">
                <v-row dense align="center">
                  <v-col cols="12">
                    <!-- 조회 조건 row -->
                    <v-row dense align="center">
                      <!-- 강종 검색 -->
                      <v-col cols="2">
                        <v-row dense align="center">
                          <v-col cols="4">
                            <div class="d-flex"><span class="">강종</span></div>
                          </v-col>
                          <v-col cols="8">
                            <v-text-field
                              class=""
                              hide-details
                              dense
                              outlined
                              v-model="pendingWordSkindOptm"
                              @focusout="searchWordSkindOptm = pendingWordSkindOptm"
                              @keyup.enter="searchWordSkindOptm = pendingWordSkindOptm"
                              clearable
                              @click:clear="searchWordSkindOptm = ''"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!-- 조회 조건 -->
                      <v-col cols="10">
                        <v-row dense align="center">
                          <v-col cols="10">
                            <v-row dense align="center" class="">
                              <v-col cols="10">
                                <v-row dense align="center">
                                  <v-col cols="2"><div class=""><span>조회수 : {{ stusListOptm.length }} 건</span></div></v-col>
                                  <v-col cols="1"><div class="f-r"><span>조회일자</span></div></v-col>
                                  <v-col cols="2">
                                    <date-picker v-model="srchEndDtOptm"></date-picker>
                                  </v-col>                      
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-end align-center hei-40p">
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" @click="onLedgrInqry">조회</v-btn>
                            <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text font-weight-bold" min-width="70px" >출력</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>  
                    </v-row>

                    <!-- 강종 그리드 row -->
                    <v-row dense align="center">              
                      <v-col cols="12">
                        <v-row dense align="center">
                          <!-- 강종 그리드 -->
                          <v-col cols="2" >
                            <wj-flex-grid 
                              :isReadOnly=true
                              :itemsSource="filteredSkindListOptm"
                              :selectionMode="'ListBox'"
                              style="height:650px;"
                              :initialized="onFlexSkindOptmInit"
                              :itemsSourceChanged="onFlexSkindOptmItemsSourceChanged"
                              :headersVisibility="'Column'"
                              :alternatingRowStep="0"
                              >
                              <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="3*" align="center"/>
                              <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="10*" />
                            </wj-flex-grid>
                          </v-col>
                          <v-col cols="10">
                            <!-- 통계 row -->
                            <v-row dense align="center">
                              <v-col cols="12">
                                <v-row dense align="center" class="">              
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">월최대중량 : {{ statsOptm.maxWegt }} kg</span></div></v-col>
                                      <v-col cols="6"><div class=""><span style="color:blue;">월평균중량 : {{ statsOptm.avgWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="3">
                                    <v-row dense align="center">
                                      <v-col cols="6"><div class=""><span style="color:blue;">재고중량 : {{ statsOptm.invntWegt }} kg</span></div></v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <!-- 현황 그리드 -->
                              <v-col cols="12">
                                <wj-flex-grid 
                                  :itemsSource="stusListOptm" 
                                  :selectionMode="'ListBox'"
                                  style="height:650px;"
                                  :initialized="onFlexStusOptmInitialized"
                                  :itemsSourceChanged="onFlexStusOptmItemsSourceChanged"
                                  :headersVisibility="'Column'"
                                  :alternatingRowStep="0"
                                  :allowMerging="'ColumnHeaders'"
                                  :autoGenerateColumns="false"
                                  >
                                  <wj-flex-grid-column :header="'코드'" :binding="'sitem_cd'" :width="65" align="center" :isReadOnly="true" :visible="false"/>
                                  <wj-flex-grid-column :header="'품명'" :binding="'sitem_nm'" :width="140" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'규격'" :binding="'stnd'" :width="60" align="center" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'단위'" :binding="'unit'" :width="50" align="center" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m11월'" :binding="'m11'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m10월'" :binding="'m10'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m09월'" :binding="'m09'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m08월'" :binding="'m08'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m07월'" :binding="'m07'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m06월'" :binding="'m06'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m05월'" :binding="'m05'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m04월'" :binding="'m04'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m03월'" :binding="'m03'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m02월'" :binding="'m02'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m01월'" :binding="'m01'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'m00월'" :binding="'m00'" :width="70" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'합계중량'" :binding="'sum_wegt'" :width="75" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'월최대 중량'" :binding="'max_wegt'" :width="75" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'월평균 중량'" :binding="'avg_wegt'" :width="75" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'현 재고 중량'" :binding="'invnt_wegt'" :width="75" format="N0" align="right" :isReadOnly="true"/>
                                  <wj-flex-grid-column :header="'적정재고 중량'" :binding="'optm_invnt_wegt'" :width="75" format="N0" align="right" :css-class="customColumnStyle"/>
                                  <wj-flex-grid-column :header="'알림(메일)'" :binding="'invnt_alrm_yn'" :width="60" align="center" dataType="Boolean"/>
                                </wj-flex-grid>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>               
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import datePicker from '@/components/common/datePicker.vue';
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjGrid from '@grapecity/wijmo.grid';
import {SUB_TAB} from "@/common/constant";

let __extends =
  (this && this.__extends) ||
  (function() {
    let extendStatics = function(d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function(d, b) {
            d.__proto__ = b;
          }) ||
        function(d, b) {
          for (let p in b) if (b.prototype.hasOwnProperty.call(p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function(d, b) {
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();

// 이전 열의 셀 값이 같은 경우에만 세로로 셀 병합
var RestrictedMergeManager = (function(_super) {
  __extends(RestrictedMergeManager, _super);
  function RestrictedMergeManager() {
    return _super.call(this) || this;
  }
  RestrictedMergeManager.prototype.getMergedRange = function(panel, r, c, clip) {
    // 병합 대상 셀이 아닌 경우 중단
    if(!(panel.columns[c].binding == 'sitem_cd' || panel.columns[c].binding == 'sitem_nm' || 
         panel.columns[c].binding == 'stnd' || panel.columns[c].binding == 'unit')
      ) {
        return;
    }
    if (clip === void 0) {
        clip = true;
    }
    var rng = null;
    rng = new wjGrid.CellRange(r, c);
    var pcol = c > 0 ? c - 1 : c;
    var val = panel.getCellData(r, c, false);
    var pval = panel.getCellData(r, pcol, false);
    while (
      rng.row > 0 &&
      panel.getCellData(rng.row - 1, c, false) == val &&
      panel.getCellData(rng.row - 1, pcol, false) == pval
    ) {
        rng.row--;
    }
    while (
      rng.row2 < panel.rows.length - 1 &&
      panel.getCellData(rng.row2 + 1, c, false) == val &&
      panel.getCellData(rng.row2 + 1, pcol, false) == pval
    ) {
      rng.row2++;
    }
    if (rng.isSingleCell) {
      rng = null;
    }
    return rng;
  };
  return RestrictedMergeManager;
})(wjGrid.MergeManager);

export default {
  components: {datePicker},
  data(){
    return{
      compSno: '',
      userSno: 0,

      // 공통
      gridDiv: "invnt",   // invnt, ship, salesOrd, optm

      skindList: [],
      
      // 재고현황
      skindCdInvnt: '',
      searchWordSkindInvnt: "",
      pendingWordSkindInvnt: "",

      srchBgnDtInvnt: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtInvnt: dayjs().format('YYYY-MM-DD'),

      statsInvnt: {crovrQty: 0, crovrWegt: 0, salesQty: 0, salesWegt: 0, purchQty: 0, purchWegt: 0, invntQty: 0, invntWegt: 0},

      flexSkindInvnt: null,
      flexStusInvnt: null,
      stusListInvnt: [],

      // 수불현황
      skindCdShip: '',
      searchWordSkindShip: "",
      pendingWordSkindShip: "",

      srchBgnDtShip: dayjs().startOf("month").format('YYYY-MM-DD'),
      srchEndDtShip: dayjs().format('YYYY-MM-DD'),

      statsShip: {crovrQty: 0, crovrWegt: 0, incrQty: 0, incrWegt: 0, decrQty: 0, decrWegt: 0, invntQty: 0, invntWegt: 0},

      flexSkindShip: null,
      flexStusShip: null,
      stusListShip: [],

      // 재고/발주
      skindCdSalesOrd: '',
      searchWordSkindSalesOrd: "",
      pendingWordSkindSalesOrd: "",

      priodDiv: "m00",
      priodDivList: [
        {cd: "m00", nm: "당일"},
        {cd: "m03", nm: "3개월"},
        {cd: "m06", nm: "6개월"},
        {cd: "m09", nm: "9개월"},
        {cd: "m12", nm: "12개월"},
      ],
      srchMmCnt: 0,

      srchBgnDtSalesOrd: dayjs().format('YYYY-MM-DD'),
      srchEndDtSalesOrd: dayjs().format('YYYY-MM-DD'),

      statsSalesOrd: {salesQty: 0, salesWegt: 0, invntQty: 0, invntWegt: 0},

      flexSkindSalesOrd: null,
      flexStusSalesOrd: null,
      stusListSalesOrd: [],

      // 적정재고
      skindCdOptm: '',
      searchWordSkindOptm: "",
      pendingWordSkindOptm: "",

      srchBgnDtOptm: dayjs().subtract(11, 'month').startOf('month').format('YYYY-MM-DD'),
      srchEndDtOptm: dayjs().format('YYYY-MM-DD'),

      statsOptm: {maxWegt: 0, avgWegt: 0, invntWegt: 0},

      flexSkindOptm: null,
      flexStusOptm: null,
      stusListOptm: [],

    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.invntStus.code);
    },
    filteredSkindListInvnt() {
      return this.skindList.filter(({skind_nm}) => 
        (!this.searchWordSkindInvnt || this.searchWordSkindInvnt.trim() === '' ||
          skind_nm && skind_nm.toUpperCase().includes(this.searchWordSkindInvnt.toUpperCase()))
      );
    },
    filteredSkindListShip() {
      return this.skindList.filter(({skind_nm}) => 
        (!this.searchWordSkindShip || this.searchWordSkindShip.trim() === '' ||
          skind_nm && skind_nm.toUpperCase().includes(this.searchWordSkindShip.toUpperCase()))
      );
    },
    filteredSkindListSalesOrd() {
      return this.skindList.filter(({skind_nm}) => 
        (!this.searchWordSkindSalesOrd || this.searchWordSkindSalesOrd.trim() === '' ||
          skind_nm && skind_nm.toUpperCase().includes(this.searchWordSkindSalesOrd.toUpperCase()))
      );
    },
    filteredSkindListOptm() {
      return this.skindList.filter(({skind_nm}) => 
        (!this.searchWordSkindOptm || this.searchWordSkindOptm.trim() === '' ||
          skind_nm && skind_nm.toUpperCase().includes(this.searchWordSkindOptm.toUpperCase()))
      );
    },
    customColumnStyle() {
      return 'sum-bg-color';
    },
    wegtColumnStyle() {
      return 'wegt-bg-color';
    }
  },
  watch: {
    // 재고/발주 조회 기간 변경
    priodDiv: {
      immediate: true,
      handler: function () {
        if(this.priodDiv == 'm00') {
          this.srchMmCnt = 0;
          this.srchBgnDtSalesOrd = this.srchEndDtSalesOrd;
        } else {
          this.srchMmCnt = this.priodDiv.substring(1);
          this.srchBgnDtSalesOrd = dayjs(this.srchEndDtSalesOrd).add(1, 'day').subtract(this.srchMmCnt, 'month').format('YYYY-MM-DD');
        }        
        console.log("watch priodDiv : ", this.srchMmCnt, this.srchBgnDtSalesOrd, this.srchEndDtSalesOrd);

        this.loadStusListSalesOrd();
      },
    },
    // 재고/발주 조회일자 변경
    srchEndDtSalesOrd: {
      immediate: true,
      handler: function () {
        if(this.priodDiv == 'm00') {
          this.srchMmCnt = 0;
          this.srchBgnDtSalesOrd = this.srchEndDtSalesOrd;
        } else {
          this.srchMmCnt = this.priodDiv.substring(1);
          this.srchBgnDtSalesOrd = dayjs(this.srchEndDtSalesOrd).add(1, 'day').subtract(this.srchMmCnt, 'month').format('YYYY-MM-DD');
        }        
        console.log("watch srchEndDtSalesOrd : ", this.srchMmCnt, this.srchBgnDtSalesOrd, this.srchEndDtSalesOrd);

        this.loadStusListSalesOrd();
      },
    },
    gridDiv: {
      immediate: true,
      handler: function () {
        if(this.gridDiv == 'optm') {
          this.loadStusListOptm();
        } 
        // else if(this.gridDiv == 'colctPay') {
        //   this.loadLedgrListColctPay();
        // }
      },
    },
    // 적정재고 조회일자 변경
    srchEndDtOptm: {
      immediate: true,
      handler: function () {
        if(this.srchEndDtOptm != null && this.srchEndDtOptm != '') {
          this.setFlexStusOptmHeader(this.flexStusOptm, false);
          this.srchBgnDtOptm = dayjs(this.srchEndDtOptm).subtract(11, 'month').startOf('month').format('YYYY-MM-DD');
          console.log("watch srchEndDtOptm : ", this.srchBgnDtOptm, this.srchEndDtOptm);
          this.loadStusListOptm();
        } 
      },
    },
  },
  created() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
  },
  mounted() {
    this.getSkindList();
  },
  methods: {
    // 재고현황
    onFlexSkindInvntInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.skindCdInvnt = flex.getCellData(ht.row, 0);
        this.loadStusListInvnt();
      });

      this.flexSkindInvnt = flex;
    },
    onFlexSkindInvntItemsSourceChanged(flex) {
      if(flex != null && this.filteredSkindListInvnt.length > 0) {
        this.skindCdInvnt = flex.getCellData(0, 0);
        this.loadStusListInvnt();
      }
    },
    onFlexStusInvntItemsSourceChanged(flex) {
      console.log("onFlexStusInvntItemsSourceChanged");

      if(flex != null && this.stusListInvnt.length > 0) {
        // 통계 계산
        this.calculateStatsInvnt();
      }
    },
    onFlexStusInvntInitialized(flex) {
      // create extra header row
      let extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      // add extra header row to the flex
      let panel = flex.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      // populate the extra header row
      const bgnCol = 6;
      panel.setCellData(0, bgnCol + 0, "이월재고");
      panel.setCellData(0, bgnCol + 1, "이월재고");
      panel.setCellData(0, bgnCol + 2, "매출");
      panel.setCellData(0, bgnCol + 3, "매출");
      panel.setCellData(0, bgnCol + 4, "매입");
      panel.setCellData(0, bgnCol + 5, "매입");
      panel.setCellData(0, bgnCol + 6, "재고");
      panel.setCellData(0, bgnCol + 7, "재고");

      // merge headers vertically
      ["sitem_cd", "sitem_nm", "stnd", "unit", "width_len", "depth_phi", "avg_unit_price", "invnt_amt"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
      });

      // center-align merged header cells
      flex.formatItem.addHandler(function(s, e) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
          let html = e.cell.innerHTML;
          e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
          // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
        }
      });

      this.flexStusInvnt = flex;
    },

    // 수불현황
    onFlexSkindShipInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.skindCdShip = flex.getCellData(ht.row, 0);
        this.loadStusListShip();
      });

      this.flexSkindShip = flex;
    },
    onFlexSkindShipItemsSourceChanged(flex) {
      if(flex != null && this.filteredSkindListShip.length > 0) {
        this.skindCdShip = flex.getCellData(0, 0);
        this.loadStusListShip();
      }
    },
    onFlexStusShipItemsSourceChanged(flex) {
      console.log("onFlexStusShipItemsSourceChanged");

      if(flex != null && this.stusListShip.length > 0) {
        // 통계 계산
        this.calculateStatsShip();
      }

      // row 색상 변경 : 중량
      flex.formatItem.addHandler((flex, event) => {
        if (event.panel.cellType === wjGrid.CellType.Cell) {
          let valueColumnIndex = flex.columns.getColumn('qty_wegt_div').index;

          // if (valueColumnIndex !== -1) {
            let cellValue = flex.getCellData(event.row, valueColumnIndex);
            
            if (cellValue == "wegt") {
              event.cell.style.backgroundColor = '#CCC760';
              // event.cell.style.fontWeight = 'bold';
            } 
          // }
        }
      });
    },
    onFlexStusShipInitialized(flex) {
      console.log("onFlexStusShipInitialized");

      // create extra header row
      // let extraRow = new wjGrid.Row();
      // extraRow.allowMerging = true;
      let extraRow1 = new wjGrid.Row();
      extraRow1.allowMerging = true;

      // add extra header row to the flex
      let panel = flex.columnHeaders;
      // panel.rows.splice(0, 0, extraRow);
      panel.rows.splice(0, 0, extraRow1);

      // populate the extra header row
      const bgnCol = 4;
      // panel.setCellData(0, bgnCol + 0, "이월재고");
      // panel.setCellData(0, bgnCol + 1, "증가");
      // panel.setCellData(0, bgnCol + 2, "증가");
      // // panel.setCellData(0, bgnCol + 3, "증가");
      // panel.setCellData(0, bgnCol + 3, "증가");
      // panel.setCellData(0, bgnCol + 4, "감소");
      // // panel.setCellData(0, bgnCol + 6, "감소");
      // // panel.setCellData(0, bgnCol + 7, "감소");
      // panel.setCellData(0, bgnCol + 5, "현 재고");

      panel.setCellData(0, bgnCol + 0, "이월재고수량");
      panel.setCellData(0, bgnCol + 1, "매입증가수량");
      panel.setCellData(0, bgnCol + 2, "조정증가수량");
      // panel.setCellData(1, bgnCol + 3, "생산수량");
      panel.setCellData(0, bgnCol + 3, "증가수량 계");
      panel.setCellData(0, bgnCol + 4, "매출감소수량");
      // panel.setCellData(1, bgnCol + 6, "투입수량");
      // panel.setCellData(1, bgnCol + 7, "수량 계");
      panel.setCellData(0, bgnCol + 5, "현재고수량");

      // merge headers vertically
      ["sitem_cd", "sitem_nm", "stnd", "unit"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
        panel.setCellData(1, col.index, col.header);
      });

      // center-align merged header cells
      flex.formatItem.addHandler(function(s, e) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
          let html = e.cell.innerHTML;
          e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
          // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
        }
      });

      // 세로 병합은 되지만, 헤더 셀의 가로 병합이 해제됨
      // -> CustomMergeManager 를 참고하여 가로 병합이 가능한 지 검토
      flex.mergeManager = new RestrictedMergeManager();
      
      this.flexStusShip = flex;
    },

    // 재고/발주
    onFlexSkindSalesOrdInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.skindCdSalesOrd = flex.getCellData(ht.row, 0);
        this.loadStusListSalesOrd();
      });

      this.flexSkindSalesOrd = flex;
    },
    onFlexSkindSalesOrdItemsSourceChanged(flex) {
      if(flex != null && this.filteredSkindListSalesOrd.length > 0) {
        this.skindCdSalesOrd = flex.getCellData(0, 0);
        this.loadStusListSalesOrd();
      }
    },
    onFlexStusSalesOrdItemsSourceChanged(flex) {
      console.log("onFlexStusSalesOrdItemsSourceChanged");

      if(flex != null && this.stusListSalesOrd.length > 0) {
        // 통계 계산
        this.calculateStatsSalesOrd();
      }
    },
    onFlexStusSalesOrdInitialized(flex) {
      // create extra header row
      let extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      // add extra header row to the flex
      let panel = flex.columnHeaders;
      panel.rows.splice(0, 0, extraRow);

      // populate the extra header row
      const bgnCol = 4;
      panel.setCellData(0, bgnCol + 0, "조회기간");
      panel.setCellData(0, bgnCol + 1, "조회기간");
      panel.setCellData(0, bgnCol + 2, "월평균");
      panel.setCellData(0, bgnCol + 3, "월평균");
      panel.setCellData(0, bgnCol + 4, "현 시점");
      panel.setCellData(0, bgnCol + 5, "현 시점");
      // panel.setCellData(0, bgnCol + 6, "발주지시");
      // panel.setCellData(0, bgnCol + 7, "발주지시");

      // merge headers vertically
      ["sitem_cd", "sitem_nm", "stnd", "unit"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
      });

      // center-align merged header cells
      flex.formatItem.addHandler(function(s, e) {
        if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
          let html = e.cell.innerHTML;
          e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
          // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
        }
      });

      this.flexStusSalesOrd = flex;
    },

    // 적정재고
    onFlexSkindOptmInit(flex) {
      // 마우스 클릭 이벤트 등록
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0) {
          return;
        }

        this.skindCdOptm = flex.getCellData(ht.row, 0);
        this.loadStusListOptm();
      });

      this.flexSkindOptm = flex;
    },
    onFlexSkindOptmItemsSourceChanged(flex) {
      if(flex != null && this.filteredSkindListOptm.length > 0) {
        this.skindCdOptm = flex.getCellData(0, 0);
        this.loadStusListOptm();
      }
    },
    onFlexStusOptmItemsSourceChanged(flex) {
      console.log("onFlexStusOptmItemsSourceChanged");

      if(flex != null && this.stusListOptm.length > 0) {
        // 통계 계산
        this.calculateStatsOptm();
      }
    },
    onFlexStusOptmInitialized(flex) {
      this.flexStusOptm = flex;
      this.setFlexStusOptmHeader(this.flexStusOptm, true);
    },
    setFlexStusOptmHeader(flex, isInit) {
      if(flex == null) {
        return;
      }

      // create extra header row
      let extraRow = new wjGrid.Row();
      extraRow.allowMerging = true;

      // add extra header row to the flex
      const spliceCnt = isInit ? 0 : 1;
      let panel = flex.columnHeaders;
      panel.rows.splice(0, spliceCnt, extraRow);

      // 현재년도의 월 수 계산
      const currDt = new Date(this.srchEndDtOptm);
      const currMm = currDt.getMonth() + 1;
      const currYy = currDt.getFullYear() % 100;
      const befYy = (currYy - 1) % 100;
      const currMmCnt = currMm;
      const befMmCnt = 12 - currMmCnt;

      // populate the extra header row
      const bgnCol = 4;
      for(let i = 0; i < 12; i++) {
        panel.setCellData(0, bgnCol + i, "매출 중량");
      }
      for(let i = 0; i < befMmCnt; i++) {
        // panel.setCellData(0, bgnCol + i, `${befYy}년`);  
        panel.setCellData(1, bgnCol + i, `'${befYy}.${i + currMm + 1}`);  
      }
      for(let i = 0; i < currMmCnt; i++) {
        // panel.setCellData(0, bgnCol + befMmCnt + i, `${currYy}년`);  
        let month = String(i + 1).padStart(2, '0');
        panel.setCellData(1, bgnCol + befMmCnt + i, `'${currYy}.${month}`);  
      }

      // merge headers vertically
      ["sitem_cd", "sitem_nm", "stnd", "unit", "sum_wegt", "max_wegt", "avg_wegt", "invnt_wegt", "optm_invnt_wegt", "invnt_alrm_yn"].forEach(function(binding) {
        let col = flex.getColumn(binding);
        col.allowMerging = true;
        panel.setCellData(0, col.index, col.header);
        // console.log("merge headers vertically : ", col);
      });

      if(isInit) {
        // center-align merged header cells
        flex.formatItem.addHandler(function(s, e) {
          if (e.panel == s.columnHeaders && e.range.rowSpan > 1) {
            let html = e.cell.innerHTML;
            e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
            // console.log("e.range.rowSpan", e.range.rowSpan, e.cell.innerHTML);
          }
        });
      }
    },

    // 조회 버튼 클릭
    onLedgrInqry() {
      if(this.gridDiv == 'invnt') {
        this.loadStusListInvnt();
      } else if(this.gridDiv == 'ship') {
        this.loadStusListShip();
      } else if(this.gridDiv == 'salesOrd') {
        this.loadStusListSalesOrd();
      } else if(this.gridDiv == 'optm') {
        this.loadStusListOptm();
      } 
    },
    // 출력 버튼 클릭

    // 강종 목록 조회
    getSkindList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        console.log("getSkindList res.code:", res.data.code);
        // console.log("res.data:", res.data);
        this.skindList = res.data.skindList;
      }).catch((error) => {
          console.log(error);
          alert(error.message);
        }
      )
    },

    // (원장) 재고현황 > 재고현황 목록 조회
    async loadStusListInvnt() {
      if(this.skindCdInvnt == null || this.skindCdInvnt == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("skind_cd", this.skindCdInvnt);
      params.append("srch_bgn_dt", this.srchBgnDtInvnt);
      params.append("srch_end_dt", this.srchEndDtInvnt);

      try {
        const res = await this.$http.post('/ledger/getInvntStusListInvnt', params);
        const stusList = res.data.stusList.map(stus => ({
          ...stus,
        }));

        this.stusListInvnt = stusList;
      } catch (e) {
        console.log(`Error on function loadStusListInvnt: ${e}`);
      }
    },
    // (원장) 재고현황 > 수불현황 목록 조회
    async loadStusListShip() {
      if(this.skindCdShip == null || this.skindCdShip == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("skind_cd", this.skindCdShip);
      params.append("srch_bgn_dt", this.srchBgnDtShip);
      params.append("srch_end_dt", this.srchEndDtShip);

      try {
        const res = await this.$http.post('/ledger/getInvntStusListShip', params);
        const stusList = res.data.stusList.map(stus => ({
          ...stus,
        }));

        this.stusListShip = stusList;
      } catch (e) {
        console.log(`Error on function loadStusListShip: ${e}`);
      }
    },
    // (원장) 재고현황 > 재고/발주 목록 조회
    async loadStusListSalesOrd() {
      if(this.skindCdSalesOrd == null || this.skindCdSalesOrd == '') {
        return;
      }

      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("skind_cd", this.skindCdSalesOrd);
      params.append("srch_mm_cnt", this.srchMmCnt);
      params.append("srch_bgn_dt", this.srchBgnDtSalesOrd);
      params.append("srch_end_dt", this.srchEndDtSalesOrd);

      try {
        const res = await this.$http.post('/ledger/getInvntStusListSalesOrd', params);
        const stusList = res.data.stusList.map(stus => ({
          ...stus,
        }));

        this.stusListSalesOrd = stusList;
      } catch (e) {
        console.log(`Error on function loadStusListSalesOrd: ${e}`);
      }
    },
    // (원장) 재고현황 > 적정재고 목록 조회
    async loadStusListOptm() {
      let params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append("skind_cd", this.skindCdOptm);
      params.append("srch_bgn_dt", this.srchBgnDtOptm);
      params.append("srch_end_dt", this.srchEndDtOptm);

      try {
        const res = await this.$http.post('/ledger/getInvntStusListOptm', params);
        const stusList = res.data.stusList.map(stus => ({
          ...stus,
          invnt_alrm_yn: stus.invnt_alrm_yn == 'Y' ? true : false,
        }));

        this.stusListOptm = stusList;
      } catch (e) {
        console.log(`Error on function loadStusListOptm: ${e}`);
      }
    },
    
    // 재고현황 통계 계산
    calculateStatsInvnt() {
      let salesQty = 0;
      let salesWegt = 0;
      let purchQty = 0;
      let purchWegt = 0;
      let invntQty = 0;
      let invntWegt = 0;
      let invntAmt = 0;

      this.stusListInvnt.forEach((e) => {
        salesQty += e.sales_qty;
        salesWegt += e.sales_wegt;
        purchQty += e.purch_qty;
        purchWegt += e.purch_wegt;
        invntQty += e.invnt_qty;
        invntWegt += e.invnt_wegt;
        invntAmt += e.invnt_amt;
      });

      this.statsInvnt.salesQty = this.$setNumberComma(Math.round(salesQty, 0));
      this.statsInvnt.salesWegt = this.$setNumberComma(Math.round(salesWegt, 0));
      this.statsInvnt.purchQty = this.$setNumberComma(Math.round(purchQty, 0));
      this.statsInvnt.purchWegt = this.$setNumberComma(Math.round(purchWegt, 0));
      this.statsInvnt.invntQty = this.$setNumberComma(Math.round(invntQty, 0));
      this.statsInvnt.invntWegt = this.$setNumberComma(Math.round(invntWegt, 0));
      this.statsInvnt.invntAmt = this.$setNumberComma(Math.round(invntAmt, 0));
    },
    // 수불현황 통계 계산
    calculateStatsShip() {
      let crovrQty = 0;
      let crovrWegt = 0;
      let incrQty = 0;
      let incrWegt = 0;
      let decrQty = 0;
      let decrWegt = 0;
      let invntQty = 0;
      let invntWegt = 0;

      this.stusListShip.forEach((e) => {
        if(e.qty_wegt_div == 'qty') {
          crovrQty += e.crovr_qty_wegt;
          incrQty += e.incr_sum_qty_wegt;
          decrQty += e.decr_sum_qty_wegt;
          invntQty += e.invnt_qty_wegt;
        } else {
          crovrWegt += e.crovr_qty_wegt;
          incrWegt += e.incr_sum_qty_wegt;
          decrWegt += e.decr_sum_qty_wegt;
          invntWegt += e.invnt_qty_wegt;
        }
      });

      this.statsShip.crovrQty = this.$setNumberComma(Math.round(crovrQty, 0));
      this.statsShip.crovrWegt = this.$setNumberComma(Math.round(crovrWegt, 0));
      this.statsShip.incrQty = this.$setNumberComma(Math.round(incrQty, 0));
      this.statsShip.incrWegt = this.$setNumberComma(Math.round(incrWegt, 0));
      this.statsShip.decrQty = this.$setNumberComma(Math.round(decrQty, 0));
      this.statsShip.decrWegt = this.$setNumberComma(Math.round(decrWegt, 0));
      this.statsShip.invntQty = this.$setNumberComma(Math.round(invntQty, 0));
      this.statsShip.invntWegt = this.$setNumberComma(Math.round(invntWegt, 0));
    },
    // 재고/발주 통계 계산
    calculateStatsSalesOrd() {
      let priodSalesQty = 0;
      let priodSalesWegt = 0;
      let invntQty = 0;
      let invntWegt = 0;

      this.stusListSalesOrd.forEach((e) => {
        priodSalesQty += e.priod_sales_qty;
        priodSalesWegt += e.priod_sales_wegt;
        invntQty += e.invnt_qty;
        invntWegt += e.invnt_wegt;
      });

      this.statsSalesOrd.priodSalesQty = this.$setNumberComma(Math.round(priodSalesQty, 0));
      this.statsSalesOrd.priodSalesWegt = this.$setNumberComma(Math.round(priodSalesWegt, 0));
      this.statsSalesOrd.invntQty = this.$setNumberComma(Math.round(invntQty, 0));
      this.statsSalesOrd.invntWegt = this.$setNumberComma(Math.round(invntWegt, 0));
    },
    // 적정재고 통계 계산
    calculateStatsOptm() {
      let maxWegt = 0;
      let avgWegt = 0;
      let invntWegt = 0;

      this.stusListOptm.forEach((e) => {
        maxWegt += e.max_wegt;
        avgWegt += e.avg_wegt;
        invntWegt += e.invnt_wegt;
      });

      this.statsOptm.maxWegt = this.$setNumberComma(Math.round(maxWegt, 0));
      this.statsOptm.avgWegt = this.$setNumberComma(Math.round(avgWegt, 0));
      this.statsOptm.invntWegt = this.$setNumberComma(Math.round(invntWegt, 0));
    },
  }
}
</script>

<style>

</style>