<template>
  <v-container fluid class="d-flex flex-column" style="height: 100%;">
    <v-row class="flex-grow-0" dense>
      <v-col cols="1">
        <h2>계정 관리</h2>
      </v-col>
      <v-col>
        <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
        <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3" class="d-flex flex-column">
        <div class="d-flex flex-column justify-end hei-30p">
          <v-row class="flex-grow-0 align-baseline">
            <v-col cols="4">
              <span>조회수 : {{ items.length }}건</span>
            </v-col>
            <v-col class="d-flex align-baseline">
              <span class="pr-2">계정</span>
              <v-text-field 
                v-model="searchWord" 
                hide-details 
                dense 
                outlined
                :readonly="isOnPendingItem"
                @click="onSearchWordClick"
                >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <wj-flex-grid
            class="mt-1 flex-grow-1"
            style="height:650px;"
            :isReadOnly=true
            :itemsSource="filteredItems"
            selectionMode="ListBox"
            :initialized="onItemGridInit"
            headersVisibility="Column"
        >
          <wj-flex-grid-column :header="'코드'" :binding="'acct_cd'" width="1*" />
          <wj-flex-grid-column :header="'계정명'" :binding="'acct_nm'" width="1*" />
          <wj-flex-grid-column :header="'구분'" :binding="'acct_div_nm'" width="1*" />
        </wj-flex-grid>
      </v-col>
      <v-col cols="9" class="grow">
        <div class="d-flex flex-column justify-end hei-30p">
        <v-row dense>
          <v-col class="d-flex justify-end">
            <v-btn small width="100" color="blue darken-2" class="white--text mr-5">출력</v-btn>
            <v-btn small width="100" color="blue darken-2" class="white--text" :disabled="!pendingItem.item_sno" @click="onCreateItem">계정 추가</v-btn>
          </v-col>
        </v-row>
        </div>
        <v-card class="mt-1 pa-1 elevation-0 bgcolor-grey1" style="background: magenta;">
          <v-row dense>
            <v-col cols="12" class="d-flex justify-end align-center">
              <v-btn small color="blue-grey lighten-5" class="mr-3" width="70" v-show="pendingItem.item_sno" @click="onItemDelete">삭제</v-btn>
              <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" :disabled="isItemCancellationDisabled" @click="onItemCancel">취소</v-btn>
              <v-btn small color="indigo darken-3" class="white--text" width="70" :disabled="!isOnPendingItem" @click="onItemSave">저장</v-btn>
            </v-col>
            <v-col cols="12">
              <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                <h4>계정 정보</h4>
                <v-row dense class="hei-40p mt-0" align="center">
                  <v-col cols="1" align="right" class="">
                    <div class=""><span style="color:red;">* </span><span>계정 코드</span></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <v-text-field
                        v-model="pendingItem.acct_cd"
                        hide-details dense outlined
                        :readonly="!!pendingItem.item_sno"
                        :background-color="!pendingItem.acct_cd ? 'white' : 'grey lighten-4'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" align="right" class="">
                    <div class=""><span style="color:red;">* </span><span>계정명</span></div>
                  </v-col>
                  <v-col cols="3" class="">
                    <v-text-field
                        class=""
                        v-model="pendingItem.acct_nm"
                        hide-details dense outlined
                        background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <div class=""><span style="color:red;">* </span><span>계정유형</span></div>
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                        row hide-details dense
                        class="pa-0 ma-0"
                        v-model="pendingItem.acct_type"
                    >
                      <v-radio :key="item.cmn_cd" :value="item.cmn_cd" :label="`${item.cmn_cd_nm}결제`" class="pr-5" v-for="item in acctType"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense class="hei-40p mt-0" align="center">
                  <v-col cols="1" align="right" class="">
                    <div class=""><span style="color:red;">* </span><span>구분</span></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <v-select
                        class=""
                        v-model="pendingItem.acct_div"
                        :items="$store.state.commonCode.acct_div"
                        item-value="cmn_cd"
                        item-text="cmn_cd_nm"
                        hide-details dense outlined
                        background-color="grey lighten-4"
                    ></v-select>
                  </v-col>
                  <v-col cols="1" align="right" class="">
                    <div class=""><span>영업관련 여부</span></div>
                  </v-col>
                  <v-col cols="1" class="">
                    <v-checkbox
                        class="pa-0 ma-0"
                        v-model="pendingItem.sales_rel_yn"
                        hide-details dense
                        label="관련있음"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as wjGrid from '@grapecity/wijmo.grid';
import moment from 'moment';
import {v4 as uuidv4} from "uuid";
import {SUB_TAB} from "@/common/constant";

const DUMMY_ITEM_SNO = '__dummy__';

const genEmptyItem = ({
  item_sno = null,
}) => ({
  item_sno: item_sno,
  // comp_sno: null,
  acct_cd: null,
  acct_nm: null,
  acct_div: 'cmn',
  sales_rel_yn: false,
  use_yn: true,
  // reg_user_sno: null,
  // reg_dtm: null,
  // upd_user_sno: null,
  // upd_dtm: null,
});

export default {
  created() {
    this.$store.dispatch('refreshCommonCode', 'acct_div');
    this.$store.dispatch('refreshCommonCode', 'acct_type');
  },
  mounted() {
    this.loadItems();
  },
  data() {
    return {
      itemGrid: null,

      srchDt: moment().format('YYYY-MM-DD'),
      searchWord: null,

      items: [],
      item: null,
      pendingItem: genEmptyItem({item_sno: DUMMY_ITEM_SNO}),
      restoreItem: null,
    };
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.account.code);
    },
    filteredItems() {
      const searchWord = this.searchWord && this.searchWord.trim().toUpperCase();
      return this.items.filter(({acct_nm}) => !searchWord || acct_nm && acct_nm.toUpperCase().includes(searchWord))
    },
    isItemCancellationDisabled() {
      if (this.isOnPendingItem) return false

      if  (this.restoreItem) return false

      return true;
    },
    isOnPendingItem() {
      return JSON.stringify(this.item) !== JSON.stringify(this.pendingItem) ||
          JSON.stringify(this.itemDtls) !== JSON.stringify(this.pendingDtls);
    },
    acctType() {
      return this.$store.state.commonCode.acct_type;
    },
  },
  methods: {
    async loadItems() {
      let params = new FormData();
      params.append("comp_sno", this.$store.state.session.compSno);
      try {
        const res = await this.$http.post('/base/getAcctList', params);
        this.items = res.data.acctList.map(item => ({
          ...item,
          item_sno: `${item.acct_cd}`,
          acct_cd: `${item.acct_cd}`,
        }));

        if (this.pendingItem && this.pendingItem.item_sno === DUMMY_ITEM_SNO) {

          const [firstItem] = this.items;
          const sno = firstItem && firstItem.item_sno || DUMMY_ITEM_SNO;
          await this.loadItem(sno);

          // const visibleColumnIndex = this.itemGrid.columns.findIndex(col => col.visible)
          // if (0 <= visibleColumnIndex) this.itemGrid.select(0, visibleColumnIndex);
        }

      } catch (e) {
        console.log(`Error on function loadItems: ${e}`);
      }
    },
    async loadItem(itemSno) {
      if (itemSno === DUMMY_ITEM_SNO) {
        const item = genEmptyItem({});
        this.item = JSON.parse(JSON.stringify(item));
        this.pendingItem = JSON.parse(JSON.stringify(item));
        this.restoreItem = null;
        return;
      }

      const params = new FormData();

      const compSno = this.$store.state.session.compSno;
      params.append("comp_sno", compSno);
      params.append("acct_cd", itemSno);
      try {
        const res = await this.$http.post('/base/getAcctDetail', params);
        const item = {
          ...res.data.acct,
          sales_rel_yn: res.data.acct.sales_rel_yn === 'Y',
          use_yn: res.data.acct.use_yn === 'Y',
          item_sno: `${res.data.acct.acct_cd}`,
          acct_cd: `${res.data.acct.acct_cd}`,
        };
        this.item = JSON.parse(JSON.stringify(item));
        this.pendingItem = JSON.parse(JSON.stringify(item));
        this.restoreItem = null;

      } catch (e) {
        console.log(`Error on function loadItem: ${e}`);
      }
    },
    onItemGridInit(flex) {
      flex.addEventListener(flex.hostElement, 'mousedown', async e => {
        const ht = flex.hitTest(e);
        if (ht.cellType !== wjGrid.CellType.Cell || ht.row < 0)
          return;

        const isTouched = !this.pendingItem.acct_cd || this.isOnPendingItem;
        if (isTouched && !confirm('저장되지 않은 데이터가 있습니다. 이동하시겠습니까?'))
          return;

        const itemSno = flex.getCellData(ht.row, 0);
        if (isTouched || itemSno !== this.pendingItem.item_sno)
          await this.loadItem(itemSno);
      });
      this.itemGrid = flex;
    },
    onItemGridChanging() {
    },
    onItemGridChanged() {
    },
    onCreateItem() {
      const isOnPendingItem = this.isOnPendingItem;
      if (isOnPendingItem && !confirm('저장되지 않은 데이터가 있습니다. 새 주문을 추가하시겠습니까?'))
        return;

      const canRestore = this.item && !!this.item.item_sno;
      const restoreItem = canRestore ? JSON.parse(JSON.stringify(this.item)) : null;

      const item = genEmptyItem({});
      this.item = JSON.parse(JSON.stringify(item));
      this.pendingItem = JSON.parse(JSON.stringify(item));
      this.restoreItem = restoreItem;
    },
    verifyItem() {
      const pendingItem = this.pendingItem;
      if (!pendingItem.acct_cd) {
        alert('계정 코드는 필수 입력값입니다.');
        return false;
      }
      if(this.items.some((item) => item.acct_cd == pendingItem.acct_cd)) {
        alert('사용 중인 계정 코드입니다.');
        return false;
      }
      if (!pendingItem.acct_nm) {
        alert('계정명은 필수 입력값입니다.');
        return false;
      }
      if (!pendingItem.acct_type) {
        alert('계정 유형은 필수 입력값입니다.');
        return false;
      }
      if (!pendingItem.acct_div) {
        alert('구분은 필수 입력값입니다.');
        return false;
      }
      return true;
    },
    async onItemSave() {
      if (!this.verifyItem()) return;

      const params = new URLSearchParams();
      const pendingItem = this.pendingItem;
      params.append('new_yn', !pendingItem.item_sno ? 'Y' : 'N');
      params.append('acct_cd', pendingItem.acct_cd);
      params.append('acct_nm', pendingItem.acct_nm);
      params.append('acct_div', pendingItem.acct_div);
      params.append('acct_type', pendingItem.acct_type);
      params.append('user_sno', this.$store.state.session.userSno);
      params.append('comp_sno', this.$store.state.session.compSno);
      if (null != pendingItem.sales_rel_yn) params.append('sales_rel_yn', pendingItem.sales_rel_yn ? 'Y' : 'N');
      if (null != pendingItem.use_yn) params.append('use_yn', pendingItem.use_yn ? 'Y' : 'N');

      try {
        const res = await this.$http.post('/base/upsertAcct', params);
        if (res.data.code === 0)
          await this.loadItem(pendingItem.acct_cd);
        await this.loadItems();

      } catch (e) {
        console.log(`Error on function onItemSave: ${e}`);
      }
    },
    async onItemDelete() {
      if (!confirm('삭제하시겠습니까?')) return;

      const params = new FormData();

      params.append("comp_sno", this.$store.state.session.compSno);
      params.append('acct_cd', this.pendingItem.acct_cd);

      let res = await this.$http.post('/base/deleteAcct', params);
      console.log("onItemDelete res", res);

      if(res.data.code == 0) {
        alert('삭제되었습니다.');

        this.pendingItem = genEmptyItem({
          item_sno: DUMMY_ITEM_SNO,
        });

        await this.loadItems();

      } else if(res.data.code == -9003) {
        alert('해당 계정으로 등록된 수금/지급이 있습니다. 삭제할 수 없습니다.');
      }

    },
    onItemCancel() {
      if (this.isOnPendingItem && !confirm('편집을 취소하시겠습니까?'))
        return;

      const restoreItem = this.restoreItem || this.item;
      this.item = JSON.parse(JSON.stringify(restoreItem));
      this.pendingItem = JSON.parse(JSON.stringify(restoreItem));
      this.restoreItem = null;
    },
    onSearchWordClick() {
      if(this.isOnPendingItem) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },
  },
}
</script>



<style>


</style>
