<template>
  <v-container>
    <v-row>
      <v-col cols="1">
        <import-ocr-image></import-ocr-image>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import {defineComponent} from 'vue';
import importOcrImage from "@/components/importOcrImage.vue";

export default defineComponent({
  name: "ocr",
  components: {
    importOcrImage,
  },
  data() {
    return {
      dialog: false,
    }
  }
});
</script>

<style scoped>
</style>
