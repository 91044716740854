<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="12" class="d-flex">
            <div class="">
              <h2>거래처 라벨</h2>
            </div>
            <v-btn small color="blue darken-2" class="ml-3 white--text font-weight-bold" v-show="!isFavorite" @click="$emit('addFavorite')">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="ml-3 font-weight-bold" v-show="isFavorite" @click="$emit('deleteFavorite')">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>
        <v-row dense align="center" class="d-flex align-center">
          <v-col cols="1"><div><span>조회수 : {{ filteredCustrs.length }} 건</span></div></v-col>
          <v-col cols="2">
            <div class="d-flex">
              <span class="pr-2">거래처</span>
              <v-text-field
                class=""
                hide-details
                dense
                outlined
                v-model="pendingWordCustr"
                @focusout="searchWordCustr = pendingWordCustr"
                @keyup.enter="searchWordCustr = pendingWordCustr"
                clearable
                @click:clear="searchWordCustr = ''"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="1"><div><span>선택수 : {{ filteredCustrLabels.length }} 건</span></div></v-col>
        </v-row>
        <v-row dense class="mt-0">
          <v-col cols="3">
            <v-row dense class="">
              <v-col cols="12">
                <wj-flex-grid 
                  :isReadOnly=true
                  :itemsSource="filteredCustrs"
                  :selectionMode="'ListBox'"
                  style="height:650px;"
                  :initialized="onflexGridInit"
                  :itemsSourceChanged="itemsSourceChanged"
                  :headersVisibility="'Column'"
                >
                  <wj-flex-grid-column :header="'번호'" :binding="'custr_sno'" width="3*" :visible="false"/>
                  <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="6*" />
                  <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="2*" />
                  <wj-flex-grid-column :header="'대표자'" :binding="'repr_nm'" width="4*" />
                </wj-flex-grid>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <div class="d-flex align-center justify-center pa-3">
              <v-btn color="blue darken-2" class="ml-3 white--text font-weight-bold" width="120px" height="135px" @click="onRegistSelectedClick" :disabled="!isRegistSelectedEnabled">선택 등록</v-btn>
            </div>
            <div class="d-flex align-center justify-center pa-3">
              <v-btn color="blue darken-2" class="ml-3 white--text font-weight-bold" width="120px" height="135px" @click="onRegistAllClick" :disabled="!isRegistAllEnabled">전체 등록</v-btn>
            </div>
            <div class="d-flex align-center justify-center pa-3">
              <v-btn color="blue darken-2" class="ml-3 white--text font-weight-bold" width="120px" height="135px" @click="onRemoveSelectedClick" :disabled="!isRemoveSelectedEnabled">선택 제외</v-btn>
            </div>
            <div class="d-flex align-center justify-center pa-3">
              <v-btn color="blue darken-2" class="ml-3 white--text font-weight-bold" width="120px" height="135px" @click="onRemoveAllClick" :disabled="!isRemoveAllEnabled">전체 제외</v-btn>
            </div>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col cols="12">
                <div>
                  <wj-flex-grid 
                    :isReadOnly=true
                    :itemsSource="filteredCustrLabels" 
                    :selectionMode="'ListBox'"
                    style="height:650px;"
                    :initialized="onFlexLabelInit"
                    :headersVisibility="'Column'"
                    >
                    <wj-flex-grid-column :header="'거래처명'" :binding="'custr_nm'" width="2*" />
                    <wj-flex-grid-column :header="'구분'" :binding="'custr_div_nm'" width="1*" />
                    <wj-flex-grid-column :header="'우편번호'" :binding="'biz_plc_zpcd'" width="1*" />
                    <wj-flex-grid-column :header="'주소'" :binding="'biz_plc_addr'" width="5*" />
                  </wj-flex-grid>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import {SUB_TAB} from "@/common/constant";

const DUMMY_CUSTR_SNO = '__dummy__';

export default {
  data() {
    return {
      compSno: null,
      userSno: null,

      searchWordCustr: "",
      pendingWordCustr: "",

      // filteredCustrs: [],   // 그리드에 표시되는 filter된 목록
      custrs: [],  // 서버에서 조회된 원본 목록
      custrSno: DUMMY_CUSTR_SNO,

      flexGrid: null,
      selectedRow: 0,
      selectedCol: 0,

      flexLabel: null,
      selectedLabelRow: 0,
      selectedLabelCol: 0,

      // custr: {},
    }
  },
  computed: {
    isFavorite() {
      return this.$store.state.favoriteTabs.some(({favor_path}) => favor_path === SUB_TAB.custrLabel.code);
    },
    filteredCustrs() {
      return this.custrs.filter(({custr_nm, added}) =>
          (!this.searchWordCustr || this.searchWordCustr.trim() === '' ||
            custr_nm && custr_nm.toUpperCase().includes(this.searchWordCustr.toUpperCase())) &&
          !added  
      );
    },
    filteredCustrLabels() {
      return this.custrs.filter(({added}) => added);
    },
    isRegistSelectedEnabled() {
      return this.flexGrid && this.flexGrid.selectedItems.length > 0;
    },
    isRegistAllEnabled() {
      return this.filteredCustrs.length > 0;
    },
    isRemoveSelectedEnabled() {
      return this.flexLabel && this.flexLabel.selectedItems.length > 0;
    },
    isRemoveAllEnabled() {
      return this.filteredCustrLabels.length > 0;
    }
  },
  watch: {
    async filteredCustrs(newValue) {
      // console.log("watch filteredSkinds:", newValue);
      // const [first] = newValue;
      // const custrSno = first && first.custr_sno || DUMMY_CUSTR_SNO;
      
      // 첫번째 row 선택 표시
      this.flexGrid.selectedRows.splice(0, this.flexGrid.selectedRows.length);
      this.flexGrid.select(0, this.selectedCol);
    },
    async filteredCustrLabels(newValue) {
      // const [first] = newValue;
      // const custrSno = first && first.custr_sno || DUMMY_CUSTR_SNO;

      this.flexLabel.select(0, 0);
    }
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);
    // console.log("created:", this.isFavorited);

    this.getCustrList();
  },
  methods: {
	  onflexGridInit(flex) {
      this.flexGrid = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        // console.log("flexDownEvent:", e);
        let ht = flex.hitTest(e);
        // console.log("ht:", ht/*, this.gridList[ht.row].custr_sno*/);       
        if(ht.cellType == 1 && ht.row >= 0) {

          this.selectedRow = ht.row;
          this.selectedCol = ht.col;
          this.custrSno = this.flexGrid.getCellData(ht.row, 0);
        }
      });
    },
    itemsSourceChanged(flex) {
      // console.log("itemsSourceChanged:", this.selectedRow);

    },
    onFlexLabelInit(flex) {
      this.flexLabel = flex;

      flex.addEventListener(flex.hostElement, "mousedown", e => {
        let ht = flex.hitTest(e);
     
        if(ht.cellType == 1 && ht.row >= 0) {

          this.selectedLabelRow = ht.row;
          this.selectedLabelCol = ht.col;
          // this.custrSno = this.flexGrid.getCellData(ht.row, 0);
        }
      });
    },

    getCustrList() {
      let params = new FormData();

      params.append("comp_sno", this.compSno);

      this.$http.post("/base/getCustrList", params).then((res) => {
        // console.log("res.data:", res.data);
        const custrs = res.data.custrList;

        custrs.forEach((e) => {
          e.added = false;
        });

        this.custrs = custrs;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    onRegistSelectedClick() {
      console.log("onRegistSelectedClick:", this.flexGrid.selectedItems);
      this.flexGrid.selectedItems.forEach((e) => {
        e.added = true;
      });
    },
    onRegistAllClick() {
      this.custrs.forEach((e) => {
        e.added = true;
      })
    },
    onRemoveSelectedClick() {
      this.flexLabel.selectedItems.forEach((e) => {
        e.added = false;
      });
    },
    onRemoveAllClick() {
      this.custrs.forEach((e) => {
        e.added = false;
      })
    },
  }
}
</script>

	   

<style>


</style>