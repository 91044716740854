<template>
  <v-container fluid class="d-flex align-center justify-center">
    <v-layout class="d-flex align-center justify-center">
      <div class="wid-100">
        <v-row dense align="center">
          <v-col cols="1">
            <div class="">
              <h2>강종 관리</h2>
            </div>
          </v-col>
          <v-col cols="11">
            <v-btn small color="blue darken-2" class="white--text" v-show="!isFavorited" @click="addFavorite">즐겨찾기 추가</v-btn>
            <v-btn small color="blue-grey lighten-5" class="" v-show="isFavorited" @click="deleteFavorite">즐겨찾기 삭제</v-btn>
          </v-col>
        </v-row>

        <div>
          <v-card class="pa-1 elevation-0" height="740px">
            <v-row dense align="center" class="d-flex align-center" >
              <v-col cols="1"><div><span>조회수 : {{skindCount}}건</span></div></v-col>
              <v-col cols="2">
                <div class="d-flex">
                  <span class="pr-2">강종</span>
                  <v-text-field
                    class=""
                    v-model="searchWord"
                    hide-details
                    dense
                    outlined
                    @keyup="searchWordKeyUp"
                    :readonly="isOnPendingSkind"
                    @click="onSearchWordClick"
                  ></v-text-field>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" align="right">
                <v-btn 
                  small 
                  color="blue darken-2" 
                  class="white--text"
                  :disabled="isNew"
                  @click="addSkindBtnClicked"
                  >강종 추가
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="3">
                <v-row class="">
                  <v-col cols="12">
                    <wj-flex-grid 
                      :isReadOnly=true
                      :itemsSource="gridList"
                      :selectionMode="'ListBox'"
                      style="height:650px;"
                      :initialized="flexInitialized"
                      :itemsSourceChanging="itemsSourceChanging"
                      :itemsSourceChanged="itemsSourceChanged"
                      :headersVisibility="'Column'"
                    >
                      <wj-flex-grid-column :header="'코드'" :binding="'skind_cd'" width="2*" />
                      <wj-flex-grid-column :header="'강종명'" :binding="'skind_nm'" width="5*" />
                      <wj-flex-grid-column :header="'사용'" :binding="'use_yn_disp'" width="2*" />
                      <wj-flex-grid-column :header="'순서'" :binding="'disp_odr'" width="2*" />
                    </wj-flex-grid>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="9">
                <v-row dense class="bgcolor-grey1">
                  <v-col cols="12" class="d-flex justify-end align-center hei-40p" >
                    <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" v-show="!isNew" @click="deleteBtnClicked">삭제</v-btn>
                    <v-btn small color="blue darken-2" class="pa-0 ma-0 mr-3 white--text" width="70" v-show="!isNew" :disabled="isOnPendingSkind">출력</v-btn>
                    <v-btn small color="blue-grey lighten-5" class="pa-0 ma-0 mr-3" width="70" @click="cancelBtnClicked" :disabled="!isOnPendingSkind && !isNew">취소</v-btn>
                    <v-btn small color="indigo darken-3" class="white--text" min-width="70" @click="saveBtnClicked" :disabled="!isOnPendingSkind">저장</v-btn>  
                  </v-col>

                  <v-col cols="12" class="pt-0 mt-0">
                    <v-card class="pa-1 mt-1 bgcolor-grey2" outlined>
                      <v-row dense>
                        <v-col cols="1">
                          <h4>상세 내용</h4>
                        </v-col>
                        <v-spacer></v-spacer>                     
                      </v-row>
                      <v-row dense class="hei-40p mt-0" align="center">
                        <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>강종코드</span></div></v-col>
                        <v-col cols="1" class="" >
                          <v-text-field
                            class=""
                            v-model="pendingSkind.skind_cd"
                            hide-details
                            dense
                            outlined
                            :disabled="!isNew"
                            :background-color="isNew ? 'white' : 'grey lighten-2'"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" align="left" class=""><div class=""><span>(영문자, 숫자 포함 2자리)</span></div></v-col>

                        <v-col cols="1" align="right" class=""><div class=""><span style="color:red;">* </span><span>강종명</span></div></v-col>
                        <v-col cols="2" class="" >
                          <v-text-field
                            class=""
                            v-model="pendingSkind.skind_nm"
                            hide-details
                            dense
                            outlined
                            background-color="white"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1" align="right" class=""><div class=""><span>재질</span></div></v-col>
                        <v-col cols="1" class="" >
                          <v-text-field
                            class=""
                            v-model="pendingSkind.skind_mtrl"
                            hide-details
                            dense
                            outlined
                            background-color="white"
                          ></v-text-field>
                        </v-col>
                        <v-col>ex) S45C, SKD-11</v-col>
                      </v-row>

                      <v-row dense class="hei-40p mt-0" align="center">
                        <v-col cols="1" align="right" class=""><div class=""><span>사용여부</span></div></v-col>
                        <v-col cols="1" class="" >
                          <v-checkbox
                            class="pa-0 ma-0"
                            v-model="pendingSkind.use_tf"
                            hide-details
                            dense
                            label="사용함"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="1" align="right" class=""><div class=""><span>원장재고</span></div></v-col>
                        <v-col cols="1" class="" >
                          <v-checkbox
                            class="pa-0 ma-0"
                            v-model="pendingSkind.ledgr_invnt_use_tf"
                            hide-details
                            dense
                            label="사용함"
                          ></v-checkbox>
                        </v-col>
                        
                        <v-col cols="1" align="right" class=""><div class=""><span>정렬순서</span></div></v-col>
                        <v-col cols="1" class="" >
                          <!-- <v-text-field
                            class=""
                            v-model="pendingSkind.disp_odr"
                            hide-details
                            dense
                            outlined
                            background-color="white"
                          ></v-text-field> -->
                          <numeric-text-field v-model="pendingSkind.disp_odr"></numeric-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import numericTextField from '@/components/common/numericTextField.vue';
// import * as wijmo from '@grapecity/wijmo';

const genEmptySkind = () => ({
  skind_cd: '',
  skind_nm: '',
  use_yn: '',
  use_tf: true,
  ledgr_invnt_use_yn: '',
  ledgr_invnt_use_tf: false,
  disp_odr: '',
});

export default {
  components: { numericTextField },
  props: ["pIsFavorited"],
  data() {
    return {
      compSno: null,
      userSno: null,
      isNew: true,
      isFavorited: this.pIsFavorited == null ? false : this.pIsFavorited == 'Y' ? true : false,
      getListMode: 'select',  // 'insert', 'update', 'delete'
      isDestroying: false,
      // isItemChanged: false,   // 항목 변경 여부

      gridList: [],   // 그리드에 표시되는 filter된 목록
      skindList: [],
      skindCount: 0,
      skindCd: null,

      flexGrid: null,
      // flexGridState: null,
      // gridFilter: null,
      isItemsSourceChanging: false,
      selectedRow: 0,
      
      searchWord: null, // 검색어

      skind: {},
      pendingSkind: {},

      // skind_cd: null,
      // skind_nm: null,
      // use_yn: false,
      // ledgr_invnt_use_yn: false,
      // disp_odr: null,
    }
  },
  computed: {
    isOnPendingSkind() {
      console.log("isOnPendingSkind:", this.skind, this.pendingSkind);
      return JSON.stringify(this.skind) !== JSON.stringify(this.pendingSkind);
    },
  },
  created() {

  },
  mounted() {
    this.compSno = this.$store.state.session.compSno;
    this.userSno = this.$store.state.session.userSno;
    // console.log("created:", this.compSno, this.userSno);

    this.getListMode = 'select';
    this.getSkindList();

    // this.gridFilter = new wjGridFilter.FlexGridFilter(this.flexGrid);
  },
  beforeDestroy() {
    // console.log("beforeDestroy");
    this.isDestroying = true;
  },
  destroyed() {
    // console.log("destroyed");
  },
  methods: {
	  flexInitialized(flexGrid) {
      this.flexGrid = flexGrid;

      flexGrid.addEventListener(flexGrid.hostElement, "mousedown", e => {
        // wijmo grid event
        // console.log("e", e);
        let ht = flexGrid.hitTest(e);
        // console.log("ht:", ht, ht.cellType, this.flexGrid.getCellData(ht.row, 0));       
        if(ht.cellType == 1 && ht.row >= 0) {   // cellType: 1(Cell), 2(Header), 5(Footer), 0(None)
          let isConfirmed = true;

          if(this.isOnPendingSkind) {
            if(!confirm("저장되지 않은 데이터가 있습니다. 이동하시겠습니까?")) {
              isConfirmed = false;
              this.flexGrid.select(this.selectedRow, this.selectedCol);
            }
          }

          if(isConfirmed) {
            this.selectedRow = ht.row;
            this.selectedCol = ht.col;
            this.skindCd = this.flexGrid.getCellData(ht.row, 0);

            this.getSkindDetail();
          }
        }
      });
    },
    // 강종 flexgrid 데이터 변경 전
    itemsSourceChanging() {
      if(this.flexGrid && !this.isDestroying) {
        this.isItemsSourceChanging = true;
        this.$saveFlexGridState(this.flexGrid);
      }
    },
    // flexgrid 데이터 변경됨
    itemsSourceChanged() {
      // console.log("itemsSourceChanged:", this.flexGrid, this.selectedRow, this.gridList.length, this.isItemsSourceChanging);
      if(this.flexGrid && !this.isDestroying) {
        this.$restoreFlexGridState(this.flexGrid);
      }

      if(this.gridList.length == 0) {
        this.isNew = true;
        this.initSkindInfo();
      }

      if(this.flexGrid != null && !this.isDestroying && this.gridList.length > 0 && this.flexGrid.collectionView.itemCount > 0) {
        // console.log("itemsSourceChanged:", this.flexGrid, this.selectedRow, this.gridList.length, this.isItemsSourceChanging, this.flexGrid.collectionView.itemCount);
        this.flexGrid.select(-1, 0);
        this.flexGrid.rows[0].isSelected = false;

        // 그리드 내 항목의 위치 설정
        // console.log("getListMode:", this.getListMode);
        if(this.getListMode == "select") {
          // 첫 항목
          this.selectedRow = 0;
          this.selectedCol = 0;
        } else if(this.getListMode == "insert" || this.getListMode == "update") {
          // 추가된 항목 
          for(let i = 0; i < this.gridList.length; i++) {
            if(this.gridList[i].skind_cd == this.skindCd) {
              this.selectedRow = i;
              break;
            }
          }
        } else if(this.getListMode == "delete") {
          // 다음 항목. 마지막인 경우 이전 항목
          this.selectedRow = this.selectedRow < this.gridList.length ? this.selectedRow : this.selectedRow - 1;
        }
      } 
      
      if(this.isItemsSourceChanging && this.flexGrid != null && !this.isDestroying && this.selectedRow >= 0 && this.flexGrid.collectionView.itemCount > 0) {
        // console.log("itemsSourceChanged - 1:", this.selectedRow);
        this.flexGrid.rows[0].isSelected = false;
        this.flexGrid.select(this.selectedRow, this.selectedCol);
        this.skindCd = this.flexGrid.getCellData(this.selectedRow, 0);
        // this.selectionMode.text = "Row";
        // this.flexGrid.rows[this.selectedRow].isSelected = true;  // row에 isSelected 설정 시 Ctrl + C -> isSelected row 가 복사됨, 마우스 드래그한 블럭 복사 안됨
        this.isItemsSourceChanging = false;
        this.getSkindDetail();
      }
    },
    
    addFavorite() {
      this.$emit('addFavorite');
      this.isFavorited = true;
    },
    deleteFavorite() {
      this.$emit('deleteFavorite');
      this.isFavorited = false;
    },
    searchWordKeyUp() {
      // console.log("searchWordKeyUp:", this.searchWord);
      this.gridList = this.skindList.filter((e) => {
        return e.skind_nm.indexOf(this.searchWord) > -1;
      });
    },
    onSearchWordClick() {
      if(this.isOnPendingSkind) {
        alert("수정 중에는 사용할 수 없습니다.");
      }
    },

    initSkindInfo() {
      const skind = genEmptySkind();
      this.skind = JSON.parse(JSON.stringify(skind, function(k, v) {return v == null ? "" : v}));
      this.pendingSkind = JSON.parse(JSON.stringify(this.skind));

      // this.skind_cd = null;
      // this.skind_nm = null;
      // this.use_yn = false;
      // this.ledgr_invnt_use_yn = false;
      // this.disp_odr = null;

      // // this.isNew = true;
      // this.isItemChanged = false;
    },
    getSkindList() {
      // console.log("getSkindList");
      // this.isItemsSourceChanging = true;
      // this.$saveFlexGridState(this.flexGrid);

      let params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("all", 'Y');

      this.$http.post("/base/getSteelKindList", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        res.data.skindList.forEach((e) => {
          e.use_yn_disp = e.use_yn == 'Y' ? '사용' : '미사용';
        });

        this.skindList = res.data.skindList;
        this.gridList = res.data.skindList;
        this.skindCount = this.skindList.length;
      }).catch(
        function (error) {
          console.log(error);
          alert(error.message);
        }
      )
    },
    getSkindDetail() {
      const params = new FormData();

      params.append("comp_sno", this.compSno);
      params.append("skind_cd", this.skindCd);

      this.$http.post("/base/getSteelKindDetail", params).then((res) => {
        console.log("res.code:", res.data.code);
        // console.log("res.data:", res.data);
        const skind = res.data.skind;

        skind.use_tf = skind.use_yn == 'Y' ? true : false;
        skind.ledgr_invnt_use_tf = skind.ledgr_invnt_use_yn == 'Y' ? true : false;

        this.skind = JSON.parse(JSON.stringify(skind, function(k, v) {return v == null ? "" : v}));
        this.pendingSkind = JSON.parse(JSON.stringify(this.skind));

        this.isNew = false;

        // // this.comp_sno = skind.comp_sno;
        // this.skind_cd = skind.skind_cd;
        // this.skind_nm = skind.skind_nm;
        // this.disp_odr = skind.disp_odr;
        // this.use_yn = skind.use_yn == 'Y' ? true : false;
        // this.ledgr_invnt_use_yn = skind.ledgr_invnt_use_yn == 'Y' ? true : false;

        // this.isNew = false;
        // this.isItemChanged = false;
      }).catch((error) => {
        console.log(error);
        alert(error.message);
      });
    },
    rowClicked(item) {
      console.log("item", item);

    },
    addSkindBtnClicked() {
      // console.log("selectedRow:", this.selectedRow);
      let isConfirmed = true;

      if(this.isOnPendingSkind) {
        if(!confirm("저장되지 않은 데이터가 있습니다. 새 강종을 추가하시겠습니까?")) {
          isConfirmed = false;
          this.flexGrid.select(this.selectedRow, this.selectedCol);
        }
      }

      if(isConfirmed) {
        this.flexGrid.select(-1, 0);
        this.flexGrid.rows[this.selectedRow].isSelected = false;
        // this.selectedRow = -1;

        this.isNew = true;
        this.initSkindInfo();
      } 
    },
    validateInput() {
      // 강종코드
      if(this.pendingSkind.skind_cd == null || this.pendingSkind.skind_cd == "") {
        alert('강종코드는 필수 입력값입니다.');
        return false;
      }

      const regex = /^[A-Za-z0-9]*$/;
			if (this.pendingSkind.skind_cd.length != 2 || regex.test(this.pendingSkind.skind_cd) == false) {
        alert('강종코드는 영문자, 숫자 포함 2자리입니다.');
        return false;
      }
      
      // 강종명
      if(this.pendingSkind.skind_nm == null || this.pendingSkind.skind_nm == "") {
        alert('강종명은 필수 입력값입니다.');
        return false;
      }

      return true;
    },
    saveBtnClicked() {
      // 입력 데이터 검증
      const isValid = this.validateInput();
      // const isValid = true;
      
      if(isValid) {
      // if(confirm("저장하시겠습니까?")) {
        const params = new FormData();

        params.append("new_yn", this.isNew ? 'Y' : 'N');
        params.append("user_sno", this.userSno);
        params.append("comp_sno", this.compSno);

        this.pendingSkind.use_yn = this.pendingSkind.use_tf ? 'Y' : 'N';
        this.pendingSkind.exch_aply_yn = this.pendingSkind.exch_aply_tf ? 'Y' : 'N';

        params.append('skind', JSON.stringify(this.pendingSkind));

        // params.append("skind_cd", this.skind_cd);
        // params.append("skind_nm", this.skind_nm);
        // params.append("use_yn", this.use_yn == true ? 'Y' : 'N');
        // params.append("ledgr_invnt_use_yn", this.ledgr_invnt_use_yn == true ? 'Y' : 'N');

        // if(this.disp_odr != null) params.append("disp_odr", this.disp_odr);

        this.$http.post("/base/upsertSteelKind", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("저장되었습니다.");

          if(this.isNew) {
            this.getListMode = 'insert';
          } else {
            this.getListMode = 'update';
          }

          this.getSkindList();

          this.isNew = false;
          // this.isItemChanged = false;
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    deleteBtnClicked() {
      if(confirm("삭제하시겠습니까?")) {
        const params = new FormData();

        params.append("comp_sno", this.compSno);
        params.append("skind_cd", this.skindCd);
        
        this.$http.post("/base/deleteSteelKind", params).then((res) => {
          console.log("res.code:", res.data.code);
          // console.log("res.data:", res.data);
          alert("삭제되었습니다.");
          
          this.getListMode = 'delete';
          
          this.getSkindList();
          // this.initSkindInfo();
        }).catch((error) => {
          console.log(error);
          alert(error.message);
        });
      }
    },
    cancelBtnClicked() {
      let isConfirmed = true;

      if(this.isOnPendingSkind) {
        if(!confirm("편집을 취소하시겠습니까?")) {
          isConfirmed = false;
        }
      }

      if(isConfirmed) {
        console.log("isConfirmed:", this.selectedRow, this.selectedCol);
        this.flexGrid.select(this.selectedRow, this.selectedCol);
        this.getSkindDetail();
      }
    },
  }
}
</script>

	   

<style>


</style>